import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { DialogContentText } from '@mui/material';
import alertImg from '../../images/alert.svg';

interface DialogBoxProps {
  showDialog: boolean
  cancelNavigation: any
  confirmNavigation: any
}

const DialogBox: React.FC<DialogBoxProps> = ({
  showDialog,
  cancelNavigation,
  confirmNavigation,
}) => {
  return (
    <Dialog open={showDialog} className="ConfirmModelL">
      <DialogContent className='text-center'>
        <DialogContentText id="alert-dialog-description"><img id="fontSet-0" className="img-fluid mb-3" src={alertImg} /> <br></br>Are you sure you want to leave this page? <br></br> All unsaved changes will be lost.</DialogContentText>
      </DialogContent>
      <DialogActions >
        <Button className="conFirmYes" color="primary" autoFocus onClick={confirmNavigation}>
          yes
        </Button>
        <Button className="conFirmNo" color="primary" onClick={cancelNavigation}>
          no
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default DialogBox
