import { Navigate, Outlet, useLocation } from 'react-router-dom';

const SetPasswordRoutes = () => {
    const search = useLocation().search;
    let token = localStorage.getItem("accessToken");
    const email = new URLSearchParams(search).get("Email");
    return (
        !token && email && email.length !== 0 ? < Outlet /> : <Navigate to="/" />
    )
};

export default SetPasswordRoutes;