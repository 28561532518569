import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Loader } from "../Components/Shared/Loader";
import closeImage from "../images/close.svg";
import { closeModal, showModal, userAuthenticate } from "../redux/Actions/action";
import ForgotPassword from "./ForgotPassword";
import "./Header.css";
import AuthenticationModal from "./Shared/AuthenticationModal";
import InputTextField from "./Shared/InputTextField";

/**
 * Functional component for login modal
 * @param {*} { onClose, loginOpen, userName, userRole, imageName }
 * @return {*} 
 */
function LoginModal({ onClose, loginOpen, userName, userRole, imageName }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayLoader, setDisplayLoader] = useState(false);
  const [rememberMe, setRememberMe] = useState("");
  const [error, setError] = useState({});

  const [login, setLogin] = useState({
    email: localStorage.getItem("userName")?.trim() || "",
    password: localStorage.getItem("password") || "",
  });

  const loginModal = useSelector((state) => state.userReducer.modal);

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      setError(validate(login));
    }
    let rem = localStorage.getItem("rememberMeEnabled") == "true" ? 1 : 0;
    setRememberMe(rem);
    if (rem == 1) {
      setCredentials();
    }
  }, [loginOpen]);

  /**
   * This method is used to submit the login details
   * @param {*} e
   */
  const loginClickSubmit = (e) => {
    e.preventDefault();

    if (rememberMe == 1) {
      localStorage.setItem("rememberMeEnabled", "true");
      storeCredentials();
    } else {
      localStorage.setItem("rememberMeEnabled", "false");
    }

    setError(validate(login));
    if (login.email?.trim().length > 0 && login.password?.trim().length > 0) {
      setDisplayLoader(true);
      dispatch(userAuthenticate(login, onCloseModal, loginInvalidCredentials));
    }
  };

  /**
   * This method is used to validate the input fields
   * @param {*} values
   * @return {*} 
   */
  const validate = (values) => {
    let error = {};
    const regex = /^(?!\.)[a-zA-Z0-9-]+(?!.*?\.\.)[a-zA-Z0-9-_.]+(?!.*\.$)+@[a-zA-Z0-9-]+[a-z.0-9]+\.[a-z.]{2,4}$/;
    if (values.email.trim() == "" || values.email == undefined) {
      error.email = "Please provide valid Email...";
    } else if (!regex.test(values.email)) {
      error.email = "Please provide valid Email format...";
    }
    if (values.password == "" || values.password == undefined) {
      error.password = "Please provide valid Password...";
    }
    return error;
  };

  /**
   * This method will call when error occurs
   */
  const loginInvalidCredentials = () => {
    setDisplayLoader(false);
  };

  /**
   * This method is used to change the remember me checkbox
   * @param {*} event
   */
  const onRememberMeChange = (event) => {
    if (event.target.checked == false) {
      setRememberMe(0);
      localStorage.clear();
    } else {
      setRememberMe(1);
    }
  };

  /**
   * This method will call after successful login 
   * @param {*} res
   */
  const onCloseModal = (res) => {
    setDisplayLoader(false);
    dispatch(closeModal("login"));
    setLogin({});
    setError({});
    if (localStorage.getItem("roleId") == 1) {
      if (localStorage.getItem("switchUser") == "true") {
        navigate("/styleguide");
      } else {
        navigate("admin/viewedit");
      }
    } else {
      navigate("/styleguide");
    }
  };

  /**
   * This method is used to open the forgot password modal and close the login modal
   */
  const onOpen = () => {
    dispatch(closeModal("login"));
    dispatch(showModal("forgotpassword"));
  };

  /**
   * This method is used to change the values in login modal
   * @param {*} e
   */
  const onLoginChange = (e) => {
    let { name, value } = e.target;
    setLogin({ ...login, [name]: value });
  };

  /**
   * This method is used to set the values
   * @param {*} e
   */
  const onLoginBlur = (e) => {
    let { name, value } = e.target;
    setLogin({ ...login, [name]: value });
    if (e.target.value != localStorage.getItem("userName")) {
      localStorage.removeItem("userName");
      localStorage.setItem("userName", e.target.value);
    }
  };

  /**
   * This method is used to store the credentials
   */
  const storeCredentials = () => {
    localStorage.setItem("userName", login.email.trim());
  };

  /**
   * This method is set the credentials
   */
  const setCredentials = () => {
    login.email = localStorage.getItem("userName");
  };

  /**
   * This method will call after user clicks on close icon
   */
  const onCloseLoginModal = () => {
    setError({});
    dispatch(closeModal("login"));

    if (rememberMe == 1) {
      setLogin({ email: localStorage.getItem("userName"), password: "" });
    } else {
      setLogin({});
    }
  };

  return (
    <Fragment>
      <AuthenticationModal open={loginModal.login} onClose={onCloseModal} ariaHideApp={false}>
        <form onSubmit={loginClickSubmit}>
          <div className="container LoginModal p-0 m-0 justify-content-between">
            <img src={closeImage} onClick={onCloseLoginModal} alt="Close" className="userLoginModel" />
            <div className="row justify-content-between">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 my-auto">
                <h6 className="loginHeader">Please Login</h6>
                <div className="col-12">
                  <div>
                    <label className="loginLabels">
                      Username<span className="required-Felid"> *</span>
                    </label>
                  </div>
                  <InputTextField
                    type="text"
                    className="loginInput w-100 mb-3"
                    name="email"
                    onChange={onLoginChange}
                    onBlur={onLoginBlur}
                    value={login.email}
                    error={error.email}
                  />
                </div>
                <div className="col-12">
                  <div>
                    <label className="loginLabels">
                      Password<span className="required-Felid"> *</span>
                    </label>
                  </div>
                  <InputTextField
                    type="password"
                    className="loginInput w-100 mb-3"
                    name="password"
                    onChange={onLoginChange}
                    value={login.password}
                    error={error.password}
                  />
                </div>
                <div className="row forgotPasswordAndRemember p-0 m-0 justify-content-between align-items-center">
                  <div className="d-flex align-items-center m-0 p-0 col-md-6 col-lg-6 col-sm-6 col-xs-12">
                    <input
                      type="checkbox"
                      checked={rememberMe == 1 ? true : false}
                      onChange={onRememberMeChange}
                      className="col-1"
                      name="remember"
                      id="remember"
                    />
                    <div className="col-9 space-nowrap">
                      <label htmlFor="remember" className="rememberMe">
                        &nbsp;Remember me
                      </label>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-6 col-xs-12 space-nowrap px-0">
                    <span className="d-flex justify-content-end">
                      <div onClick={onOpen} className="forgotpassword m-0">
                        Forgot Password?
                      </div>
                    </span>
                  </div>
                  <div className="col-lg-12 pt-3 my-2" align="center">
                    <button className="model btnModel loginBtn">LOGIN</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <div>{displayLoader ? <Loader /> : <div></div>}</div>
      </AuthenticationModal>
      <ForgotPassword onClose={onCloseModal} />
    </Fragment>
  );
}

export default LoginModal;
