import MaterialTable from "@material-table/core";
import { Check, Clear } from "@material-ui/icons";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import deleteImg from "../../../images/delete.svg";
import {
  addAssetType,
  deleteListType,
  getAssetList,
  getAssetTypes,
  updateAssetType
} from "../../../redux/Actions/action";
import { Loader } from "../../Shared/Loader";
import "./BrandAssetsLists.css";

/* Icons used in the material table */
const tableIcons = {
  Add: () => <span className="applySettingsBtn"> ADD TYPE </span>,
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: () => (
    <div>
      <img alt="" src={deleteImg} />
    </div>
  ),
};

toast.configure({
  autoClose: 2000,
});

/**
 * Brand Assets List Functional Component
 * @return {*}
 */
const BrandAssetsLists = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  // state for assetId and assetType
  const [selectedAssetId, setSelectedAssetId] = useState("");
  const [selectedAssetType, setSelectedAssetType] = useState([""]);

  const assetTypesList = useSelector((state) => state.assetReducer.getAllAssets);
  const assetRecords = useSelector((state) => state.assetReducer.getAssetRecords);

  useEffect(() => {
    localStorage.setItem("switchUser", "false");
    localStorage.removeItem("orgType");
    localStorage.removeItem("brandAssetsOptionsName");
    localStorage.removeItem("templatesOptionsName");
    localStorage.removeItem("schoolMenuIndex");
    sessionStorage.removeItem("primaryValue");
    sessionStorage.removeItem("secondaryValue");
    sessionStorage.removeItem("gradientValue");
    localStorage.setItem("orgName", "Super Admin");
    
    if (localStorage.getItem("roleId") === "1") {
      dispatch(getAssetList(successHandler, errorHandler));
    } else {
      navigate("/");
      localStorage.setItem("schoolMenuIndex", 0);
    }
  }, [assetRecords.length]);

  const brandAssetsArray = [];
  const templatesArray = [];

  assetTypesList.map((item) => {
    if (item.assetType === "Brand Assets") {
      brandAssetsArray.push(item);
    } else if (item.assetType === "Templates") {
      templatesArray.push(item);
    }
  });

  /**
   * This method is called when user clicks on the list individual row
   * @param {*} e
   * @param {*} rowData
   */
  const handleRowClick = (e, rowData) => {
    dispatch(getAssetTypes(rowData.id), successHandler, errorHandler);
    setSelectedAssetId(rowData.id);
    setSelectedAssetType(rowData.name + " Options");
  };

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method is called after successful assetType deletion
   * @param {*} oldData
   */
  const deleteSuccessHandler = (oldData) => {
    setTimeout(() => {
      dispatch(getAssetTypes(oldData.asset_id), successHandler, errorHandler);
    }, 500);
  };


  /* Preparing an array of existing assetType names */
  let uniqueArray = [];
  for (let index = 0; index < assetRecords.length; index++) {
    const element = assetRecords[index];
    uniqueArray.push(element?.logoTypeName);
  }

  return (
    <div className="row mt-3 assetsList">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12 pb-2">
        <h6 className="titLe py-2 m-0 my-auto mainTitle">
          Assets <span> - List</span>
        </h6>
      </div>
      <div className="col-lg-3 assetTypeList">
        <h6 className="m-0 py-2 mt-1">Brand Assets</h6>
        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
          <MaterialTable
            title={""}
            onRowClick={handleRowClick}
            columns={[
              {
                title: "",
                field: "name",
                cellStyle: {
                  textTransform: "capitalize",
                },
              },
            ]}
            options={{
              search: false,
              sorting: false,
              emptyRowsWhenPaging: false,
              paging: false,
              draggable: false,
              headerStyle: {
                color: "#15355C ",
                fontWeight: "bolder",
              },
              rowStyle: (data, index) => {
                let bgColor = index % 2 === 0 ? "#F2F4F6" : "white";
                let color = "#000";
                let fs = "";
                let fw = "";
                let ff = "";
                if (Number(selectedAssetId) === data.id) {
                  color = "#000000";
                  fs = "12px";
                  fw = "bold";
                  ff = "roboto-bold";
                }
                return {
                  backgroundColor: bgColor,
                  color: color,
                  fontSize: fs,
                  fontWeight: fw,
                  fontFamily: ff,
                };
              },
              cellStyle: {
                padding: "0px",
                paddingLeft: "15px",
              },
            }}
            components={{
              Container: (props) => (
                <div>
                  <div {...props} />
                </div>
              ),
            }}
            data={brandAssetsArray}
            actions={[]}
          />
        </div>
        <h6 className="m-0 py-2">Templates</h6>
        <div style={{ maxHeight: "200px", overflow: "scroll" }}>
          <MaterialTable
            title={""}
            onRowClick={handleRowClick}
            columns={[
              {
                title: "Templates",
                field: "name",
                cellStyle: {
                  textTransform: "capitalize",
                },
              },
            ]}
            options={{
              search: false,
              sorting: false,
              emptyRowsWhenPaging: false,
              paging: false,
              draggable: false,
              headerStyle: {
                color: "#15355C ",
                fontWeight: "bolder",
              },
              rowStyle: (data, index) => {
                let bgColor = index % 2 === 0 ? "#F2F4F6" : "white";
                let color = "#000";
                let fs = "";
                let fw = "";
                let ff = "";
                if (Number(selectedAssetId) === data.id) {
                  color = "#000000";
                  fs = "12px";
                  fw = "bold";
                  ff = "roboto-bold";
                }
                return {
                  backgroundColor: bgColor,
                  color: color,
                  fontSize: fs,
                  fontWeight: fw,
                  fontFamily: ff,
                };
              },
              cellStyle: {
                padding: "0px",
                paddingLeft: "15px",
              },
            }}
            components={{
              Container: (props) => (
                <div>
                  <div {...props} />
                </div>
              ),
            }}
            data={templatesArray}
            actions={[]}
          />
        </div>
      </div>
      {selectedAssetType.length > 1 ? (
        selectedAssetType === "Fonts Options" ? (
          <>
            <div className="col-lg-9 assetsList fontOptionList" id="assetsListTypes">
              <h1 className="text-center m-0 py-2 mt-5" style={{ fontFamily: "roboto-bold", fontSize: "14px" }}>
                Configure Fonts in Organization portal
              </h1>
            </div>
          </>
        ) : selectedAssetType === "Colours Options" ? (
          <>
            <div className="col-lg-9 assetsList fontOptionList" id="assetsListTypes">
              <h1 className="text-center m-0 py-2 mt-5" style={{ fontFamily: "roboto-bold", fontSize: "14px" }}>
                Configure Colours in Organization portal
              </h1>
            </div>
          </>
        ) : (
          <div className="col-lg-9 assetsList fontOptionList" id="assetsListTypes">
            <MaterialTable
              title={selectedAssetType}
              icons={tableIcons}
              columns={[
                {
                  title: "Asset Type",
                  field: "logoTypeName",
                  validate: (rowData) => {
                    const regex = new RegExp(/[^a-zA-Z0-9 ]/);
                    if (rowData.length == undefined) {
                      if (rowData?.logoTypeName?.trim().length > 50) {
                        return "MaxLength 50";
                      } else if (regex.test(rowData?.logoTypeName?.trim())) {
                        return "No special Char";
                      }
                      return true;
                    }

                    if (rowData.length != undefined) {
                      if (rowData.trim().length === 0) {
                        return "Required";
                      } else if (rowData.trim().length > 50) {
                        return "MaxLength 50";
                      } else if (regex.test(rowData?.trim())) {
                        return "No special Char";
                      }
                      return true;
                    }
                  },
                  headerStyle: {
                    color: "#AAAAAA",
                  },
                  cellStyle: {
                    color: "#000000",
                    textTransform: "capitalize",
                  },
                },
                {
                  title: "Size",
                  field: "defaultSizeCms",
                  validate: (rowData) => {
                    if (rowData.length == undefined) {
                      if (rowData?.defaultSizeCms?.trim().length > 20) {
                        return "MaxLength 20 Char";
                      }
                      return true;
                    }

                    if (rowData.length != undefined) {
                      if (rowData?.trim().length > 20) {
                        return "MaxLength 20 Char";
                      }
                      return true;
                    }
                  },
                  headerStyle: {
                    color: "#AAAAAA",
                  },
                },
                {
                  title: "Size (dpi)",
                  field: "defaultSizeDpt",
                  headerStyle: {
                    color: "#AAAAAA",
                  },
                },
                // {
                //   title: "File Type",
                //   field: "defaultFileType",
                //   // validate: (rowData) => {
                //   //   if (rowData.length != undefined) {
                //   //     if (rowData.trim().length == 0) {
                //   //       return "Required";
                //   //     }
                //   //     return true;
                //   //   }
                //   // },
                //   headerStyle: {
                //     color: "#AAAAAA",
                //   },
                // },
                {
                  title: "Comments",
                  field: "comments",
                  headerStyle: {
                    color: "#AAAAAA",
                  },
                  cellStyle: {
                    textTransform: "capitalize",
                  },
                },
              ]}
              cellEditable={{
                onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                  return new Promise((resolve, reject) => {
                    rowData[columnDef.field] = newValue;
                    // const regexExp = /[0-9]\X[0-9]/;
                    if (newValue.trim().length === 0) {
                      rowData[columnDef.field] = "-";
                      dispatch(updateAssetType(rowData, selectedAssetId));
                    } else {
                      dispatch(updateAssetType(rowData, selectedAssetId));
                    }
                    resolve();
                  });
                },
              }}
              options={{
                search: false,
                sorting: false,
                emptyRowsWhenPaging: false,
                paging: false,
                draggable: false,
                actionsColumnIndex: -1,
                addRowPosition: "last",
                headerStyle: {
                  color: "#15355C",
                  fontSize: "15px",
                  fontWeight: "bold",
                },
                rowStyle: (data, index) => {
                  let bgColor = index % 2 === 0 ? "#F2F4F6" : "white";
                  let color = "darkblue";
                  return {
                    backgroundColor: bgColor,
                    color: color,
                  };
                },
                cellStyle: {
                  padding: "0px",
                  paddingLeft: "15px",
                },
              }}
              components={{
                Container: (props) => (
                  <div className="ps-2">
                    <div {...props} />
                  </div>
                ),
              }}
              data={assetRecords}
              actions={[]}
              editable={{
                onRowAdd: (newData) =>
                  new Promise((resolve, reject) => {
                    if (newData.logoTypeName.trim().length > 0) {
                      dispatch(addAssetType(newData, selectedAssetId));
                      resolve();
                    } else {
                      <div className="warningMsg">
                        {toast.warning(`Please Provide Valid ${selectedAssetType.split(" ")[0]} Type Name`)}
                      </div>;
                      reject();
                    }
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve, reject) => {
                    dispatch(deleteListType(oldData.logo_id, deleteSuccessHandler(oldData), errorHandler));
                    resolve();
                  }),
              }}
            />
          </div>
        )
      ) : (
        ""
      )}

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
};

export default BrandAssetsLists;
