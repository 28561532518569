import $ from "jquery";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import closeImage from "../../../images/close.png";
import Icon from "../../../images/logoheader.svg";
import {
  getAssetTypes,
  getGroupLogos,
  getGroupNames,
  isGroupNameExisted,
  UpdateAssetDetails,
} from "../../../redux/Actions/action";
import InputTextField from "../InputTextField";
import { Loader } from "../Loader";
import SuggestionsListComponent from "../Suggestion";
import "./LogoUploadModal.css";

toast.configure({
  autoClose: 1000,
});

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
};

/**
 * Functional component for BrandAssetEditDetailsModal
 * @param {*} { showAssetDetailsModal, setShowAssetDetailsModal }
 * @return {*} 
 */
const BrandAssetEditAssetDetailsModal = ({ showAssetDetailsModal, setShowAssetDetailsModal }) => {
  const dispatch = useDispatch();
  const [assetsData, setAssetsData] = useState([]);
  const [groupName, setGroupName] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(false);
  const [error, setError] = useState({});

  /* for suggestions */
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);

  const assetDetailsFormData = new FormData();

  let dynamicName = localStorage.getItem("brandAssetsOptionsName");
  let adminAssetsData = [];
  let optionsData = [];

  const brandAssets = useSelector((state) => state.assetReducer.getBrandAssets);
  const groupNames = useSelector((state) => state.userReducer.GroupNames);
  const assetRecords = useSelector((state) => state.assetReducer.getAssetRecords);

  adminAssetsData = useSelector((state) => state.userReducer.GroupLogosData);

  useEffect(() => {
    let reqObj = {
      name: localStorage.getItem("orgName"),
      assetType: dynamicName,
    };
    dispatch(getGroupNames(reqObj, successHandler, errorHandler));

    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: dynamicName,
    };
    dispatch(getGroupLogos(obj));
    const filteredData = adminAssetsData?.filter((item) => item.groupName == localStorage.getItem("genericGroupName"));
    setGroupName(filteredData[0]?.groupName);
    setAssetsData(filteredData);
    if (brandAssets) {
      let assetObj = brandAssets.filter((x) => x.name.trim() == dynamicName);
      let assetId = parseInt(assetObj[0]?.id);

      if (assetId) {
        dispatch(getAssetTypes(assetId), successHandler, errorHandler);
      }
    }
  }, [dynamicName]);

  /**
   * This method will call after Successful function execution
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /* filtered assetType Options data   */
  for (let i = 0; i < assetRecords.length; i++) {
    optionsData.push({
      id: assetRecords[i].logo_id,
      name: assetRecords[i].logoTypeName,
    });
  }

  /**
   *This method is used to validate the Group Name 
   * @param {*} values
   * @return {*} 
   */
  const validate = (values) => {
    let error = {};
    const regex1 = /^[\d]*[a-z_ ][a-z \d_]*$/gi;
    if (values == "") {
      error.groupName = "Please provide Group Name...";
    } else if (!regex1.test(values.trim())) {
      error.groupName = "Group Name should be alphanumeric";
    } else if (values.trim().length < 3) {
      error.groupName = "Group Name should be between 3 to 50 characters";
    }
    return error;
  };

  /**
   * This method is used to set the Group Name Error to the Error Object
   */
  const setErrorForGroupName = () => {
    let error = {};
    error.groupName = `Group Name Already Exists`;
    setError(error);
  };

  /**
   *This method is used for close Modal 
   */
  const closeAssetDetailsModal = () => {
    setShowAssetDetailsModal(false);
    setFilteredSuggestions([]);
  };

  /**
   *This method is used to set the Group Name 
   * @param {*} evt
   */
  const groupNameChangeHandler = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    const userInput = evt.target.value.trim();
    // Filter our suggestions that don't contain the user's input
    const unLinked = groupNames.filter((suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1);
    setFilteredSuggestions(unLinked);
    setActiveSuggestionIndex(0);
    setShowSuggestions(true);
    setGroupName(evt.target.value);
    setError(validate(evt.target.value.trim()));
  };

  /**
   * This method is used to check the Group Name Existence
   * @param {*} e
   */
  const onGroupNameBlur = (e) => {
    setGroupName(e.target.value);
    const obj = {
      name: localStorage.getItem("orgName"),
      groupName: e.target.value.trim(),
      assetType: dynamicName,
    };
    setError(validate(e.target.value.trim()));
    setTimeout(() => {
      setShowSuggestions(false);
      dispatch(isGroupNameExisted(obj, setErrorForGroupName));
    }, 500);
  };

  /**
   * onClicking suggestions this method will fired
   * @param {*} e
   */
  const onSuggestionClick = (e) => {
    setFilteredSuggestions([]);
    setGroupName(e.target.textContent.trim());
    setActiveSuggestionIndex(0);
    setShowSuggestions(false);
    setError(validate(e.target.textContent.trim()));
    const obj = {
      name: localStorage.getItem("orgName"),
      groupName: e.target.textContent,
      assetType: dynamicName,
    };
    dispatch(isGroupNameExisted(obj, setErrorForGroupName));
  };

  /**
   * This method is used to change the file type
   * @param {*} event
   * @param {*} index
   */
  const fileTypeChange = (event, index) => {
    let sel = document.getElementById(`select-${index}`);
    let text = sel.options[sel.selectedIndex].text;

    event.preventDefault();
    const filteredRecord = assetRecords.filter((x) => x.logoTypeName == text);
    setTimeout(() => {
      document.getElementById(`txtWidth-${index}`).value = filteredRecord[0].defaultSizeCms;
      document.getElementById(`txtDpi-${index}`).value = filteredRecord[0].defaultSizeDpt;
    }, 100);
  };

  /**
   * This method is used for Update the asset details
   * @param {*} e
   * @return {*} 
   */
  const updateAssetDetails = (e) => {
    e.preventDefault();
    const regex1 = /^[\d]*[a-z_ ][a-z \d_]*$/gi;
    if (groupName.trim().length == 0) {
      <div className="warningMsg">{toast.warning("Please provide Group Name...")}</div>;
      return;
    } else if (!regex1.test(groupName.trim())) {
      <div className="warningMsg">{toast.warning("Group Name should be alphanumeric...")}</div>;
      return;
    } else if (groupName.trim().length < 3) {
      <div className="warningMsg">{toast.warning("Group Name should be between 3 to 50 characters...")}</div>;
      return;
    }

    let selectedOptions = [];
    if (assetsData.length > 0) {
      if (dynamicName == "Approved Images") {
        let approvedImagesFileNameList = [];
        for (let index = 0; index < assetsData.length; index++) {
          let sel = document.getElementById(`select-${index}`);
          let text = sel.options[sel.selectedIndex].text;
          let obj = text + assetsData[index].fileExtension;

          let approvedImagesElement = document.getElementById(`approvedImages-${index}`);
          if (approvedImagesElement) {
            if ((approvedImagesElement.value).trim().length == 0) {
              <div className="warningMsg">{toast.warning("Please provide file Name...")}</div>;
              return;
            } else if ((approvedImagesElement.value).trim().length < 3) {
              <div className="warningMsg">{toast.warning("File Name should be between 3 to 50 characters...")}</div>;
              return;
            } else if ((approvedImagesElement.value).trim().length > 50) {
              <div className="warningMsg">{toast.warning("File Name should be between 3 to 50 characters...")}</div>;
              return;
            }
          }
          approvedImagesFileNameList.push(approvedImagesElement.value.trim().toLowerCase() + obj)
        }
        console.log(approvedImagesFileNameList);
        let uniqueFileNameArray = Array.from(new Set(approvedImagesFileNameList));
        if (uniqueFileNameArray.length != approvedImagesFileNameList.length) {
          <div className="warningMsg">{toast.warning("File Name should be unique...")}</div>;
          return;
        }
      } else {
        for (let i = 0; i < assetsData.length; i++) {
          let sel = document.getElementById(`select-${i}`);
          let text = sel.options[sel.selectedIndex].text;

          let obj = text + assetsData[i].fileExtension;
          selectedOptions.push(obj);
        }

        const toFindDuplicates = (selectedOptions) =>
          selectedOptions.filter((item, index) => selectedOptions.indexOf(item) !== index);
        const duplicateElements = toFindDuplicates(selectedOptions);

        for (let index = 0; index < duplicateElements.length; index++) {
          if (duplicateElements.length > 0) {
            return <div className="warningMsg">{toast.warning("Please select unique file type")}</div>;
          }
        }
      }
    }

    const assetDetailsList = [];
    let camelCaseGroup = groupName.charAt(0).toUpperCase() + groupName.slice(1);

    for (let i = 0; i < assetsData.length; i++) {
      let sel = document.getElementById(`select-${i}`);
      let text = sel.options[sel.selectedIndex].text;

      const filteredRecord = assetRecords.filter((x) => x.logoTypeName == text);
      if (dynamicName == "Approved Images") {
        assetDetailsList.push({
          fileName: document.getElementById(`approvedImages-${i}`).value,
          fileType: filteredRecord.length > 0 ? filteredRecord[0].logoTypeName : assetsData[i].fileType,
          fileSize: document.getElementById(`txtWidth-${i}`).value,
          dpi: document.getElementById(`txtDpi-${i}`).value,
          assetId: assetsData[i].id,
          externalUse: document.getElementById(`externalUse-${i}`).checked,
          groupThumbnail: document.getElementById(`groupThumbnailRadio-${i}`).checked,
          comments: document.getElementById(`comments-${i}`).value,
        });
      } else {
        assetDetailsList.push({
          fileType: filteredRecord.length > 0 ? filteredRecord[0].logoTypeName : assetsData[i].fileType,
          fileSize: document.getElementById(`txtWidth-${i}`).value,
          dpi: document.getElementById(`txtDpi-${i}`).value,
          assetId: assetsData[i].id,
          externalUse: document.getElementById(`externalUse-${i}`).checked,
          groupThumbnail: document.getElementById(`groupThumbnailRadio-${i}`).checked,
          comments: document.getElementById(`comments-${i}`).value,
        });
      }
    }

    assetDetailsFormData.append("name", localStorage.getItem("orgName"));
    assetDetailsFormData.append("groupName", camelCaseGroup.trim());
    assetDetailsFormData.append("assetType", dynamicName);
    assetDetailsFormData.append("assetList", JSON.stringify(assetDetailsList));
    setDisplayLoader(true);
    dispatch(UpdateAssetDetails(assetDetailsFormData, successHandlerForDetails, errorHandler));
  };

  /**
   * This method will called after assetDetails Updated
   */
  const successHandlerForDetails = (resp) => {
    let editedGroupName = resp.data[0].groupName;
    localStorage.setItem("genericGroupName", editedGroupName.trim());
    setDisplayLoader(false);
    setShowSuggestions(false);
    closeAssetDetailsModal();
    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: dynamicName,
    };
    dispatch(getGroupLogos(obj));
  };

  /**
  *this method is used for handle the checkbox
  * @param {*} e
  * @param {*} index
  */
  const checkBoxHandler = (e, index) => {
    console.log(e.target.checked);
    let isChecked = e.target.checked;
    console.log(index);
    $(".check").prop("checked", false);

    if (isChecked == true) {
      console.log(e.target.checked);
      $("#" + e.target.id).prop("checked", true);
    } else {
      $("#" + e.target.id).prop("checked", false);
    }
  }

  /* table data based on response of that particular group */
  const rowsName = assetsData.map((item, index) => (
    console.log((item.fileExtension).split(".")[1]),
    <tr className="LogoDetailTable" id={"upload" + index} style={{ width: "100%" }}>
      <td id={`lbl-${index}`} style={{ width: "6%", wordBreak: "break-all", paddingRight: "5px" }}>
        {dynamicName == "Approved Images" ? (
          <input
            autoComplete="off"
            onChange={(e) =>
              (e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, ""))}
            type="text"
            style={{
              minWidth: "90%",
              border: "1px solid #D4D4D4",
              borderRadius: "2px",
              padding: "4px 3px",
            }}
            id={`approvedImages-${index}`}
            maxLength="50"
          />
        ) :
          (
            <div
              title={item.fileName}
              style={{
                // maxWidth: "80px",
                // overflow: "hidden",
                // textOverflow: "ellipsis",
                // whiteSpace: "nowrap",
                // padding: "4px 3px",
                minWidth: "90%",
                padding: "0px 3px",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
                // webkitLineClamp: "1",
                // webkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                marginTop: "5px",
              }}
            >
              {item.fileName}
            </div>
          )
        }
      </td>
      <td style={{ width: "4%" }}>
        <div style={{ width: "95%" }}>
          <input
            id={`txtFileType-${index}`}
            type="text"
            autoComplete="off"
            value={(item.fileExtension).split(".")[1].toUpperCase()}
            readOnly
            className="LogoSelectField"
            style={{ width: "80%" }}
          />
        </div>
      </td>
      <td style={{ width: "7%" }}>
        <div style={{ width: "95%" }}>
          <select
            className="LogoSelectField selectFieldType"
            style={{ width: "90%", height: "27px" }}
            id={`select-${index}`}
            onChange={(event) => fileTypeChange(event, index)}
          >
            <option value={item.id}>{item.fileType}</option>
            {optionsData.map((data) => {
              if (item.fileType != data.name) {
                return <option value={data.id}>{data.name}</option>;
              }
            })}
          </select>
        </div>
      </td>
      <td style={{ width: "4%" }}>
        <div style={{ width: "95%" }}>
          <input
            id={`txtWidth-${index}`}
            type="text"
            className="LogoSelectField"
            autoComplete="off"
            maxLength={20}
            style={{ width: "90%" }}
          />
        </div>
      </td>
      <td style={{ width: "4%" }}>
        <div style={{ width: "95%" }}>
          <input
            id={`txtDpi-${index}`}
            type="text"
            autoComplete="off"
            className="LogoSelectField"
            style={{ width: "90%" }}
          />
        </div>
      </td>
      <td style={{ width: "6%" }}>
        <div style={{ width: "95%" }}>
          <div style={{ padding: "4px 3px", textAlign: "center" }}>
            <input
              id={`groupThumbnailRadio-${index}`}
              type="checkbox"
              className="me-1 check"
              onChange={(e) => checkBoxHandler(e, index)}
              name="external"
              style={{
                marginTop: "2px",
              }}
            />
          </div>
        </div>
      </td>
      <td style={{ width: "6%" }}>
        <div style={{ width: "95%" }}>
          <div style={{ padding: "4px 3px", textAlign: "center" }}>
            <input
              id={`externalUse-${index}`}
              type="checkbox"
              className="me-1"
              name="external"
              style={{ marginTop: "2px" }}
            />
          </div>
        </div>
      </td>
      <td style={{ width: "4%" }}>
        <div style={{ width: "95%" }}>
          <input
            id={`comments-${index}`}
            type="text"
            maxLength="500"
            size="500"
            autoComplete="off"
            className="LogoSelectField"
            style={{ width: "80%" }}
          />
        </div>
      </td>
    </tr>
  ));

  useEffect(() => {
    /* set the response data to the fields */
    if (assetsData.length > 0) {
      for (let index = 0; index < assetsData.length; index++) {
        if (document.getElementById(`externalUse-${index}`)) {
          document.getElementById(`externalUse-${index}`).checked = assetsData[index].externalUse;
        }
        if (document.getElementById(`txtWidth-${index}`)) {
          document.getElementById(`txtWidth-${index}`).value = assetsData[index].fileSize;
        }
        if (document.getElementById(`txtDpi-${index}`)) {
          document.getElementById(`txtDpi-${index}`).value = assetsData[index].dpi;
        }
        if (document.getElementById(`approvedImages-${index}`)) {
          document.getElementById(`approvedImages-${index}`).value = assetsData[index].fileName;
        }
        if (document.getElementById(`groupThumbnailRadio-${index}`)) {
          document.getElementById(`groupThumbnailRadio-${index}`).checked = assetsData[index].groupThumbnail;
        }
        if (document.getElementById(`comments-${index}`)) {
          document.getElementById(`comments-${index}`).value = assetsData[index].comments;
        }
      }
    }
  }, [assetsData]);

  return (
    <Modal isOpen={showAssetDetailsModal} style={customStyles} ariaHideApp={false}>
      <form onSubmit={updateAssetDetails}>
        <div className="container logo-upload-modal" style={{ width: "950px" }}>
          <div className="d-flex justify-content-end">
            <img
              id="closeButton"
              src={closeImage}
              onClick={closeAssetDetailsModal}
              alt="Close"
              className="modal-close-icon float-end w-auto me-0"
            />
          </div>

          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-5">
              <img className="img-fluid" src={Icon} alt="" />
            </div>
            <div className="col-lg-7 col-md-7 col-sm-7  my-auto">
              <p className="edit m-0">Edit {dynamicName}</p>
            </div>
          </div>

          <div className="mt-3 py-3" style={{ background: "#fff", borderRadius: "5px" }}>
            <div className="row LogoFields">
              <div className="col-lg-6 col-md-6 col-sm-6 inptBorder">
                <label className="labels ms-3">{dynamicName} Group Name</label>
                <div className="d-flex">
                  <div className="col-8 editAssetDetails">
                    <InputTextField
                      type="text"
                      id="groupNameTextField"
                      className="text-input ms-3"
                      name="groupName"
                      value={groupName}
                      onChange={groupNameChangeHandler}
                      onBlur={onGroupNameBlur}
                      minLength="3"
                      maxLength="50"
                      style={{
                        padding: "5px 6px!important",
                        borderRadius: "3px",
                        border: "1px solid #9A9B9F",
                        fontSize: "12px",
                        width: "90%",
                      }}
                    />
                    <p className="errorMsg p-0 errMsg mt-0" style={{ marginLeft: "18px" }}>
                      {error.groupName}
                    </p>
                    {showSuggestions && groupNames && (
                      <SuggestionsListComponent
                        filteredSuggestions={filteredSuggestions}
                        activeSuggestionIndex={activeSuggestionIndex}
                        onClick={onSuggestionClick}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-1 justify-content-center align-items-center text-center">
              <div
                className="px-3 my-3"
                style={{
                  maxHeight: "200px",
                  overflowY: "scroll",
                }}
              >
                <table
                  className="table table-borderless"
                  style={{ display: "table !important", borderTop: "0.28299999237060547px solid #9A9B9F" }}
                >
                  <thead>
                    <tr className="LogoUploadLabel assetDetailUpdate">
                      <th className="align-middle">{dynamicName == "Approved Images" ? "Image Name" : "Asset Name"}</th>
                      <th className="align-middle">File Type</th>
                      <th className="align-middle">Asset Type</th>
                      <th className="align-middle">Size</th>
                      <th className="align-middle">Size (dpi)</th>
                      <th className="align-middle text-center">Group Thumbnail</th>
                      <th className="align-middle text-center">Approved for <br /> external use</th>
                      <th className="align-middle">Comments</th>
                    </tr>
                  </thead>
                  <tbody>{rowsName}</tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="footer py-3 justify-content-center">
            <button type="submit" className="button">
              UPDATE
            </button>
          </div>
        </div>
      </form>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </Modal>
  );
};

export default BrandAssetEditAssetDetailsModal;
