import axios from "axios";

const instance = axios.create({
  baseURL: "https://www.grafixark.com/GrafixArk",
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      config.headers["Authorization"] = "Bearer " + token; // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response.data.status == 408 && !originalConfig._retry) {
      originalConfig._retry = true;
      try {
        const rs = await instance.get("https://www.grafixark.com/GrafixArk/auth/refreshtoken", {
          headers: {
            isRefreshToken: "true",
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
        });
        const accessToken = rs?.data?.refreshToken;
        localStorage.setItem("accessToken", accessToken);
        originalConfig.headers.Authorization = localStorage.getItem("accessToken");
        return instance(originalConfig);
      } catch (_error) {
        return Promise.reject(_error);
      }
    }
    return Promise.reject(err.response);
  }
);
export default instance;
