import Tooltip from "@mui/material/Tooltip";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Login from "../Components/LoginModal";
import logo from "../images/logoheader.svg";
import logout from "../images/logout.svg";
import settings from "../images/users_icon.svg";
import { logoutUser, showModal } from "../redux/Actions/action";
// import { modalData } from "../redux/Reducers/userreducer";
import "./Header.css";
import { Loader } from "./Shared/Loader";
import SignupModal from "./SignupModal";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for header part
 * @return {*}
 */
const Header = () => {
  const ConfirmModal = React.lazy(() => import("./Shared/Modals/ConfirmModal"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showLogin, setShowLogin] = useState(false);
  // const [imageName, setImageName] = useState("");
  const [userName, setUsername] = useState("");
  // const [userRole, setUserRole] = useState("");
  const [switchUser, setSwitchUser] = useState("");

  useEffect(() => {
    setSwitchUser(localStorage.getItem("switchUser"));
  });

  const [showLogout, setShowLogout] = useState(false);
  const closeLogout = () => {
    setShowLogout(false);
  };

  /* assigning the window pathname to a variable */
  const paramName = window.location.pathname;

  if (!paramName) {
    setUsername(localStorage.getItem("orgName"));
  }

  /**
   * This method will call when user click on signUp button
   */
  // const signupClick = () => {
  //   dispatch(showModal(modalData.signup));
  // };

  /**
   * This method will call when user click on login button
   */
  const loginClick = () => {
    dispatch(showModal("login"));
  };

  /**
   * This method is used for closing the login modal
   */
  const onLoginClose = () => {
    setShowLogin(false);
  };

  /**
   * This method will call when user clicks on the grafixark logo
   */
  const onLogoClick = () => {
    localStorage.setItem("mainMenu", "0s");
    if (localStorage.getItem("status") === "true" && localStorage.getItem("roleId") === "1" && switchUser === "false") {
      localStorage.setItem("menuIndex", 0);
      window.open("/admin/viewedit", "_self");
    } else if (
      localStorage.getItem("status") === "true" &&
      localStorage.getItem("roleId") === "1" &&
      switchUser === "true"
    ) {
      localStorage.setItem("schoolMenuIndex", 0);
      window.open("/styleguide", "_self");
    } else if (localStorage.getItem("status") === "true" && localStorage.getItem("roleId") === "2") {
      localStorage.setItem("schoolMenuIndex", 0);
      window.open("/styleguide", "_self");
    } else if (localStorage.getItem("status") === "true" && localStorage.getItem("roleId") === "3") {
      localStorage.setItem("schoolMenuIndex", 0);
      window.open("/styleguide", "_self");
    } else {
      navigate("/");
    }
  };

  /**
   * User click on link
   */
  const onAdminClick = () => {
    // let url = "/admin/viewEdit";
    localStorage.setItem("switchUser", "false");
    localStorage.removeItem("orgType");
    localStorage.removeItem("brandAssetsOptionsName");
    localStorage.removeItem("templatesOptionsName");
    localStorage.removeItem("schoolMenuIndex");
    sessionStorage.removeItem("primaryValue");
    sessionStorage.removeItem("secondaryValue");
    sessionStorage.removeItem("gradientValue");
    localStorage.setItem("orgName", "Super Admin");
    localStorage.setItem("mainMenu", "0s");
    window.open("/admin/viewedit", "_self");
    // navigate(url);
  };

  /**
   * This method will shows logout dialog when users clicks on logout
   */
  const onLogoutClick = () => {
    setShowLogout(true);
  };

  /**
   * This method will logout the user when confirmed in dialog
   */
  const logOut = () => {
    if (localStorage.getItem("rememberMeEnabled").toString() === "true") {
      localStorage.removeItem("switchUser");
      localStorage.removeItem("orgType");
      localStorage.removeItem("orgSwitchName");
      localStorage.removeItem("orgName");
      localStorage.removeItem("roleId");
      localStorage.removeItem("status");
      localStorage.removeItem("accessToken");
      localStorage.removeItem("userId");
      localStorage.removeItem("schoolMenuIndex");
      localStorage.removeItem("loginUserEmail");
      localStorage.removeItem("menuIndex");
      localStorage.removeItem("firstName");
      localStorage.removeItem("name");
      localStorage.removeItem("brandAssetsOptionsName");
      localStorage.removeItem("templatesOptionsName");
    } else {
      localStorage.clear();
    }
    dispatch(logoutUser());
    setShowLogout(false);
  };

  return (
    <div>
      {paramName === "/termsAndConditions" ||
      paramName === "/feedback" ||
      paramName === "/setpassword" ||
      paramName === "/setPassword" ||
      paramName === "/colourPreview" ? (
        <div>
          <div
            id="remove-padding"
            className="container px-2"
            style={{ paddingTop: "6px", borderBottom: "2px solid grey" }}
          >
            <div className="fullscreen">
              <div className="row px-1 displayItems">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 px-0">
                  <div style={{ display: "inline-block", cursor: "pointer" }}>
                    <img src={logo} alt="" className="header-img" onClick={onLogoClick} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div id="remove-padding" className="container px-2" style={{ paddingTop: "6px" }}>
            <div className="fullscreen">
              <div className="row px-1 displayItems">
                <div className="col-lg-6 col-md-6 col-sm-6 col-6 px-0">
                  <div style={{ display: "inline-block", cursor: "pointer" }}>
                    <img src={logo} alt="" className="header-img" onClick={onLogoClick} />
                  </div>
                </div>

                {localStorage.getItem("status") !== "true" ? (
                  <>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-6" align="right">
                      {/* <button className="signup btn-auth" onClick={signupClick}>
                        SIGN UP
                      </button> */}
                      <button className="login btn-auth" onClick={loginClick}>
                        LOGIN
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-lg-1 col-md-1 col-sm-1 col-1"></div>
              </div>
            </div>
          </div>

          <div>
            <div className="header p-0">
              <div className="container">
                <div className="row">
                  {localStorage.getItem("status") === "true" ? (
                    <>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 my-auto header-text ">
                        <div className="text-truncate w-50">
                          {localStorage.getItem("roleId") === "1" && switchUser === "false" ? (
                            "Super Admin Portal"
                          ) : localStorage.getItem("roleId") === "2" ? (
                            <Tooltip title={`${localStorage.getItem("orgName")}`}>
                              <span>{localStorage.getItem("orgName")}</span>
                            </Tooltip>
                          ) : localStorage.getItem("roleId") === "3" ? (
                            <Tooltip title={`${localStorage.getItem("orgName")}`}>
                              <span>{localStorage.getItem("orgName")}</span>
                            </Tooltip>
                          ) : (
                            <Tooltip title={`${localStorage.getItem("orgName")}`}>
                              <span>{localStorage.getItem("orgName")}</span>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 my-auto" align="right">
                        <div className="droAlign" style={{ display: "flex", float: "floatRight" }}>
                          {localStorage.getItem("roleId") === "1" && switchUser === "true" && (
                            <span
                              className="my-auto"
                              style={{
                                color: "rgb(0 184 241)",
                                textDecoration: "underline",
                                cursor: "pointer",
                                fontSize: "12px",
                              }}
                              onClick={() => onAdminClick()}
                            >
                              Back to Super Admin Portal
                            </span>
                          )}

                          <div className="header-icons my-auto mx-3">
                            <label className="userName" style={{ display: "flex", float: "floatRight" }}>
                              <img src={settings} className="settings-icon my-auto mx-2" alt="userImage" />
                              <label className="my-auto">
                                {localStorage.getItem("roleId") === "1" ? (
                                  localStorage.getItem("name")
                                ) : switchUser === "true" ? (
                                  <span>{"Super Admin"}</span>
                                ) : localStorage.getItem("roleId") === "3" ? (
                                  <span>{localStorage.getItem("firstName")}</span>
                                ) : (
                                  localStorage.getItem("orgName")
                                )}
                              </label>
                            </label>
                          </div>
                          <Tooltip title="Logout">
                            <img src={logout} onClick={onLogoutClick} className="logOut" alt="Logout" />
                          </Tooltip>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      {window.location.pathname === "/aboutus" ? (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 header-text">About Us</div>
                      ) : (
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 header-text">Welcome</div>
                      )}

                      <div className="col-lg-6 col-md-6 col-sm-6 col-6 header-text"></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <SignupModal />
      <Login
        login={showLogin}
        onClose={onLoginClose}
        loginOpen={loginClick}
        userName={userName}
        // userRole={userRole}
        // imageName={imageName}
      />

      <Suspense fallback={<Loader />}>
        {showLogout && (
          <ConfirmModal
            open={showLogout}
            onConfirm={logOut}
            onClose={closeLogout}
            titleText={"Are you sure you want to Logout?"}
          />
        )}
      </Suspense>
    </div>
  );
};
export default Header;
