import MaterialTable from "@material-table/core";
import { Check, Clear, DeleteOutline, Edit, Search } from "@material-ui/icons";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import active from "../../../images/active.svg";
import customerIcon from "../../../images/customericon.svg";
import inactive from "../../../images/inactive.svg";
import { getOrganizationUsers } from "../../../redux/Actions/action";
import { Loader } from "../../Shared/Loader";
import "./EditUser";
import "./viewEdit.css";

/* Icons used in the material table */
const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

/**
 * View and Edit the Customers
 * @return {*}
 */
function ViewEdit() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  // pagination state
  const [paging, setPaging] = useState(false);
  const customers = useSelector((state) => state.userReducer.customers);

  useEffect(() => {
    localStorage.setItem("switchUser", "false");
    localStorage.removeItem("orgType");
    localStorage.removeItem("brandAssetsOptionsName");
    localStorage.removeItem("templatesOptionsName");
    localStorage.removeItem("schoolMenuIndex");
    sessionStorage.removeItem("primaryValue");
    sessionStorage.removeItem("secondaryValue");
    sessionStorage.removeItem("gradientValue");
    localStorage.setItem("orgName", "Super Admin");
    
    if (localStorage.getItem("roleId") === "1") {
      dispatch(getOrganizationUsers(successHandler, errorHandler));
      if (customers) {
        const val = { value: customers.length > 10 ? true : false };
        setPaging(val.value);
      } else {
        setPaging(false);
      }
    } else {
      navigate("/");
      localStorage.setItem("schoolMenuIndex", 0);
    }
  }, [customers.length]);

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * User click on link
   * @param {*} prop
   */
  const onAdminClick = (prop) => {
    let url = "/" + prop.name;
    localStorage.setItem("switchUser", "true");
    localStorage.setItem("orgType", prop.type);
    localStorage.setItem("orgName", prop.name);
    navigate(url);
  };

  /**
   * onClicking edit button Navigate to Edit page
   * @param {*} customers
   */
  const gotoEditPage = (customers) => {
    navigate("/admin/editUser", { state: JSON.stringify(customers) });
  };

  return (
    <div className="row pt-3">
      <div className="member-heading mb-1">
        <div>
          <div className="mainTitle">
            <h6 className="titLe m-0 pb-2 my-auto">
              Customers <span>- View / Edit </span>
            </h6>
          </div>

          <h6 className="m-0 pt-3 subTitle"> Search Customers </h6>
        </div>
      </div>

      <div className="viewEditTable">
        <MaterialTable
          title=""
          icons={tableIcons}
          columns={[
            {
              title: "Organization",
              field: "name",
              width: "40%",
              cellStyle: {
                textTransform: "capitalize",
              },
              render: (customers) => (
                <div className="d-flex">
                  <div className="me-2">
                    <img className="img-fluid memBrIcon"  alt="" src={customerIcon} />
                  </div>
                  <div className="my-auto">{customers.name}</div>
                </div>
              ),
            },
            {
              title: "URL",
              field: "email",
              width: "40%",
              render: (customers) => {
                return (
                  <span
                    style={{
                      color: "#0d6efd",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => onAdminClick(customers)}
                  >
                    https://www.grafixark.com/{customers.name}
                  </span>
                );
              },
            },
            {
              title: "Status",
              field: "isVerfied",
              headerStyle: { textAlign: "center" },
              render: (customers) => {
                return customers.status === "active" ? (
                  <div className="text-center" style={{ color: "Green" }}>
                    <img title="Active" alt="" src={active} />
                  </div>
                ) : customers.status == null ? (
                  <div className="text-center" style={{ color: "Red" }}>
                    <img title="Inactive" alt="" src={inactive} />
                  </div>
                ) : customers.status === "inactive" ? (
                  <div className="text-center" style={{ color: "Red" }}>
                    <img title="Inactive" alt="" src={inactive} />
                  </div>
                ) : customers.status === "disabled" ? (
                  <div className="text-center" style={{ color: "Red" }}>
                    <img title="Inactive" alt="" src={inactive} />
                  </div>
                ) : (
                  <span>{customers.status}</span>
                );
              },
            },
            {
              title: "Actions",
              field: "organizationMenu",
              headerStyle: { textAlign: "center" },
              render: (customers) => (
                <div className="text-center">
                  <Edit
                    style={{ width: "18px", height: "18px", cursor: "pointer" }}
                    onClick={() => gotoEditPage(customers)}
                  />
                </div>
              ),
            },
          ]}
          options={{
            search: true,
            sorting: false,
            emptyRowsWhenPaging: false,
            draggable: false,
            paging: paging,
            pageSize: 10,
            headerStyle: {
              color: "#15355C ",
              fontSize: "15px",
              fontWeight: "bold",
            },
            cellStyle: {
              padding: "0px",
              paddingLeft: "15px",
            },
          }}
          components={{
            Container: (props) => (
              <div>
                <div {...props} />
              </div>
            ),
          }}
          data={customers}
        />
      </div>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default ViewEdit;
