import { Fragment } from "react";

function IconButton({ className = "", imageClass, buttonClass, img, label, onClick }) {
  return (
    <Fragment>
      <button className={className} onClick={onClick}>
        <img src={img} className={imageClass} />
      </button>
      <button className={buttonClass} onClick={onClick}>
        {label}
      </button>
    </Fragment>
  );
}

export default IconButton;
