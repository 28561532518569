import * as types from "../Actions/actionType";

const assetData = {
  getBrandAssets: [],
  getAllAssets: [],
  getAssetRecords: [],
};

export const assetReducer = (asset = assetData, action) => {
  switch (action.type) {
    case types.FETCH_BrandAssets:
      return {
        ...asset,
        getBrandAssets: action.payload,
      };
    case types.FETCH_ALL_ASSETS:
      return {
        ...asset,
        getAllAssets: action.payload,
      };
    case types.FETCH_ASSET_RECORDS:
      return {
        ...asset,
        getAssetRecords: action.payload,
      };
    default:
      return {
        ...asset,
      };
  }
};
