import Modal from "react-modal";
import { useDispatch } from "react-redux";

const AuthenticationModal = ({ open, onClose, onSubmit, children, titleText, onRequestClose }) => {
  const dispatch = useDispatch();

  return (
    <div>
      <Modal
        isOpen={open}
        onRequestClose={onRequestClose}
        contentLabel="Example Modal centered"
        className="authentication-modal"
        centered
        ariaHideApp={false}
      >
        {/* <img
          src={closeImage}
          onClick={onClose}
          alt="Close"
          className="modal-close-icon"
        /> */}
        <div style={{ padding: "10px 20px" }}>
          {/* <div style={{ color: "white" }}>
             <h5 className="form-header pt-3"> </h5> 
            <p className="loginHeader">{titleText}</p>
          </div> */}

          <div className="container p-0 m-0">{children}</div>
        </div>
      </Modal>
    </div>
  );
};

export default AuthenticationModal;
