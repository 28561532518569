import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeImage from "../images/close.svg";
import { closeModal, forgotPassword, showModal } from "../redux/Actions/action";
import "./Header.css";
import ResetPassword from "./ResetPassword";
import AuthenticationModal from "./Shared/AuthenticationModal";
import InputTextField from "./Shared/InputTextField";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for forgot password modal
 * @param {*} { open, onClose, loginOpen }
 * @return {*} 
 */
function ForgotPassword({ open, onClose, loginOpen }) {
  const dispatch = useDispatch();

  const [error, setError] = useState({});
  const [openReset, setOpenReset] = useState(false);
  const onCloseReset = () => setOpenReset(false);
  const onOpenReset = () => setOpenReset(true);

  const forgotObj = {
    email: "",
  };

  const forgotpassword = useSelector((state) => state.userReducer.modal);
  const [userEmail, setUserEmail] = useState(forgotObj);

  /**
   * This method is used for set the email change
   * @param {*} e
   */
  const onEmailChange = (e) => {
    let { name, value } = e.target;
    setUserEmail({ ...userEmail, [name]: value });
  };

  /**
   * This method is used for reset password
   * @param {*} e
   */
  const onPasswordReset = (e) => {
    e.preventDefault();
    setError(validate(userEmail));
    if (userEmail.email.trim() != "") {
      dispatch(forgotPassword(userEmail, successHandler, errorHandler));
      dispatch(closeModal("forgotpassword"));
    }
  };

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setUserEmail(forgotObj);
  }

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setUserEmail(forgotObj);
  }
  /**
   * This method will call when user clicks on the login text
   */
  const onLoginOpen = () => {
    dispatch(showModal("login"));
    dispatch(closeModal("forgotpassword"));
  };

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      setError(validate(userEmail));
    }
  }, [userEmail]);

  /**
   * This method is used to validate the email
   * @param {*} values
   * @return {*} 
   */
  const validate = (values) => {
    let error = {};
    if (values.email == "") {
      error.email = "Please enter Email ";
    }
    return error;
  };

  /**
   * This method is used to close the authentication modal
   */
  const onCloseModal = () => {
    dispatch(closeModal("forgotpassword"));
  };

  /**
   * This method is used to close the modal
   */
  const onCloseForgotModal = () => {
    setUserEmail(forgotObj);
    setError({});
    dispatch(closeModal("forgotpassword"));
  };

  return (
    <Fragment>
      <AuthenticationModal
        open={forgotpassword.forgotpassword}
        titleText=""
        onClose={onCloseModal}
        ariaHideApp={false}
      // onRequestClose={onCloseModal}
      >
        <form onSubmit={onPasswordReset}>
          <div className="container p-0 m-0 justify-content-between">
            <img src={closeImage} onClick={onCloseForgotModal} alt="Close" className="userLoginModel" />
            <div className="row p-0 py-3 m-0 justify-content-between col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2 p-0">
                <h6 className="loginHeader">
                  <p className="mb-1">Forgot your password?</p>
                  Don't worry! Just fill in your email and we'll help you reset it.
                </h6>
                <div className="col-12">
                  <div className="col-12">
                    <label className="loginLabels">
                      Username<span className="required-Felid"> *</span>
                    </label>
                    <InputTextField
                      type="email"
                      className="loginInput w-100  mb-3"
                      name="email"
                      onChange={onEmailChange}
                      value={userEmail.email}
                      error={error.email}
                    />
                    <div className="forgotPasswordAndRemember">
                      <button className="model btnModel float-start mb-4">Reset</button>
                      <span>
                        <Link to="" className="forgotpassword m-0 py-2 float-end" onClick={onLoginOpen}>
                          Login?
                        </Link>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 my-auto">
                <div align="center">
                  <img className="w-100" src={footerLogo} />
                </div>
              </div> */}
            </div>
          </div>
        </form>
      </AuthenticationModal>
      <ResetPassword open={openReset} loginOpen={loginOpen} />
    </Fragment>
  );
}

export default ForgotPassword;
