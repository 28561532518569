import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as types from "../Actions/actionType";
import { envVariables } from "./../../Environments/env.local";
import interceptor from "./../../interceptor";

/* API */
const api = envVariables.ip;

toast.configure({
  autoClose: 2000,
});

export const addNewUser = () => ({
  type: types.ADD_USER,
});

export const addNewLogos = () => ({
  type: types.ADD_LOGOS,
});

export const userLogin = (userLoggedIn) => ({
  type: types.USER_LOGIN,
  payload: userLoggedIn,
});

export const userLogout = () => ({
  type: types.USER_LOGOUT,
});

export const resetPasswordData = () => ({
  type: types.RESET_PASSWORD,
});

export const closeModal = (id) => ({
  type: types.CLOSE_MODALS,
  payload: id,
});

export const doFetchCustomers = (val) => ({
  type: types.FETCH_CUSTOMERS,
  payload: val,
});

export const doFetchBrandAssets = (val) => ({
  type: types.FETCH_BrandAssets,
  payload: val,
});

export const doFetchTemplates = (val) => ({
  type: types.FETCH_TEMPLATES,
  payload: val,
});

export const doFetchGroups = (val) => ({
  type: types.FETCH_GROUP_NAMES,
  payload: val,
});

export const doFetchAllAssets = (val) => ({
  type: types.FETCH_ALL_ASSETS,
  payload: val,
});

export const doFetchAssetRecords = (val) => ({
  type: types.FETCH_ASSET_RECORDS,
  payload: val,
});

export const fetchSuperAdmins = (val) => ({
  type: types.GET_ADMIN_USERS,
  payload: val,
});

export const showModal = (id) => ({
  type: types.SHOW_MODAL,
  payload: id,
});

export const forgotPasswordData = (errorData) => ({
  type: types.FORGOT_PASSWORD,
  payload: errorData,
});

export const userVerificationId = (id) => ({
  type: types.USER_VERIFIED,
  payload: id,
});

export const doFetchGroupLogos = (val) => ({
  type: types.GROUP_LOGOS,
  payload: val,
});

export const doFetchStyleGuide = (val) => ({
  type: types.STYLE_GUIDE,
  payload: val,
});

export const doFetchSchoolMottos = (val) => ({
  type: types.SCHOOL_MOTTO,
  payload: val,
});

export const doFetchColors = (val) => ({
  type: types.COLORS,
  payload: val,
});

export const doFetchFonts = (val) => ({
  type: types.FONTS,
  payload: val,
});

export const doFetchMemberMasterData = (val) => ({
  type: types.MEMBER_MASTER_DATA,
  payload: val,
});

export const doFetchGroupsDataByMemberId = (val) => ({
  type: types.GROUP_MASTER_DATA_BY_MEMBER_ID,
  payload: val,
});

export const doFetchGroupsDataByOrganization = (val) => ({
  type: types.GROUP_MASTER_DATA_BY_ORGANIZATION,
  payload: val,
});

export const doFetchGroupMasterData = (val) => ({
  type: types.GROUP_MASTER_DATA,
  payload: val,
});

export const doFetchMembersDataByGroupId = (val) => ({
  type: types.MEMBER_MASTER_DATA_BY_GROUP_ID,
  payload: val,
});

export const doFetchMembersDataByOrganization = (val) => ({
  type: types.MEMBER_MASTER_DATA_BY_ORGANIZATION,
  payload: val,
});

export const addNewMember = () => ({
  type: types.ADD_MEMBER,
});

export const doFetchLetterHeads = (val) => ({
  type: types.LETTER_HEADS,
  payload: val,
});

export const doFetchIcons = (val) => ({
  type: types.ICONS,
  payload: val,
});

export const doFetchOrgMembers = (val) => ({
  type: types.ORG_MEMBERS,
  payload: val,
});

export const doFetchOrgGroups = (val) => ({
  type: types.ORG_GROUPS,
  payload: val,
});

export const doFetchGroupsDataByAssetId = (val) => ({
  type: types.GROUP_MASTER_DATA_BY_ASSETS_ID,
  payload: val,
});

export const doFetchMembersDataByAssetId = (val) => ({
  type: types.MEMBER_MASTER_DATA_BY_ASSETS_ID,
  payload: val,
});

export const doFetchAssetsByUserId = (val) => ({
  type: types.FETCH_ASSETS_BY_USER_ID,
  payload: val,
});

export const doFetchColoursConfigurations = (val) => ({
  type: types.FETCH_COLOURS_CONFIGURATIONS_BY_ORG_NAME,
  payload: val,
});

export const doFetchGoogleFonts = (val) => ({
  type: types.FETCH_GOOGLE_FONTS,
  payload: val,
});

export const doFetchDescription = (val) => ({
  type: types.DESCRIPTION,
  payload: val,
});

export const notify = (err) => {
  return <div className="warningMsg">{toast.warning(err.data.message)}</div>;
};

export const addUserSuccess = () => {
  return <div className="success">{toast.success("User Created Successfully...")}</div>;
};

export const inviteSuccess = () => {
  return <div className="success">{toast.success("User Invitation Link Sent Successfully...")}</div>;
};

export const updateUserSuccess = () => {
  return <div className="success">{toast.success("User Updated Successfully...")}</div>;
};

export const updateBrandAssetsSuccess = () => {
  return <div className="success">{toast.success("Brand Assets Updated Successfully...")}</div>;
};

export const signUpError = () => {
  return <div className="warningMsg">{toast.warning("Email Id already Exists...")}</div>;
};

export const signUpNotify = () => {
  return <div className="success">{toast.success("Successfully Registered..")}</div>;
};

export const forgotPasswordSuccess = () => {
  return <div className="success">{toast.success("Password reset link successfully sent to your MailId..")}</div>;
};

export const updateBrandAssetSuccess = () => {
  return <div className="success">{toast.success("Successfully Updated the Brand Assets..")}</div>;
};

export const updateTemplateAssetSuccess = () => {
  return <div className="success">{toast.success("Successfully Updated the Template Assets..")}</div>;
};

export const addLogosSuccess = () => {
  return <div className="success">{toast.success("Asset details updated successfully..")}</div>;
};

export const addLogosToGroupSuccess = () => {
  return <div className="success">{toast.success("Asset Added Successfully..")}</div>;
};

export const addMemberSuccess = () => {
  return <div className="success">{toast.success("Member added successfully..")}</div>;
};

export const updateGroupSuccess = () => {
  return <div className="success">{toast.success("Group Updated Successfully..")}</div>;
};

export const updateGroupError = (errMsg) => {
  return <div className="success">{toast.error(errMsg)}</div>;
};

export const assetSharedSuccess = () => {
  return <div className="success">{toast.success("File successfully shared.")}</div>;
};

export const assetDetailsUpdatedSuccess = () => {
  return <div className="success">{toast.success("Asset details updated successfully..")}</div>;
};

export const groupNameUpdatedSuccess = () => {
  return <div className="success">{toast.success("Group Name updated successfully..")}</div>;
};

export /**
 * This service is used to register the organization Used in Signup Model Page
 * @param {*} newUser
 * @param {*} successCallback
 * @return {*}
 */
  const organizationSignup = (newUser, successCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/public/signUpOrg`, newUser)
        .then((resp) => {
          dispatch(addNewUser(resp.data));
          successCallback();
          signUpNotify();
        })
        .catch(() => {
          dispatch(addNewUser([]));
          signUpError();
        });
    };
  };

export /**
 * This service is used to set the password for the first time registered user via Email Link
 * @param {*} payload
 * @param {*} successCallback
 * @return {*}
 */
  const setPassword = (payload, successCallback) => {
    return function () {
      interceptor
        .post(`${api}/organization/set-password`, payload, {
          headers: { "content-type": "application/json" },
        })
        .then(() => {
          successCallback();
        })
        .catch(() => { });
    };
  };

export /**
 * This service is used to send email to customer
 * @param {*} payload
 * @param {*} successCallback
 * @return {*}
 */
  const sendFeedbackMail = (payload, successCallback) => {
    return function () {
      interceptor
        .post(`${api}/feedback/sendMail`, payload, {
          headers: { "content-type": "application/json" },
        })
        .then(() => {
          successCallback();
        })
        .catch(() => { });
    };
  };

export /**
 * This service is used to authenticate the user Used in Login Modal Page
 * @param {*} loginData
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const userAuthenticate = (loginData, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/auth/login`, loginData)
        .then((resp) => {
          localStorage.setItem("orgName", resp.data.name);
          localStorage.setItem("firstName", resp?.data?.firstName);
          localStorage.setItem("name", resp?.data?.name);
          localStorage.setItem("roleId", resp.data.roleId);
          localStorage.setItem("status", resp.data.status);
          localStorage.setItem("accessToken", resp.data.accessToken);
          localStorage.setItem("userId", resp.data.userId);
          localStorage.setItem("loginUserEmail", resp.data.emailID);
          localStorage.setItem("orgType", resp.data.orginationType);
          localStorage.setItem("switchUser", "false");
          localStorage.setItem("mainMenu", "0s");

          dispatch(userLogin(resp.data));
          successCallback(resp.data);
        })
        .catch((error) => {
          if (error.data?.statusCode === 400) {
            toast.warning(error.data.message);
          }
          errorCallback();
        });
    };
  };

export /**
 * This service is used to set the password in Forgot Password Page
 * @param {*} emailId
 * @return {*}
 */
  const forgotPassword = (emailId, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/public/forgot`, emailId)
        .then((resp) => {
          dispatch(forgotPasswordData(resp.data));
          dispatch(closeModal());
          forgotPasswordSuccess();
          successCallback();
        })
        .catch((error) => {
          errorCallback();
          dispatch(forgotPasswordData({}));
          notify(error);
        });
    };
  };

export /**
 * This service is used to Reset the Password Used in Reset Password Page
 * @param {*} obj
 * @param {*} show
 * @return {*}
 */
  const resetPassword = (obj, successHandler) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/public/reset`, obj)
        .then((resp) => {
          dispatch(resetPasswordData(resp.data));
          successHandler();
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.warning(error.data);
          }
        });
    };
  };

export /**
 * This service is used to check the user name already exists or not used in Add User Page
 * @param {*} user
 * @param {*} successCallback
 * @return {*}
 */
  const isUserNameExisted = (user, successCallback) => {
    return function () {
      interceptor
        .get(`${api}/organization/userName/${user}`)
        .then((resp) => {
          successCallback(resp.data);
        })
        .catch(() => { });
    };
  };

export /**
 * This service is used to check the user email already exists or not used in Add USer Page
 * @param {*} email
 * @param {*} successCallback
 * @return {*}
 */
  const isEmailExisted = (email, successCallback) => {
    return function () {
      interceptor
        .get(`${api}/organization/email/${email}`)
        .then((resp) => {
          successCallback(resp.data);
        })
        .catch(() => { });
    };
  };

export /**
 * This service is used to check the user email already exists or not used in Add USer Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const fetchSuperAdminUsers = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/user/`)
        .then((resp) => {
          dispatch(fetchSuperAdmins(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Create the Super Admin User in Members Page
 * @param {*} user
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const addSuperAdminUser = (user, successCallback, errorCallback) => {
    return function () {
      const obj = {
        lastName: "",
        firstName: "",
        name: user.name,
        emailID: user.emailID,
        mobileNum: "",
        password: "",
        title: "",
        schoolName: "",
        address1: "",
        address2: "",
        role: {},
      };
      interceptor
        .post(`${api}/user/add`, obj)
        .then(() => {
          toast.success("Super Admin Successfully Created..");
          successCallback();
        })
        .catch((error) => {
          if (error.data?.statusCode === 400) {
            toast.warning(error.data.message);
          }
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Update the Super Admin User in Members Page
 * @param {*} user
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const UpdateSuperAdminUser = (user, successCallback, errorCallback) => {
    return function () {
      delete user.tableData;
      interceptor
        .post(`${api}/user/id/${user.newData.id}`, user.newData)
        .then(() => {
          successCallback();
          toast.success("Super Admin Successfully Updated..");
        })
        .catch((error) => {
          toast.error(error.data.message);
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Create the Organization in Add USer Page
 * @param {*} user
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const createOrganization = (user, successCallback, errorCallback) => {
    return function () {
      const formData = new FormData();

      if (user.file) {
        user.file ? formData.append("image", user.file) : formData.append("image", "Test");

        formData.append(
          "organization",
          JSON.stringify({
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            role: {},
            subscriptionDetails: {
              active: user.status,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );

        delete user.tableData;

        interceptor
          .post(`${api}/organization/saveOrganization`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            addUserSuccess();
            successCallback();
          })
          .catch(() => {
            errorCallback();
          });
      } else {
        formData.append(
          "organization",
          JSON.stringify({
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            role: {},
            subscriptionDetails: {
              active: user.status,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );

        delete user.tableData;

        interceptor
          .post(`${api}/organization/saveOrganizationWithoutLogo`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            addUserSuccess();
            successCallback();
          })
          .catch(() => {
            errorCallback();
          });
      }
    };
  };

export /**
 * This service is used to Invite the Organization User in Add User and Edit User
 * @param {*} user
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const inviteUser = (user, successCallback, errorCallback) => {
    return function () {
      const formData = new FormData();

      if (user.file) {
        user.file ? formData.append("image", user.file) : formData.append("image", "Test");

        formData.append(
          "organization",
          JSON.stringify({
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            role: {},
            subscriptionDetails: {
              active: user.status,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );

        delete user.tableData;
        interceptor
          .post(`${api}/organization/add`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then((resp) => {
            successCallback(resp.data.data);
            inviteSuccess();
          })
          .catch(() => {
            errorCallback();
          });
      } else {
        formData.append(
          "organization",
          JSON.stringify({
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            role: {},
            subscriptionDetails: {
              active: user.status,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );

        delete user.tableData;
        interceptor
          .post(`${api}/organization/inviteWithoutLogo`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then((resp) => {
            successCallback(resp.data);
            inviteSuccess();
          })
          .catch(() => {
            errorCallback();
          });
      }
    };
  };

export /**
 * This service is used to Invite the Organization User in Add User and Edit User
 * @param {*} user
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const editInviteUser = (user, successCallback, errorCallback) => {
    return function () {
      const formData = new FormData();

      if (user.file) {
        user.file ? formData.append("image", user.file) : formData.append("image", "Test");

        formData.append(
          "organization",
          JSON.stringify({
            id: user.id,
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            invitedBy: localStorage.getItem("loginUserEmail"),
            isVerfied: user.isVerified,
            role: {},
            subscriptionDetails: {
              id: user.subscriptionId,
              active: user.active,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );

        delete user.tableData;
        interceptor
          .post(`${api}/organization/addWithLogo`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            inviteSuccess();
            successCallback();
          })
          .catch((error) => {
            if (error.data?.statusCode === 400) {
              toast.warning(error.data.message);
            }
            errorCallback();
          });
      } else {
        formData.append(
          "organization",
          JSON.stringify({
            id: user.id,
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            invitedBy: localStorage.getItem("loginUserEmail"),
            isVerfied: user.isVerified,
            role: {},
            subscriptionDetails: {
              id: user.subscriptionId,
              active: user.active,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );

        delete user.tableData;
        interceptor
          .post(`${api}/organization/addWithoutLogo`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            inviteSuccess();
            successCallback();
          })
          .catch((error) => {
            if (error.data?.statusCode === 400) {
              toast.warning(error.data.message);
            }
            errorCallback();
          });
      }
    };
  };

export /**
 * This service is used to Update the Organization User in Edit User Page
 * @param {*} user
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const updateOrganization = (user, successCallback, errorCallback) => {
    return function () {
      const formData = new FormData();
      if (user.file) {
        formData.append("image", user.file);

        formData.append(
          "organization",
          JSON.stringify({
            id: user.id,
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            invitedBy: localStorage.getItem("loginUserEmail"),
            isVerfied: user.isVerified,
            role: {},
            subscriptionDetails: {
              id: user.subscriptionId,
              active: user.active,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );
        delete user.tableData;
        interceptor
          .post(`${api}/organization/updateOrganization`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            updateUserSuccess();
            successCallback();
          })
          .catch((error) => {
            if (error.data?.statusCode === 400) {
              toast.warning(error.data.message);
            }
            errorCallback();
          });
      } else {
        formData.append(
          "organization",
          JSON.stringify({
            id: user.id,
            type: user.type,
            name: user.Name.trim(),
            address: "",
            firstName: user.firstName,
            schoolContactNumber: user.schoolContactNumber,
            lastName: user.lastName,
            email: user.email,
            invitedBy: localStorage.getItem("loginUserEmail"),
            isVerfied: user.isVerified,
            role: {},
            subscriptionDetails: {
              id: user.subscriptionId,
              active: user.active,
              subscriptionType: user.subscriptionType,
              subscription_date: user.subscriptionDate,
              last_renewal_date: user.lastRenewalDate,
              next_renewal_date: user.nextRenewalDate,
              user_pick: user.userPack,
            },
          })
        );
        delete user.tableData;
        interceptor
          .post(`${api}/organization/updateOrgWithoutLogo`, formData, {
            headers: { "content-type": "multipart/form-data" },
          })
          .then(() => {
            updateUserSuccess();
            successCallback();
          })
          .catch((error) => {
            if (error.data?.statusCode === 400) {
              toast.warning(error.data.message);
            }
            errorCallback();
          });
      }
    };
  };

export /**
 * This service is used to Get the Brand Assets List in Brand Assets Lists Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getBrandAssetList = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assetmenu/getAssetMenu`)
        .then((resp) => {
          dispatch(doFetchBrandAssets(resp.data.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Get the Brand Assets List in Brand Assets Lists Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getTemplatesList = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/templatemenu/getTemplates`)
        .then((resp) => {
          dispatch(doFetchTemplates(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Update the Brand Assets in Brand Assets Page
 * @param {*} assets
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const updateBrandAssets = (assets, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assetmenu/add`, assets, {
          headers: { "content-type": "application/json" },
        })
        .then(() => {
          updateBrandAssetSuccess();
          successCallback();
        })
        .catch((error) => {
          toast.warning(error.data.message);
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Add the template in Template page
 * @param {*} assets
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const updateTemplateAssets = (assets, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/templatemenu/add`, assets, {
          headers: { "content-type": "application/json" },
        })
        .then(() => {
          updateTemplateAssetSuccess();
          successCallback();
        })
        .catch((error) => {
          toast.warning(error.data.message);
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Get Brand Assets List Type Records Used in Brand Assets List and Logo Add Modal
 * @param {*} id
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getAssetTypes = (id, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/masterLogo/getLogos/${id}`)
        .then((resp) => {
          dispatch(doFetchAssetRecords(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Add the Asset Type to the Brand Asset Used in Brand Assets List Page
 * @param {*} asset
 * @param {*} id
 * @return {*}
 */
  const addAssetType = (asset, id) => {
    return function (dispatch) {
      const obj = {
        logoTypeName: asset.logoTypeName.trim(),
        defaultSizeCms: asset.defaultSizeCms || null,
        defaultSizeDpt: asset.defaultSizeDpt || null,
        defaultFileType: asset.defaultFileType || null,
        comments: asset.comments || null,
        asset_id: id,
      };
      interceptor
        .post(`${api}/masterLogo/addLogos`, obj)
        .then(() => {
          addLogosSuccess();
          dispatch(getAssetTypes(obj.asset_id));
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.warning(error.data.message);
          }
          dispatch(getAssetTypes(obj.asset_id));
        });
    };
  };

export /**
 * This service is used to Update the Asset Type to the Brand Asset Used in Brand Assets List
 * @param {*} asset
 * @param {*} id
 * @return {*}
 */
  const updateAssetType = (asset, id) => {
    return function (dispatch) {
      const obj = {
        logoTypeName: asset.logoTypeName.trim(),
        defaultSizeCms: asset.defaultSizeCms.trim(),
        defaultSizeDpt: asset.defaultSizeDpt.trim(),
        defaultFileType: asset.defaultFileType.trim(),
        comments: asset.comments.trim(),
        logo_id: asset.logo_id,
        asset_id: id,
      };
      interceptor
        .post(`${api}/masterLogo/addLogos`, obj)
        .then(() => {
          addLogosSuccess();
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.warning(error.data.message);
            dispatch(getAssetTypes(id));
          }
        });
    };
  };

export /**
 * This service is used to get the Organization Users Used in ViewEdit Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getOrganizationUsers = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/organization/`)
        .then((resp) => {
          dispatch(doFetchCustomers(resp.data.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchCustomers([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to check the Group Name already exists or not used in Log Modal Page
 * @param {*} objGroup
 * @param {*} callback
 * @return {*}
 */
  const isGroupNameExisted = (objGroup, callback) => {
    return function () {
      interceptor
        .post(`${api}/assets/checkGroupName`, objGroup)
        .then(() => { })
        .catch(() => {
          callback();
        });
    };
  };

export /**
 * This service is used to get the logos under the Group Used in Logo Modal Page
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getGroupLogos = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/all/`, obj)
        .then((resp) => {
          dispatch(doFetchGroupLogos(resp.data));
          successCallback(resp.data);
        })
        .catch(() => {
          dispatch(doFetchGroupLogos([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the logos under the Group Used in Logo Modal Page
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getStyleGuide = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/all/`, obj)
        .then((resp) => {
          dispatch(doFetchStyleGuide([]));
          dispatch(doFetchStyleGuide(resp.data));
          successCallback(resp.data);
        })
        .catch(() => {
          dispatch(doFetchStyleGuide([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Logout the user from the Application Used in Header
 * @return {*}
 */
  const logoutUser = () => {
    return function (dispatch) {
      dispatch(userLogout());
    };
  };

export /**
 * This service is used to save the logos to the Particular Group Used in Logo Upload Modal
 * @param {*} newLogos
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const AddingNewLogos = (newLogos, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/saveAsset`, newLogos, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((resp) => {
          dispatch(addNewLogos(resp.data));
          addLogosToGroupSuccess();
          successCallback(resp);
        })
        .catch(() => {
          dispatch(addNewLogos([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to upload the styleguide PDF
 * @param {*} newPDF
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const AddingNewPDF = (newPDF, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/uploadPdf`, newPDF, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * Method to be called for adding new school
 * @param {*} newPDF
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const AddingNewSchoolMotto = (newSCM, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/uploadPdf`, newSCM, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the list of Groups and Related To Member Used in Edit Member Page
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getGroupsByMemberId = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/Members/groupsByMemberId`, obj)
        .then((resp) => {
          dispatch(doFetchGroupsDataByMemberId(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchGroupsDataByMemberId([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the list of Groups and Related To Organization Used in ADD Member Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getGroupsByOrganization = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/Members/groupsByOrg/${localStorage.getItem("orgName")}`)
        .then((resp) => {
          dispatch(doFetchGroupsDataByOrganization(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to check the user email already exists or not Used in Add Members Page
 * @param {*} email
 * @return {*}
 */
  const isEmailExistedForMember = (email) => {
    return function () {
      interceptor
        .get(`${api}/user/checkMail/${email}`)
        .then(() => { })
        .catch((error) => {
          if (error.data?.statusCode === 400) {
            toast.warning(error.data.message);
          }
        });
    };
  };

export /**
 * This service is used to add the member to the organization Used in Add Members Page
 * @param {*} newMember
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const addMember = (newMember, successCallback, failureCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/Members/addMemebers`, newMember)
        .then((resp) => {
          dispatch(addNewMember(resp.data));
          addMemberSuccess();
          successCallback();
        })
        .catch((error) => {
          dispatch(addNewMember([]));
          failureCallback();
          if (error.data?.statusCode === 400) {
            toast.warning(error.data.message);
          }
        });
    };
  };

export /**
 * This service is used to update the member to the organization Used in Edit Members Page
 * @param {*} updateMember
 * @param {*} successCallback
 * @return {*}
 */
  const updateMember = (updateMember, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/Members/editMembers`, updateMember)
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the list of Members and Related To Group Used in Edit Group Page
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getMembersByGroupId = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/Groups/membersByGroupId`, obj)
        .then((resp) => {
          dispatch(doFetchMembersDataByGroupId(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchMembersDataByGroupId([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the list of Members and Related To Organization Used in Create Group Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getMembersByOrganization = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/Groups/membersByOrg/${localStorage.getItem("orgName")}`)
        .then((resp) => {
          dispatch(doFetchMembersDataByOrganization(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to check the group already exists or not Used in Create Group Page
 * @param {*} groupName
 * @param {*} callback
 * @return {*}
 */
  const isGroupExistedForMember = (obj, callback) => {
    return function () {
      interceptor
        .post(`${api}/Groups/groupNameExist`, obj)
        .then(() => { })
        .catch(() => {
          callback();
        });
    };
  };

export /**
 * This service is used to add the Group to the organization Used in Create Group Page
 * @param {*} newMember
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const addGroup = (groupInfo, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/Groups/addGroup`, groupInfo)
        .then((resp) => {
          successCallback(resp.data);
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to update the Group to the organization Used in Edit Group Page
 * @param {*} updateGroup
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const updateGroup = (updateGroup, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/Groups/editGroups`, updateGroup)
        .then(() => {
          updateGroupSuccess();
          successCallback();
        })
        .catch((err) => {
          updateGroupError(err.data);
          errorCallback();
        });
    };
  };

export /**
 * Service to be called for sharing the asset
 * @param {*} newUser
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const shareAsset = (assetDetails, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/shareAsset`, assetDetails)
        .then(() => {
          assetSharedSuccess();
          successCallback();
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.warning(error.data.message);
          }
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the school mottos under the Group SchoolMotto Page
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getSchoolMottos = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/all/`, obj)
        .then((resp) => {
          dispatch(doFetchSchoolMottos(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchSchoolMottos([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the color under the Group Used in Color Page
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getColors = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/all/`, obj)
        .then((resp) => {
          dispatch(doFetchColors(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchColors([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the ICONS
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getIcons = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/all/`, obj)
        .then((resp) => {
          dispatch(doFetchIcons(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchIcons([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the Letter Heads
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getLetterHeads = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/all/`, obj)
        .then((resp) => {
          dispatch(doFetchLetterHeads(resp.data));
          successCallback(resp.data);
        })
        .catch(() => {
          dispatch(doFetchLetterHeads([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the fonts under the Group Used in Fonts Page
 * @param {*} orgName
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getFonts = (orgName, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/fonts/getFonts/${orgName}`)
        .then((resp) => {
          dispatch(doFetchFonts(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchFonts([]));
          errorCallback();
        });
    };
  };

export /**
 * @param {*} newFNTS
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const AddingNewFonts = (newFNTS, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/fonts/uploadTtfFonts`, newFNTS, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((resp) => {
          successCallback(resp);
        })
        .catch((error) => {
          toast.warning(error.data.message);
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Get the  Assets List in  Lists Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getAssetList = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/assetmenu/getList`)
        .then((resp) => {
          dispatch(doFetchAllAssets(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchAllAssets([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Get the  Assets List in  Lists Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getOrgMembers = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/Members/getAll/${localStorage.getItem("orgName")}`)
        .then((resp) => {
          dispatch(doFetchOrgMembers(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Get the Assets List in Lists Page
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getOrgGroups = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/Groups/getAll/${localStorage.getItem("orgName")}`)
        .then((resp) => {
          dispatch(doFetchOrgGroups(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the list of Groups and Related To Asset Used in Share Page
 * @param {*} assetId
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getGroupsByAsset = (assetId, groupName, assetType, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/assets/getGroupsByAsset/${localStorage.getItem("orgName")}/${assetId}/${groupName}/${assetType}`)
        .then((resp) => {
          dispatch(doFetchGroupsDataByAssetId(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the list of Members and Related To Asset Used in Share Page
 * @param {*} assetId
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getMembersByAsset = (assetId, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/assets/getMembersByAsset/${localStorage.getItem("orgName")}/${assetId}`)
        .then((resp) => {
          dispatch(doFetchMembersDataByAssetId(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the assets by the memberId Used in School User Pages(RoleId = 3)
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getAssetsByMemberId = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/getAssetsByMemberId`, obj)
        .then((resp) => {
          dispatch(doFetchAssetsByUserId(resp.data));
          successCallback(resp.data);
        })
        .catch(() => {
          dispatch(doFetchAssetsByUserId([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the assets by the memberId Used in School User Pages(RoleId = 3)
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getColorsByMemberId = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/colours/getColorsByMemberId`, obj)
        .then((resp) => {
          dispatch(doFetchColoursConfigurations(resp.data));
          successCallback();
        })
        .catch(() => {
          dispatch(doFetchAssetsByUserId([]));
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Update the member status Used in Org Members Pages(RoleId = 2)
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const editMemberStatus = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/Members/editStatus`, obj)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
    * This service is used to Save the Colour Configurations Used in Brand Assets - Colours in School Admin Pages(RoleId = 2)
    @param {*} obj
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const saveColourConfigurations = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/colours/saveColor`, obj)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback();
        })
        .catch((error) => {
          if (error.status === 400) {
            toast.warning(error.data.message);
          }
          errorCallback();
        });
    };
  };

export /**
    * This service is used to get the Colours Used in Colour View Page
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const getColoursData = (successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/colours/getColors/${localStorage.getItem("orgName")}`)
        .then((resp) => {
          dispatch(doFetchColoursConfigurations(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
    * This service is used to get the Colours Used in Colour View Page
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const getColoursPreviewData = (orgName, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/colours/getColorsPreview/${orgName}`)
        .then((resp) => {
          dispatch(doFetchColoursConfigurations(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to get the group names
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const getGroupNames = (obj, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .post(`${api}/assets/getAssetGroups`, obj)
        .then((resp) => {
          dispatch(doFetchGroups(resp.data));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to Delete the Asset
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const deleteAsset = (assetId, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/assets/deleteAsset/${assetId}`)
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
    * This service is used to get the Google Predefined Fonts Used in Fonts Page
    @param {*} key
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const getGoogleFonts = (key, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`https://content-webfonts.googleapis.com/v1/webfonts?key=${key}`)
        .then((resp) => {
          dispatch(doFetchGoogleFonts(resp.data.items));
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to save the Fonts
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const saveFonts = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/fonts/addFonts`, obj)
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to update the Fonts
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const editFonts = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/fonts/editFonts`, obj)
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to delete the fonts under the Group Used in Fonts Page
 * @param {*} orgName
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const deleteFonts = (successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/fonts/deleteFonts/${localStorage.getItem("orgName")}`)
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
 * This service is used to check the member first name already exists or not used in Add Member Page
 * @param {*} firstName
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const isMemberFirstNameExisted = (firstName, successCallback, errorCallback) => {
    return function () {
      interceptor
        .get(`${api}/Members/checkFirstName/${localStorage.getItem("orgName")}/${firstName}`)
        .then((resp) => {
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
    * This service is used to filter the members by the Group Id used in Org Members Page
    @param {*} groupId
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const getMembersListByGroupId = (groupId, successCallback, errorCallback) => {
    return function () {
      interceptor
        .get(`${api}/Members/membersByGroupId/${localStorage.getItem("orgName")}/${groupId}`)
        .then((resp) => {
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
    * This service is used to delete the Brand Asset Menu item used in BrandAssets Page
    @param {*} id
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const deleteBrandAsset = (id, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/assetmenu/deleteAssetType/${id}`)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
    * This service is used to delete the Template Asset Menu item used in Templates Page
    @param {*} id
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const deleteTemplateAsset = (id, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/templatemenu/deleteTemplateType/${id}`)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
    * This service is used to delete the list type from the brand and template Assets used in List menu Page
    @param {*} id
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const deleteListType = (id, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/masterLogo/deleteListType/${id}`)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
    * This service is used to delete the super admin user used in members Page
    @param {*} id
    @param {*} successCallback
    @param {*} errorCallback
    @return {*}
    */
  const deleteSuperAdmin = (id, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/user/deleteSuperAdmin/${id}`)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
 * This service is used to Add/Update the Description
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const addEditDescription = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/saveDescription`, obj)
        .then((resp) => {
          successCallback();
          toast.success(resp.data.message);
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
  * This service is used to get the Description
   @param {*} successCallback
   @param {*} errorCallback
   @param {*} orgName
   @param {*} assetType
   @return {*}
  */
  const getDescription = (orgName, assetType, successCallback, errorCallback) => {
    return function (dispatch) {
      interceptor
        .get(`${api}/assets/getDescription/${orgName}/${assetType}`)
        .then((resp) => {
          if (Object.keys(resp.data.description).length == 2) {
            resp.data.description =
              '{"doc": {"type": "doc", "content": [{"type": "paragraph"}]}, "selection": {"head": 1, "type": "text", "anchor": 1}, "storedMarks": []}';
          }
          dispatch(doFetchDescription(resp.data));
          successCallback();
        })
        .catch(() => {
          let data = {
            description:
              '{"doc": {"type": "doc", "content": [{"type": "paragraph"}]}, "selection": {"head": 1, "type": "text", "anchor": 1}, "storedMarks": []}',
          };
          dispatch(doFetchDescription(data));
          errorCallback();
        });
    };
  };

export /**
  * This service is used to save the logos to the Particular Group Used in Logo Upload Modal
  @param {*} newLogos
  @param {*} successCallback
  @param {*} errorCallback
  @return {*}
  */
  const AddingThumbnails = (newLogos, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/uploadThumbnail`, newLogos)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
  * This service is used to set as group thumbnail
  @param {*} thumbnail
  @param {*} successCallback
  @param {*} errorCallback
  @return {*}
  */
  const SetAsGroupThumbnail = (thumbnail, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/setGroupThumbnail`, thumbnail)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };

export /**
  * This service is used to delete the  Organization Member
  @param {*} id
  @param {*} successCallback
  @param {*} errorCallback
  @return {*}
  */
  const deleteOrgMember = (id, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/Members/deleteMember/${id}`)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
 * This service is used to Update the asset details of Particular Group Used in Upload Modal
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const UpdateAssetDetails = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/saveAssetDetails`, obj)
        .then((resp) => {
          assetDetailsUpdatedSuccess();
          successCallback(resp);
        })
        .catch((err) => {
          toast.warning(err.data);
          errorCallback();
        });
    };
  };

export /**
  * This service is used to delete the thumbnail for assets
  @param {*} id
  @param {*} successCallback
  @param {*} errorCallback
  @return {*}
  */
  const DeleteThumbnail = (id, successCallback, errorCallback) => {
    return function () {
      interceptor
        .delete(`${api}/assets/deleteThumbnail/${id}`)
        .then((resp) => {
          toast.success(resp.data.message);
          successCallback(resp.data);
        })
        .catch((error) => {
          errorCallback(error);
        });
    };
  };

export /**
 * This service is used to Delete the asset details of Particular Group Used in Upload Modal
 * @param {*} obj
 * @param {*} successCallback
 * @param {*} errorCallback
 * @return {*}
 */
  const DeleteAssetDetails = (obj, successCallback, errorCallback) => {
    return function () {
      interceptor
        .post(`${api}/assets/deleteSavedAssets`, obj)
        .then(() => {
          successCallback();
        })
        .catch(() => {
          errorCallback();
        });
    };
  };
