import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { Carousel } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import logos from "../images/logos.png";
import { showModal, userVerificationId } from "../redux/Actions/action";
import "./Homepage.css";
import { Authorization } from "./Shared/Authorization";

/**
 * Functional component for homepage
 * @param {*} props
 * @return {*} 
 */
const Homepage = (props) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const search = useLocation().search;
  const userVerifyId = new URLSearchParams(search).get("verifyId");
  const resetLocation = location.pathname.search("/reset") != -1 ? true : false;

  useEffect(() => {
    resetLocation && dispatch(showModal("resetLocation"));
    resetLocation && dispatch(userVerificationId(userVerifyId));
  }, [resetLocation]);

  /**
   * This method will call when user clicks on login button
   */
  const loginClick = () => {
    if (props && props.getParams) {
      props.loginClick();
    } else {
      dispatch(showModal("login"));
    }
  };

  return (
    <div>
      {localStorage.getItem("status") != "true" ? (
        <div>
          <Carousel interval={10000} className="carouselSlider" touch={false}>
            <Carousel.Item className="carouselSlide1">
              <div className="container">
                <div className="row sliderGridView">
                  <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                    <Carousel.Caption className="carousel-caption">
                      <p className="text-start sliderText m-0 py-2">
                        Digital Asset Management
                        <br />
                        Platform for Educators
                      </p>
                      <div className="text-start  py-3">
                        <img src={logos} alt="Logos" />
                      </div>
                      <div className="py-4 d-flex justify-content-start">
                        <button className="login btn-auth m-0" onClick={loginClick}>
                          LOGIN
                        </button>
                      </div>
                    </Carousel.Caption>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="carouselSlide2">
              <div className="container">
                <div className="row sliderGridView">
                  <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                    <Carousel.Caption className="carousel-caption">
                      <p className="text-start sliderText m-0 py-2">
                        GrafixArk can create memorable
                        <br />
                        branded assets for your school.
                      </p>
                      <div className="text-start  py-3">
                        <p className="text-start m-0">
                          Any thing from an all important School Logo or
                          <br />
                          Crest to a House Identity or a fun and inspiring
                          <br />
                          PBL campaign.
                        </p>
                      </div>
                      <div className="py-4 d-flex justify-content-start">
                        <button className="login btn-auth m-0" onClick={loginClick}>
                          LOGIN
                        </button>
                      </div>
                    </Carousel.Caption>
                  </div>
                </div>
              </div>
            </Carousel.Item>
            <Carousel.Item className="carouselSlide3">
              <div className="container">
                <div className="row sliderGridView">
                  <div className="col-lg-6 col-md-6 col-sm-6 my-auto">
                    <Carousel.Caption className="carousel-caption">
                      <p className="text-start sliderText m-0 py-2">
                        GrafixArk is simply the perfect
                        <br />
                        platform to curate all of your
                        <br />
                        school's branded assets for
                        <br />
                        ease of use and distribution.
                      </p>
                      <div className="text-start  py-3">
                        <p className="text-start m-0">
                          If you are looking for a best practice brand asset
                          <br />
                          creation and management solution - look no further
                          <br />
                          than GrafixArk.
                        </p>
                      </div>

                      <div className="py-4 d-flex justify-content-start">
                        <button className="login btn-auth m-0" onClick={loginClick}>
                          LOGIN
                        </button>
                      </div>
                    </Carousel.Caption>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      ) : (
        <Authorization />
      )}
    </div>
  );
};
export default Homepage;
