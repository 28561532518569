import MaterialTable from "@material-table/core";
import Switch from "@material-ui/core/Switch";
import { Check, Clear, DeleteOutline, Edit, Search } from "@material-ui/icons";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import customerIcon from "../images/customericon.svg";
import deleteImg from "../images/delete.svg";
import {
  deleteOrgMember,
  editMemberStatus,
  getGroupsByOrganization,
  getMembersListByGroupId,
  getOrgMembers,
} from "../redux/Actions/action";
import "./Admin.css";
import { Loader } from "./Shared/Loader";
import ConfirmModal from "./Shared/Modals/ConfirmModal";

/* Icons used in the material table */
const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

//Switch props
const label = { inputProps: { "aria-label": "Switch demo" } };

/**
 * Functional component for organization members
 * @return {*} 
 */
const Orgmembers = () => {
  let dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayLoader, setDisplayLoader] = useState(true);
  const [paging, setPaging] = useState(true);
  const [membersMasterData, setMembersMasterData] = useState([]);
  const [showLogout, setShowLogout] = useState(false);
  const [editMemberData, setEditMemberData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [memberID, setMemberID] = useState("");

  const groupsMasterDataByOrg = useSelector((state) => state.userReducer.GroupsMasterDataByOrganization);
  const orgMembersData = useSelector((state) => state.userReducer.orgMembers);
  const optionsData = [];
  const switchUser = localStorage.getItem("switchUser");
  const roleId = localStorage.getItem("roleId");

  useEffect(() => {
    if (roleId === "1" && switchUser === "false") {
      navigate("/");
    }
    dispatch(getOrgMembers(successHandler, errorHandler));
    if (orgMembersData) {
      const val = { value: orgMembersData.length > 10 ? true : false };
      setMembersMasterData(orgMembersData);
      setPaging(val.value);
    } else {
      setPaging(false);
    }
    dispatch(getGroupsByOrganization());
  }, [orgMembersData.length]);

  useEffect(() => {
    if (orgMembersData) {
      setMembersMasterData(orgMembersData);
    }
  }, [orgMembersData]);

  for (let i = 0; i < groupsMasterDataByOrg.length; i++) {
    optionsData.push({
      id: groupsMasterDataByOrg[i].groupId,
      name: groupsMasterDataByOrg[i].groupName,
    });
  }

  /**
   * This method is used to get the Members related to Group
   * @param {*} event
   */
  const onGroupChange = (event) => {
    event.preventDefault();
    (async () => {
      dispatch(getMembersListByGroupId(event.target.value, successHandlerForGetMembers, errorHandler));
    })();
  };

  /**
   * This method will call after successful get request
   * @param {*} data
   */
  const successHandlerForGetMembers = (data) => {
    setMembersMasterData(data);
    const val = { value: data.length > 10 ? true : false };
    setPaging(val.value);
  };

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call when users click on add member button 
   */
  const addMemberHandler = () => {
    navigate("/orgmembers/add");
  };

  /**
   * This method will navigates to edit page when users click on edit icon
   * @param {*} membersMasterData
   */
  const gotoEditPage = (membersMasterData) => {
    navigate("/orgmembers/edit", { state: JSON.stringify(membersMasterData) });
  };

  /**
   * To Confirm Member Delete
   * @param {*} memberData
   */
  const onConfirmMemberDelete = (memberData) => {
    setShowDelete(true);
    setMemberID(memberData.memberId);
  };

  /**
   * Close Confirm Modal If user Clicked "No"
   */
  const closeDelete = () => {
    setShowDelete(false);
  };

  /**
   * Delete Member If user Clicked "Yes"
   */
  const deleteMemberHandler = () => {
    setShowDelete(false);
    setDisplayLoader(true);
    dispatch(deleteOrgMember(memberID, memberSuccessHandler, errorHandler));
  };

  /**
   * Method will call after successful executed function
   */
  const memberSuccessHandler = () => {
    dispatch(getOrgMembers(successHandler, errorHandler));
  };

  /**
   * This method is used to change the member status switch 
   * @param {*} event
   * @param {*} membersMasterData
   */
  const onMemberStatus = (event, membersMasterData) => {
    if (event.target.checked == true) {
      membersMasterData.status = 1;
    } else {
      membersMasterData.status = 0;
    }

    setEditMemberData(membersMasterData);
    setShowLogout(true);
  };

  /**
   * This method will call when user click on the update confirm
   */
  const updateMember = () => {
    let filteredItems = [];
    let dymStatus = "";
    if (editMemberData.status == 1) {
      dymStatus = "active";
    } else if (editMemberData.status == 0) {
      dymStatus = "disabled";
    } else {
      dymStatus = "inactive";
    }
    filteredItems = orgMembersData.map((item) => {
      if (item.memberId == editMemberData.memberId) {
        return {
          designation: item.designation,
          email: item.email,
          first_name: item.first_name,
          groupId: item.groupId,
          groupName: item.groupName,
          id: item.id,
          last_name: item.last_name,
          memberId: item.memberId,
          organizationName: item.organizationName,
          status: dymStatus,
        };
      } else {
        return item;
      }
    });
    setMembersMasterData(filteredItems);

    setDisplayLoader(true);
    delete editMemberData.tableData;
    const obj = {
      id: editMemberData.memberId,
      status: editMemberData.status,
    };
    dispatch(editMemberStatus(obj, updateSuccessHandler, updateErrorHandler));
    setTimeout(() => {
      dispatch(getOrgMembers());
    }, 500);
  };

  /**
   * This method will call after successful updation
   */
  const updateSuccessHandler = () => {
    setShowLogout(false);
    setDisplayLoader(false);
  };

  /**
   * This method will call if any error occurs in updation
   */
  const updateErrorHandler = () => {
    setShowLogout(false);
    setDisplayLoader(false);
  };

  /**
   * This method is used to close the confirm dialog
   */
  const closeConfirmDialog = () => {
    setShowLogout(false);
  };

  return (
    <div className="admin-members mt-3">
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h6 className="titLe py-2 m-0 my-auto">
            Admin <span> - Members</span>
          </h6>
        </div>
      </div>

      <div className="row">
        <h6 className="m-0 pb-0 MemberSubTitle"> Search Members </h6>
        <div className="col-lg-10 adminViewTable">
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={[
              {
                title: "Name",
                field: "first_name",
                width: "30%",
                cellStyle: {
                  textTransform: "capitalize",
                },
                render: (membersMasterData) => {
                  return (
                    <span
                      style={{
                        color: "#080707",
                        cursor: "pointer",
                      }}
                    >
                      <div className="d-flex">
                        <div className="me-2 memBrIcon">
                          <img className="img-fluid" src={customerIcon} />
                        </div>
                        <div className="my-auto">
                          <p className="m-0">
                            {membersMasterData.first_name} {membersMasterData.last_name}
                          </p>
                        </div>
                      </div>
                    </span>
                  );
                },
              },
              {
                title: "Email",
                field: "email",
              },
              {
                title: "Position",
                field: "designation",
                cellStyle: {
                  textTransform: "capitalize",
                },
              },

              {
                title: "Status",
                field: "status",
                headerStyle: { textAlign: "center" },
                render: (membersMasterData) => {
                  return (
                    <div className="text-center">
                      {membersMasterData.status == "active" ? (
                        <Switch
                          {...label}
                          checked={true}
                          onChange={(event) => onMemberStatus(event, membersMasterData)}
                        />
                      ) : membersMasterData.status == "disabled" ? (
                        <Switch
                          {...label}
                          checked={false}
                          onChange={(event) => onMemberStatus(event, membersMasterData)}
                        />
                      ) : (
                        <Switch
                          {...label}
                          checked={false}
                          disabled
                          onChange={(event) => onMemberStatus(event, membersMasterData)}
                        />
                      )}
                    </div>
                  );
                },
              },
              {
                title: "Action",
                field: "organizationMenu",
                headerStyle: { textAlign: "center" },
                render: (membersMasterData) => (
                  <div className="text-center">
                    <Edit
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color: "#080707",
                      }}
                      onClick={() => gotoEditPage(membersMasterData)}
                    />
                    <span
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                        color: "#080707",
                      }}
                      onClick={() => onConfirmMemberDelete(membersMasterData)}
                    >
                      <img alt="" src={deleteImg} />
                    </span>
                  </div>
                ),
              },
            ]}
            options={{
              search: true,
              sorting: false,
              emptyRowsWhenPaging: false,
              draggable: false,
              paging: paging,
              pageSize: 10,
              headerStyle: {
                color: "#15355C ",
                fontSize: "15px",
                fontWeight: "bold",
              },
              rowStyle: (data, index) => ({
                backgroundColor: index % 2 == 0 ? "#F2F4F6" : "white",
                color: "darkblue",
              }),
              cellStyle: {
                padding: "0px",
                paddingLeft: "15px",
              },
            }}
            components={{
              Container: (props) => (
                <div>
                  <div {...props} />
                </div>
              ),
            }}
            data={membersMasterData}
          />
        </div>

        <div className="col-lg-2 groupSelectLst">
          <button className="btnAddMember w-100" onClick={addMemberHandler}>
            ADD NEW MEMBER
          </button>
          <br />

          <div className="py-2 pt-3" style={{ fontSize: "13px" }}>
            Group Type
          </div>
          <select className="form-select members_select" onChange={(event) => onGroupChange(event)}>
            <option value={1} disabled selected>
              Select Group
            </option>
            {/* <option value={0}>ALL</option> */}
            {optionsData.map((item) => {
              return <option value={item.id}>{item.name}</option>;
            })}
          </select>
        </div>
      </div>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
      <ConfirmModal
        open={showLogout}
        onConfirm={updateMember}
        onClose={closeConfirmDialog}
        titleText={"Are you sure you want to Update Member Status?"}
      />

      {showDelete ? (
        <ConfirmModal
          open={showDelete}
          onConfirm={deleteMemberHandler}
          onClose={closeDelete}
          titleText={"Are you sure you want to delete this team member?"}
          titleText1={"This action cannot be undone!"}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Orgmembers;
