import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { envVariables } from "../../Environments/env.local";
import downloadAll from "../../images/DownloadAll.svg";
import downloadImg from "../../images/downloadlist.svg";
import editIcon from "../../images/Editicon.svg";
import permission from "../../images/permission.svg";
import Settings from "../../images/settings.svg";
import shareImg from "../../images/sharelist.svg";
import { addEditDescription, getAssetsByMemberId, getDescription, getFonts, showModal } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./brandAssets.css";
import Editor from "nib-core";
import addIcon from "../../images/add.svg";
import "../ContentEditable/editorStyles.css";
import { useCallbackPrompt } from "../Hooks/useCallbackPrompt";
import DialogBox from "../Hooks/DialogBox.component";

const api = envVariables.ip;

/**
 * Functional component for FontsView
 * @return {*} 
 */
function FontsView() {
  const FontsConfigModal = React.lazy(() => import("../Shared/Modals/FontsConfigModal"));
  const ShareModal = React.lazy(() => import("./../Buttonspages/Share"));
  const Permissions = React.lazy(() => import("../Buttonspages/permissions"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  // state for showing config modal and dialog
  const [showFontsConfig, setShowFontsConfig] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  // state for userRole
  const [userRole, setUserRole] = useState("");

  // state for description save and content
  const [saveDescriptionAction, setSaveDescriptionAction] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const [content, setContent] = useState({});
  const switchUser = localStorage.getItem("switchUser");
  const roleId = localStorage.getItem("roleId");

  let descriptionText = [];
  let innerHtmlContent = "";
  descriptionText = useSelector((state) => state.userReducer.Description);

  useEffect(() => {
    if (roleId === "1" && switchUser === "false") {
      navigate("/");
    }
    dispatch(getDescription(localStorage.getItem("orgName"), "Fonts", successHandler, errorHandler));
    innerHtmlContent = document.querySelector(".ProseMirror")?.innerHTML;

    if (innerHtmlContent) {
      document.getElementById("editContentDiv").setAttribute("class", "d-none");
      document.getElementById("contentDiv").innerHTML = innerHtmlContent;
    }
  }, [descriptionText?.description?.length]);

  let adminFontsData = [];
  let userFontsData = [];
  let fontFamilyView = [];
  let fontsSetZeroData = [];
  let fontsSetOneData = [];
  let fontsSetTwoData = [];
  let fontsSetThreeData = [];
  let fontsStaticSet = [];

  adminFontsData = useSelector((state) => state.userReducer.FontsData);
  userFontsData = useSelector((state) => state.userReducer.AssetsDataByUserId);

  if (adminFontsData?.length > 0) {
    fontsSetZeroData = adminFontsData.filter((x) => x.fontSet == 0);
    fontsSetOneData = adminFontsData.filter((x) => x.fontSet == 1);
    fontsSetTwoData = adminFontsData.filter((x) => x.fontSet == 2);
    fontsSetThreeData = adminFontsData.filter((x) => x.fontSet == 3);
    fontsStaticSet = adminFontsData.filter((x) => x.fontSet == null);
  }

  if (userFontsData?.length > 0) {
    fontsSetZeroData = userFontsData.filter((x) => x.fontSet == 0);
    fontsSetOneData = userFontsData.filter((x) => x.fontSet == 1);
    fontsSetTwoData = userFontsData.filter((x) => x.fontSet == 2);
    fontsSetThreeData = userFontsData.filter((x) => x.fontSet == 3);
    fontsStaticSet = userFontsData.filter((x) => x.fontSet == null);
  }

  sessionStorage.removeItem("fontsSet");

  useEffect(() => {
    setUserRole(localStorage.getItem("roleId"));
    setDisplayLoader(true);

    if (localStorage.getItem("roleId") == 3) {
      const obj = {
        id: localStorage.getItem("userId"),
        assetType: "Fonts",
      };
      dispatch(getAssetsByMemberId(obj, successHandler, errorHandler));
    } else {
      dispatch(getFonts(localStorage.getItem("orgName"), successHandler, errorHandler));
    }
  }, []);

  /**
   * Handle the Service success response
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 1000);
  };

  /**
   * Handle the service failure response
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * To share the assets
   */
  const onShareClick = () => {
    dispatch(showModal("share"));
  };

  /**
   * To Permission the assets
   */
  const onPermissionClick = () => {
    dispatch(showModal("permission"));
  };

  /**
   * This method is used for Edit Fonts when clicked on edit icon
   */
  const onClickEditHandler = () => {
    navigate("/brandassets/fontsEdit", {
      state: JSON.stringify(""),
    });
  };

  /**
   * @param {*} url
   * @param {*} index
   * @return {*} 
   */
  const dynamicFontForSetZero = (url, index) => {
    var upFontFace = new FontFace(`upFontZero${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontZero${index}`;
  };

  /**
   * @param {*} url
   * @param {*} index
   * @return {*} 
   */
  const dynamicFontForSetOne = (url, index) => {
    var upFontFace = new FontFace(`upFontOne${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontOne${index}`;
  };

  /**
   * @param {*} url
   * @param {*} index
   * @return {*} 
   */
  const dynamicFontForSetTwo = (url, index) => {
    var upFontFace = new FontFace(`upFontTwo${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontTwo${index}`;
  };

  /**
   * @param {*} url
   * @param {*} index
   * @return {*} 
   */
  const dynamicFontForSetThree = (url, index) => {
    var upFontFace = new FontFace(`upFontThree${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontThree${index}`;
  };

  /**
   * @param {*} url
   * @param {*} index
   * @return {*} 
   */
  const dynamicFontForStaticFields = (url, index) => {
    var upFontFace = new FontFace(`upFontStatic${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontStatic${index}`;
  };

  /**
   * to download all FontSets Fonts 
   */
  const downloadAllSetFonts = () => {
    const path = `${api}/fonts/downloadOrgFonts/${localStorage.getItem("orgName")}`;
    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", "image.png"); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  /**
   * to download individual FontSet Fonts
   * @param {*} fontFamily
   */
  const downloadPack = (fontFamily) => {
    let element = fontFamily.target;
    let fontSetId = element.id;
    const path = `${api}/fonts/downloadFonts/${localStorage.getItem("orgName")}/${fontSetId}`;

    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", "image.png");
    document.body.appendChild(link);
    link.click();
  };

  /**
   * To download the Google Font
   * @param {*} id
   */
  const downloadGoogleFont = (id) => {
    let element = id.target;
    let fontId = element.id;

    const path = `${api}/fonts/downloadGoogleFont/${fontId}`;

    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", "image.png");
    document.body.appendChild(link);
    link.click();
  };

  for (let index = 0; index < fontsSetZeroData?.length; index++) {
    fontFamilyView.push(
      fontsSetZeroData.length >= index + 1 ? (
        <div className="row">
          {index == 0 ? (
            <div className="col-lg-12">
              {/* <hr /> */}
              <div className="d-flex">
                <h6
                  className="py-3 m-0"
                  style={{
                    fontSize: `${fontsSetZeroData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetZero(fontsSetZeroData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetZeroData[index]?.fontFamily}
                </h6>
                <Tooltip title="Download Pack">
                  <img
                    id={fontsSetZeroData[index]?.fontSet}
                    role="button"
                    src={downloadAll}
                    className={fontsSetZeroData[index]?.fontFamily}
                    onClick={downloadPack}
                  />
                </Tooltip>
              </div>
              {fontsSetZeroData[0]?.description.split("\n").map((i, key) => {
                return (
                  <p className="mb-1" key={key}>
                    {i}
                  </p>
                );
              })}
            </div>
          ) : (
            <div className="col-lg-12">
              <div className="d-flex">
                <h6
                  className="py-2 m-0"
                  style={{
                    fontSize: `${fontsSetZeroData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetZero(fontsSetZeroData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetZeroData[index]?.fontName}

                  {fontsSetZeroData[index]?.fontName.length > 0 ? (
                    <Tooltip title="Download">
                      {fontsSetZeroData[index]?.type == 1 ? (
                        <a download href={fontsSetZeroData[index]?.fontURL}>
                          <img role="button" className="my-auto" src={downloadImg} />
                        </a>
                      ) : (
                        <a>
                          <img
                            role="button"
                            className="my-auto"
                            src={downloadImg}
                            id={fontsSetZeroData[index]?.id}
                            onClick={downloadGoogleFont}
                          />
                        </a>
                      )}
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </h6>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )
    );
  }

  for (let index = 0; index < fontsSetOneData?.length; index++) {
    fontFamilyView.push(
      fontsSetOneData.length >= index + 1 ? (
        <div className="row">
          {index == 0 ? (
            <div className="col-lg-12">
              <hr />
              <div className="d-flex">
                <h6
                  className="py-3 m-0"
                  style={{
                    fontSize: `${fontsSetOneData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetOne(fontsSetOneData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetOneData[index]?.fontFamily}
                </h6>
                <Tooltip title="Download Pack">
                  <img
                    id={fontsSetOneData[index]?.fontSet}
                    role="button"
                    src={downloadAll}
                    className={fontsSetOneData[index]?.fontFamily}
                    onClick={downloadPack}
                  />
                </Tooltip>
              </div>
              {fontsSetOneData[0]?.description.split("\n").map((i, key) => {
                return (
                  <p className="mb-1" key={key}>
                    {i}
                  </p>
                );
              })}
            </div>
          ) : (
            <div className="col-lg-12">
              <div className="d-flex">
                <h6
                  className="py-2 m-0"
                  style={{
                    fontSize: `${fontsSetOneData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetOne(fontsSetOneData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetOneData[index]?.fontName}

                  {fontsSetOneData[index]?.fontName.length > 0 ? (
                    <Tooltip title="Download">
                      {fontsSetOneData[index]?.type == 1 ? (
                        <a download href={fontsSetOneData[index]?.fontURL}>
                          <img role="button" className="my-auto" src={downloadImg} />
                        </a>
                      ) : (
                        <a>
                          <img
                            role="button"
                            className="my-auto"
                            src={downloadImg}
                            id={fontsSetOneData[index]?.id}
                            onClick={downloadGoogleFont}
                          />
                        </a>
                      )}
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </h6>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )
    );
  }

  for (let index = 0; index < fontsSetTwoData?.length; index++) {
    fontFamilyView.push(
      fontsSetTwoData.length >= index + 1 ? (
        <div className="row">
          {index == 0 ? (
            <div className="col-lg-12">
              <hr />
              <div className="d-flex">
                <h6
                  className="py-3 m-0"
                  style={{
                    fontSize: `${fontsSetTwoData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetTwo(fontsSetTwoData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetTwoData[index]?.fontFamily}
                </h6>
                <Tooltip title="Download Pack">
                  <img
                    id={fontsSetTwoData[index]?.fontSet}
                    role="button"
                    src={downloadAll}
                    className={fontsSetTwoData[index]?.fontFamily}
                    onClick={downloadPack}
                  />
                </Tooltip>
              </div>
              {fontsSetTwoData[0]?.description.split("\n").map((i, key) => {
                return (
                  <p className="mb-1" key={key}>
                    {i}
                  </p>
                );
              })}
            </div>
          ) : (
            <div className="col-lg-12">
              <div className="d-flex">
                <h6
                  className="py-2 m-0"
                  style={{
                    fontSize: `${fontsSetTwoData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetTwo(fontsSetTwoData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetTwoData[index]?.fontName}
                  {fontsSetTwoData[index]?.fontName.length > 0 ? (
                    <Tooltip title="Download">
                      <Tooltip title="Download">
                        {fontsSetTwoData[index]?.type == 1 ? (
                          <a download href={fontsSetTwoData[index]?.fontURL}>
                            <img role="button" className="my-auto" src={downloadImg} />
                          </a>
                        ) : (
                          <a>
                            <img
                              role="button"
                              className="my-auto"
                              src={downloadImg}
                              id={fontsSetTwoData[index]?.id}
                              onClick={downloadGoogleFont}
                            />
                          </a>
                        )}
                      </Tooltip>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </h6>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )
    );
  }

  for (let index = 0; index < fontsSetThreeData?.length; index++) {
    fontFamilyView.push(
      fontsSetThreeData.length >= index + 1 ? (
        <div className="row">
          {index == 0 ? (
            <div className="col-lg-12">
              <hr />
              <div className="d-flex">
                <h6
                  className="py-3 m-0"
                  style={{
                    fontSize: `${fontsSetThreeData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetThree(fontsSetThreeData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetThreeData[index]?.fontFamily}
                </h6>
                <Tooltip title="Download Pack">
                  <img
                    id={fontsSetThreeData[index]?.fontSet}
                    role="button"
                    src={downloadAll}
                    className={fontsSetThreeData[index]?.fontFamily}
                    onClick={downloadPack}
                  />
                </Tooltip>
              </div>
              {fontsSetThreeData[0]?.description.split("\n").map((i, key) => {
                return (
                  <p className="mb-1" key={key}>
                    {i}
                  </p>
                );
              })}
            </div>
          ) : (
            <div className="col-lg-12">
              <div className="d-flex">
                <h6
                  className="py-2 m-0"
                  style={{
                    fontSize: `${fontsSetThreeData[index]?.fontSize}pt`,
                    fontFamily: dynamicFontForSetThree(fontsSetThreeData[index]?.fontURL, index),
                  }}
                >
                  {fontsSetThreeData[index]?.fontName}
                  {fontsSetThreeData[index]?.fontName.length > 0 ? (
                    <Tooltip title="Download">
                      <Tooltip title="Download">
                        {fontsSetThreeData[index]?.type == 1 ? (
                          <a download href={fontsSetThreeData[index]?.fontURL}>
                            <img role="button" className="my-auto" src={downloadImg} />
                          </a>
                        ) : (
                          <a>
                            <img
                              role="button"
                              className="my-auto"
                              src={downloadImg}
                              id={fontsSetThreeData[index]?.id}
                              onClick={downloadGoogleFont}
                            />
                          </a>
                        )}
                      </Tooltip>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                </h6>
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )
    );
  }

  for (let index = 0; index < fontsStaticSet?.length; index++) {
    fontFamilyView.push(
      <div>
        <hr />
        <div className="row py-3">
          <div>
            {index == 0 ? (
              <div>
                <h4 className="fw-bold" style={{ fontSize: "20px" }}>
                  Font Hierarchy
                </h4>

                <p>
                  We use different fonts and weights to help order information on the page - this is called hierarchy.
                  The hierarchy we've created here refers to the order of importance of the fonts.
                </p>
                <p>
                  If you have access to design software such as Indesign, Quark Xpress etc. You may want to set up a
                  design template that incorporates this font hierarchy into 'style sheets'.
                </p>
                <hr className="my-3" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-lg-6 mt-2 my-auto">
            {index == 0 ? (
              <div className="d-flex mt-2 justify-content-between">
                <div>
                  <h1
                    className="my-auto text-wrap text-break"
                    style={{
                      fontSize: `${fontsStaticSet[index]?.fontSize}pt`,
                      lineHeight: `${fontsStaticSet[index]?.leadValue}pt`,
                      letterSpacing: `${fontsStaticSet[index]?.minTrack}pt`,
                      fontFamily: dynamicFontForStaticFields(fontsStaticSet[index]?.fontURL, index),
                    }}
                  >
                    TITLE
                  </h1>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H1
                  </span>
                </div>
              </div>
            ) : index == 1 ? (
              <div className="d-flex mt-2 justify-content-between">
                <div>
                  <h1
                    className="my-auto text-wrap text-break"
                    style={{
                      fontSize: `${fontsStaticSet[index]?.fontSize}pt`,
                      lineHeight: `${fontsStaticSet[index]?.leadValue}pt`,
                      letterSpacing: `${fontsStaticSet[index]?.minTrack}pt`,
                      fontFamily: dynamicFontForStaticFields(fontsStaticSet[index]?.fontURL, index),
                    }}
                  >
                    HEADLINE
                  </h1>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H2
                  </span>
                </div>
              </div>
            ) : index == 2 ? (
              <div className="d-flex mt-2 justify-content-between">
                <div>
                  <h1
                    className="my-auto text-wrap text-break"
                    style={{
                      fontSize: `${fontsStaticSet[index]?.fontSize}pt`,
                      lineHeight: `${fontsStaticSet[index]?.leadValue}pt`,
                      letterSpacing: `${fontsStaticSet[index]?.minTrack}pt`,
                      fontFamily: dynamicFontForStaticFields(fontsStaticSet[index]?.fontURL, index),
                    }}
                  >
                    Sub heading
                  </h1>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H3
                  </span>
                </div>
              </div>
            ) : index == 3 ? (
              <div className="d-flex mt-2 justify-content-between">
                <div>
                  <h1
                    className="my-auto text-wrap text-break"
                    style={{
                      fontSize: `${fontsStaticSet[index]?.fontSize}pt`,
                      lineHeight: `${fontsStaticSet[index]?.leadValue}pt`,
                      letterSpacing: `${fontsStaticSet[index]?.minTrack}pt`,
                      fontFamily: dynamicFontForStaticFields(fontsStaticSet[index]?.fontURL, index),
                    }}
                  >
                    Body
                  </h1>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H4
                  </span>
                </div>
              </div>
            ) : index == 4 ? (
              <div className="d-flex mt-2 justify-content-between">
                <div>
                  <h1
                    className="my-auto text-wrap text-break"
                    style={{
                      fontSize: `${fontsStaticSet[index]?.fontSize}pt`,
                      lineHeight: `${fontsStaticSet[index]?.leadValue}pt`,
                      letterSpacing: `${fontsStaticSet[index]?.minTrack}pt`,
                      fontFamily: dynamicFontForStaticFields(fontsStaticSet[index]?.fontURL, index),
                    }}
                  >
                    Captions
                  </h1>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H5
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-lg-6 my-auto ps-lg-5">
            <p className="m-0 fontLabel">Font: {fontsStaticSet[index]?.fontName}</p>
            <p className="m-0 fontDesc">
              Size: {fontsStaticSet[index]?.fontSize}pt | Leading: {fontsStaticSet[index]?.leadValue}pt | Min Tracking:
              {fontsStaticSet[index]?.minTrack}pt
            </p>
          </div>
        </div>
      </div>
    );
  }

  /**
   * Update Fonts Configuration Settings
   */
  const onClickFontsConfig = () => {
    setShowFontsConfig(true);
  };

  /**
   * SERVICE SUCCESS HANDLER
   */
  const successHandlerForGet = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      setContent(JSON.parse(descriptionText?.description));
    }, 500);
  };

  /**
   * SERVICE ERROR HANDLER
   */
  const errorHandlerForGet = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * TO MAKE THE DESCRIPTION EDITABLE
   */
  const setDescriptionEditable = () => {
    if (document.getElementById("editContentDiv")) {
      document.getElementById("editContentDiv").removeAttribute("style");
      document.getElementById("editContentDiv").removeAttribute("class");
      document.getElementById("editContentDiv").setAttribute("class", "col-11");
      document.getElementById("contentDiv").setAttribute("class", "d-none");
      setAutoFocus(true);
      setSaveDescriptionAction(true);
    }
  };

  /**
   * THIS METHOD IS USED TO SET THE UPDATED DESCRIPTION TO THE STATE VARIABLE
   * @param {*} e
   */
  const editorTextChanged = (e) => {
    setContent(e);
    setShowDialog(true);
  };

  /**
   * This method is used to SAVE DESCRIPTION
   */
  const saveDescription = () => {
    if (document.getElementById("editContentDiv")) {
      setShowDialog(false);
      setSaveDescriptionAction(false);
      const objSave = {
        orgName: localStorage.getItem("orgName"),
        assetType: "Fonts",
        description: content,
      };
      dispatch(addEditDescription(objSave, successHandlerForDescription, errorHandlerForGet));
    }
  };

  /**
   * SERVICE SUCCESS HANDLER FOR DESCRIPTION
   */
  const successHandlerForDescription = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      document.getElementById("editContentDiv").style.pointerEvents = "none";
      document.getElementById("editContentDiv").style.cursor = "not-allowed";
      document.getElementById("editContentDiv").setAttribute("class", "d-none");
      document.getElementById("contentDiv").removeAttribute("class");
      document.getElementById("contentDiv").setAttribute("class", "col-11");
      dispatch(getDescription(localStorage.getItem("orgName"), "Fonts", successHandlerForGet, errorHandlerForGet));
    }, 500);
  };

  useEffect(() => {
    return (() => {
      if (window.location.pathname == "/brandassets/fontsEdit") {
      } else {
        window.location.reload();
      }
    })

  }, [])

  return (
    <div className="mt-3 userFonts">
      <div
        className="row mb-2"
        style={{
          background: "#fff",
          zIndex: "9999",
          position: "relative",
        }}
      >
        <div className="col-lg-6 col-md-6 col-sm-6 col-12">
          <h6 className="titLe py-2 m-0 my-auto">
            Brand Assets <span> - Fonts</span>
          </h6>
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6 col-12 my-auto">
          <div className="d-flex justify-content-end">
            <div>
              {userRole === "1" || userRole === "2" ? (
                <div className="d-flex px-1 buttonList">
                  {adminFontsData.length > 0 ? (
                    <Tooltip title="Edit Fonts" placement="top-start">
                      <div className="buttonGap me-0" onClick={onClickEditHandler}>
                        <IconButton
                          className="iconsStyles borderBtn"
                          img={editIcon}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="UPDATE"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}

                  <Tooltip title="Fonts Configuration">
                    <div className="buttonGap me-0" onClick={onClickFontsConfig}>
                      <IconButton
                        className="iconsStyles borderBtn"
                        img={Settings}
                        imageClass="imageHeight"
                        buttonClass="download"
                        label="UPDATE"
                      />
                    </div>
                  </Tooltip>

                  {adminFontsData.length > 0 ? (
                    <Tooltip title="Permission" placement="top-start">
                      <div className="buttonGap me-0" onClick={onPermissionClick}>
                        <IconButton
                          className="iconsStyles borderBtn"
                          img={permission}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="Permission"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Permission" placement="top-start">
                      <div className="buttonGap me-0">
                        <IconButton
                          className="iconsStyles borderBtn disable"
                          img={permission}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="Permission"
                        />
                      </div>
                    </Tooltip>
                  )}

                  {adminFontsData.length > 0 ? (
                    <Tooltip title="Share" placement="top-start">
                      <div className="buttonGap me-0" onClick={onShareClick}>
                        <IconButton
                          className="iconsStyles borderBtn"
                          img={shareImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Share" placement="top-start">
                      <div className="buttonGap me-0">
                        <IconButton
                          className="iconsStyles borderBtn disable"
                          img={shareImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  )}

                  {adminFontsData.length > 0 ? (
                    <Tooltip title="Download All">
                      <div className="buttonGap me-0" onClick={downloadAllSetFonts}>
                        <IconButton
                          className="iconsStyles"
                          img={downloadAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Download All">
                      <div className="buttonGap me-0">
                        <IconButton
                          className="iconsStyles disable"
                          img={downloadAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              {userRole === "3" ? (
                <div className="d-flex px-1 buttonList">
                  {userFontsData.length > 0 ? (
                    <Tooltip title="Download All">
                      <div className="buttonGap me-0" onClick={downloadAllSetFonts}>
                        <IconButton
                          className="iconsStyles"
                          img={downloadAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Download All">
                      <div className="buttonGap me-0">
                        <IconButton
                          className="iconsStyles disable"
                          img={downloadAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <hr />

      <div
        className="row userColors mt-3"
        style={{
          background: "#fff",
          position: "relative",
        }}
      >
        <div className="row">
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />

          {/* DESCRIPTION READABLE - IF DESCRIPTION ALREADY EXISTS */}
          <div className="col-11 my-auto" id="contentDiv" />

          {/* DESCRIPTION EDITABLE - IF DESCRIPTION NOT AVAILABLE/ EDIT THE DESCRIPTION */}
          <div className="col-11 my-auto" id="editContentDiv" style={{ pointerEvents: "none", cursor: "not-allowed" }}>
            {descriptionText?.description ? (
              <Editor
                config={{
                  plugins: {
                    options: "inline blockquote list link",
                  },
                  toolbar: {
                    options: "top",
                    top: {
                      options: "inline blockquote list link",
                      inline: { options: "strong em underline subsup link" },
                    },
                    inline: {
                      options: "inline list",
                      inline: { options: "strong em underline" },
                    },
                  },
                }}
                onChange={(e) => editorTextChanged(e)}
                defaultValue={JSON.parse(descriptionText.description)}
                autoFocus={autoFocus}
              />
            ) : (
              ""
            )}
          </div>

          {localStorage.getItem("roleId") === "1" || localStorage.getItem("roleId") === "2" ? (
            <div className="col-1 my-auto d-flex justify-content-end">
              {saveDescriptionAction ? (
                <Tooltip title="Save Description">
                  <div className="buttonGap me-0" onClick={saveDescription}>
                    <IconButton
                      id="addIcon"
                      className="iconsStyles"
                      img={addIcon}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Edit Description">
                  <div className="buttonGap me-0" onClick={setDescriptionEditable}>
                    <IconButton
                      id="editIcon"
                      className="iconsStyles"
                      img={editIcon}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        {/* <EditText assetType="Fonts" /> */}
      </div>

      {adminFontsData?.length == 0 && (userRole === "1" || userRole === "2") ? (
        <div className="row text-center pt-5 mt-5">
          <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
            No Fonts are available
          </h1>
          <p
            style={{
              color: "#15355c",
              fontWeight: "bold",
              fontSize: "12px",
            }}
          >
            Please configure fonts by pressing above settings icon.
          </p>
        </div>
      ) : userFontsData?.length == 0 && userRole === "3" ? (
        <div className="row text-center pt-5 mt-5">
          <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
            No fonts are available
          </h1>
        </div>
      ) : (
        <div className="row mb-2" style={{ alignItems: "center" }}>
          {fontFamilyView}
        </div>
      )}

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
      <Suspense fallback={<Loader />}>
        {showFontsConfig &&
          <FontsConfigModal showConfigUpload={showFontsConfig} setShowConfigUpload={setShowFontsConfig} />
        }
        <ShareModal asset="null" assetId={fontsStaticSet[0]?.assetId} groupName="null" assetType="Fonts" />
        <Permissions asset="null" assetId={fontsStaticSet[0]?.assetId} groupName="null" assetType="Fonts" />
      </Suspense>

    </div>
  );
}

export default FontsView;
