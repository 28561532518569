import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import AdminExpandedmenu from "./AdminExpandMenu";
import Expandedmenu from "./ExpandedMenu";

/**
 * Functional component for sideBar
 * @param {*} { component }
 * @return {*} 
 */
const SideBar = ({ component }) => {
  const navigate = useNavigate();
  const loggedInDetails = useSelector((state) => state.userReducer.loggedInDetails);
  const [switchUser, setSwitchUser] = useState("");

  useEffect(() => {
    setSwitchUser(localStorage.getItem("switchUser"));
    if (localStorage.getItem("status") !== "true") {
      navigate("/");
    }
  }, [loggedInDetails]);

  return (
    <div
      style={{
        height: "calc(100vh - 206.5px)",
        overflowY: "scroll",
      }}
    >
      <>
        <div className="my-2 container">
          <div className="row">
            <div
              className="col-lg-2 col-md-2 col-sm-12 col-12"
              style={{
                height: "calc(100vh - 222px)",
                overflowY: "scroll",
                position: "sticky",
                top: "8px",
              }}
            >
              <div className="row mt-3">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="side-panel h-100">
                    {localStorage.getItem("roleId") === "1" && switchUser === "false" && (
                      <AdminExpandedmenu />
                    )}
                    {localStorage.getItem("roleId") !== "1" && <Expandedmenu />}
                    {localStorage.getItem("roleId") === "1" && switchUser === "true" && (
                      <Expandedmenu />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-10 col-md-10 col-sm-10 col-10 col-xs-10 pb-3"
              style={{
                borderLeft: "2px solid #E5E4E4",
              }}
            >
              {component}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default SideBar;
