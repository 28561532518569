import Switch from "@material-ui/core/Switch";
import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import simpleColorConverter from "simple-color-converter";
import editIcon from "../../images/edit_blue.svg";
import IconBlue from "../../images/ico_copy_blue.svg";
import CopyWhite from "../../images/ico_copy_grey.svg";
import { getColoursData, saveColourConfigurations } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import { useCallbackPrompt } from "../Hooks/useCallbackPrompt";
import { Loader } from "../Shared/Loader";
import "./ColorsView.css";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for colors edit
 * @return {*}
 */
function ColorsEdit() {
  const DialogBox = React.lazy(() => import("../Hooks/DialogBox.component.tsx"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  let loc = useLocation();
  let data = JSON.parse(loc.state);
  // useRef
  const primaryHexColorRef = useRef([]);
  const secondaryHexColorRef = useRef([]);

  const [coloursData, setColoursData] = useState(data);
  if (data == null) {
    data = coloursData;
  }
  const coloursConfigurations = data;

  // state for showing dialog
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  // Primary Conversions
  let primaryColourNames = [];
  let primaryCmykCodes = [];
  let primaryRgbCodes = [];
  let primaryPantoneCodes = [];

  // Secondary Conversions
  let secondaryColourNames = [];
  let secondaryCmykCodes = [];
  let secondaryRgbCodes = [];
  let secondaryPantoneCodes = [];

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(false);

  // state for primary and secondary colors one and two
  const [selectedPrimaryGradientColorOne, setSelectedPrimaryGradientColorOne] = useState("#eaeaeb");
  const [selectedSecondaryGradientColorOne, setSelectedSecondaryGradientColorOne] = useState("#eaeaeb");
  const [selectedPrimaryGradientColorTwo, setSelectedPrimaryGradientColorTwo] = useState("#eaeaeb");
  const [selectedSecondaryGradientColorTwo, setSelectedSecondaryGradientColorTwo] = useState("#eaeaeb");

  sessionStorage.removeItem("primaryValue");
  sessionStorage.removeItem("secondaryValue");
  sessionStorage.removeItem("gradientValue");

  const primaryGradientOptionsOne = [];
  const PrimaryGradientOptionsTwo = [];
  const secondaryGradientOptionsOne = [];
  const secondaryGradientOptionsTwo = [];

  // htmlDivs For Primary, secondary and gradient
  const primaryValuesHTMLDivs = [];
  const secondaryValuesHTMLDivs = [];
  const gradientValuesHTMLDivs = [];

  const [primaryColoursArray, setPrimaryColoursArray] = useState([]);
  const [secondaryColoursArray, setSecondaryColoursArray] = useState([]);

  // Prepare the Request object
  let obj = {
    gradientsColors: {
      g1: "",
      g2: "",
    },
    primaryColors: {
      p1: "",
      p2: "",
      p3: "",
      p4: "",
      primaryColorNames: [],
      primaryPantoneNames: [],
      primaryCMYKValues: [],
    },
    secondaryColors: {
      s1: "",
      s2: "",
      s3: "",
      s4: "",
      s1Color: "",
      s2Color: "",
      s3Color: "",
      s4Color: "",
      secondaryColorNames: [],
      secondaryPantoneNames: [],
      secondaryCMYKValues: [],
    },
    orgName: localStorage.getItem("orgName"),
  };

  // state for color
  const [color1, setColor1] = useState("#000");
  const [color2, setColor2] = useState("#000");
  const [color3, setColor3] = useState("#000");
  const [color4, setColor4] = useState("#000");

  // Switch props
  const colorChange = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (coloursData == null) {
      navigate("/brandassets/coloursview");
    }

    // Primary Colours Fill with default colours and values
    const primaryColoursNamesArray = [];
    for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
      document.getElementById("pred" + index).style.background =
        coloursConfigurations?.primaryColors["p" + [index + 1]];
      document.getElementById("h6" + index).style.color = coloursConfigurations?.primaryColors["p" + [index + 1]];
      document.getElementById("h6" + index).value = primaryColourNames[index];
      primaryColoursNamesArray.push(primaryColourNames[index]);

      var primaryNodeList = document.querySelectorAll("[id=div1-" + index + "]");
      primaryNodeList.forEach((node) => {
        node.style.background = coloursConfigurations?.primaryColors["p" + [index + 1]];
      });

      if (
        primaryCmykCodes[index]?.split("-")[0] == "null" &&
        primaryCmykCodes[index]?.split("-")[1] == "null" &&
        primaryCmykCodes[index]?.split("-")[2] == "null" &&
        primaryCmykCodes[index]?.split("-")[3] == "null"
      ) {
        document.getElementById("PC" + index).value = primaryCmykCodes[index]?.split("-")[0].replace("null", "");
        document.getElementById("PM" + index).value = primaryCmykCodes[index]?.split("-")[1].replace("null", "");
        document.getElementById("PY" + index).value = primaryCmykCodes[index]?.split("-")[2].replace("null", "");
        document.getElementById("PK" + index).value = primaryCmykCodes[index]?.split("-")[3].replace("null", "");
      } else {
        document.getElementById("PC" + index).value = primaryCmykCodes[index]?.split("-")[0].replace("null", 0);
        document.getElementById("PM" + index).value = primaryCmykCodes[index]?.split("-")[1].replace("null", 0);
        document.getElementById("PY" + index).value = primaryCmykCodes[index]?.split("-")[2].replace("null", 0);
        document.getElementById("PK" + index).value = primaryCmykCodes[index]?.split("-")[3].replace("null", 0);
      }

      document.getElementById("PR" + index).value = primaryRgbCodes[index]?.r;
      document.getElementById("PG" + index).value = primaryRgbCodes[index]?.g;
      document.getElementById("PB" + index).value = primaryRgbCodes[index]?.b;

      document.getElementById("PHex" + index).value = coloursConfigurations?.primaryColors["p" + [index + 1]];
      document.getElementById("PPantone" + index).value = primaryPantoneCodes[index];
    }
    setPrimaryColoursArray(primaryColoursNamesArray);

    // Secondary Colours Fill with default colours and values
    const secondaryColoursNamesArray = [];
    for (let index = 0; index < coloursConfigurations?.scCount; index++) {
      document.getElementById("sh6" + index).style.color = coloursConfigurations?.secondaryColors["s" + [index + 1]];
      document.getElementById("sh6" + index).value = secondaryColourNames[index];
      secondaryColoursNamesArray.push(secondaryColourNames[index]);

      if (
        secondaryCmykCodes[index]?.split("-")[0] == "null" &&
        secondaryCmykCodes[index]?.split("-")[1] == "null" &&
        secondaryCmykCodes[index]?.split("-")[2] == "null" &&
        secondaryCmykCodes[index]?.split("-")[3] == "null"
      ) {
        document.getElementById("SC" + index).value = secondaryCmykCodes[index]?.split("-")[0].replace("null", "");
        document.getElementById("SM" + index).value = secondaryCmykCodes[index]?.split("-")[1].replace("null", "");
        document.getElementById("SY" + index).value = secondaryCmykCodes[index]?.split("-")[2].replace("null", "");
        document.getElementById("SK" + index).value = secondaryCmykCodes[index]?.split("-")[3].replace("null", "");
      } else {
        document.getElementById("SC" + index).value = secondaryCmykCodes[index]?.split("-")[0].replace("null", 0);
        document.getElementById("SM" + index).value = secondaryCmykCodes[index]?.split("-")[1].replace("null", 0);
        document.getElementById("SY" + index).value = secondaryCmykCodes[index]?.split("-")[2].replace("null", 0);
        document.getElementById("SK" + index).value = secondaryCmykCodes[index]?.split("-")[3].replace("null", 0);
      }

      document.getElementById("SR" + index).value = secondaryRgbCodes[index]?.r;
      document.getElementById("SG" + index).value = secondaryRgbCodes[index]?.g;
      document.getElementById("SB" + index).value = secondaryRgbCodes[index]?.b;

      document.getElementById("SHex" + index).value = coloursConfigurations?.secondaryColors["s" + [index + 1]];
      document.getElementById("SPantone" + index).value = secondaryPantoneCodes[index];

      var secondaryNodeList = document.querySelectorAll("[id=sDiv1-" + index + "]");
      secondaryNodeList.forEach((node) => {
        node.style.background = coloursConfigurations?.secondaryColors["s" + [index + 1]];
      });
    }
    setSecondaryColoursArray(secondaryColoursNamesArray);

    // Gradient Colours Fill with default colours and values
    for (let index = 0; index < coloursConfigurations?.gcCount; index++) {
      document.getElementById("gradient" + index).style.background =
        "linear-gradient(" +
        "45deg," +
        coloursConfigurations?.gradientColors["g" + [index + 1]].split(",")[0] +
        "," +
        coloursConfigurations?.gradientColors["g" + [index + 1]].split(",")[1] +
        ")";
    }

    setColor1(coloursConfigurations?.secondaryColors.s1Color);
    setColor2(coloursConfigurations?.secondaryColors.s2Color);
    setColor3(coloursConfigurations?.secondaryColors.s3Color);
    setColor4(coloursConfigurations?.secondaryColors.s4Color);

    setTimeout(() => {
      // Default set the dropdown selected value
      const parentSelectElement1 = document.getElementById("PGS0");
      if (parentSelectElement1) {
        const childOptionElements = parentSelectElement1.getElementsByTagName("option");
        const filterHtmlCollection = Array.from(childOptionElements);
        filterHtmlCollection.splice(0, 1);
        for (let childIndex = 0; childIndex < filterHtmlCollection.length; childIndex++) {
          if (
            filterHtmlCollection[childIndex].value.split("-")[1] ==
            coloursConfigurations?.gradientColors["g1"].split(",")[0]
          ) {
            document.getElementById("PGS0").value = filterHtmlCollection[childIndex].value;
            setSelectedPrimaryGradientColorOne(filterHtmlCollection[childIndex].value.split("-")[0]);
          }
        }
      }

      const parentSelectElement2 = document.getElementById("SGS0");
      if (parentSelectElement2) {
        const childOptionElements = parentSelectElement2.getElementsByTagName("option");
        const filterHtmlCollection = Array.from(childOptionElements);
        filterHtmlCollection.splice(0, 1);
        for (let childIndex = 0; childIndex < filterHtmlCollection.length; childIndex++) {
          if (coloursConfigurations?.gcCount == 2) {
            if (
              filterHtmlCollection[childIndex].value.split("-")[1] ==
              coloursConfigurations?.gradientColors["g1"].split(",")[1]
            ) {
              if (document.getElementById("PGS0")) {
                var hidableId1 = document.getElementById("PGS0").value;
                document.getElementById(hidableId1.split("-")[0] + 1).style.display = "none";
              }
              document.getElementById("SGS0").value = filterHtmlCollection[childIndex].value;
              setSelectedSecondaryGradientColorOne(filterHtmlCollection[childIndex].value.split("-")[0]);

              setTimeout(() => {
                if (document.getElementById("SGS0")) {
                  var hidableId2 = document.getElementById("SGS0").value;
                  hidableId2 = hidableId2.split("-")[0].substring(0, hidableId2.split("-")[0].length - 1);
                  if (document.getElementById(hidableId2)) {
                    document.getElementById(hidableId2).style.display = "none";
                  }
                }
              }, 100);
            }
          } else if (coloursConfigurations?.gcCount == 1) {
            if (
              filterHtmlCollection[childIndex].value.split("-")[1] ==
              coloursConfigurations?.gradientColors["g1"].split(",")[1]
            ) {
              if (document.getElementById("PGS0")) {
                var hidableId3 = document.getElementById("PGS0").value;
                document.getElementById(hidableId3.split("-")[0] + 1).style.display = "none";
              }
              document.getElementById("SGS0").value = filterHtmlCollection[childIndex].value;
              setSelectedSecondaryGradientColorOne(filterHtmlCollection[childIndex].value.split("-")[0]);
              setTimeout(() => {
                if (document.getElementById("SGS0")) {
                  var hidableId4 = document.getElementById("SGS0").value;
                  hidableId4 = hidableId4.split("-")[0].substring(0, hidableId4.split("-")[0].length - 1);
                  if (document.getElementById(hidableId4)) {
                    document.getElementById(hidableId4).style.display = "none";
                  }
                }
              }, 100);
            }
          }
        }
      }

      const parentSelectElement3 = document.getElementById("PGS1");
      if (parentSelectElement3) {
        const childOptionElements = parentSelectElement3.getElementsByTagName("option");
        const filterHtmlCollection = Array.from(childOptionElements);
        filterHtmlCollection.splice(0, 1);
        for (let childIndex = 0; childIndex < filterHtmlCollection.length; childIndex++) {
          if (coloursConfigurations?.gcCount == 2) {
            if (
              filterHtmlCollection[childIndex].value.split("-")[1] ==
              coloursConfigurations?.gradientColors["g2"].split(",")[0]
            ) {
              document.getElementById("PGS1").value = filterHtmlCollection[childIndex].value;
              setSelectedPrimaryGradientColorTwo(filterHtmlCollection[childIndex].value.split("-")[0]);
            }
          } else if (coloursConfigurations?.gcCount == 1) {
            if (
              filterHtmlCollection[childIndex].value.split("-")[1] ==
              coloursConfigurations?.gradientColors["g1"].split(",")[1]
            ) {
              document.getElementById("PGS1").value = filterHtmlCollection[childIndex].value;
              setSelectedPrimaryGradientColorTwo(filterHtmlCollection[childIndex].value.split("-")[0]);
            }
          }
        }
      }

      const parentSelectElement4 = document.getElementById("SGS1");
      if (parentSelectElement4) {
        const childOptionElements = parentSelectElement4.getElementsByTagName("option");
        const filterHtmlCollection = Array.from(childOptionElements);
        filterHtmlCollection.splice(0, 1);
        for (let childIndex = 0; childIndex < filterHtmlCollection.length; childIndex++) {
          if (
            filterHtmlCollection[childIndex].value.split("-")[1] ==
            coloursConfigurations?.gradientColors["g2"].split(",")[1]
          ) {
            if (document.getElementById("PGS1")) {
              var hidableId5 = document.getElementById("PGS1").value;
              document.getElementById(hidableId5.split("-")[0] + 1).style.display = "none";
            }
            document.getElementById("SGS1").value = filterHtmlCollection[childIndex].value;
            setSelectedSecondaryGradientColorTwo(filterHtmlCollection[childIndex].value.split("-")[0]);
            setTimeout(() => {
              if (document.getElementById("SGS1")) {
                var hidableId6 = document.getElementById("SGS1").value;
                hidableId6 = hidableId6.split("-")[0].substring(0, hidableId6.split("-")[0].length - 1);
                if (document.getElementById(hidableId6)) {
                  document.getElementById(hidableId6).style.display = "none";
                }
              }
            }, 100);
          }
        }
      }
    }, 100);
  }, []);

  /**
   * Service Success Handler
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      dispatch(getColoursData(successHandlerTwo, errorHandler));
      navigate("/brandassets/coloursview");
    }, 500);
  };

  /**
   * Service Error Handler
   */
  const successHandlerTwo = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * Service Error Handler
   */
  const errorHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      setShowDialog(true);
    }, 500);
  };

  /**
   * This method is used to copy the Primary Colour Code
   * @param {*} index
   */
  function copyToClipboardPrimaryColor(index) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(coloursConfigurations?.primaryColors["p" + [index + 1]]).select();
    document.execCommand("copy");
    $temp.remove();
    toast.success("Copied to clipboard");
  }

  /**
   * This method is used to copy the Secondary Colour Code
   * @param {*} index
   */
  function copyToClipboardSecondaryColor(index) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(coloursConfigurations?.secondaryColors["s" + [index + 1]]).select();
    document.execCommand("copy");
    $temp.remove();
    toast.success("Copied to clipboard");
  }

  /**
   * This method is used to get the Hex colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurPrimaryHexColour = (e, index) => {
    let primaryHexValue = e.target.value;
    var regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{8})$/;
    var primaryRGBColour;
    var colourName;

    if (primaryHexValue.includes("#")) {
      if (!regex.test(primaryHexValue)) {
        document.getElementById("PHex" + index).value = "#";
        return <div className="warningMsg">{toast.warning("Invalid Hex color Code")}</div>;
      }
      primaryHexValue = primaryHexValue.replace("#", "");

      if (primaryHexValue.length == 3) {
        primaryRGBColour = new simpleColorConverter({
          hex3: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex3: e.target.value,
          to: "html",
        });
      } else if (primaryHexValue.length == 4) {
        primaryRGBColour = new simpleColorConverter({
          hex4: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex4: e.target.value,
          to: "html",
        });
      } else if (primaryHexValue.length == 6) {
        primaryRGBColour = new simpleColorConverter({
          hex6: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex6: e.target.value,
          to: "html",
        });
      } else if (primaryHexValue.length == 8) {
        primaryRGBColour = new simpleColorConverter({
          hex8: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex8: e.target.value,
          to: "html",
        });
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color must contains the #")}</div>;
    }

    if (
      primaryHexValue.length == 3 ||
      primaryHexValue.length == 4 ||
      primaryHexValue.length == 6 ||
      primaryHexValue.length == 8
    ) {
      if (!colourName.error) {
        // To set the background colour to the div strip
        document.getElementById("pred" + index).style.background = e.target.value;

        // To set the ColourName to display beside the organization name and apply the style to the div
        document.getElementById("h6" + index).style.color = e.target.value;

        // To set the Hex colour to the text box
        document.getElementById("PHex" + index).value = e.target.value;

        // To set the RGB Colour to the text box
        document.getElementById("PR" + index).value = primaryRGBColour.color.r;
        document.getElementById("PG" + index).value = primaryRGBColour.color.g;
        document.getElementById("PB" + index).value = primaryRGBColour.color.b;

        var nodeList = document.querySelectorAll("[id=div1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = e.target.value;
        });

        // ------------------------- Set the Dropdown values Start ------------------------------------------------------
        if (document.getElementById("PCG1" + index)) {
          document.getElementById("PCG1" + index).value = "PCG1" + index + "-" + e.target.value;
        }
        if (document.getElementById("PCG1" + index + 1)) {
          document.getElementById("PCG1" + index + 1).value = "PCG1" + index + 1 + "-" + e.target.value;
        }
        if (document.getElementById("PCG2" + index)) {
          document.getElementById("PCG2" + index).value = "PCG2" + index + "-" + e.target.value;
        }
        if (document.getElementById("PCG2" + index + 1)) {
          document.getElementById("PCG2" + index + 1).value = "PCG2" + index + 1 + "-" + e.target.value;
        }
        // -------------------------- Set the Dropdown values End -------------------------------------------------------
      } else {
        return <div className="warningMsg">{toast.warning(colourName.error)}</div>;
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color length must be between [3,4,6,8] excludes #")}</div>;
    }
  };

  /**
   * This method is used to get the Hex colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurSecondaryHexColour = (e, index) => {
    let secondaryHexValue = e.target.value;
    var regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{8})$/;
    var secondaryRGBColour;
    var colourName;

    if (secondaryHexValue.includes("#")) {
      if (!regex.test(secondaryHexValue)) {
        document.getElementById("SHex" + index).value = "#";
        return <div className="warningMsg">{toast.warning("Invalid Hex color Code")}</div>;
      }
      secondaryHexValue = secondaryHexValue.replace("#", "");

      if (secondaryHexValue.length == 3) {
        secondaryRGBColour = new simpleColorConverter({
          hex3: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex3: e.target.value,
          to: "html",
        });
      } else if (secondaryHexValue.length == 4) {
        secondaryRGBColour = new simpleColorConverter({
          hex4: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex4: e.target.value,
          to: "html",
        });
      } else if (secondaryHexValue.length == 6) {
        secondaryRGBColour = new simpleColorConverter({
          hex6: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex6: e.target.value,
          to: "html",
        });
      } else if (secondaryHexValue.length == 8) {
        secondaryRGBColour = new simpleColorConverter({
          hex8: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex8: e.target.value,
          to: "html",
        });
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color must contains the #")}</div>;
    }

    if (
      secondaryHexValue.length == 3 ||
      secondaryHexValue.length == 4 ||
      secondaryHexValue.length == 6 ||
      secondaryHexValue.length == 8
    ) {
      if (!colourName.error) {
        // To set the ColourName to display beside the organization name in the div
        document.getElementById("sh6" + index).style.color = e.target.value;

        // To set the Hex colour to the text box
        document.getElementById("SHex" + index).value = e.target.value;

        // To set the RGB Colour to the text box
        document.getElementById("SR" + index).value = secondaryRGBColour.color.r;
        document.getElementById("SG" + index).value = secondaryRGBColour.color.g;
        document.getElementById("SB" + index).value = secondaryRGBColour.color.b;

        var nodeList = document.querySelectorAll("[id=sDiv1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = e.target.value;
        });

        // -------------------------------- Set the Dropdown values Start ----------------------------------------------
        if (document.getElementById("SCG1" + index)) {
          document.getElementById("SCG1" + index).value = "SCG1" + index + "-" + e.target.value;
        }
        if (document.getElementById("SCG1" + index + 1)) {
          document.getElementById("SCG1" + index + 1).value = "SCG1" + index + 1 + "-" + e.target.value;
        }
        if (document.getElementById("SCG2" + index)) {
          document.getElementById("SCG2" + index).value = "SCG2" + index + "-" + e.target.value;
        }
        if (document.getElementById("SCG2" + index + 1)) {
          document.getElementById("SCG2" + index + 1).value = "SCG2" + index + 1 + "-" + e.target.value;
        }
        // -------------------------------- Set the Dropdown values End ------------------------------------------------
      } else {
        return <div className="warningMsg">{toast.warning(colourName.error)}</div>;
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color length must be between [3,4,6,8] excludes #")}</div>;
    }
  };

  /**
   * This method is used to get the RGB colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurPrimaryRGBColour = (e, index) => {
    let { name, value } = e.target;
    let hexLength = 3;
    var hexColour;

    if (
      document.getElementById("PR" + index).value != "" &&
      document.getElementById("PG" + index).value != "" &&
      document.getElementById("PB" + index).value != ""
    ) {
      if (document.getElementById("PHex" + index)) {
        var hexColourCode = document.getElementById("PHex" + index).value.replace("#", "");
        hexLength = hexColourCode.length;
      }

      if (hexLength == 3) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex3",
        });
      } else if (hexLength == 4) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex4",
        });
      } else if (hexLength == 6) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex6",
        });
      } else if (hexLength == 8) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex8",
        });
      } else {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex3",
        });
      }

      var colourName = new simpleColorConverter({
        rgb:
          "rgb " +
          document.getElementById("PR" + index).value +
          " " +
          document.getElementById("PG" + index).value +
          " " +
          document.getElementById("PB" + index).value,
        to: "html",
      });

      if (!colourName.error) {
        // To set the background colour to the div strip
        document.getElementById("pred" + index).style.background = "#" + hexColour.color;

        // To set the ColourName to display beside the organization name and apply the style to the div
        document.getElementById("h6" + index).style.color = "#" + hexColour.color;
        document.getElementById(name + index).value = value;

        // To set the Hex colour to the text box
        document.getElementById("PHex" + index).value = "#" + hexColour.color;

        var nodeList = document.querySelectorAll("[id=div1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = "#" + hexColour.color;
        });

        // ------------------------- Set the Dropdown values Start ---------------------------------------
        if (document.getElementById("PCG1" + index)) {
          document.getElementById("PCG1" + index).value = "PCG1" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("PCG1" + index + 1)) {
          document.getElementById("PCG1" + index + 1).value = "PCG1" + index + 1 + "-#" + hexColour.color;
        }
        if (document.getElementById("PCG2" + index)) {
          document.getElementById("PCG2" + index).value = "PCG2" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("PCG2" + index + 1)) {
          document.getElementById("PCG2" + index + 1).value = "PCG2" + index + 1 + "-#" + hexColour.color;
        }
        // -------------------------- Set the Dropdown values End ----------------------------------------------
      } else {
        return (
          <div className="warningMsg">{toast.warning(colourName.error + " RGB values must be between 0 to 255")}</div>
        );
      }
    } else {
      return (
        <div className="warningMsg">{toast.warning("Can't convert color RGB values must be between 0 to 255")}</div>
      );
    }
  };

  /**
   * This method is used to get the RGB colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurSecondaryRGBColour = (e, index) => {
    let { name, value } = e.target;
    let hexLength = 3;
    var hexColour;

    if (
      document.getElementById("SR" + index).value != "" &&
      document.getElementById("SG" + index).value != "" &&
      document.getElementById("SB" + index).value != ""
    ) {
      if (document.getElementById("SHex" + index)) {
        var hexColourCode = document.getElementById("SHex" + index).value.replace("#", "");
        hexLength = hexColourCode.length;
      }

      if (hexLength == 3) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex3",
        });
      } else if (hexLength == 4) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex4",
        });
      } else if (hexLength == 6) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex6",
        });
      } else if (hexLength == 8) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex8",
        });
      } else {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex3",
        });
      }

      var colourName = new simpleColorConverter({
        rgb:
          "rgb " +
          document.getElementById("SR" + index).value +
          " " +
          document.getElementById("SG" + index).value +
          " " +
          document.getElementById("SB" + index).value,
        to: "html",
      });

      if (!colourName.error) {
        // To set the ColourName to display beside the organization name in the div
        document.getElementById("sh6" + index).style.color = "#" + hexColour.color;
        document.getElementById(name + index).value = value;

        // To set the Hex colour to the text box
        document.getElementById("SHex" + index).value = "#" + hexColour.color;

        var nodeList = document.querySelectorAll("[id=sDiv1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = "#" + hexColour.color;
        });

        // --------------------------- Set the Dropdown values Start -------------------------------------
        if (document.getElementById("SCG1" + index)) {
          document.getElementById("SCG1" + index).value = "SCG1" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("SCG1" + index + 1)) {
          document.getElementById("SCG1" + index + 1).value = "SCG1" + index + 1 + "-#" + hexColour.color;
        }
        if (document.getElementById("SCG2" + index)) {
          document.getElementById("SCG2" + index).value = "SCG2" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("SCG2" + index + 1)) {
          document.getElementById("SCG2" + index + 1).value = "SCG2" + index + 1 + "-#" + hexColour.color;
        }
        // ---------------------------- Set the Dropdown values End -------------------------------------------
      } else {
        return (
          <div className="warningMsg">{toast.warning(colourName.error + " RGB values must be between 0 to 255")}</div>
        );
      }
    } else {
      return (
        <div className="warningMsg">{toast.warning("Can't convert color RGB values must be between 0 to 255")}</div>
      );
    }
  };

  /**
   * This method is used to get the CMYK colour value
   * @param {*} e
   */
  const onBlurPrimaryCMYKColour = (e) => {
    if (e.target.value > 100) {
      return <div className="warningMsg">{toast.warning("CMYK values must be between 0 to 100")}</div>;
    }
  };

  /**
   * This method is used to get the CMYK colour value
   * @param {*} e
   */
  const onBlurSecondaryCMYKColour = (e) => {
    if (e.target.value > 100) {
      return <div className="warningMsg">{toast.warning("CMYK values must be between 0 to 100")}</div>;
    }
  };

  /**
   * This method is used to default set the # to the HEX colour input
   * @param {*} event
   */
  function inputValue(event) {
    if (event.target.value.length == 0) {
      event.target.value = "#";
    } else if (event.target.value.toLowerCase().indexOf("#") == -1) {
      event.target.value = "#" + event.target.value; // prepend # to the input value
    }
  }

  /**
   * This method is fired when user select the Gradient Colours Options One
   * @param {*} event
   * @param {*} index
   */
  const gradientOne = (event, index) => {
    setShowDialog(true);
    if (event.target.value.length > 3) {
      if (event.target.value.startsWith("PCG1")) {
        setSelectedPrimaryGradientColorOne(event.target.value.split("-")[0]);
      } else if (event.target.value.startsWith("SCG1")) {
        setSelectedPrimaryGradientColorOne(event.target.value.split("-")[0]);
      } else if (event.target.value.startsWith("PCG2")) {
        setSelectedPrimaryGradientColorTwo(event.target.value.split("-")[0]);
      } else if (event.target.value.startsWith("SCG2")) {
        setSelectedPrimaryGradientColorTwo(event.target.value.split("-")[0]);
      }

      const parentSelectElement = document.getElementById("SGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index1 = 0; index1 < childOptionElements.length; index1++) {
          document.getElementById(childOptionElements[index1].id).removeAttribute("style");
        }
      }

      document.getElementById(event.target.value.split("-")[0] + "1").style.display = "none";
    } else {
      const parentSelectElement = document.getElementById("PGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index2 = 0; index2 < childOptionElements.length; index2++) {
          document.getElementById(childOptionElements[index2].id).removeAttribute("style");
        }
      }

      const parentSelectElement1 = document.getElementById("SGS" + index);
      if (parentSelectElement1) {
        const childOptionElements1 = parentSelectElement1.getElementsByTagName("option");
        for (let index3 = 0; index3 < childOptionElements1.length; index3++) {
          document.getElementById(childOptionElements1[index3].id).removeAttribute("style");
        }
      }

      document.getElementById("PGS" + index).value = "PCG";
      document.getElementById("SGS" + index).value = "SCG";

      if (index == 0) {
        setSelectedPrimaryGradientColorOne("#eaeaeb");
        setSelectedSecondaryGradientColorOne("#eaeaeb");
      } else if (index == 1) {
        setSelectedPrimaryGradientColorTwo("#eaeaeb");
        setSelectedSecondaryGradientColorTwo("#eaeaeb");
      }
    }
  };

  /**
   * This method is fired when user select the Gradient Colours Options Two
   * @param {*} event
   * @param {*} index
   */
  const gradientTwo = (event, index) => {
    setShowDialog(true);
    if (event.target.value.length > 3) {
      if (event.target.value.startsWith("PCG1")) {
        setSelectedSecondaryGradientColorOne(event.target.value.split("-")[0]);
      } else if (event.target.value.startsWith("SCG1")) {
        setSelectedSecondaryGradientColorOne(event.target.value.split("-")[0]);
      } else if (event.target.value.startsWith("PCG2")) {
        setSelectedSecondaryGradientColorTwo(event.target.value.split("-")[0]);
      } else if (event.target.value.startsWith("SCG2")) {
        setSelectedSecondaryGradientColorTwo(event.target.value.split("-")[0]);
      }

      const parentSelectElement = document.getElementById("PGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index1 = 0; index1 < childOptionElements.length; index1++) {
          document.getElementById(childOptionElements[index1].id).removeAttribute("style");
        }
      }
      let id = event.target.value.split("-")[0];
      id = id.substring(0, id.length - 1);
      document.getElementById(id).style.display = "none";
    } else {
      const parentSelectElement = document.getElementById("SGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index2 = 0; index2 < childOptionElements.length; index2++) {
          document.getElementById(childOptionElements[index2].id).removeAttribute("style");
        }
      }

      const parentSelectElement1 = document.getElementById("PGS" + index);
      if (parentSelectElement1) {
        const childOptionElements1 = parentSelectElement1.getElementsByTagName("option");
        for (let index3 = 0; index3 < childOptionElements1.length; index3++) {
          document.getElementById(childOptionElements1[index3].id).removeAttribute("style");
        }
      }

      document.getElementById("PGS" + index).value = "PCG";
      document.getElementById("SGS" + index).value = "SCG";

      if (index == 0) {
        setSelectedPrimaryGradientColorOne("#eaeaeb");
        setSelectedSecondaryGradientColorOne("#eaeaeb");
      } else if (index == 1) {
        setSelectedPrimaryGradientColorTwo("#eaeaeb");
        setSelectedSecondaryGradientColorTwo("#eaeaeb");
      }
    }
  };

  /**
   * This method is used to apply the Gradient
   * @param {*} index
   */
  const applyGradient = (index) => {
    let colourOne = "";
    let colourTwo = "";

    if (index == 0) {
      if (document.getElementById(selectedPrimaryGradientColorOne)) {
        colourOne = document.getElementById(selectedPrimaryGradientColorOne).value.split("-")[1];
      } else {
        let gr1 = document.getElementById("gradient" + index);
        gr1.style.removeProperty("background");
        gr1.style.removeProperty("backgroundImage");
        gr1.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 1")}</div>;
      }
      if (document.getElementById(selectedSecondaryGradientColorOne)) {
        colourTwo = document.getElementById(selectedSecondaryGradientColorOne).value.split("-")[1];
      } else {
        let gr2 = document.getElementById("gradient" + index);
        gr2.style.removeProperty("background");
        gr2.style.removeProperty("backgroundImage");
        gr2.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 2")}</div>;
      }
      let element = document.getElementById("gradient" + index);
      element.style.removeProperty("background");
      element.style.removeProperty("backgroundImage");
      element.style.backgroundImage = "linear-gradient(" + "45deg," + colourOne + "," + colourTwo + ")";
    }
    if (index == 1) {
      if (document.getElementById(selectedPrimaryGradientColorTwo)) {
        colourOne = document.getElementById(selectedPrimaryGradientColorTwo).value.split("-")[1];
      } else {
        let gr1 = document.getElementById("gradient" + index);
        gr1.style.removeProperty("background");
        gr1.style.removeProperty("backgroundImage");
        gr1.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 1")}</div>;
      }
      if (document.getElementById(selectedSecondaryGradientColorTwo)) {
        colourTwo = document.getElementById(selectedSecondaryGradientColorTwo).value.split("-")[1];
      } else {
        let gr2 = document.getElementById("gradient" + index);
        gr2.style.removeProperty("background");
        gr2.style.removeProperty("backgroundImage");
        gr2.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 2")}</div>;
      }
      let element = document.getElementById("gradient" + index);
      element.style.removeProperty("background");
      element.style.removeProperty("backgroundImage");
      element.style.backgroundImage = "linear-gradient(" + "45deg," + colourOne + "," + colourTwo + ")";
    }
  };

  /**
   * This method is used to restrict the special characters entry in the HEX color input
   * @param {*} event
   */
  const onkeyPress = (event) => {
    if (event.key == "Enter") {
      if (event.charCode == 13) {
        event.preventDefault();
        const nextField = document.querySelectorAll("[class*=ColorInput]");
        if (nextField[3] !== null) {
          nextField[3].focus();
        }
      }
    }
    var regex = /^[a-zA-Z0-9]+$/;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  /**
   * This method is used to Update the Gradient One Dropdown values
   */
  const onBlurPrimaryColourName = (e, index) => {
    if (e.target.value.trim().length > 0) {
      // checking the existence of colour name
      const filteredPrimary = primaryColoursArray.filter((item, id) => id !== index);
      if (filteredPrimary.includes(e.target.value.trim()) || secondaryColoursArray.includes(e.target.value.trim())) {
        document.getElementById("h6" + index).style.borderBottom = "1px solid red";
        return <div className="warningMsg">{toast.warning("Colour Name Already Exists")}</div>;
      } else {
        filteredPrimary.splice(index, 0, e.target.value.trim());
        setPrimaryColoursArray(filteredPrimary);
        
      if (coloursConfigurations?.gcCount == 1) {
        document.getElementById("PCG1" + index).textContent = e.target.value;
        document.getElementById("PCG1" + index + 1).textContent = e.target.value;
      }
      if (coloursConfigurations?.gcCount == 2) {
        document.getElementById("PCG1" + index).textContent = e.target.value;
        document.getElementById("PCG1" + index + 1).textContent = e.target.value;
        document.getElementById("PCG2" + index).textContent = e.target.value;
        document.getElementById("PCG2" + index + 1).textContent = e.target.value;
      }
      document.getElementById("h6" + index).style.borderBottom = "none";
      document.getElementById("h6" + index).removeAttribute("disabled");
    }
    } else {
      document.getElementById("h6" + index).style.borderBottom = "1px solid red";
      return <div className="warningMsg">{toast.warning("Primary Colour Name Can't be empty")}</div>;
    }
  };

  /**
   * This method is used to set the border bottom when edit the colour name
   * @param {*} index
   */
  const setPrimaryContentEditable = (index) => {
    document.getElementById("h6" + index).removeAttribute("disabled");
    document.getElementById("h6" + index).style.borderBottom = "1px solid rgb(63, 81, 181)";
  };

  /**
   * This method is used to Update the Gradient Two Dropdown values
   * @param {*} e
   * @param {*} index
   */
  const onBlurSecondaryColourName = (e, index) => {
    if (e.target.value.trim().length > 0) {
      // checking the existence of colour name
      const filteredSecondary = secondaryColoursArray.filter((item, id) => id !== index);
      if (filteredSecondary.includes(e.target.value.trim()) || primaryColoursArray.includes(e.target.value.trim())) {
        document.getElementById("sh6" + index).style.borderBottom = "1px solid red";
        return <div className="warningMsg">{toast.warning("Colour Name Already Exists")}</div>;
      } else {
        filteredSecondary.splice(index, 0, e.target.value.trim());
        setSecondaryColoursArray(filteredSecondary);

      if (coloursConfigurations?.gcCount == 1) {
        document.getElementById("SCG1" + index).textContent = e.target.value;
        document.getElementById("SCG1" + index + 1).textContent = e.target.value;
      }
      if (coloursConfigurations?.gcCount == 2) {
        document.getElementById("SCG1" + index).textContent = e.target.value;
        document.getElementById("SCG1" + index + 1).textContent = e.target.value;
        document.getElementById("SCG2" + index).textContent = e.target.value;
        document.getElementById("SCG2" + index + 1).textContent = e.target.value;
      }
      document.getElementById("sh6" + index).style.borderBottom = "none";
      document.getElementById("sh6" + index).removeAttribute("disabled");
    }
    } else {
      document.getElementById("sh6" + index).style.borderBottom = "1px solid red";
      return <div className="warningMsg">{toast.warning("Secondary Colour Name Can't be empty")}</div>;
    }
  };

  /**
   * This method is used to set the border bottom when edit the colour name
   * @param {*} index
   */
  const setSecondaryContentEditable = (index) => {
    document.getElementById("sh6" + index).removeAttribute("disabled");
    document.getElementById("sh6" + index).style.borderBottom = "1px solid rgb(63, 81, 181)";
  };

  /**
   * This method is used to save the colour
   */
  const saveColour = () => {

    // Before Prepare object Check the validations
    if (document.getElementById("PHex0")) {
      if (
        document.getElementById("PHex0").value.length == 4 ||
        document.getElementById("PHex0").value.length == 5 ||
        document.getElementById("PHex0").value.length == 7 ||
        document.getElementById("PHex0").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 1")}</div>;
      }
    }
    if (document.getElementById("PHex1")) {
      if (
        document.getElementById("PHex1").value.length == 4 ||
        document.getElementById("PHex1").value.length == 5 ||
        document.getElementById("PHex1").value.length == 7 ||
        document.getElementById("PHex1").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 2")}</div>;
      }
    }
    if (document.getElementById("PHex2")) {
      if (
        document.getElementById("PHex2").value.length == 4 ||
        document.getElementById("PHex2").value.length == 5 ||
        document.getElementById("PHex2").value.length == 7 ||
        document.getElementById("PHex2").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 3")}</div>;
      }
    }
    if (document.getElementById("PHex3")) {
      if (
        document.getElementById("PHex3").value.length == 4 ||
        document.getElementById("PHex3").value.length == 5 ||
        document.getElementById("PHex3").value.length == 7 ||
        document.getElementById("PHex3").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 4")}</div>;
      }
    }

    if (document.getElementById("SHex0")) {
      if (
        document.getElementById("SHex0").value.length == 4 ||
        document.getElementById("SHex0").value.length == 5 ||
        document.getElementById("SHex0").value.length == 7 ||
        document.getElementById("SHex0").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 1")}</div>;
      }
    }
    if (document.getElementById("SHex1")) {
      if (
        document.getElementById("SHex1").value.length == 4 ||
        document.getElementById("SHex1").value.length == 5 ||
        document.getElementById("SHex1").value.length == 7 ||
        document.getElementById("SHex1").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 2")}</div>;
      }
    }
    if (document.getElementById("SHex2")) {
      if (
        document.getElementById("SHex2").value.length == 4 ||
        document.getElementById("SHex2").value.length == 5 ||
        document.getElementById("SHex2").value.length == 7 ||
        document.getElementById("SHex2").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 3")}</div>;
      }
    }
    if (document.getElementById("SHex3")) {
      if (
        document.getElementById("SHex3").value.length == 4 ||
        document.getElementById("SHex3").value.length == 5 ||
        document.getElementById("SHex3").value.length == 7 ||
        document.getElementById("SHex3").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 4")}</div>;
      }
    }

    if (
      document.getElementById("PR0")?.value.length == 0 ||
      document.getElementById("PG0")?.value.length == 0 ||
      document.getElementById("PB0")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 1")}</div>;
    }

    if (
      document.getElementById("PR0")?.value > 255 ||
      document.getElementById("PG0")?.value > 255 ||
      document.getElementById("PB0")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC0")?.value > 100 ||
      document.getElementById("PM0")?.value > 100 ||
      document.getElementById("PY0")?.value > 100 ||
      document.getElementById("PK0")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("PR1")?.value.length == 0 ||
      document.getElementById("PG1")?.value.length == 0 ||
      document.getElementById("PB1")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 2")}</div>;
    }

    if (
      document.getElementById("PR1")?.value > 255 ||
      document.getElementById("PG1")?.value > 255 ||
      document.getElementById("PB1")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC1")?.value > 100 ||
      document.getElementById("PM1")?.value > 100 ||
      document.getElementById("PY1")?.value > 100 ||
      document.getElementById("PK1")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("PR2")?.value.length == 0 ||
      document.getElementById("PG2")?.value.length == 0 ||
      document.getElementById("PB2")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 3")}</div>;
    }

    if (
      document.getElementById("PR2")?.value > 255 ||
      document.getElementById("PG2")?.value > 255 ||
      document.getElementById("PB2")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC2")?.value > 100 ||
      document.getElementById("PM2")?.value > 100 ||
      document.getElementById("PY2")?.value > 100 ||
      document.getElementById("PK2")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("PR3")?.value.length == 0 ||
      document.getElementById("PG3")?.value.length == 0 ||
      document.getElementById("PB3")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 4")}</div>;
    }

    if (
      document.getElementById("PR3")?.value > 255 ||
      document.getElementById("PG3")?.value > 255 ||
      document.getElementById("PB3")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC3")?.value > 100 ||
      document.getElementById("PM3")?.value > 100 ||
      document.getElementById("PY3")?.value > 100 ||
      document.getElementById("PK3")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR0")?.value.length == 0 ||
      document.getElementById("SG0")?.value.length == 0 ||
      document.getElementById("SB0")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 1")}</div>;
    }

    if (
      document.getElementById("SR0")?.value > 255 ||
      document.getElementById("SG0")?.value > 255 ||
      document.getElementById("SB0")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC0")?.value > 100 ||
      document.getElementById("SM0")?.value > 100 ||
      document.getElementById("SY0")?.value > 100 ||
      document.getElementById("SK0")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR1")?.value.length == 0 ||
      document.getElementById("SG1")?.value.length == 0 ||
      document.getElementById("SB1")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 2")}</div>;
    }

    if (
      document.getElementById("SR1")?.value > 255 ||
      document.getElementById("SG1")?.value > 255 ||
      document.getElementById("SB1")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC1")?.value > 100 ||
      document.getElementById("SM1")?.value > 100 ||
      document.getElementById("SY1")?.value > 100 ||
      document.getElementById("SK1")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR2")?.value.length == 0 ||
      document.getElementById("SG2")?.value.length == 0 ||
      document.getElementById("SB2")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 3")}</div>;
    }

    if (
      document.getElementById("SR2")?.value > 255 ||
      document.getElementById("SG2")?.value > 255 ||
      document.getElementById("SB2")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC2")?.value > 100 ||
      document.getElementById("SM2")?.value > 100 ||
      document.getElementById("SY2")?.value > 100 ||
      document.getElementById("SK2")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR3")?.value.length == 0 ||
      document.getElementById("SG3")?.value.length == 0 ||
      document.getElementById("SB3")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 4")}</div>;
    }

    if (
      document.getElementById("SR3")?.value > 255 ||
      document.getElementById("SG3")?.value > 255 ||
      document.getElementById("SB3")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC3")?.value > 100 ||
      document.getElementById("SM3")?.value > 100 ||
      document.getElementById("SY3")?.value > 100 ||
      document.getElementById("SK3")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    // Prepare the Primary Colours
    if (coloursConfigurations?.pcCount == 1) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      delete obj.primaryColors.p2;
      delete obj.primaryColors.p3;
      delete obj.primaryColors.p4;
    } else if (coloursConfigurations?.pcCount == 2) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      if (document.getElementById("PHex1")) {
        if (document.getElementById("PHex1").value != "#eaeaeb") {
          obj.primaryColors.p2 = document.getElementById("PHex1").value;
        } else {
          obj.primaryColors.p2 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p2 = "#eaeaeb";
      }
      delete obj.primaryColors.p3;
      delete obj.primaryColors.p4;
    } else if (coloursConfigurations?.pcCount == 3) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      if (document.getElementById("PHex1")) {
        if (document.getElementById("PHex1").value != "#eaeaeb") {
          obj.primaryColors.p2 = document.getElementById("PHex1").value;
        } else {
          obj.primaryColors.p2 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p2 = "#eaeaeb";
      }
      if (document.getElementById("PHex2")) {
        if (document.getElementById("PHex2").value != "#eaeaeb") {
          obj.primaryColors.p3 = document.getElementById("PHex2").value;
        } else {
          obj.primaryColors.p3 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p3 = "#eaeaeb";
      }
      delete obj.primaryColors.p4;
    } else if (coloursConfigurations?.pcCount == 4) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      if (document.getElementById("PHex1")) {
        if (document.getElementById("PHex1").value != "#eaeaeb") {
          obj.primaryColors.p2 = document.getElementById("PHex1").value;
        } else {
          obj.primaryColors.p2 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p2 = "#eaeaeb";
      }
      if (document.getElementById("PHex2")) {
        if (document.getElementById("PHex2").value != "#eaeaeb") {
          obj.primaryColors.p3 = document.getElementById("PHex2").value;
        } else {
          obj.primaryColors.p3 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p3 = "#eaeaeb";
      }
      if (document.getElementById("PHex3")) {
        if (document.getElementById("PHex3").value != "#eaeaeb") {
          obj.primaryColors.p4 = document.getElementById("PHex3").value;
        } else {
          obj.primaryColors.p4 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p4 = "#eaeaeb";
      }
    }

    // Prepare the Secondary Colours
    if (coloursConfigurations?.scCount == 1) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      delete obj.secondaryColors.s2;
      delete obj.secondaryColors.s3;
      delete obj.secondaryColors.s4;
    } else if (coloursConfigurations?.scCount == 2) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      if (document.getElementById("SHex1")) {
        if (document.getElementById("SHex1").value != "#eaeaeb") {
          obj.secondaryColors.s2 = document.getElementById("SHex1").value;
        } else {
          obj.secondaryColors.s2 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s2 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      obj.secondaryColors.s2Color = color2;
      delete obj.secondaryColors.s3;
      delete obj.secondaryColors.s4;
    } else if (coloursConfigurations?.scCount == 3) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      if (document.getElementById("SHex1")) {
        if (document.getElementById("SHex1").value != "#eaeaeb") {
          obj.secondaryColors.s2 = document.getElementById("SHex1").value;
        } else {
          obj.secondaryColors.s2 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s2 = "#eaeaeb";
      }
      if (document.getElementById("SHex2")) {
        if (document.getElementById("SHex2").value != "#eaeaeb") {
          obj.secondaryColors.s3 = document.getElementById("SHex2").value;
        } else {
          obj.secondaryColors.s3 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s3 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      obj.secondaryColors.s2Color = color2;
      obj.secondaryColors.s3Color = color3;
      delete obj.secondaryColors.s4;
    } else if (coloursConfigurations?.scCount == 4) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      if (document.getElementById("SHex1")) {
        if (document.getElementById("SHex1").value != "#eaeaeb") {
          obj.secondaryColors.s2 = document.getElementById("SHex1").value;
        } else {
          obj.secondaryColors.s2 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s2 = "#eaeaeb";
      }
      if (document.getElementById("SHex2")) {
        if (document.getElementById("SHex2").value != "#eaeaeb") {
          obj.secondaryColors.s3 = document.getElementById("SHex2").value;
        } else {
          obj.secondaryColors.s3 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s3 = "#eaeaeb";
      }
      if (document.getElementById("SHex3")) {
        if (document.getElementById("SHex3").value != "#eaeaeb") {
          obj.secondaryColors.s4 = document.getElementById("SHex3").value;
        } else {
          obj.secondaryColors.s4 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s4 = "#eaeaeb";
      }
    }
    obj.secondaryColors.s1Color = color1;
    obj.secondaryColors.s2Color = color2;
    obj.secondaryColors.s3Color = color3;
    obj.secondaryColors.s4Color = color4;

    // Prepare the Gradient Colours
    if (coloursConfigurations?.gcCount == 1) {
      if (selectedPrimaryGradientColorOne != "#eaeaeb" && selectedSecondaryGradientColorOne != "#eaeaeb") {
        obj.gradientsColors.g1 =
          document.getElementById(selectedPrimaryGradientColorOne).value.split("-")[1] +
          "," +
          document.getElementById(selectedSecondaryGradientColorOne).value.split("-")[1];
      } else {
        obj.gradientsColors.g1 = "#eaeaeb,#eaeaeb";
      }
      delete obj.gradientsColors.g2;
    } else if (coloursConfigurations?.gcCount == 2) {
      if (selectedPrimaryGradientColorOne != "#eaeaeb" && selectedSecondaryGradientColorOne != "#eaeaeb") {
        obj.gradientsColors.g1 =
          document.getElementById(selectedPrimaryGradientColorOne).value.split("-")[1] +
          "," +
          document.getElementById(selectedSecondaryGradientColorOne).value.split("-")[1];
      } else {
        obj.gradientsColors.g1 = "#eaeaeb,#eaeaeb";
      }
      if (selectedPrimaryGradientColorTwo != "#eaeaeb" && selectedSecondaryGradientColorTwo != "#eaeaeb") {
        obj.gradientsColors.g2 =
          document.getElementById(selectedPrimaryGradientColorTwo).value.split("-")[1] +
          "," +
          document.getElementById(selectedSecondaryGradientColorTwo).value.split("-")[1];
      } else {
        obj.gradientsColors.g2 = "#eaeaeb,#eaeaeb";
      }
    }

    // Set Primary Colour Names to the object
    for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
      if (document.getElementById("h6" + index)) {
        if (document.getElementById("h6" + index).value.trim()) {
          obj.primaryColors.primaryColorNames.push(document.getElementById("h6" + index).value);
        } else {
          return (
            <div className="warningMsg">
              {toast.warning("Primary Colour Name - " + Number(index + 1) + " Can't be empty")}
            </div>
          );
        }
      }
    }

    // Set Primary Pantone Codes to the object
    for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
      obj.primaryColors.primaryPantoneNames.push(document.getElementById("PPantone" + index).value);
    }

    // Set Primary CMYK Values to the object
    for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
      obj.primaryColors.primaryCMYKValues.push(
        (document.getElementById("PC" + index).value || null) +
          "-" +
          (document.getElementById("PM" + index).value || null) +
          "-" +
          (document.getElementById("PY" + index).value || null) +
          "-" +
          (document.getElementById("PK" + index).value || null)
      );
    }

    // Set Secondary Colour Names to the object
    for (let index = 0; index < coloursConfigurations?.scCount; index++) {
      if (document.getElementById("sh6" + index)) {
        if (document.getElementById("sh6" + index).value.trim()) {
          obj.secondaryColors.secondaryColorNames.push(document.getElementById("sh6" + index).value);
        } else {
          return (
            <div className="warningMsg">
              {toast.warning("Secondary Colour Name - " + Number(index + 1) + " Can't be empty")}
            </div>
          );
        }
      }
    }

    // Set Secondary Pantone Codes to the object
    for (let index = 0; index < coloursConfigurations?.scCount; index++) {
      obj.secondaryColors.secondaryPantoneNames.push(document.getElementById("SPantone" + index).value);
    }

    // Set Secondary CMYK Values to the object
    for (let index = 0; index < coloursConfigurations?.scCount; index++) {
      obj.secondaryColors.secondaryCMYKValues.push(
        (document.getElementById("SC" + index).value || null) +
          "-" +
          (document.getElementById("SM" + index).value || null) +
          "-" +
          (document.getElementById("SY" + index).value || null) +
          "-" +
          (document.getElementById("SK" + index).value || null)
      );
    }
    setShowDialog(false);

    let combinedArray = obj.primaryColors.primaryColorNames + "," + obj.secondaryColors.secondaryColorNames
    const unique = [...new Set(combinedArray.split(","))]

    if (combinedArray.split(",").length != unique.length) {
      return <div className="warningMsg">{toast.warning("Colour Name Already Exists")}</div>;
    }

    if (obj.orgName) {
      setDisplayLoader(true);
      dispatch(saveColourConfigurations(obj, successHandler, errorHandler));
    }
  };

  /**
   * On Colour Change Check Box change event
   * @param {*} event
   * @param {*} index
   */
  const onColourChange = (event, index) => {
    setShowDialog(true);
    if (index == 0) {
      if (event.target.checked) {
        setColor1("#000");
      } else {
        setColor1("#fff");
      }
    } else if (index == 1) {
      if (event.target.checked) {
        setColor2("#000");
      } else {
        setColor2("#fff");
      }
    } else if (index == 2) {
      if (event.target.checked) {
        setColor3("#000");
      } else {
        setColor3("#fff");
      }
    } else if (index == 3) {
      if (event.target.checked) {
        setColor4("#000");
      } else {
        setColor4("#fff");
      }
    }
  };

  // primaryColourName and primaryPantoneCodes and primaryCMYKCodes Dynamically loaded
  for (let index = 0; index < coloursConfigurations?.primaryColors?.primaryColorNames?.split(",").length; index++) {
    primaryColourNames.push(coloursConfigurations?.primaryColors?.primaryColorNames?.split(",")[index]);
    primaryPantoneCodes.push(coloursConfigurations?.primaryColors?.primaryPantoneNames?.split(",")[index]);
    primaryCmykCodes.push(coloursConfigurations?.primaryColors?.primaryCMYKValues?.split(",")[index]);
  }

  // secondaryColourName and secondaryPantoneCodes Dynamically loaded
  for (let index = 0; index < coloursConfigurations?.secondaryColors?.secondaryColorNames?.split(",").length; index++) {
    secondaryColourNames.push(coloursConfigurations?.secondaryColors?.secondaryColorNames?.split(",")[index]);
    secondaryPantoneCodes.push(coloursConfigurations?.secondaryColors?.secondaryPantoneNames?.split(",")[index]);
    secondaryCmykCodes.push(coloursConfigurations?.secondaryColors?.secondaryCMYKValues?.split(",")[index]);
  }

  // Primary HTML Div Dynamically loaded
  for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
    var hex = coloursConfigurations?.primaryColors["p" + [index + 1]];
    hex = hex.replace("#", "");

    if (hex.length == 3) {
      var rgbColour = new simpleColorConverter({
        hex3: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(rgbColour.color);
    }
    if (hex.length == 4) {
      var rgbColour = new simpleColorConverter({
        hex4: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(rgbColour.color);
    }
    if (hex.length == 6) {
      var rgbColour = new simpleColorConverter({
        hex6: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(rgbColour.color);
    }
    if (hex.length == 8) {
      var rgbColour = new simpleColorConverter({
        hex8: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(rgbColour.color);
    }

    primaryValuesHTMLDivs.push(
      <div
        className={
          coloursConfigurations?.pcCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
            : coloursConfigurations?.pcCount == 2
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : coloursConfigurations?.pcCount == 3
            ? "col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3"
            : coloursConfigurations?.pcCount == 4
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : ""
        }
      >
        <div className="p-1" id={"pred" + index}></div>
        <div className="border-bottom py-2 d-flex justify-content-between">
          <input
            type="text"
            id={"h6" + index}
            maxLength="30"
            disabled
            autoComplete="off"
            style={{
              color: coloursConfigurations?.primaryColors["p" + [index + 1]],
            }}
            onChange={(e) => ((e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")), setShowDialog(true))}
            onBlur={(event) => onBlurPrimaryColourName(event, index)}
          />
          <Tooltip title="Edit">
            <img
              src={editIcon}
              style={{ cursor: "pointer", width: "18px", height: "18px" }}
              onClick={() => setPrimaryContentEditable(index)}
            />
          </Tooltip>
        </div>

        <div className="py-1 pb-2 d-flex border-bottom">
          <div className="col-6">
            <div style={{ width: "93%" }}>
              <div className="d-flex pb-1">
                <label className="text-title m-0 pe-2">WEB / HEX</label>
                <Tooltip title="Copy">
                  <img
                    id="imgCopy"
                    src={IconBlue}
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboardPrimaryColor(index)}
                  />
                </Tooltip>
              </div>
              <input
                type="text"
                name="PHex"
                id={"PHex" + index}
                className="ColorInput w-100"
                onInput={inputValue}
                onKeyPress={(event) => onkeyPress(event)}
                onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
                onBlur={(event) => onBlurPrimaryHexColour(event, index)}
                ref={(el) => (primaryHexColorRef.current[index] = el)}
                autoComplete="off"
              />
            </div>
          </div>
          <div className="col-6 d-flex my-auto mt-1">
            <div className="d-flex" style={{ width: "70%" }}>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title">R</div>
                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PR"
                  id={"PR" + index}
                  onBlur={(event) => onBlurPrimaryRGBColour(event, index)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title">G</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PG"
                  id={"PG" + index}
                  onBlur={(event) => onBlurPrimaryRGBColour(event, index)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title">B</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PB"
                  id={"PB" + index}
                  onBlur={(event) => onBlurPrimaryRGBColour(event, index)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-1 pt-2 d-flex border-bottom">
          <div className="col-6 my-auto">
            <div className="d-flex" style={{ width: "90%" }}>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title m-0 pb-1">C</div>
                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  name="PC"
                  id={"PC" + index}
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  onBlur={(e) => onBlurPrimaryCMYKColour(e)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title m-0 pb-1">M</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PM"
                  id={"PM" + index}
                  onBlur={(e) => onBlurPrimaryCMYKColour(e)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title m-0 pb-1">Y</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PY"
                  id={"PY" + index}
                  onBlur={(e) => onBlurPrimaryCMYKColour(e)}
                />
              </div>

              <div>
                <div className="d-flex justify-content-center text-title m-0 pb-1">K</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PK"
                  id={"PK" + index}
                  onBlur={(e) => onBlurPrimaryCMYKColour(e)}
                />
              </div>
            </div>
          </div>

          <div className="pe-3 col-3">
            <div>
              <label className="text-title m-0 pb-1">PANTONE</label>
            </div>
            <input
              type="text"
              autoComplete="off"
              name="pantone"
              maxLength="15"
              id={"PPantone" + index}
              className="ColorInput w-100"
              onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
            />
          </div>
          <div className="col-3">
            <div>
              <label className="text-title m-0 pb-1">TINTS</label>
            </div>
            <p className="m-0 TextClr pb-2">100% - 10%</p>
          </div>
        </div>

        <div className="ColourSelection d-flex">
          <div className="ColourViewGridLeft w-75" id={"div1-" + index}></div>
          <div className="ColourViewGridRight w-25">
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "1" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.9" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.8" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.7" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.6" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.5" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.4" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.3" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.2" }}></div>
            <div className="colorViewList border-bottom-0" id={"div1-" + index} style={{ opacity: "0.1" }}></div>
          </div>
        </div>
      </div>
    );
  }

  // Secondary HTML Div Dynamically loaded
  for (let index = 0; index < coloursConfigurations?.scCount; index++) {
    var hex = coloursConfigurations?.secondaryColors["s" + [index + 1]];
    hex = hex.replace("#", "");
    if (hex.length == 3) {
      var rgbColour = new simpleColorConverter({
        hex3: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(rgbColour.color);
    }
    if (hex.length == 4) {
      var rgbColour = new simpleColorConverter({
        hex4: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(rgbColour.color);
    }
    if (hex.length == 6) {
      var rgbColour = new simpleColorConverter({
        hex6: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(rgbColour.color);
    }
    if (hex.length == 8) {
      var rgbColour = new simpleColorConverter({
        hex8: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(rgbColour.color);
    }
    secondaryValuesHTMLDivs.push(
      <div
        className={
          coloursConfigurations?.scCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
            : coloursConfigurations?.scCount == 2
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : coloursConfigurations?.scCount == 3
            ? "col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3"
            : coloursConfigurations?.scCount == 4
            ? "col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3"
            : ""
        }
      >
        <div className="border-bottom py-2 d-flex">
          <div className="col-10 d-flex">
            <input
              type="text"
              className={
                coloursConfigurations?.scCount == 1 || coloursConfigurations?.scCount == 2
                  ? "col-12"
                  : coloursConfigurations?.scCount == 3
                  ? "col-11"
                  : "col-10"
              }
              id={"sh6" + index}
              maxLength="30"
              disabled
              autoComplete="off"
              onChange={(e) => ((e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")), setShowDialog(true))}
              onBlur={(event) => onBlurSecondaryColourName(event, index)}
            />

            <Tooltip title="Edit">
              <img
                src={editIcon}
                style={{ cursor: "pointer", width: "18px", height: "18px", zIndex: "1" }}
                onClick={() => setSecondaryContentEditable(index)}
              />
            </Tooltip>
          </div>
          <div className="col-2 d-flex justify-content-end">
            {index == 0 ? (
              <Switch
                {...colorChange}
                name="colorChange"
                checked={color1 == "#000" ? true : false}
                size="small"
                onChange={(event) => onColourChange(event, index)}
              />
            ) : index == 1 ? (
              <Switch
                {...colorChange}
                name="colorChange"
                checked={color2 == "#000" ? true : false}
                size="small"
                onChange={(event) => onColourChange(event, index)}
              />
            ) : index == 2 ? (
              <Switch
                {...colorChange}
                name="colorChange"
                checked={color3 == "#000" ? true : false}
                size="small"
                onChange={(event) => onColourChange(event, index)}
              />
            ) : index == 3 ? (
              <Switch
                {...colorChange}
                name="colorChange"
                checked={color4 == "#000" ? true : false}
                size="small"
                onChange={(event) => onColourChange(event, index)}
              />
            ) : (
              ""
            )}
          </div>
        </div>

        <div
          className="SecDrColorsBgr ps-1 pt-2"
          id={"sDiv1-" + index}
          style={{
            background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
          }}
        >
          <div className="py-1 ps-2">
            <div className="SecondaryWebInput">
              <div className="d-flex pb-1">
                <label
                  className="m-0 text-title pe-2"
                  style={{
                    color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                  }}
                >
                  WEB / HEX
                </label>

                <Tooltip title="Copy">
                  <img
                    id="imgCopy"
                    src={CopyWhite}
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboardSecondaryColor(index)}
                  />
                </Tooltip>
              </div>
              <input
                type="text"
                autoComplete="off"
                name="SHex"
                id={"SHex" + index}
                className="ColorInput w-100"
                onInput={inputValue}
                onKeyPress={(event) => onkeyPress(event)}
                ref={(el) => (secondaryHexColorRef.current[index] = el)}
                onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
                onBlur={(event) => onBlurSecondaryHexColour(event, index)}
              />
            </div>
          </div>

          <div className="py-1 d-flex w-100 ps-2">
            <div style={{ width: "38%" }}>
              <div className="d-flex">
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    R
                  </div>
                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SR"
                    id={"SR" + index}
                    onBlur={(event) => onBlurSecondaryRGBColour(event, index)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    G
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SG"
                    id={"SG" + index}
                    onBlur={(event) => onBlurSecondaryRGBColour(event, index)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    B
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SB"
                    id={"SB" + index}
                    onBlur={(event) => onBlurSecondaryRGBColour(event, index)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="py-1 d-flex w-100 ps-2">
            <div className="w-50">
              <div className="d-flex">
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    C
                  </div>
                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SC"
                    id={"SC" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    M
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SM"
                    id={"SM" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    Y
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SY"
                    id={"SY" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>

                <div>
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    K
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SK"
                    id={"SK" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="py-1 ps-2">
            <div className="SecondaryWebInput">
              <div>
                <label
                  className="text-title m-0 pb-1"
                  style={{
                    color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                  }}
                >
                  PANTONE
                </label>
              </div>
              <input
                type="text"
                autoComplete="off"
                name="pantone"
                maxLength="15"
                id={"SPantone" + index}
                className="ColorInput w-100 mb-3"
                onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
              />
            </div>
          </div>
        </div>

        <div className="SecondaryColourViewGrid w-100">
          <div className="ps-2 SecondaryListGrid" id={"sDiv1-" + index}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              100%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.9",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              90%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.8",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              80%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.7",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              70%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.6",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              60%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.5",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              50%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.4",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              40%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.3",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              30%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.2",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              20%
            </label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1 border-bottom-0"
            id={"sDiv1-" + index}
            style={{
              opacity: "0.1",
            }}
          >
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              10%
            </label>
          </div>
        </div>
      </div>
    );
  }

  // Gradient HTML Div Dynamically loaded
  for (let index = 0; index < coloursConfigurations?.gcCount; index++) {
    gradientValuesHTMLDivs.push(
      <div
        className={
          coloursConfigurations?.gcCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            : coloursConfigurations?.gcCount == 2
            ? "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            : ""
        }
      >
        <div className="border-bottom py-2 pt-3 d-flex justify-content-between">
          <h6 className="m-0">Gradient {index + 1}</h6>
          <h6 className="m-0 text-decoration-underline ColorApply" onClick={() => applyGradient(index)}>
            APPLY
          </h6>
        </div>

        <div className="border-bottom py-2 d-flex justify-content-between DropDownSelect">
          <div className="w-50 me-4">
            <label className="pb-1 GradinTitleLabel">Colour 1</label>
            <select
              id={"PGS" + index}
              className="form-select w-100 rounded colorSelectDropdown ps-1"
              onChange={(event) => gradientOne(event, index)}
            >
              <option value="PCG" id="PCG">
                Please Select Colour
              </option>
              {index == 0 ? primaryGradientOptionsOne : secondaryGradientOptionsOne}
            </select>
          </div>
          <div className="w-50">
            <label className="pb-1 GradinTitleLabel">Colour 2</label>
            <select
              id={"SGS" + index}
              className="form-select w-100 rounded colorSelectDropdown ps-1"
              onChange={(event) => gradientTwo(event, index)}
            >
              <option value="SCG" id="SCG">
                Please Select Colour
              </option>
              {index == 0 ? PrimaryGradientOptionsTwo : secondaryGradientOptionsTwo}
            </select>
          </div>
        </div>

        <div
          className="ColourSelection d-flex"
          id={"gradient" + index}
          style={{
            minHeight: "300px",
          }}
        ></div>
      </div>
    );
  }

  // Gradient Colour Dropdown One data to be reset
  for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
    primaryGradientOptionsOne.push(
      <option
        value={"PCG1" + index + "-" + coloursConfigurations?.primaryColors["p" + [index + 1]]}
        id={"PCG1" + index}
      >
        {primaryColourNames[index]}
      </option>
    );
    secondaryGradientOptionsOne.push(
      <option
        value={"PCG2" + index + "-" + coloursConfigurations?.primaryColors["p" + [index + 1]]}
        id={"PCG2" + index}
      >
        {primaryColourNames[index]}
      </option>
    );
    PrimaryGradientOptionsTwo.push(
      <option
        value={"PCG1" + index + "1" + "-" + coloursConfigurations?.primaryColors["p" + [index + 1]]}
        id={"PCG1" + index + "1"}
      >
        {primaryColourNames[index]}
      </option>
    );
    secondaryGradientOptionsTwo.push(
      <option
        value={"PCG2" + index + "1" + "-" + coloursConfigurations?.primaryColors["p" + [index + 1]]}
        id={"PCG2" + index + "1"}
      >
        {primaryColourNames[index]}
      </option>
    );
  }

  // Gradient Colour Dropdown Two data to be reset
  for (let index = 0; index < coloursConfigurations?.scCount; index++) {
    primaryGradientOptionsOne.push(
      <option
        value={"SCG1" + index + "-" + coloursConfigurations?.secondaryColors["s" + [index + 1]]}
        id={"SCG1" + index}
      >
        {secondaryColourNames[index]}
      </option>
    );
    secondaryGradientOptionsOne.push(
      <option
        value={"SCG2" + index + "-" + coloursConfigurations?.secondaryColors["s" + [index + 1]]}
        id={"SCG2" + index}
      >
        {secondaryColourNames[index]}
      </option>
    );
    PrimaryGradientOptionsTwo.push(
      <option
        value={"SCG1" + index + "1" + "-" + coloursConfigurations?.secondaryColors["s" + [index + 1]]}
        id={"SCG1" + index + "1"}
      >
        {secondaryColourNames[index]}
      </option>
    );
    secondaryGradientOptionsTwo.push(
      <option
        value={"SCG2" + index + "1" + "-" + coloursConfigurations?.secondaryColors["s" + [index + 1]]}
        id={"SCG2" + index + "1"}
      >
        {secondaryColourNames[index]}
      </option>
    );
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        {showPrompt && (
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        )}
      </Suspense>
      <div className="mt-3 colorSection">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <h6 className="titLe py-2 m-0 my-auto border-bottom-0">
              Brand Assets <span> - Colours</span>
            </h6>
          </div>
        </div>

        <hr className="ColorsBorder" />

        <div
          className="row userColors"
          style={{
            background: "#fff",
            // zIndex: "9999",
            position: "relative",
          }}
        >
          <EditText assetType="Colours" />
        </div>

        {coloursConfigurations?.length == 0 ? (
          <div className="row text-center pt-5 mt-5">
            <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
              No colours are available
            </h1>
            <p
              style={{
                color: "#15355c",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Please configure colours by pressing above settings icon.
            </p>
          </div>
        ) : (
          <div>
            <div className="PriMartColors py-3 pt-1">
              <div className="row mt-2">
                <div className="col-lg-6">
                  <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <p
                    className="text-uppercase pb-1 m-0 ConFigSave text-decoration-underline"
                    style={{ cursor: "pointer" }}
                    onClick={saveColour}
                  >
                    Update Configuration
                  </p>
                </div>
              </div>
              <hr className="ColorsBorder" />

              <div className="row">{primaryValuesHTMLDivs}</div>
            </div>

            {coloursConfigurations?.scCount > 0 ? (
              <div className="SecDrColors py-3">
                <h5 className="border-bottom text-uppercase pb-1">Secondary Colours</h5>
                <div className="row">{secondaryValuesHTMLDivs}</div>
              </div>
            ) : (
              ""
            )}

            {coloursConfigurations?.gcCount > 0 ? (
              <div className="GrDntColors py-3">
                <h5 className="border-bottom mb-0 text-uppercase pb-1">Gradients</h5>
                <div className="row">{gradientValuesHTMLDivs}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </>
  );
}

export default ColorsEdit;
