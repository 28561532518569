import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeImage from "../images/close.svg";
import { closeModal, resetPassword, showModal } from "../redux/Actions/action";
import "./Header.css";
import AuthenticationModal from "./Shared/AuthenticationModal";
import InputTextField from "./Shared/InputTextField";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for reset password
 * @param {*} { open, onClose, loginOpen }
 * @return {*} 
 */
function ResetPassword({ open, onClose, loginOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetPasswordModal = useSelector((state) => state.userReducer.modal);
  const userId = useSelector((state) => state.userReducer.userVerifiedId);
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [resetPasswords, setResetPasswords] = useState({
    id: userId,
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setResetPasswords((prev) => ({ ...prev, id: userId }));
  }, [userId]);

  /**
   *This method is used to validate the input fields
   * @param {*} e
   */
  const handleValidation = (e) => {
    const passwordInputValue = e?.target?.value;
    const passwordInputFieldName = e?.target?.name;

    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8}/;
      const passwordLength = passwordInputValue.trim().length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Please provide valid password";
      } else if (!minLengthPassword) {
        errMsg =
          "Password must be minimum 8 characters include one uppercase, lowercase, number and special character: @$! % * ? &";
      } else if (uppercasePassword < 1) {
        errMsg = "Must contain one uppercase letter";
      } else if (lowercasePassword < 1) {
        errMsg = "Must contain one lowercase letter";
      } else if (digitsPassword < 1) {
        errMsg = "Must contain one number";
      } else if (specialCharPassword < 1) {
        errMsg = "Must contain one special character: @$! % * ? &";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
      setConfirmPasswordError("");
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" && resetPasswords.confirmPassword.length > 0)
    ) {
      if (resetPasswords.confirmPassword !== resetPasswords.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else if (resetPasswords.password.length > 0 && resetPasswords.confirmPassword == "") {
        setConfirmPasswordError("");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  /**
   * This method is used for password submit
   * @param {*} e
   */
  const onResetPasswordSubmit = (e) => {
    e.preventDefault();
    if (resetPasswords.password == "" && resetPasswords.confirmPassword == "") {
      setPasswordErr("Please provide valid password");
      setConfirmPasswordError("Please provide valid confirm password");
    } else if (resetPasswords.password.length != "" && resetPasswords.confirmPassword == "") {
      setConfirmPasswordError("Please provide valid confirm password");
    } else if (resetPasswords.password != resetPasswords.confirmPassword) {
      setConfirmPasswordError("Confirm password is not matched");
    } else if (passwordError.length > 0 || confirmPasswordError.length > 0) {

    } else {
      dispatch(resetPassword(resetPasswords, successHandler, errorHandler));
    }
  };

  /**
   * This method will call after successful password reset
   */
  const successHandler = () => {
    toast.success("Successfully Reset the password..");
    dispatch(closeModal("resetLocation"));
    dispatch(showModal("login"));
    navigate("/");
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => { };

  /**
   * This method will call when user click on the login text
   */
  const onLoginOpen = () => {
    onClose();
    loginOpen();
  };

  /**
   *This method is used to change the values of input field
   * @param {*} e
   */
  const onResetPasswordChange = (e) => {
    let { name, value } = e.target;
    setResetPasswords({ ...resetPasswords, [name]: value.trim() });
  };

  /**
   * This method will call after successful execution
   */
  const onCloseModal = () => {
    dispatch(closeModal("resetLocation"));
  };

  /**
   *This method will call when user clicks on the close icon
   */
  const onCloseResetPasswordModal = () => {
    dispatch(closeModal("resetLocation"));
    navigate("/");
  };

  return (
    <div className="resetPasswordModal">
      <AuthenticationModal
        open={resetPasswordModal.resetLocation}
        onClose={onCloseModal}
        titleText=""
        ariaHideApp={false}
      >
        <form onSubmit={onResetPasswordSubmit} style={{ maxWidth: "285px" }}>
          <div className="container p-0 m-0 justify-content-between">
            <img src={closeImage} onClick={onCloseResetPasswordModal} alt="Close" className="userLoginModel" />
            <div className="row justify-content-between">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-2">
                <h6 className="loginHeader">Reset password</h6>
                <div className="col-12">
                  <label className="loginLabels">
                    Enter Password<span className="required-Felid">*</span>
                  </label>
                  <InputTextField
                    type="password"
                    className="loginInput w-100 mb-3"
                    name="password"
                    onChange={onResetPasswordChange}
                    onBlur={handleValidation}
                    onKeyUp={handleValidation}
                    value={resetPasswords.password}
                  />
                  {passwordError.length > 0 ?
                    <p className="resetPswd mb-0">{passwordError}</p>
                    :
                    <p className="resetPswd d-none"></p>
                  }
                </div>
                <div className="col-12">
                  <label className="loginLabels">
                    Re-enter Password
                    <span className="required-Felid">*</span>
                  </label>
                  <InputTextField
                    type="password"
                    className="loginInput w-100 mb-3"
                    name="confirmPassword"
                    onChange={onResetPasswordChange}
                    onKeyUp={handleValidation}
                    value={resetPasswords.confirmPassword}
                  />
                  {confirmPasswordError.length > 0 ?
                    <p className="resetPswd mb-0">{confirmPasswordError}</p>
                    :
                    <p className="resetPswd d-none"></p>
                  }
                </div>

                <div className="forgotPasswordAndRemember">
                  <button className="model btnModel">Reset</button>
                  <span>
                    <Link to="" className="forgotpassword m-0 float-end py-2" onClick={onLoginOpen}>
                      Login?
                    </Link>
                  </span>
                </div>
              </div>
              {/* <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 my-auto">
                <div align="center">
                  <img className="w-100" src={footerLogo} />
                </div>
              </div> */}
            </div>
          </div>
        </form>
      </AuthenticationModal>
    </div>
  );
}

export default ResetPassword;
