import * as types from "../Actions/actionType";

export const modalData = {
  signup: "signup",
  logoupload: "logoupload",
};

const addUserInitialState = {
  newUser: {},
  modal: {},
  loggedInDetails: { status: false },
  userVerifiedId: {},
  errorMessage: {},
  adminUsers: [],
  customers: [],
  GroupLogosData: [],
  StyleGuide: [],
  GroupsMasterData: [],
  MembersMasterData: [],
  GroupsMasterDataByMemberId: [],
  GroupsMasterDataByOrganization: [],
  MembersMasterDataByGroupId: [],
  MembersMasterDataByOrganization: [],
  SchoolMottosData: [],
  ColorsData: [],
  letterheads: [],
  icons: [],
  FontsData: [],
  orgMembers: [],
  orgGroups: [],
  GroupsMasterDataByAsset: [],
  MembersMasterDataByAsset: [],
  AssetsDataByUserId: [],
  ColoursData: [],
  GroupNames: [],
  GroupFonts: [],
  Description: [],
};

export const userReducer = (userAdd = addUserInitialState, action) => {
  switch (action.type) {
    case types.ADD_USER:
      return {
        ...userAdd,
      };
    case types.ADD_MEMBER:
      return {
        ...userAdd,
      };
    case types.CLOSE_MODALS:
      return {
        ...userAdd,
        modal: { ...userAdd.modal, [action.payload]: false },
      };

    case types.SHOW_MODAL:
      return {
        ...userAdd,
        modal: { ...userAdd.modal, [action.payload]: true },
      };
    case types.FORGOT_PASSWORD:
      return {
        ...userAdd,
        errorMessage: action.payload,
      };
    case types.USER_LOGIN:
      return {
        ...userAdd,
        loggedInDetails: action.payload,
      };
    case types.USER_LOGOUT:
      return {
        ...userAdd,
        loggedInDetails: { status: false },
      };
    case types.USER_VERIFIED:
      return {
        ...userAdd,
        userVerifiedId: action.payload,
      };
    case types.GET_ADMIN_USERS:
      return {
        ...userAdd,
        adminUsers: action.payload,
      };
    case types.FETCH_CUSTOMERS:
      return {
        ...userAdd,
        customers: action.payload,
      };
    case types.GROUP_LOGOS:
      return {
        ...userAdd,
        GroupLogosData: action.payload,
      };
    case types.STYLE_GUIDE:
      return {
        ...userAdd,
        StyleGuide: action.payload,
      };
    case types.SCHOOL_MOTTO:
      return {
        ...userAdd,
        SchoolMottosData: action.payload,
      };

    case types.COLORS:
      return {
        ...userAdd,
        ColorsData: action.payload,
      };
    case types.FONTS:
      return {
        ...userAdd,
        FontsData: action.payload,
      };
    case types.GROUP_MASTER_DATA:
      return {
        ...userAdd,
        GroupsMasterData: action.payload,
      };
    case types.MEMBER_MASTER_DATA:
      return {
        ...userAdd,
        MembersMasterData: action.payload,
      };
    case types.GROUP_MASTER_DATA_BY_MEMBER_ID:
      return {
        ...userAdd,
        GroupsMasterDataByMemberId: action.payload,
      };
    case types.MEMBER_MASTER_DATA_BY_GROUP_ID:
      return {
        ...userAdd,
        MembersMasterDataByGroupId: action.payload,
      };
    case types.GROUP_MASTER_DATA_BY_ORGANIZATION:
      return {
        ...userAdd,
        GroupsMasterDataByOrganization: action.payload,
      };
    case types.MEMBER_MASTER_DATA_BY_ORGANIZATION:
      return {
        ...userAdd,
        MembersMasterDataByOrganization: action.payload,
      };
    case types.LETTER_HEADS:
      return {
        ...userAdd,
        letterheads: action.payload,
      };
    case types.ICONS:
      return {
        ...userAdd,
        icons: action.payload,
      };
    case types.ORG_MEMBERS:
      return {
        ...userAdd,
        orgMembers: action.payload,
      };
    case types.ORG_GROUPS:
      return {
        ...userAdd,
        orgGroups: action.payload,
      };
    case types.GROUP_MASTER_DATA_BY_ASSETS_ID:
      return {
        ...userAdd,
        GroupsMasterDataByAsset: action.payload,
      };
    case types.MEMBER_MASTER_DATA_BY_ASSETS_ID:
      return {
        ...userAdd,
        MembersMasterDataByAsset: action.payload,
      };
    case types.FETCH_ASSETS_BY_USER_ID:
      return {
        ...userAdd,
        AssetsDataByUserId: action.payload,
      };
    case types.FETCH_COLOURS_CONFIGURATIONS_BY_ORG_NAME:
      return {
        ...userAdd,
        ColoursData: action.payload,
      };
    case types.FETCH_GROUP_NAMES:
      return {
        ...userAdd,
        GroupNames: action.payload,
      };
    case types.FETCH_GOOGLE_FONTS:
      return {
        ...userAdd,
        GroupFonts: action.payload,
      };
    case types.DESCRIPTION:
      return {
        ...userAdd,
        Description: action.payload,
      };
    default:
      return {
        ...userAdd,
      };
  }
};
