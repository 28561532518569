import Switch from "@material-ui/core/Switch";
import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import simpleColorConverter from "simple-color-converter";
import editIcon from "../../images/edit_blue.svg";
import IconBlue from "../../images/ico_copy_blue.svg";
import CopyWhite from "../../images/ico_copy_grey.svg";
import Settings from "../../images/settings.svg";
import { getColoursData, saveColourConfigurations } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import { useCallbackPrompt } from "../Hooks/useCallbackPrompt";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./Colors.css";

toast.configure({
  autoClose: 2000,
});

/**
 * Colours Functional component
 * @return {*}
 */
function Colors() {
  const ColourUploadModal = React.lazy(() => import("../../Components/Shared/Modals/ColourUploadModal"));
  const DialogBox = React.lazy(() => import("../Hooks/DialogBox.component.tsx"));

  let dispatch = useDispatch();
  let navigate = useNavigate();

  // state for showing configurtaion modal and dialog
  const [showColorConfiguration, setShowColorConfiguration] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  // Primary, Secondary and Gradient Colours Dynamic Sections
  const primaryValuesHTMLDivs = [];
  const secondaryValuesHTMLDivs = [];
  const gradientValuesHTMLDivs = [];

  // Dynamic Gradient Dropdown Options
  const primaryGradientOptionsOne = [];
  const PrimaryGradientOptionsTwo = [];
  const secondaryGradientOptionsOne = [];
  const secondaryGradientOptionsTwo = [];

  // Used to copy the Colour code of Primary and Secondary
  const primaryHexColorRef = useRef([]);
  const secondaryHexColorRef = useRef([]);

  // Used to display the loader while we call the service ot timeOut functions
  const [displayLoader, setDisplayLoader] = useState(false);

  // Used for Gradient Selection in Dropdown
  const [selectedPrimaryGradientColorOne, setSelectedPrimaryGradientColorOne] = useState("#eaeaeb");
  const [selectedSecondaryGradientColorOne, setSelectedSecondaryGradientColorOne] = useState("#eaeaeb");
  const [selectedPrimaryGradientColorTwo, setSelectedPrimaryGradientColorTwo] = useState("#eaeaeb");
  const [selectedSecondaryGradientColorTwo, setSelectedSecondaryGradientColorTwo] = useState("#eaeaeb");

  // Used to set the Colour Configuration based on below counts
  const [primaryCount, setPrimaryCount] = useState(sessionStorage.getItem("primaryValue") || 0);
  const [secondaryCount, setSecondaryCount] = useState(sessionStorage.getItem("secondaryValue") || 0);
  const [gradientCount, setGradientCount] = useState(sessionStorage.getItem("gradientValue") || 0);

  // Below variables are used for secondary section label colour
  const [color1, setColor1] = useState("#000");
  const [color2, setColor2] = useState("#000");
  const [color3, setColor3] = useState("#000");
  const [color4, setColor4] = useState("#000");

  const [primaryColourNames, setPrimaryColourNames] = useState([]);
  const [secondaryColourNames, setSecondaryColourNames] = useState([]);

  // Prepare the Request object
  let obj = {
    gradientsColors: {
      g1: "",
      g2: "",
    },
    primaryColors: {
      p1: "",
      p2: "",
      p3: "",
      p4: "",
      primaryColorNames: [],
      primaryPantoneNames: [],
      primaryCMYKValues: [],
    },
    secondaryColors: {
      s1: "",
      s2: "",
      s3: "",
      s4: "",
      s1Color: "",
      s2Color: "",
      s3Color: "",
      s4Color: "",
      secondaryColorNames: [],
      secondaryPantoneNames: [],
      secondaryCMYKValues: [],
    },
    orgName: localStorage.getItem("orgName"),
  };

  // Switch props
  const colorChange = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    if (primaryCount == 0 || !primaryCount) {
      navigate("/");
    }
    setPrimaryCount(sessionStorage.getItem("primaryValue"));
    setSecondaryCount(sessionStorage.getItem("secondaryValue"));
    setGradientCount(sessionStorage.getItem("gradientValue"));

    // Primary Colours Fill with default colours and values
    const primaryColoursNamesArray = [];
    for (let index = 0; index < primaryCount; index++) {
      document.getElementById("pred" + index).style.background = "#eaeaeb";
      document.getElementById("h6" + index).style.color = "#9A9B9F";
      document.getElementById("h6" + index).value = "Primary Colour " + Number(index + 1);
      primaryColoursNamesArray.push("Primary Colour " + Number(index + 1));

      var primaryNodeList = document.querySelectorAll("[id=div1-" + index + "]");
      primaryNodeList.forEach((node) => {
        node.style.background = "#eaeaeb";
      });

      document.getElementById("PC" + index).value = "";
      document.getElementById("PM" + index).value = "";
      document.getElementById("PY" + index).value = "";
      document.getElementById("PK" + index).value = "";

      document.getElementById("PR" + index).value = 234;
      document.getElementById("PG" + index).value = 234;
      document.getElementById("PB" + index).value = 235;

      document.getElementById("PHex" + index).value = "#eaeaeb";
      document.getElementById("PPantone" + index).value = "Not Provided";
    }
    setPrimaryColourNames(primaryColoursNamesArray);

    // Secondary Colours Fill with default colours and values
    const secondaryColoursNamesArray = [];
    for (let index = 0; index < secondaryCount; index++) {
      document.getElementById("sh6" + index).style.color = "#9A9B9F";
      document.getElementById("sh6" + index).value = "Secondary Colour " + Number(index + 1);
      secondaryColoursNamesArray.push("Secondary Colour " + Number(index + 1));

      var secondaryNodeList = document.querySelectorAll("[id=sDiv1-" + index + "]");
      secondaryNodeList.forEach((node) => {
        node.style.background = "#eaeaeb";
      });

      document.getElementById("SC" + index).value = "";
      document.getElementById("SM" + index).value = "";
      document.getElementById("SY" + index).value = "";
      document.getElementById("SK" + index).value = "";

      document.getElementById("SR" + index).value = 234;
      document.getElementById("SG" + index).value = 234;
      document.getElementById("SB" + index).value = 235;

      document.getElementById("SHex" + index).value = "#eaeaeb";
      document.getElementById("SPantone" + index).value = "Not Provided";
    }
    setSecondaryColourNames(secondaryColoursNamesArray);

    // Gradient Colours Fill with default colours and values
    for (let index = 0; index < gradientCount; index++) {
      document.getElementById("gradient" + index).style.background = "#eaeaeb";
    }

    // Gradient Colours Dropdown One data to be reset
    for (let index = 0; index < primaryCount; index++) {
      primaryGradientOptionsOne.push(
        <option value={"PCG1" + index + "-#eaeaeb"} id={"PCG1" + index}>
          {"Primary Colour" + Number(index + 1)}
        </option>
      );
      secondaryGradientOptionsOne.push(
        <option value={"PCG2" + index + "-#eaeaeb"} id={"PCG2" + index}>
          {"Primary Colour" + Number(index + 1)}
        </option>
      );
      PrimaryGradientOptionsTwo.push(
        <option value={"PCG1" + index + "1" + "-#eaeaeb"} id={"PCG1" + index + "1"}>
          {"Primary Colour" + Number(index + 1)}
        </option>
      );
      secondaryGradientOptionsTwo.push(
        <option value={"PCG2" + index + "1" + "-#eaeaeb"} id={"PCG2" + index + "1"}>
          {"Primary Colour" + Number(index + 1)}
        </option>
      );
    }

    // Gradient Colours Dropdown Two data to be reset
    for (let index = 0; index < secondaryCount; index++) {
      primaryGradientOptionsOne.push(
        <option value={"SCG1" + index + "-#eaeaeb"} id={"SCG1" + index}>
          {"Secondary Colour" + Number(index + 1)}
        </option>
      );
      secondaryGradientOptionsOne.push(
        <option value={"SCG2" + index + "-#eaeaeb"} id={"SCG2" + index}>
          {"Secondary Colour" + Number(index + 1)}
        </option>
      );
      PrimaryGradientOptionsTwo.push(
        <option value={"SCG1" + index + "1" + "-#eaeaeb"} id={"SCG1" + index + "1"}>
          {"Secondary Colour" + Number(index + 1)}
        </option>
      );
      secondaryGradientOptionsTwo.push(
        <option value={"SCG2" + index + "1" + "-#eaeaeb"} id={"SCG2" + index + "1"}>
          {"Secondary Colour" + Number(index + 1)}
        </option>
      );
    }
  }, [
    sessionStorage.getItem("primaryValue"),
    sessionStorage.getItem("secondaryValue"),
    sessionStorage.getItem("gradientValue"),
    primaryCount,
    secondaryCount,
    gradientCount,
  ]);

  /**
   * This method is used to get the Hex colours value
   * @param {*} e
   * @param {*} index
   */
  const onBlurPrimaryHexColour = (e, index) => {
    let hex = e.target.value;
    var regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{8})$/;
    var rgbColour;
    var colourName;

    if (hex.includes("#")) {
      if (!regex.test(hex)) {
        document.getElementById("PHex" + index).value = "#";
        return <div className="warningMsg">{toast.warning("Invalid Hex color Code")}</div>;
      }
      hex = hex.replace("#", "");
      if (hex.length == 3) {
        rgbColour = new simpleColorConverter({
          hex3: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex3: e.target.value,
          to: "html",
        });
      } else if (hex.length == 4) {
        rgbColour = new simpleColorConverter({
          hex4: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex4: e.target.value,
          to: "html",
        });
      } else if (hex.length == 6) {
        rgbColour = new simpleColorConverter({
          hex6: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex6: e.target.value,
          to: "html",
        });
      } else if (hex.length == 8) {
        rgbColour = new simpleColorConverter({
          hex8: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex8: e.target.value,
          to: "html",
        });
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color must contains the #")}</div>;
    }

    if (hex.length == 3 || hex.length == 4 || hex.length == 6 || hex.length == 8) {
      if (!colourName.error) {
        // To set the background colour to the div strip
        document.getElementById("pred" + index).style.background = e.target.value;

        // To set the ColourName to display beside the organization name and apply the style to the div
        document.getElementById("h6" + index).style.color = e.target.value;

        // To set the Hex colour to the text box
        document.getElementById("PHex" + index).value = e.target.value;

        // To set the RGB Colour to the text box
        document.getElementById("PR" + index).value = rgbColour.color.r;
        document.getElementById("PG" + index).value = rgbColour.color.g;
        document.getElementById("PB" + index).value = rgbColour.color.b;

        var nodeList = document.querySelectorAll("[id=div1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = e.target.value;
        });

        // ------------------------------ Set the Dropdown values Start ----------------------------------
        if (document.getElementById("PCG1" + index)) {
          document.getElementById("PCG1" + index).value = "PCG1" + index + "-" + e.target.value;
        }
        if (document.getElementById("PCG1" + index + 1)) {
          document.getElementById("PCG1" + index + 1).value = "PCG1" + index + 1 + "-" + e.target.value;
        }
        if (document.getElementById("PCG2" + index)) {
          document.getElementById("PCG2" + index).value = "PCG2" + index + "-" + e.target.value;
        }
        if (document.getElementById("PCG2" + index + 1)) {
          document.getElementById("PCG2" + index + 1).value = "PCG2" + index + 1 + "-" + e.target.value;
        }
        // ------------------------------ Set the Dropdown values End ------------------------------------
      } else {
        return <div className="warningMsg">{toast.warning(colourName.error)}</div>;
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color length must be between [3,4,6,8] excludes #")}</div>;
    }
  };

  /**
   * This method is used to get the Hex colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurSecondaryHexColour = (e, index) => {
    let hex = e.target.value;
    var regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{4}|[A-Fa-f0-9]{8})$/;
    var rgbColour;
    var colourName;

    if (hex.includes("#")) {
      if (!regex.test(hex)) {
        document.getElementById("SHex" + index).value = "#";
        return <div className="warningMsg">{toast.warning("Invalid Hex color Code")}</div>;
      }
      hex = hex.replace("#", "");
      if (hex.length == 3) {
        rgbColour = new simpleColorConverter({
          hex3: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex3: e.target.value,
          to: "html",
        });
      } else if (hex.length == 4) {
        rgbColour = new simpleColorConverter({
          hex4: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex4: e.target.value,
          to: "html",
        });
      } else if (hex.length == 6) {
        rgbColour = new simpleColorConverter({
          hex6: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex6: e.target.value,
          to: "html",
        });
      } else if (hex.length == 8) {
        rgbColour = new simpleColorConverter({
          hex8: e.target.value,
          to: "rgb",
        });

        colourName = new simpleColorConverter({
          hex8: e.target.value,
          to: "html",
        });
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color must contains the #")}</div>;
    }

    if (hex.length == 3 || hex.length == 4 || hex.length == 6 || hex.length == 8) {
      if (!colourName.error) {
        // To set the ColourName to display beside the organization name in the div
        document.getElementById("sh6" + index).style.color = e.target.value;

        // To set the Hex colour to the text box
        document.getElementById("SHex" + index).value = e.target.value;

        // To set the RGB Colour to the text box
        document.getElementById("SR" + index).value = rgbColour.color.r;
        document.getElementById("SG" + index).value = rgbColour.color.g;
        document.getElementById("SB" + index).value = rgbColour.color.b;

        var nodeList = document.querySelectorAll("[id=sDiv1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = e.target.value;
        });

        // ------------------------------------ Set the Dropdown values Start ----------------------------
        if (document.getElementById("SCG1" + index)) {
          document.getElementById("SCG1" + index).value = "SCG1" + index + "-" + e.target.value;
        }
        if (document.getElementById("SCG1" + index + 1)) {
          document.getElementById("SCG1" + index + 1).value = "SCG1" + index + 1 + "-" + e.target.value;
        }
        if (document.getElementById("SCG2" + index)) {
          document.getElementById("SCG2" + index).value = "SCG2" + index + "-" + e.target.value;
        }
        if (document.getElementById("SCG2" + index + 1)) {
          document.getElementById("SCG2" + index + 1).value = "SCG2" + index + 1 + "-" + e.target.value;
        }
        // ------------------------------------ Set the Dropdown values End ------------------------------
      } else {
        return <div className="warningMsg">{toast.warning(colourName.error)}</div>;
      }
    } else {
      return <div className="warningMsg">{toast.warning("Hex color length must be between [3,4,6,8] excludes #")}</div>;
    }
  };

  /**
   * This method is used to get the RGB colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurPrimaryRGBColour = (e, index) => {
    let { name, value } = e.target;
    let hexLength = 3;
    var hexColour;
    if (
      document.getElementById("PR" + index).value != "" &&
      document.getElementById("PG" + index).value != "" &&
      document.getElementById("PB" + index).value != ""
    ) {
      if (document.getElementById("PHex" + index)) {
        var hexColourCode = document.getElementById("PHex" + index).value.replace("#", "");
        hexLength = hexColourCode.length;
      }

      if (hexLength == 3) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex3",
        });
      } else if (hexLength == 4) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex4",
        });
      } else if (hexLength == 6) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex6",
        });
      } else if (hexLength == 8) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex8",
        });
      } else {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("PR" + index).value +
            " " +
            document.getElementById("PG" + index).value +
            " " +
            document.getElementById("PB" + index).value,
          to: "hex3",
        });
      }

      var colourName = new simpleColorConverter({
        rgb:
          "rgb " +
          document.getElementById("PR" + index).value +
          " " +
          document.getElementById("PG" + index).value +
          " " +
          document.getElementById("PB" + index).value,
        to: "html",
      });

      if (!colourName.error) {
        // To set the background colour to the div strip
        document.getElementById("pred" + index).style.background = "#" + hexColour.color;

        // To set the ColourName to display beside the organization name and apply the style to the div
        document.getElementById("h6" + index).style.color = "#" + hexColour.color;
        document.getElementById(name + index).value = value;

        // To set the Hex colour to the text box
        document.getElementById("PHex" + index).value = "#" + hexColour.color;

        var nodeList = document.querySelectorAll("[id=div1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = "#" + hexColour.color;
        });

        // -------------------------- Set the Dropdown values Start --------------------------------------
        if (document.getElementById("PCG1" + index)) {
          document.getElementById("PCG1" + index).value = "PCG1" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("PCG1" + index + 1)) {
          document.getElementById("PCG1" + index + 1).value = "PCG1" + index + 1 + "-#" + hexColour.color;
        }

        if (document.getElementById("PCG2" + index)) {
          document.getElementById("PCG2" + index).value = "PCG2" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("PCG2" + index + 1)) {
          document.getElementById("PCG2" + index + 1).value = "PCG2" + index + 1 + "-#" + hexColour.color;
        }
        // --------------------------- Set the Dropdown values End ---------------------------------------
      } else {
        return (
          <div className="warningMsg">{toast.warning(colourName.error + " RGB values must be between 0 to 255")}</div>
        );
      }
    } else {
      return (
        <div className="warningMsg">{toast.warning("Can't convert color RGB values must be between 0 to 255")}</div>
      );
    }
  };

  /**
   * This method is used to get the RGB colour value
   * @param {*} e
   * @param {*} index
   */
  const onBlurSecondaryRGBColour = (e, index) => {
    let { name, value } = e.target;
    let hexLength = 3;
    var hexColour;

    if (
      document.getElementById("SR" + index).value != "" &&
      document.getElementById("SG" + index).value != "" &&
      document.getElementById("SB" + index).value != ""
    ) {
      if (document.getElementById("SHex" + index)) {
        var hexColourCode = document.getElementById("SHex" + index).value.replace("#", "");
        hexLength = hexColourCode.length;
      }

      if (hexLength == 3) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex3",
        });
      } else if (hexLength == 4) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex4",
        });
      } else if (hexLength == 6) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex6",
        });
      } else if (hexLength == 8) {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex8",
        });
      } else {
        hexColour = new simpleColorConverter({
          rgb:
            "rgb " +
            document.getElementById("SR" + index).value +
            " " +
            document.getElementById("SG" + index).value +
            " " +
            document.getElementById("SB" + index).value,
          to: "hex3",
        });
      }

      var colourName = new simpleColorConverter({
        rgb:
          "rgb " +
          document.getElementById("SR" + index).value +
          " " +
          document.getElementById("SG" + index).value +
          " " +
          document.getElementById("SB" + index).value,
        to: "html",
      });

      if (!colourName.error) {
        // To set the ColourName to display beside the organization name in the div
        document.getElementById("sh6" + index).style.color = "#" + hexColour.color;
        document.getElementById(name + index).value = value;

        // To set the Hex colour to the text box
        document.getElementById("SHex" + index).value = "#" + hexColour.color;

        var nodeList = document.querySelectorAll("[id=sDiv1-" + index + "]");
        nodeList.forEach((node) => {
          node.style.background = "#" + hexColour.color;
        });

        // ---------------------------------- Set the Dropdown values Start ------------------------------
        if (document.getElementById("SCG1" + index)) {
          document.getElementById("SCG1" + index).value = "SCG1" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("SCG1" + index + 1)) {
          document.getElementById("SCG1" + index + 1).value = "SCG1" + index + 1 + "-#" + hexColour.color;
        }
        if (document.getElementById("SCG2" + index)) {
          document.getElementById("SCG2" + index).value = "SCG2" + index + "-#" + hexColour.color;
        }
        if (document.getElementById("SCG2" + index + 1)) {
          document.getElementById("SCG2" + index + 1).value = "SCG2" + index + 1 + "-#" + hexColour.color;
        }
        // ---------------------------------- Set the Dropdown values End --------------------------------
      } else {
        return (
          <div className="warningMsg">{toast.warning(colourName.error + " RGB values must be between 0 to 255")}</div>
        );
      }
    } else {
      return (
        <div className="warningMsg">{toast.warning("Can't convert color RGB values must be between 0 to 255")}</div>
      );
    }
  };

  /**
   * This method is used to get the CMYK colour value
   * @param {*} e
   */
  const onBlurPrimaryCMYKColour = (e) => {
    if (e.target.value > 100) {
      return <div className="warningMsg">{toast.warning("CMYK values must be between 0 to 100")}</div>;
    }
  };

  /**
   * This method is used to get the CMYK colour value
   * @param {*} e
   */
  const onBlurSecondaryCMYKColour = (e) => {
    if (e.target.value > 100) {
      return <div className="warningMsg">{toast.warning("CMYK values must be between 0 to 100")}</div>;
    }
  };

  /**
   * This method is used to Update the Gradient One Dropdown values
   * @param {*} e
   * @param {*} index
   */
  const onBlurPrimaryColourName = (e, index) => {
    if (e.target.value.trim().length > 0) {
      // checking the existence of colour name
      const filteredPrimary = primaryColourNames.filter((item, id) => id !== index);
      if (filteredPrimary.includes(e.target.value.trim()) || secondaryColourNames.includes(e.target.value.trim())) {
        document.getElementById("h6" + index).style.borderBottom = "1px solid red";
        return <div className="warningMsg">{toast.warning("Colour Name Already Exists")}</div>;
      } else {
        filteredPrimary.splice(index, 0, e.target.value.trim());
        setPrimaryColourNames(filteredPrimary);
        if (gradientCount == 1) {
          document.getElementById("PCG1" + index).textContent = e.target.value;
          document.getElementById("PCG1" + index + 1).textContent = e.target.value;
        }
        if (gradientCount == 2) {
          document.getElementById("PCG1" + index).textContent = e.target.value;
          document.getElementById("PCG1" + index + 1).textContent = e.target.value;
          document.getElementById("PCG2" + index).textContent = e.target.value;
          document.getElementById("PCG2" + index + 1).textContent = e.target.value;
        }
        document.getElementById("h6" + index).style.borderBottom = "none";
        document.getElementById("h6" + index).setAttribute("disabled", "true");
      }
    } else {
      document.getElementById("h6" + index).style.borderBottom = "1px solid red";
      return <div className="warningMsg">{toast.warning("Primary Colour Name Can't be empty")}</div>;
    }
  };

  /**
   * This method is used to set the border bottom when edit the colour name
   * @param {*} index
   */
  const setPrimaryContentEditable = (index) => {
    document.getElementById("h6" + index).removeAttribute("disabled");
    document.getElementById("h6" + index).style.borderBottom = "1px solid rgb(63, 81, 181)";
  };

  /**
   * This method is used to Update the Gradient Two Dropdown values
   * @param {*} e
   * @param {*} index
   */
  const onBlurSecondaryColourName = (e, index) => {
    if (e.target.value.trim().length > 0) {
      // checking the existence of colour name
      const filteredSecondary = secondaryColourNames.filter((item, id) => id !== index);
      if (filteredSecondary.includes(e.target.value.trim()) || primaryColourNames.includes(e.target.value.trim())) {
        document.getElementById("sh6" + index).style.borderBottom = "1px solid red";
        return <div className="warningMsg">{toast.warning("Colour Name Already Exists")}</div>;
      } else {
        filteredSecondary.splice(index, 0, e.target.value.trim());
        setSecondaryColourNames(filteredSecondary);

        if (gradientCount == 1) {
          document.getElementById("SCG1" + index).textContent = e.target.value;
          document.getElementById("SCG1" + index + 1).textContent = e.target.value;
        }
        if (gradientCount == 2) {
          document.getElementById("SCG1" + index).textContent = e.target.value;
          document.getElementById("SCG1" + index + 1).textContent = e.target.value;
          document.getElementById("SCG2" + index).textContent = e.target.value;
          document.getElementById("SCG2" + index + 1).textContent = e.target.value;
        }
        document.getElementById("sh6" + index).style.borderBottom = "none";
        document.getElementById("sh6" + index).setAttribute("disabled", "true");
      }
    } else {
      document.getElementById("sh6" + index).style.borderBottom = "1px solid red";
      return <div className="warningMsg">{toast.warning("Secondary Colour Name Can't be empty")}</div>;
    }
  };

  /**
   * This method is used to set the border bottom when edit the colour name
   * @param {*} index
   */
  const setSecondaryContentEditable = (index) => {
    document.getElementById("sh6" + index).removeAttribute("disabled");
    document.getElementById("sh6" + index).style.borderBottom = "1px solid rgb(63, 81, 181)";
  };

  /**
   * This method is used to restrict the special characters entry in the HEX color input
   * @param {*} event
   */
  const onkeyPress = (event) => {
    if (event.key == "Enter") {
      if (event.charCode == 13) {
        event.preventDefault();
        const nextField = document.querySelectorAll("[class*=ColorInput]");
        if (nextField[3] !== null) {
          nextField[3].focus();
        }
      }
    }
    var regex = /^[a-zA-Z0-9]+$/;
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  /**
   * This method is used to default set the # to the HEX colour input
   * @param {*} event
   */
  function inputValue(event) {
    if (event.target.value.length == 0) {
      event.target.value = "#";
    } else if (event.target.value.toLowerCase().indexOf("#") == -1) {
      event.target.value = "#" + event.target.value; // prepend # to the input value
    }
  }

  /**
   * This method is fired when user select the Gradient Colours Options One
   * @param {*} event
   * @param {*} index
   */
  const gradientOne = (event, index) => {
    setShowDialog(true);
    if (event.target.value.length > 3) {
      if (event.target.value.startsWith("PCG1")) {
        setSelectedPrimaryGradientColorOne(event.target.value);
      } else if (event.target.value.startsWith("SCG1")) {
        setSelectedPrimaryGradientColorOne(event.target.value);
      } else if (event.target.value.startsWith("PCG2")) {
        setSelectedPrimaryGradientColorTwo(event.target.value);
      } else if (event.target.value.startsWith("SCG2")) {
        setSelectedPrimaryGradientColorTwo(event.target.value);
      }

      const parentSelectElement = document.getElementById("SGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index1 = 0; index1 < childOptionElements.length; index1++) {
          document.getElementById(childOptionElements[index1].id).removeAttribute("style");
        }
      }

      document.getElementById(event.target.value.split("-")[0] + "1").style.display = "none";
    } else {
      const parentSelectElement = document.getElementById("PGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index2 = 0; index2 < childOptionElements.length; index2++) {
          document.getElementById(childOptionElements[index2].id).removeAttribute("style");
        }
      }

      const parentSelectElement1 = document.getElementById("SGS" + index);
      if (parentSelectElement1) {
        const childOptionElements1 = parentSelectElement1.getElementsByTagName("option");
        for (let index3 = 0; index3 < childOptionElements1.length; index3++) {
          document.getElementById(childOptionElements1[index3].id).removeAttribute("style");
        }
      }

      document.getElementById("PGS" + index).value = "PCG";
      document.getElementById("SGS" + index).value = "SCG";

      if (index == 0) {
        setSelectedPrimaryGradientColorOne("#eaeaeb");
        setSelectedSecondaryGradientColorOne("#eaeaeb");
      } else if (index == 1) {
        setSelectedPrimaryGradientColorTwo("#eaeaeb");
        setSelectedSecondaryGradientColorTwo("#eaeaeb");
      }
    }
  };

  /**
   * This method is fired when user select the Gradient Colours Options Two
   * @param {*} event
   * @param {*} index
   */
  const gradientTwo = (event, index) => {
    setShowDialog(true);
    if (event.target.value.length > 3) {
      if (event.target.value.startsWith("PCG1")) {
        setSelectedSecondaryGradientColorOne(event.target.value);
      } else if (event.target.value.startsWith("SCG1")) {
        setSelectedSecondaryGradientColorOne(event.target.value);
      } else if (event.target.value.startsWith("PCG2")) {
        setSelectedSecondaryGradientColorTwo(event.target.value);
      } else if (event.target.value.startsWith("SCG2")) {
        setSelectedSecondaryGradientColorTwo(event.target.value);
      }

      const parentSelectElement = document.getElementById("PGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index1 = 0; index1 < childOptionElements.length; index1++) {
          document.getElementById(childOptionElements[index1].id).removeAttribute("style");
        }
      }
      let id = event.target.value.split("-")[0];
      id = id.substring(0, id.length - 1);
      document.getElementById(id).style.display = "none";
    } else {
      const parentSelectElement = document.getElementById("SGS" + index);
      if (parentSelectElement) {
        const childOptionElements = parentSelectElement.getElementsByTagName("option");
        for (let index2 = 0; index2 < childOptionElements.length; index2++) {
          document.getElementById(childOptionElements[index2].id).removeAttribute("style");
        }
      }

      const parentSelectElement1 = document.getElementById("PGS" + index);
      if (parentSelectElement1) {
        const childOptionElements1 = parentSelectElement1.getElementsByTagName("option");
        for (let index3 = 0; index3 < childOptionElements1.length; index3++) {
          document.getElementById(childOptionElements1[index3].id).removeAttribute("style");
        }
      }

      document.getElementById("PGS" + index).value = "PCG";
      document.getElementById("SGS" + index).value = "SCG";

      if (index == 0) {
        setSelectedPrimaryGradientColorOne("#eaeaeb");
        setSelectedSecondaryGradientColorOne("#eaeaeb");
      } else if (index == 1) {
        setSelectedPrimaryGradientColorTwo("#eaeaeb");
        setSelectedSecondaryGradientColorTwo("#eaeaeb");
      }
    }
  };

  /**
   * This method is used to apply the Gradient
   * @param {*} index
   */
  const applyGradient = (index) => {
    let colourOne = "";
    let colourTwo = "";

    if (index == 0) {
      if (document.getElementById(selectedPrimaryGradientColorOne.split("-")[0])) {
        colourOne = document.getElementById(selectedPrimaryGradientColorOne.split("-")[0]).value.split("-")[1];
      } else {
        let gr1 = document.getElementById("gradient" + index);
        gr1.style.removeProperty("background");
        gr1.style.removeProperty("backgroundImage");
        gr1.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 1")}</div>;
      }
      if (document.getElementById(selectedSecondaryGradientColorOne.split("-")[0])) {
        colourTwo = document.getElementById(selectedSecondaryGradientColorOne.split("-")[0]).value.split("-")[1];
      } else {
        let gr2 = document.getElementById("gradient" + index);
        gr2.style.removeProperty("background");
        gr2.style.removeProperty("backgroundImage");
        gr2.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 2")}</div>;
      }
      let element = document.getElementById("gradient" + index);
      element.style.removeProperty("background");
      element.style.removeProperty("backgroundImage");
      element.style.backgroundImage = "linear-gradient(" + "45deg," + colourOne + "," + colourTwo + ")";
    }
    if (index == 1) {
      if (document.getElementById(selectedPrimaryGradientColorTwo.split("-")[0])) {
        colourOne = document.getElementById(selectedPrimaryGradientColorTwo.split("-")[0]).value.split("-")[1];
      } else {
        let gr1 = document.getElementById("gradient" + index);
        gr1.style.removeProperty("background");
        gr1.style.removeProperty("backgroundImage");
        gr1.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 1")}</div>;
      }
      if (document.getElementById(selectedSecondaryGradientColorTwo.split("-")[0])) {
        colourTwo = document.getElementById(selectedSecondaryGradientColorTwo.split("-")[0]).value.split("-")[1];
      } else {
        let gr2 = document.getElementById("gradient" + index);
        gr2.style.removeProperty("background");
        gr2.style.removeProperty("backgroundImage");
        gr2.style.backgroundImage = "linear-gradient(" + "45deg," + "#eaeaeb" + "," + "#eaeaeb" + ")";
        return <div className="warningMsg">{toast.warning("Please select the Gradient colour 2")}</div>;
      }
      let element = document.getElementById("gradient" + index);
      element.style.removeProperty("background");
      element.style.removeProperty("backgroundImage");
      element.style.backgroundImage = "linear-gradient(" + "45deg," + colourOne + "," + colourTwo + ")";
    }
  };

  /**
   * This method is used to copy the Primary Colour Code
   * @param {*} e
   * @param {*} index
   */
  function copyToClipboardPrimaryColor(e, index) {
    primaryHexColorRef.current[index].select();
    document.execCommand("copy");
    e.target.focus();
    toast.success("Copied to clipboard");
  }

  /**
   * This method is used to copy the Secondary Colour Code
   * @param {*} e
   * @param {*} index
   */
  function copyToClipboardSecondaryColor(e, index) {
    secondaryHexColorRef.current[index].select();
    document.execCommand("copy");
    e.target.focus();
    toast.success("Copied to clipboard");
  }

  /**
   * This method is used to show the Colour Configuration Settings
   */
  const onClickConfigurationHandler = () => {
    setShowColorConfiguration(true);
  };

  /**
   * This method is used to save the colour
   * @return {*}
   */
  const saveColour = () => {

    // Before Prepare object Check the validations
    if (document.getElementById("PHex0")) {
      if (
        document.getElementById("PHex0").value.length == 4 ||
        document.getElementById("PHex0").value.length == 5 ||
        document.getElementById("PHex0").value.length == 7 ||
        document.getElementById("PHex0").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 1")}</div>;
      }
    }
    if (document.getElementById("PHex1")) {
      if (
        document.getElementById("PHex1").value.length == 4 ||
        document.getElementById("PHex1").value.length == 5 ||
        document.getElementById("PHex1").value.length == 7 ||
        document.getElementById("PHex1").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 2")}</div>;
      }
    }
    if (document.getElementById("PHex2")) {
      if (
        document.getElementById("PHex2").value.length == 4 ||
        document.getElementById("PHex2").value.length == 5 ||
        document.getElementById("PHex2").value.length == 7 ||
        document.getElementById("PHex2").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 3")}</div>;
      }
    }
    if (document.getElementById("PHex3")) {
      if (
        document.getElementById("PHex3").value.length == 4 ||
        document.getElementById("PHex3").value.length == 5 ||
        document.getElementById("PHex3").value.length == 7 ||
        document.getElementById("PHex3").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Primary hex code 4")}</div>;
      }
    }

    if (document.getElementById("SHex0")) {
      if (
        document.getElementById("SHex0").value.length == 4 ||
        document.getElementById("SHex0").value.length == 5 ||
        document.getElementById("SHex0").value.length == 7 ||
        document.getElementById("SHex0").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 1")}</div>;
      }
    }

    if (document.getElementById("SHex1")) {
      if (
        document.getElementById("SHex1").value.length == 4 ||
        document.getElementById("SHex1").value.length == 5 ||
        document.getElementById("SHex1").value.length == 7 ||
        document.getElementById("SHex1").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 2")}</div>;
      }
    }

    if (document.getElementById("SHex2")) {
      if (
        document.getElementById("SHex2").value.length == 4 ||
        document.getElementById("SHex2").value.length == 5 ||
        document.getElementById("SHex2").value.length == 7 ||
        document.getElementById("SHex2").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 3")}</div>;
      }
    }

    if (document.getElementById("SHex3")) {
      if (
        document.getElementById("SHex3").value.length == 4 ||
        document.getElementById("SHex3").value.length == 5 ||
        document.getElementById("SHex3").value.length == 7 ||
        document.getElementById("SHex3").value.length == 9
      ) {
      } else {
        return <div className="warningMsg">{toast.warning("Provide Proper Secondary hex code 4")}</div>;
      }
    }

    if (
      document.getElementById("PR0")?.value.length == 0 ||
      document.getElementById("PG0")?.value.length == 0 ||
      document.getElementById("PB0")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 1")}</div>;
    }

    if (
      document.getElementById("PR0")?.value > 255 ||
      document.getElementById("PG0")?.value > 255 ||
      document.getElementById("PB0")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC0")?.value > 100 ||
      document.getElementById("PM0")?.value > 100 ||
      document.getElementById("PY0")?.value > 100 ||
      document.getElementById("PK0")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("PR1")?.value.length == 0 ||
      document.getElementById("PG1")?.value.length == 0 ||
      document.getElementById("PB1")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 2")}</div>;
    }

    if (
      document.getElementById("PR1")?.value > 255 ||
      document.getElementById("PG1")?.value > 255 ||
      document.getElementById("PB1")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC1")?.value > 100 ||
      document.getElementById("PM1")?.value > 100 ||
      document.getElementById("PY1")?.value > 100 ||
      document.getElementById("PK1")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("PR2")?.value.length == 0 ||
      document.getElementById("PG2")?.value.length == 0 ||
      document.getElementById("PB2")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 3")}</div>;
    }

    if (
      document.getElementById("PR2")?.value > 255 ||
      document.getElementById("PG2")?.value > 255 ||
      document.getElementById("PB2")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC2")?.value > 100 ||
      document.getElementById("PM2")?.value > 100 ||
      document.getElementById("PY2")?.value > 100 ||
      document.getElementById("PK2")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("PR3")?.value.length == 0 ||
      document.getElementById("PG3")?.value.length == 0 ||
      document.getElementById("PB3")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Primary RGB code 4")}</div>;
    }

    if (
      document.getElementById("PR3")?.value > 255 ||
      document.getElementById("PG3")?.value > 255 ||
      document.getElementById("PB3")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Primary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("PC3")?.value > 100 ||
      document.getElementById("PM3")?.value > 100 ||
      document.getElementById("PY3")?.value > 100 ||
      document.getElementById("PK3")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Primary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR0")?.value.length == 0 ||
      document.getElementById("SG0")?.value.length == 0 ||
      document.getElementById("SB0")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 1")}</div>;
    }

    if (
      document.getElementById("SR0")?.value > 255 ||
      document.getElementById("SG0")?.value > 255 ||
      document.getElementById("SB0")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC0")?.value > 100 ||
      document.getElementById("SM0")?.value > 100 ||
      document.getElementById("SY0")?.value > 100 ||
      document.getElementById("SK0")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR1")?.value.length == 0 ||
      document.getElementById("SG1")?.value.length == 0 ||
      document.getElementById("SB1")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 2")}</div>;
    }

    if (
      document.getElementById("SR1")?.value > 255 ||
      document.getElementById("SG1")?.value > 255 ||
      document.getElementById("SB1")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC1")?.value > 100 ||
      document.getElementById("SM1")?.value > 100 ||
      document.getElementById("SY1")?.value > 100 ||
      document.getElementById("SK1")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR2")?.value.length == 0 ||
      document.getElementById("SG2")?.value.length == 0 ||
      document.getElementById("SB2")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 3")}</div>;
    }

    if (
      document.getElementById("SR2")?.value > 255 ||
      document.getElementById("SG2")?.value > 255 ||
      document.getElementById("SB2")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC2")?.value > 100 ||
      document.getElementById("SM2")?.value > 100 ||
      document.getElementById("SY2")?.value > 100 ||
      document.getElementById("SK2")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    if (
      document.getElementById("SR3")?.value.length == 0 ||
      document.getElementById("SG3")?.value.length == 0 ||
      document.getElementById("SB3")?.value.length == 0
    ) {
      return <div className="warningMsg">{toast.warning("Provide Proper Secondary RGB code 4")}</div>;
    }

    if (
      document.getElementById("SR3")?.value > 255 ||
      document.getElementById("SG3")?.value > 255 ||
      document.getElementById("SB3")?.value > 255
    ) {
      return <div className="warningMsg">{toast.warning("Secondary RGB values must be between 0 to 255")}</div>;
    }

    if (
      document.getElementById("SC3")?.value > 100 ||
      document.getElementById("SM3")?.value > 100 ||
      document.getElementById("SY3")?.value > 100 ||
      document.getElementById("SK3")?.value > 100
    ) {
      return <div className="warningMsg">{toast.warning("Secondary CMYK values must be between 0 to 100")}</div>;
    }

    // Prepare the Primary Colours
    if (sessionStorage.getItem("primaryValue") == 1) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      delete obj.primaryColors.p2;
      delete obj.primaryColors.p3;
      delete obj.primaryColors.p4;
    } else if (sessionStorage.getItem("primaryValue") == 2) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      if (document.getElementById("PHex1")) {
        if (document.getElementById("PHex1").value != "#eaeaeb") {
          obj.primaryColors.p2 = document.getElementById("PHex1").value;
        } else {
          obj.primaryColors.p2 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p2 = "#eaeaeb";
      }
      delete obj.primaryColors.p3;
      delete obj.primaryColors.p4;
    } else if (sessionStorage.getItem("primaryValue") == 3) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      if (document.getElementById("PHex1")) {
        if (document.getElementById("PHex1").value != "#eaeaeb") {
          obj.primaryColors.p2 = document.getElementById("PHex1").value;
        } else {
          obj.primaryColors.p2 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p2 = "#eaeaeb";
      }
      if (document.getElementById("PHex2")) {
        if (document.getElementById("PHex2").value != "#eaeaeb") {
          obj.primaryColors.p3 = document.getElementById("PHex2").value;
        } else {
          obj.primaryColors.p3 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p3 = "#eaeaeb";
      }
      delete obj.primaryColors.p4;
    } else if (sessionStorage.getItem("primaryValue") == 4) {
      if (document.getElementById("PHex0")) {
        if (document.getElementById("PHex0").value != "#eaeaeb") {
          obj.primaryColors.p1 = document.getElementById("PHex0").value;
        } else {
          obj.primaryColors.p1 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p1 = "#eaeaeb";
      }
      if (document.getElementById("PHex1")) {
        if (document.getElementById("PHex1").value != "#eaeaeb") {
          obj.primaryColors.p2 = document.getElementById("PHex1").value;
        } else {
          obj.primaryColors.p2 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p2 = "#eaeaeb";
      }
      if (document.getElementById("PHex2")) {
        if (document.getElementById("PHex2").value != "#eaeaeb") {
          obj.primaryColors.p3 = document.getElementById("PHex2").value;
        } else {
          obj.primaryColors.p3 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p3 = "#eaeaeb";
      }
      if (document.getElementById("PHex3")) {
        if (document.getElementById("PHex3").value != "#eaeaeb") {
          obj.primaryColors.p4 = document.getElementById("PHex3").value;
        } else {
          obj.primaryColors.p4 = "#eaeaeb";
        }
      } else {
        obj.primaryColors.p4 = "#eaeaeb";
      }
    }

    // Prepare the Secondary Colours
    if (sessionStorage.getItem("secondaryValue") == 1) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      delete obj.secondaryColors.s2;
      delete obj.secondaryColors.s3;
      delete obj.secondaryColors.s4;
    } else if (sessionStorage.getItem("secondaryValue") == 2) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      if (document.getElementById("SHex1")) {
        if (document.getElementById("SHex1").value != "#eaeaeb") {
          obj.secondaryColors.s2 = document.getElementById("SHex1").value;
        } else {
          obj.secondaryColors.s2 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s2 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      obj.secondaryColors.s2Color = color2;
      delete obj.secondaryColors.s3;
      delete obj.secondaryColors.s4;
    } else if (sessionStorage.getItem("secondaryValue") == 3) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      if (document.getElementById("SHex1")) {
        if (document.getElementById("SHex1").value != "#eaeaeb") {
          obj.secondaryColors.s2 = document.getElementById("SHex1").value;
        } else {
          obj.secondaryColors.s2 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s2 = "#eaeaeb";
      }
      if (document.getElementById("SHex2")) {
        if (document.getElementById("SHex2").value != "#eaeaeb") {
          obj.secondaryColors.s3 = document.getElementById("SHex2").value;
        } else {
          obj.secondaryColors.s3 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s3 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      obj.secondaryColors.s2Color = color2;
      obj.secondaryColors.s3Color = color3;
      delete obj.secondaryColors.s4;
    } else if (sessionStorage.getItem("secondaryValue") == 4) {
      if (document.getElementById("SHex0")) {
        if (document.getElementById("SHex0").value != "#eaeaeb") {
          obj.secondaryColors.s1 = document.getElementById("SHex0").value;
        } else {
          obj.secondaryColors.s1 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s1 = "#eaeaeb";
      }
      if (document.getElementById("SHex1")) {
        if (document.getElementById("SHex1").value != "#eaeaeb") {
          obj.secondaryColors.s2 = document.getElementById("SHex1").value;
        } else {
          obj.secondaryColors.s2 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s2 = "#eaeaeb";
      }
      if (document.getElementById("SHex2")) {
        if (document.getElementById("SHex2").value != "#eaeaeb") {
          obj.secondaryColors.s3 = document.getElementById("SHex2").value;
        } else {
          obj.secondaryColors.s3 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s3 = "#eaeaeb";
      }
      if (document.getElementById("SHex3")) {
        if (document.getElementById("SHex3").value != "#eaeaeb") {
          obj.secondaryColors.s4 = document.getElementById("SHex3").value;
        } else {
          obj.secondaryColors.s4 = "#eaeaeb";
        }
      } else {
        obj.secondaryColors.s4 = "#eaeaeb";
      }
      obj.secondaryColors.s1Color = color1;
      obj.secondaryColors.s2Color = color2;
      obj.secondaryColors.s3Color = color3;
      obj.secondaryColors.s4Color = color4;
    }

    // Prepare the Gradient Colours
    if (sessionStorage.getItem("gradientValue") == 1) {
      if (selectedPrimaryGradientColorOne != "#eaeaeb" && selectedSecondaryGradientColorOne != "#eaeaeb") {
        obj.gradientsColors.g1 =
          selectedPrimaryGradientColorOne.split("-")[1] + "," + selectedSecondaryGradientColorOne.split("-")[1];
      } else {
        obj.gradientsColors.g1 = "#eaeaeb,#eaeaeb";
      }
      delete obj.gradientsColors.g2;
    } else if (sessionStorage.getItem("gradientValue") == 2) {
      if (selectedPrimaryGradientColorOne != "#eaeaeb" && selectedSecondaryGradientColorOne != "#eaeaeb") {
        obj.gradientsColors.g1 =
          selectedPrimaryGradientColorOne.split("-")[1] + "," + selectedSecondaryGradientColorOne.split("-")[1];
      } else {
        obj.gradientsColors.g1 = "#eaeaeb,#eaeaeb";
      }
      if (selectedPrimaryGradientColorTwo != "#eaeaeb" && selectedSecondaryGradientColorTwo != "#eaeaeb") {
        obj.gradientsColors.g2 =
          selectedPrimaryGradientColorTwo.split("-")[1] + "," + selectedSecondaryGradientColorTwo.split("-")[1];
      } else {
        obj.gradientsColors.g2 = "#eaeaeb,#eaeaeb";
      }
    }

    // Set Primary Colour Names to the object
    for (let index = 0; index < primaryCount; index++) {
      if (document.getElementById("h6" + index).value) {
        obj.primaryColors.primaryColorNames.push(document.getElementById("h6" + index).value);
      } else {
        return (
          <div className="warningMsg">
            {toast.warning("Primary Colour Name - " + Number(index + 1) + " Can't be empty")}
          </div>
        );
      }
    }

    // Set Primary Pantone Codes to the object
    for (let index = 0; index < primaryCount; index++) {
      obj.primaryColors.primaryPantoneNames.push(document.getElementById("PPantone" + index).value);
    }

    // Set Primary CMYK Values to the object
    for (let index = 0; index < primaryCount; index++) {
      obj.primaryColors.primaryCMYKValues.push(
        (document.getElementById("PC" + index).value || null) +
          "-" +
          (document.getElementById("PM" + index).value || null) +
          "-" +
          (document.getElementById("PY" + index).value || null) +
          "-" +
          (document.getElementById("PK" + index).value || null)
      );
    }

    // Set Secondary Colour Names to the object
    for (let index = 0; index < secondaryCount; index++) {
      if (document.getElementById("sh6" + index).value) {
        obj.secondaryColors.secondaryColorNames.push(document.getElementById("sh6" + index).value);
      } else {
        return (
          <div className="warningMsg">
            {toast.warning("Secondary Colour Name - " + Number(index + 1) + " Can't be empty")}
          </div>
        );
      }
    }

    // Set Secondary Pantone Codes to the object
    for (let index = 0; index < secondaryCount; index++) {
      obj.secondaryColors.secondaryPantoneNames.push(document.getElementById("SPantone" + index).value);
    }

    // Set Secondary CMYK Values to the object
    for (let index = 0; index < secondaryCount; index++) {
      obj.secondaryColors.secondaryCMYKValues.push(
        (document.getElementById("SC" + index).value || null) +
          "-" +
          (document.getElementById("SM" + index).value || null) +
          "-" +
          (document.getElementById("SY" + index).value || null) +
          "-" +
          (document.getElementById("SK" + index).value || null)
      );
    }
    setShowDialog(false);

    let combinedArray = obj.primaryColors.primaryColorNames + "," + obj.secondaryColors.secondaryColorNames
    const unique = [...new Set(combinedArray.split(","))]

    if (combinedArray.split(",").length != unique.length) {
      return <div className="warningMsg">{toast.warning("Colour Name Already Exists")}</div>;
    }

    if (obj.orgName) {
      setDisplayLoader(true);
      dispatch(saveColourConfigurations(obj, successHandler, errorHandler));
    }
  };

  /**
   * Service Success Handler
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      dispatch(getColoursData(successHandlerForGetColours, errorHandler));
      navigate("/brandassets/coloursview");
      sessionStorage.removeItem("primaryValue");
      sessionStorage.removeItem("secondaryValue");
      sessionStorage.removeItem("gradientValue");
    }, 500);
  };

  /**
   * This method will call after successful fetch request
   */
  const successHandlerForGetColours = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * Service Error Handler
   */
  const errorHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      setShowDialog(true);
    }, 500);
  };

  /**
   * On Colour Change Check Box change event
   * @param {*} event
   * @param {*} index
   */
  const onColourChange = (event, index) => {
    setShowDialog(true);
    if (index == 0) {
      if (event.target.checked) {
        setColor1("#000");
      } else {
        setColor1("#fff");
      }
    } else if (index == 1) {
      if (event.target.checked) {
        setColor2("#000");
      } else {
        setColor2("#fff");
      }
    } else if (index == 2) {
      if (event.target.checked) {
        setColor3("#000");
      } else {
        setColor3("#fff");
      }
    } else if (index == 3) {
      if (event.target.checked) {
        setColor4("#000");
      } else {
        setColor4("#fff");
      }
    }
  };

  // Gradient Colour Dropdown One data to be reset
  for (let index = 0; index < primaryCount; index++) {
    primaryGradientOptionsOne.push(
      <option value={"PCG1" + index + "-#eaeaeb"} id={"PCG1" + index}>
        {"Primary Colour" + Number(index + 1)}
      </option>
    );
    secondaryGradientOptionsOne.push(
      <option value={"PCG2" + index + "-#eaeaeb"} id={"PCG2" + index}>
        {"Primary Colour" + Number(index + 1)}
      </option>
    );
    PrimaryGradientOptionsTwo.push(
      <option value={"PCG1" + index + "1" + "-#eaeaeb"} id={"PCG1" + index + "1"}>
        {"Primary Colour" + Number(index + 1)}
      </option>
    );
    secondaryGradientOptionsTwo.push(
      <option value={"PCG2" + index + "1" + "-#eaeaeb"} id={"PCG2" + index + "1"}>
        {"Primary Colour" + Number(index + 1)}
      </option>
    );
  }

  // Gradient Colour Dropdown Two data to be reset
  for (let index = 0; index < secondaryCount; index++) {
    primaryGradientOptionsOne.push(
      <option value={"SCG1" + index + "-#eaeaeb"} id={"SCG1" + index}>
        {"Secondary Colour" + Number(index + 1)}
      </option>
    );
    secondaryGradientOptionsOne.push(
      <option value={"SCG2" + index + "-#eaeaeb"} id={"SCG2" + index}>
        {"Secondary Colour" + Number(index + 1)}
      </option>
    );
    PrimaryGradientOptionsTwo.push(
      <option value={"SCG1" + index + "1" + "-#eaeaeb"} id={"SCG1" + index + "1"}>
        {"Secondary Colour" + Number(index + 1)}
      </option>
    );
    secondaryGradientOptionsTwo.push(
      <option value={"SCG2" + index + "1" + "-#eaeaeb"} id={"SCG2" + index + "1"}>
        {"Secondary Colour" + Number(index + 1)}
      </option>
    );
  }

  // Primary HTML Div Dynamically loaded
  for (let index = 0; index < primaryCount; index++) {
    primaryValuesHTMLDivs.push(
      <div
        className={
          primaryCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
            : primaryCount == 2
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : primaryCount == 3
            ? "col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3"
            : primaryCount == 4
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : ""
        }
      >
        <div className="p-1" id={"pred" + index}></div>
        <div className="border-bottom py-2 d-flex w-100 justify-content-between">
          <input
            type="text"
            id={"h6" + index}
            maxLength="30"
            disabled
            autoComplete="off"
            onChange={(e) => ((e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")), setShowDialog(true))}
            onBlur={(event) => onBlurPrimaryColourName(event, index)}
          />
          <Tooltip title="Edit">
            <img
              src={editIcon}
              style={{ cursor: "pointer", width: "18px", height: "18px" }}
              onClick={() => setPrimaryContentEditable(index)}
            />
          </Tooltip>
        </div>
        <div className="py-1 pb-2 d-flex border-bottom">
          <div className="col-6">
            <div style={{ width: "93%" }}>
              <div className="d-flex pb-1">
                <label className="text-title m-0 pe-2">WEB / HEX</label>
                <Tooltip title="Copy">
                  <img
                    id="imgCopy"
                    src={IconBlue}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => copyToClipboardPrimaryColor(e, index)}
                  />
                </Tooltip>
              </div>
              <input
                type="text"
                autoComplete="off"
                name="PHex"
                id={"PHex" + index}
                className="ColorInput w-100"
                onInput={inputValue}
                onKeyPress={(event) => onkeyPress(event)}
                ref={(el) => (primaryHexColorRef.current[index] = el)}
                onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
                onBlur={(event) => onBlurPrimaryHexColour(event, index)}
              />
            </div>
          </div>
          <div className="d-flex col-6 my-auto mt-1">
            <div className="d-flex" style={{ width: "70%" }}>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title">R</div>
                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PR"
                  id={"PR" + index}
                  onBlur={(event) => onBlurPrimaryRGBColour(event, index)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title">G</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PG"
                  id={"PG" + index}
                  onBlur={(event) => onBlurPrimaryRGBColour(event, index)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title">B</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PB"
                  id={"PB" + index}
                  onBlur={(event) => onBlurPrimaryRGBColour(event, index)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="py-1 pt-2 d-flex border-bottom">
          <div className="col-6 my-auto">
            <div className="d-flex" style={{ width: "90%" }}>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title m-0 pb-1">C</div>
                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PC"
                  id={"PC" + index}
                  onBlur={(event) => onBlurPrimaryCMYKColour(event)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title m-0 pb-1">M</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PM"
                  id={"PM" + index}
                  onBlur={(event) => onBlurPrimaryCMYKColour(event)}
                />
              </div>
              <div className="me-1">
                <div className="d-flex justify-content-center text-title m-0 pb-1">Y</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PY"
                  id={"PY" + index}
                  onBlur={(event) => onBlurPrimaryCMYKColour(event)}
                />
              </div>

              <div>
                <div className="d-flex justify-content-center text-title m-0 pb-1">K</div>

                <input
                  type="text"
                  className="ColorInput w-100 text-center"
                  maxLength="3"
                  autoComplete="off"
                  onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                  name="PK"
                  id={"PK" + index}
                  onBlur={(event) => onBlurPrimaryCMYKColour(event)}
                />
              </div>
            </div>
          </div>
          <div className="pe-3 col-3">
            <div>
              <label className="text-title m-0 pb-1">PANTONE</label>
            </div>
            <input
              type="text"
              autoComplete="off"
              name="pantone"
              maxLength="15"
              id={"PPantone" + index}
              className="ColorInput w-100"
              onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
            />
          </div>
          <div className="col-3">
            <div>
              <label className="text-title m-0 pb-1">TINTS</label>
            </div>
            <label className="py-auto" style={{ padding: "5px 0px" }}>
              100% - 10%
            </label>
          </div>
        </div>
        <div className="ColourSelection d-flex">
          <div className="ColourViewGridLeft w-75" id={"div1-" + index}></div>
          <div className="ColourViewGridRight w-25">
            <div className="colorViewList" id={"div1-" + index}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.9" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.8" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.7" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.6" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.5" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.4" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.3" }}></div>
            <div className="colorViewList" id={"div1-" + index} style={{ opacity: "0.2" }}></div>
            <div className="colorViewList border-bottom-0" id={"div1-" + index} style={{ opacity: "0.1" }}></div>
          </div>
        </div>
      </div>
    );
  }

  // Secondary HTML Div Dynamically loaded
  for (let index = 0; index < secondaryCount; index++) {
    secondaryValuesHTMLDivs.push(
      <div
        className={
          secondaryCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
            : secondaryCount == 2
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : secondaryCount == 3
            ? "col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3"
            : secondaryCount == 4
            ? "col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3"
            : ""
        }
      >
        <div className="border-bottom py-2 d-flex">
          <div className="col-10 d-flex justify-content-between">
            <input
              type="text"
              className={
                secondaryCount == 1 || secondaryCount == 2 ? "col-12" : secondaryCount == 3 ? "col-11" : "col-10"
              }
              id={"sh6" + index}
              maxLength="30"
              disabled
              autoComplete="off"
              onChange={(e) => ((e.target.value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, "")), setShowDialog(true))}
              onBlur={(event) => onBlurSecondaryColourName(event, index)}
            />

            <Tooltip title="Edit">
              <img
                src={editIcon}
                style={{ cursor: "pointer", width: "18px", height: "18px", zIndex: "1" }}
                onClick={() => setSecondaryContentEditable(index)}
              />
            </Tooltip>
          </div>
          <div className="col-2 d-flex justify-content-end">
            <Switch
              {...colorChange}
              name="colorChange"
              defaultChecked
              size="small"
              onChange={(event) => onColourChange(event, index)}
            />
          </div>
        </div>

        <div className="SecDrColorsBgr ps-1 pt-2" id={"sDiv1-" + index}>
          <div className="py-1 ps-2">
            <div className="SecondaryWebInput">
              <div className="d-flex pb-1">
                <label
                  className="m-0 text-title pe-2"
                  style={{
                    color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                  }}
                >
                  WEB / HEX
                </label>

                <Tooltip title="Copy">
                  <img
                    id="imgCopy"
                    src={CopyWhite}
                    style={{ cursor: "pointer" }}
                    onClick={(e) => copyToClipboardSecondaryColor(e, index)}
                  />
                </Tooltip>
              </div>
              <input
                type="text"
                autoComplete="off"
                name="SHex"
                id={"SHex" + index}
                className="ColorInput w-100"
                onInput={inputValue}
                onKeyPress={(event) => onkeyPress(event)}
                ref={(el) => (secondaryHexColorRef.current[index] = el)}
                onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
                onBlur={(event) => onBlurSecondaryHexColour(event, index)}
              />
            </div>
          </div>

          <div className="py-1 d-flex w-100 ps-2">
            <div style={{ width: "38%" }}>
              <div className="d-flex">
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    R
                  </div>
                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SR"
                    id={"SR" + index}
                    onBlur={(event) => onBlurSecondaryRGBColour(event, index)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    G
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SG"
                    id={"SG" + index}
                    onBlur={(event) => onBlurSecondaryRGBColour(event, index)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    B
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SB"
                    id={"SB" + index}
                    onBlur={(event) => onBlurSecondaryRGBColour(event, index)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="py-1 d-flex w-100 ps-2">
            <div className="w-50">
              <div className="d-flex">
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    C
                  </div>
                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SC"
                    id={"SC" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    M
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SM"
                    id={"SM" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>
                <div className="me-1">
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    Y
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SY"
                    id={"SY" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>

                <div>
                  <div
                    className="d-flex justify-content-center text-title"
                    style={{
                      color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                    }}
                  >
                    K
                  </div>

                  <input
                    type="text"
                    className="ColorInput SecondaryInputFields text-center"
                    maxLength="3"
                    autoComplete="off"
                    onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                    name="SK"
                    id={"SK" + index}
                    onBlur={(event) => onBlurSecondaryCMYKColour(event)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="py-1 ps-2">
            <div className="SecondaryWebInput">
              <div>
                <label
                  className="text-title m-0 pb-1"
                  style={{
                    color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
                  }}
                >
                  PANTONE
                </label>
              </div>
              <input
                type="text"
                autoComplete="off"
                name="pantone"
                maxLength="15"
                id={"SPantone" + index}
                className="ColorInput w-100 mb-3"
                onChange={(e) => ((e.target.value = e.target.value), setShowDialog(true))}
              />
            </div>
          </div>
        </div>

        <div className="SecondaryColourViewGrid w-100">
          <div className="ps-2 SecondaryListGrid" id={"sDiv1-" + index}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              100%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.9" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              90%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.8" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              80%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.7" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              70%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.6" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              60%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.5" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              50%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.4" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              40%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.3" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              30%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1" id={"sDiv1-" + index} style={{ opacity: "0.2" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              20%
            </label>
          </div>
          <div className="ps-2 SecondaryListGrid1 border-bottom-0" id={"sDiv1-" + index} style={{ opacity: "0.1" }}>
            <label
              className="p-1 bd-highlight"
              style={{
                color: index == 0 ? color1 : index == 1 ? color2 : index == 2 ? color3 : index == 3 ? color4 : "",
              }}
            >
              10%
            </label>
          </div>
        </div>
      </div>
    );
  }

  // Gradient HTML Div Dynamically loaded
  for (let index = 0; index < gradientCount; index++) {
    gradientValuesHTMLDivs.push(
      <div
        className={
          gradientCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            : gradientCount == 2
            ? "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            : ""
        }
      >
        <div className="border-bottom py-2 pt-3 d-flex justify-content-between">
          <h6 className="m-0">Gradient {index + 1}</h6>
          <h6 className="m-0 text-decoration-underline ColorApply" onClick={() => applyGradient(index)}>
            APPLY
          </h6>
        </div>

        <div className="border-bottom py-2 d-flex justify-content-between DropDownSelect">
          <div className="w-50 me-4">
            <label className="pb-1 GradinTitleLabel">Colour 1</label>
            <select
              id={"PGS" + index}
              className="form-select w-100 rounded colorSelectDropdown ps-1"
              onChange={(event) => gradientOne(event, index)}
            >
              <option value="PCG" id={"PCG" + index}>
                Please Select Colour
              </option>
              {index == 0 ? primaryGradientOptionsOne : secondaryGradientOptionsOne}
            </select>
          </div>
          <div className="w-50">
            <label className="pb-1 GradinTitleLabel">Colour 2</label>
            <select
              id={"SGS" + index}
              className="form-select w-100 rounded colorSelectDropdown ps-1"
              onChange={(event) => gradientTwo(event, index)}
            >
              <option value="SCG" id={"SCG" + index}>
                Please Select Colour
              </option>
              {index == 0 ? PrimaryGradientOptionsTwo : secondaryGradientOptionsTwo}
            </select>
          </div>
        </div>

        <div
          className="ColourSelection d-flex"
          id={"gradient" + index}
          style={{
            minHeight: "300px",
          }}
        ></div>
      </div>
    );
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        {showPrompt && (
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        )}
      </Suspense>
      <div className="mt-3 colorSection" id="mainDiv">
        <div className="row mb-2">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <h6 className="titLe py-2 m-0 my-auto">
              Brand Assets <span> - Colours</span>
            </h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="d-flex justify-content-end">
              <div>
                <div className="d-flex px-1 buttonList">
                  <Tooltip title="Colour Configuration" placement="top-start">
                    <div className="buttonGap me-0" onClick={onClickConfigurationHandler}>
                      <IconButton
                        className="iconsStyles borderBtn border-end-0"
                        img={Settings}
                        imageClass="imageHeight"
                        buttonClass="download"
                        label="UPDATE"
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <Suspense fallback={<Loader />}>
                {showColorConfiguration && (
                  <ColourUploadModal
                    showColorConfiguration={showColorConfiguration}
                    setShowColorConfiguration={setShowColorConfiguration}
                  />
                )}
              </Suspense>
            </div>
          </div>
        </div>

        <hr className="m-1 mb-2"></hr>

        <div className="row userColors">
          <EditText assetType="Colours" />
        </div>

        {primaryCount == null || secondaryCount == null || gradientCount == null ? (
          <div className="row text-center pt-5 mt-5">
            <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
              No colour Configurations are available
            </h1>
            <p
              style={{
                color: "#15355c",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Please configure colours by pressing above settings icon.
            </p>
          </div>
        ) : (
          <div>
            <div className="PriMartColors py-3 pt-4">
              <div className="row">
                <div className="col-lg-6">
                  <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                </div>
                <div className="col-lg-6 d-flex justify-content-end">
                  <p
                    className="text-uppercase pb-1 m-0 ConFigSave text-decoration-underline"
                    style={{ cursor: "pointer" }}
                    onClick={saveColour}
                  >
                    Save Configuration
                  </p>
                </div>
              </div>
              <hr className="ColorsBorder" />

              <div className="row">{primaryValuesHTMLDivs}</div>
            </div>

            {secondaryCount > 0 ? (
              <div className="SecDrColors py-3">
                <h5 className="border-bottom text-uppercase pb-1">Secondary Colours</h5>
                <div className="row">{secondaryValuesHTMLDivs}</div>
              </div>
            ) : (
              ""
            )}

            {gradientCount > 0 ? (
              <div className="GrDntColors py-3">
                <h5 className="border-bottom mb-0 text-uppercase pb-1">Gradients</h5>
                <div className="row">{gradientValuesHTMLDivs}</div>
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </div>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </>
  );
}

export default Colors;
