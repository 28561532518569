export const ADD_USER = "ADD_USER";
export const ADD_MEMBER = "ADD_MEMBER";
export const CLOSE_MODALS = "CLOSE_MODALS";
export const SHOW_MODAL = "SHOW_MODAL";
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const USER_VERIFIED = "USER_VERIFIED";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const GET_ADMIN_USERS = "GET_ADMIN_USERS";
export const FETCH_CUSTOMERS = "FETCH_CUSTOMERS";
export const FETCH_BrandAssets = "Brand_Assets";
export const FETCH_TEMPLATES = "TEMPLATES";
export const FETCH_ALL_ASSETS = "FETCH_ALL_ASSETS";
export const FETCH_ASSET_RECORDS = "ASSET_RECORDS";
export const ADD_LOGOS = "ADD_LOGOS";
export const GROUP_LOGOS = "GROUP_LOGOS";
export const MEMBER_MASTER_DATA = "MEMBER_MASTER_DATA";
export const GROUP_MASTER_DATA_BY_MEMBER_ID = "GROUP_MASTER_DATA_BY_MEMBER_ID";
export const GROUP_MASTER_DATA_BY_ORGANIZATION = "GROUP_MASTER_DATA_BY_ORGANIZATION";
export const GROUP_MASTER_DATA = "GROUP_MASTER_DATA";
export const MEMBER_MASTER_DATA_BY_GROUP_ID = "MEMBER_MASTER_DATA_BY_GROUP_ID";
export const MEMBER_MASTER_DATA_BY_ORGANIZATION = "MEMBER_MASTER_DATA_BY_ORGANIZATION";
export const SCHOOL_MOTTO = "SCHOOL_MOTTO";
export const COLORS = "COLORS";
export const FONTS = "FONTS";
export const LETTER_HEADS = "LETTER_HEADS";
export const ICONS = "ICONS";
export const ORG_MEMBERS = "ORG_MEMBERS";
export const ORG_GROUPS = "ORG_GROUPS";
export const GROUP_MASTER_DATA_BY_ASSETS_ID = "GROUP_MASTER_DATA_BY_ASSETS_ID";
export const MEMBER_MASTER_DATA_BY_ASSETS_ID = "MEMBER_MASTER_DATA_BY_ASSETS_ID";
export const FETCH_ASSETS_BY_USER_ID = "FETCH_ASSETS_BY_USER_ID";
export const FETCH_COLOURS_CONFIGURATIONS_BY_ORG_NAME = "FETCH_COLOURS_CONFIGURATIONS_BY_ORG_NAME";
export const FETCH_GROUP_NAMES = "GROUP_NAMES";
export const FETCH_GOOGLE_FONTS = "FETCH_GOOGLE_FONTS";
export const STYLE_GUIDE = "STYLE_GUIDE";
export const DESCRIPTION = "DESCRIPTION";
