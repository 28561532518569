import Tooltip from "@mui/material/Tooltip";
import Editor from "nib-core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import addIcon from "../../images/add.svg";
import editIcon from "../../images/Editicon.svg";
import { addEditDescription, getDescription } from "../../redux/Actions/action";
import DialogBox from "../Hooks/DialogBox.component";
import { useCallbackPrompt } from "../Hooks/useCallbackPrompt";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./editorStyles.css";

toast.configure({
  autoClose: 2000,
});

/**
 * EDIT DESCRIPTION TEXT
 * @param {*} props
 * @return {*} 
 */
const EditText = (props) => {
  const dispatch = useDispatch();

  const [displayLoader, setDisplayLoader] = useState(false);
  const [saveDescriptionAction, setSaveDescriptionAction] = useState(false);
  const [autoFocus, setAutoFocus] = useState(false);
  const [content, setContent] = useState({});
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  let descriptionText = [];
  let innerHtmlContent = "";
  descriptionText = useSelector((state) => state.userReducer.Description);

  useEffect(() => {
    dispatch(getDescription(localStorage.getItem("orgName"), props.assetType, successHandler, errorHandler));
    innerHtmlContent = document.querySelector(".ProseMirror")?.innerHTML;

    if (innerHtmlContent) {
      document.getElementById("editContentDiv").setAttribute("class", "d-none");
      document.getElementById("contentDiv").innerHTML = innerHtmlContent;
    }
  }, [descriptionText?.description?.length, props.assetType]);

  
  useEffect(() => {
    return () => {
      if (props.assetType != "Fonts") {
        window.location.reload();
      }
    };
  }, [props.assetType]);

  /**
   * SERVICE SUCCESS HANDLER
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      setContent(JSON.parse(descriptionText?.description));
    }, 500);
  };

  /**
   * SERVICE ERROR HANDLER
   */
  const errorHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * TO MAKE THE DESCRIPTION EDITABLE
   */
  const setDescriptionEditable = () => {
    if (document.getElementById("editContentDiv")) {
      document.getElementById("editContentDiv").removeAttribute("style");
      document.getElementById("editContentDiv").removeAttribute("class");
      document.getElementById("editContentDiv").setAttribute("class", "col-11");
      document.getElementById("contentDiv").setAttribute("class", "d-none");
      setAutoFocus(true);
      setSaveDescriptionAction(true);
    }
  };

  /**
   * THIS METHOD IS USED TO SET THE UPDATED DESCRIPTION TO THE STATE VARIABLE
   * @param {*} e
   */
  const editorTextChanged = (e) => {
    setContent(e);
    setShowDialog(true);
  };

  /**
   * This method is used to SAVE DESCRIPTION
   */
  const saveDescription = () => {
    if (document.getElementById("editContentDiv")) {
      setShowDialog(false);
      setSaveDescriptionAction(false);
      const objSave = {
        orgName: localStorage.getItem("orgName"),
        assetType: props.assetType,
        description: content,
      };
      dispatch(addEditDescription(objSave, successHandlerForDescription, errorHandler));
    }
  };

  /**
   * SERVICE SUCCESS HANDLER FOR DESCRIPTION
   */
  const successHandlerForDescription = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      document.getElementById("editContentDiv").style.pointerEvents = "none";
      document.getElementById("editContentDiv").style.cursor = "not-allowed";
      document.getElementById("editContentDiv").setAttribute("class", "d-none");
      document.getElementById("contentDiv").removeAttribute("class");
      document.getElementById("contentDiv").setAttribute("class", "col-11");
      dispatch(getDescription(localStorage.getItem("orgName"), props.assetType, successHandler, errorHandler));
    }, 500);
  };

  return (
    <>
      <div className="container-fluid my-1">
        <div className="row">
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />

          {/* DESCRIPTION READABLE - IF DESCRIPTION ALREADY EXISTS */}
          <div className="col-11 my-auto" id="contentDiv" />

          {/* DESCRIPTION EDITABLE - IF DESCRIPTION NOT AVAILABLE/ EDIT THE DESCRIPTION */}
          <div className="col-11 my-auto" id="editContentDiv" style={{ pointerEvents: "none", cursor: "not-allowed" }}>
            {descriptionText?.description ? (
              <Editor
                config={{
                  plugins: {
                    options: "inline blockquote list link",
                  },
                  toolbar: {
                    options: "top",
                    top: {
                      options: "inline blockquote list link",
                      inline: { options: "strong em underline subsup link" },
                    },
                    inline: {
                      options: "inline list",
                      inline: { options: "strong em underline" },
                    },
                  },
                }}
                onChange={(e) => editorTextChanged(e)}
                defaultValue={JSON.parse(descriptionText.description)}
                autoFocus={autoFocus}
              />
            ) : (
              ""
            )}
          </div>

          {localStorage.getItem("roleId") === "1" || localStorage.getItem("roleId") === "2" ? (
            <div className="col-1 my-auto d-flex justify-content-end">
              {saveDescriptionAction ? (
                <Tooltip title="Save Description">
                  <div className="buttonGap me-0" onClick={saveDescription}>
                    <IconButton
                      id="addIcon"
                      className="iconsStyles"
                      img={addIcon}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>
              ) : (
                <Tooltip title="Edit Description">
                  <div className="buttonGap me-0" onClick={setDescriptionEditable}>
                    <IconButton
                      id="editIcon"
                      className="iconsStyles"
                      img={editIcon}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>
              )}
            </div>
          ) : (
            ""
          )}
        </div>

        <div>{displayLoader ? <Loader /> : <div></div>}</div>
      </div>
    </>
  );
};

export default EditText;
