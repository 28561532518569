import "bootstrap/dist/css/bootstrap.min.css";
import footerLogo from "../images/footerIcon.svg";
import "./Footer.css";

/**
 * Functional component for footer 
 * @return {*} 
 */
const Footer = () => {
  return (
    <div id="remove-padding" className="container-fluid footers" style={{ zIndex: "+9999" }}>
      <div className="btn-gradient-1"></div>

      <div className="container">
        <div className="row py-2">
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="row">
              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                <div className="frOm"><p> </p></div>
                <img className="img-footer img-fluid" src={footerLogo} />
                <div className="copyrights p-0">© Copyright Grafix Group. All rights reserved.</div>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 my-auto">
                <div className="footer-section">
                  <li className="pb-2">
                    <a href="/aboutus">About Us</a>
                  </li>
                  <li className="pb-2">
                    <a href="/underConstruction">Contact Us</a>
                  </li>

                  {/* <div className="pt-2">Terms & Conditions</div> */}
                </div>
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                {/* <div className="footer-section">
                  <div>
                    <div className="d-flex pt-1">
                    <p className="my-auto"> <img src={facebook} className="my-auto" />
                      &nbsp;&nbsp;
                      Facebook</p>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex pt-1">
                    <p className="my-auto"> <img src={twitter} className="my-auto" />
                      &nbsp;&nbsp;
                     Twitter</p>
                    </div>
                  </div>

                  <div>
                    <div className="d-flex pt-1">
                    <p className="my-auto"> <img src={instagram} className="my-auto" />
                      &nbsp;&nbsp;
                      Instagram</p>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                {/* <div className="footer-4th-col">
                  <div className="subscribe-text">
                    Subscribe to our newsletter
                  </div>
                  <form>
                    <input
                      type="email"
                      className="input-fields subscribe m-0"
                      placeholder="Email Address"
                    />
                    <button className="buttons">Ok</button>
                  </form>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
