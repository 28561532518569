import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

const ConfirmModal = ({ open, onClose, onConfirm, titleText, titleText1 }) => {
  return (
    <Dialog
      className="ConfirmModelL"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{""}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {titleText}
          <br />
          {titleText1}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className="conFirmYes" onClick={onConfirm} color="primary">
          Yes
        </Button>
        <Button className="conFirmNo" onClick={onClose} color="primary" autoFocus>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default ConfirmModal;
