import { Navigate, Outlet } from 'react-router-dom';

const SuperAdminRoutes = () => {
    let auth = localStorage.getItem("accessToken");
    let roleId = localStorage.getItem("roleId");
    return (
        auth && roleId === "1" ? <Outlet /> : <Navigate to="/" />
    )
};

export default SuperAdminRoutes;