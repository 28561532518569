import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footers from "../src/Components/Footer";
import Headers from "../src/Components/Header";
import Letterhead from "../src/Components/Templates/LetterHead";
import "./App.css";
import Adduser from "./Components/Admin/AdminCustomers/Adduser";
import ViewEdit from "./Components/Admin/AdminCustomers/ViewEdit";
import EditUser from "./Components/Admin/AdminCustomers/EditUser";
import BrandAssets from "./Components/Admin/BrandAssets/BrandAssets";
import BrandAssetsLists from "./Components/Admin/Lists/BrandAssetsLists";
import Members from "./Components/Admin/SuperAdminUsers/Members";
import Templates from "./Components/Admin/Templates/Templates";
import BrandAssetGeneric from "./Components/BrandAssets/BrandAssetGeneric";
import BrandAssetGenericDetails from "./Components/BrandAssets/BrandAssetGenericDetails";
import Colors from "./Components/BrandAssets/Colors";
import ColorsView from "./Components/BrandAssets/ColorsView";
import ColoursEdit from "./Components/BrandAssets/ColoursEdit";
import ColoursShare from "./Components/BrandAssets/ColoursShare";
import Fonts from "./Components/BrandAssets/Fonts";
import FontsEdit from "./Components/BrandAssets/FontsEdit";
import FontsView from "./Components/BrandAssets/FontsView";
import Groups from "./Components/Groups";
import { default as Homepage, default as Homepages } from "./Components/Homepage";
import Orgmembers from "./Components/Orgmembers";
import PageUnderConstruction from "./Components/PageUnderConstruction";
import Password from "./Components/Password";
import SideBar from "./Components/SideMenu";
import Styleguide from "./Components/Styleguide";
import TemplateDetails from "./Components/Templates/TemplateDetails";
import { appRoutes } from "./util/constants";
import SuperAdminRoutes from "./redux/helpers/SuperAdminRoutes";
import AdminRoutes from "./redux/helpers/AdminRoutes";
import PrivateRoutes from "./redux/helpers/PrivateRoutes";
import ResetPasswordRoutes from "./redux/helpers/ResetPasswordRoutes";
import SetPasswordRoutes from "./redux/helpers/SetPasswordRoutes";
import React, { Suspense } from "react";
import { Loader } from "./Components/Shared/Loader";

function App() {
  const FeedBack = React.lazy(() => import("./Components/FeedBack"));
  const Tor = React.lazy(() => import("./Components/terms"));
  const AboutUs = React.lazy(() => import("./Components/AboutUs"));
  const UnderConstruction = React.lazy(() => import("./Components/underConstruction"));
  const AddMembers = React.lazy(() => import("./Components/AddMembers"));
  const EditMembers = React.lazy(() => import("./Components/EditMember"));
  const Creategroup = React.lazy(() => import("./Components/Creategroup"));
  const Editgroup = React.lazy(() => import("./Components/Editgroup"));

  return (
    <Router basename={"/"}>
      <Headers />
      <Routes>
        <Route element={<SuperAdminRoutes />} >
          <Route path={appRoutes.addUser} element={<SideBar component={<Adduser />} />} />
          <Route path={appRoutes.viewEdit} element={<SideBar component={<ViewEdit />} />} />
          <Route path={appRoutes.editUser} element={<SideBar component={<EditUser />} />} />
          <Route path={appRoutes.adminBrandAssets} element={<SideBar component={<BrandAssets />} />} />
          <Route path={appRoutes.adminTemplates} element={<SideBar component={<Templates />} />} />
          <Route path={appRoutes.lists} element={<SideBar component={<BrandAssetsLists />} />} />
          <Route path={appRoutes.adminMembers} element={<SideBar component={<Members />} />} />
        </Route>

        <Route element={<PrivateRoutes />}>
          <Route path={appRoutes.styleGuide} element={<SideBar component={<Styleguide />} />} />
          <Route path={appRoutes.brandAssetGeneric} element={<SideBar component={<BrandAssetGeneric />} />} />
          <Route path={appRoutes.assetDetails} element={<SideBar component={<BrandAssetGenericDetails />} />} />
          <Route path={appRoutes.letterHeadTemplate} element={<SideBar component={<Letterhead />} />} />
          <Route path={appRoutes.templateDetails} element={<SideBar component={<TemplateDetails />} />} />
          <Route path={appRoutes.coloursView} element={<SideBar component={<ColorsView />} />} />
          <Route path={appRoutes.fontsView} element={<SideBar component={<FontsView />} />} />
          <Route path={appRoutes.colourShare} element={<ColoursShare />} />
          <Route path={appRoutes.termsAndConditions} element={<Suspense fallback={<Loader />}> <Tor /> </Suspense>} />
        </Route>

        <Route element={<AdminRoutes />}>
          <Route path={appRoutes.groups} element={<SideBar component={<Groups />} />} />
          <Route path={appRoutes.groupsCreateGroup} element={<SideBar component={<Suspense fallback={<Loader />}> <Creategroup /> </Suspense>} />} />
          <Route path={appRoutes.groupsEditGroup} element={<SideBar component={<Suspense fallback={<Loader />}> <Editgroup /> </Suspense>} />} />
          <Route path={appRoutes.orgMembers} element={<SideBar component={<Orgmembers />} />} />
          <Route path={appRoutes.orgMembersAdd} element={<SideBar component={<Suspense fallback={<Loader />}> <AddMembers /> </Suspense>} />} />
          <Route path={appRoutes.orgMembersEdit} element={<SideBar component={<Suspense fallback={<Loader />}> <EditMembers /> </Suspense>} />} />
          <Route path={appRoutes.fonts} element={<SideBar component={<Fonts />} />} />
          <Route path={appRoutes.fontsEdit} element={<SideBar component={<FontsEdit />} />} />
          <Route path={appRoutes.colours} element={<SideBar component={<Colors />} />} />
          <Route path={appRoutes.coloursEdit} element={<SideBar component={<ColoursEdit />} />} />
        </Route>

        <Route element={<ResetPasswordRoutes />}>
          <Route path={appRoutes.resetpassword} element={<Homepages />} />
          <Route path={appRoutes.resetPasswordId} element={<Homepages />} />
        </Route>

        <Route element={<SetPasswordRoutes />}>
          <Route path={appRoutes.setPassword} element={<Password />} />
        </Route>

        <Route exact path={appRoutes.rootRoute} element={<Homepages />} />
        <Route path={appRoutes.homepage} element={<SideBar component={<Homepage />} />} />
        <Route path={appRoutes.feedback} element={<Suspense fallback={<Loader />}> <FeedBack /> </Suspense>} />
        <Route path={appRoutes.aboutUs} element={<Suspense fallback={<Loader />}> <AboutUs /> </Suspense>} />
        <Route path={appRoutes.pageUnderConstruction} element={<SideBar component={<PageUnderConstruction />} />} />
        <Route path={appRoutes.underConstruction} element={<Suspense fallback={<Loader />}> <UnderConstruction /> </Suspense>} />

      </Routes>
      <Footers />
    </Router>
  );
}

export default App;
