import { Navigate, Outlet, useLocation } from 'react-router-dom';

const ResetPasswordRoutes = () => {
    const search = useLocation().search;
    let token = localStorage.getItem("accessToken");
    const userVerifyId = new URLSearchParams(search).get("verifyId");
    return (
        !token && userVerifyId && userVerifyId.length !== 0 ? <Outlet /> : <Navigate to="/" />
    )
};

export default ResetPasswordRoutes;