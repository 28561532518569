import MaterialTable, { MTableAction } from "@material-table/core";
import { ChevronLeft, ChevronRight, Clear, FirstPage, LastPage } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import { default as React, forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import customerIcon from "../../../images/adminprofilimg.svg";
import deleteImg from "../../../images/delete.svg";
import {
  addSuperAdminUser,
  deleteSuperAdmin,
  fetchSuperAdminUsers,
  UpdateSuperAdminUser
} from "../../../redux/Actions/action";
import { Loader } from "../../Shared/Loader";
import "./Members.css";

/* Icons used in the material table */
const tableIcons = {
  Add: () => <span className="applySettingsBtn"> ADD USER </span>,
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: () => (
    <div style={{}}>
      <img alt="" src={deleteImg} />
    </div>
  ),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
};

toast.configure({
  autoClose: 2000,
});

/**
 * Super Admin Users Functional Component
 * @return {*}
 */
function Members() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const addActionRef = React.useRef();

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);
  // pagination state
  const [paging, setPaging] = useState(true);
  
  let superAdminUsers = [];
  superAdminUsers = useSelector((state) => state.userReducer.adminUsers);

  useEffect(() => {
    localStorage.setItem("switchUser", "false");
    localStorage.removeItem("orgType");
    localStorage.removeItem("brandAssetsOptionsName");
    localStorage.removeItem("templatesOptionsName");
    localStorage.removeItem("schoolMenuIndex");
    sessionStorage.removeItem("primaryValue");
    sessionStorage.removeItem("secondaryValue");
    sessionStorage.removeItem("gradientValue");
    localStorage.setItem("orgName", "Super Admin");
    
    if (localStorage.getItem("roleId") === "1") {
      dispatch(fetchSuperAdminUsers(successHandler, errorHandler));
      if (superAdminUsers) {
        const val = { value: superAdminUsers.length > 10 ? true : false };
        setPaging(val.value);
      } else {
        setPaging(false);
      }
    } else {
      navigate("/");
      localStorage.setItem("schoolMenuIndex", 0);
    }
  }, [superAdminUsers.length]);

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call after successful fetch request
   */
  const onFetchSuccessData = () => {
    dispatch(fetchSuperAdminUsers(successHandler, errorHandler));
    if (superAdminUsers) {
      const val = { value: superAdminUsers.length > 10 ? true : false };
      setPaging(val.value);
    } else {
      setPaging(false);
    }
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  return (
    <div className="adminUsers mt-3">
      <div className="row mb-1">
        <div className="col-lg-9 d-flex">
          <h6 className="titLe py-2 m-0 my-auto fw-bold">
            Admin <span>- Users</span>
          </h6>
        </div>

        <div className="col-lg-3 d-flex justify-content-end">
          <button className="applySettingsBtn" onClick={() => addActionRef.current.click()}>
            ADD USER
          </button>
        </div>
      </div>
      <hr className="w-100 mt-2 mb-3" />
      <div>
        <h6 className="subTitle"> Search Users </h6>
      </div>

      <div>
        <MaterialTable
          title=""
          columns={[
            {
              title: "User",
              field: "name",
              width: "47%",
              cellStyle: {
                textTransform: "capitalize",
                color: "#2b2b2b !important",
              },

              validate: (rowData) => {
                if (rowData.name == undefined || rowData.name.trim() === "") {
                  return "Required";
                }
                return true;
              },
              render: (superAdminUsers) => (
                <div className="d-flex">
                  <div className="me-2">
                    <img className="img-fluid memBrIcon" alt="" src={customerIcon} />
                  </div>
                  <div className="my-auto">{superAdminUsers.name}</div>
                </div>
              ),
            },
            {
              title: "Email",
              field: "emailID",
              width: "47%",
              cellStyle: {
                color: "#2b2b2b !important",
              },
              validate: (rowData) => {
                const regex = /^(?!\.)[a-zA-Z0-9-]+(?!.*?\.\.)[a-zA-Z0-9-_.]+(?!.*\.$)+@[a-zA-Z0-9-]+[a-z.0-9]+\.[a-z.]{2,4}$/;
                if (rowData.emailID == undefined || rowData.emailID === "") {
                  return "Required";
                } else if (!regex.test(rowData.emailID)) {
                  return "Enter valid email format";
                }
                return true;
              },
            },
          ]}
          icons={tableIcons}
          data={superAdminUsers}
          options={{
            search: true,
            sorting: false,
            emptyRowsWhenPaging: false,
            draggable: false,
            paging: paging,
            pageSize: 10,
            actionsColumnIndex: -1,
            addRowPosition: "last",
            headerStyle: {
              fontSize: "15px",
              fontWeight: "bold",
            },
            rowStyle: (data, index) => ({
              backgroundColor: index % 2 === 0 ? "#ffffff" : "#ffffff",
              color: "darkblue",
            }),
            cellStyle: {
              padding: "0px",
              paddingLeft: "15px",
            },
          }}
          components={{
            Action: (props) => {
              if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
                return <MTableAction {...props} />;
              } else {
                return <div ref={addActionRef} onClick={props.action.onClick} />;
              }
            },
          }}
          editable={{
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                dispatch(deleteSuperAdmin(oldData.id, onFetchSuccessData, errorHandler));
                resolve();
              }),
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                dispatch(addSuperAdminUser(newData, onFetchSuccessData, errorHandler));
                resolve();
              }),
            onRowUpdate: (newData, oldValue, rowData) => {
              return new Promise((resolve, reject) => {
                dispatch(UpdateSuperAdminUser({ ...rowData, newData }, onFetchSuccessData, errorHandler));
                resolve();
              });
            },
          }}
        />
      </div>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default Members;
