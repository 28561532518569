import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const Authorization = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("roleId") == 1) {
      if (localStorage.getItem("switchUser") == "true") {
        navigate("/styleguide");
      } else {
        navigate("admin/viewedit");
      }
    } else {
      navigate("/styleguide");
    }
  }, []);
  return <div className="spin"></div>;
};
