import Tooltip from "@mui/material/Tooltip";
import React, { Suspense, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import upload from "../../images/upload.svg";
import deleteImg from "../../images/delete.svg";
import uploadThumbnailImg from "../../images/uploadThumbnail.svg";
import excel from "../../images/excel.svg";
import pdf from "../../images/pdf.svg";
import ppt from "../../images/ppt.svg";
import shareAllImg from "../../images/shareAll.svg";
import shareImg from "../../images/sharelist.svg";
import word from "../../images/word.svg";
import downloadImg from "../../images/downloadlist.svg";
import downloadAll from "../../images/DownloadAll.svg";
import settings from "../../images/settings.svg";
import permissionAll from "../../images/permissionall.svg";
import permission from "../../images/permission.svg";
import {
  AddingThumbnails,
  deleteAsset,
  DeleteThumbnail,
  getAssetsByMemberId,
  getLetterHeads,
  SetAsGroupThumbnail,
  showModal,
} from "../../redux/Actions/action";
import "../BrandAssets/brandAssets.css";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import { envVariables } from "./../../Environments/env.local";
import EditAssetDetailsModal from "../Shared/Modals/EditAssetDetailsModal";

const api = envVariables.ip;
toast.configure({
  autoClose: 2000,
});

/**
 * This is functional component used for all Template details page
 * @return {*} 
 */
function TemplateDetails() {
  const LetterHeadUploadModal = React.lazy(() => import("../Shared/Modals/LetterHeadUploadModal"));
  const ShareModal = React.lazy(() => import("./../Buttonspages/Share"));
  const Permissions = React.lazy(() => import("../Buttonspages/permissions"));
  const ConfirmModal = React.lazy(() => import("../Shared/Modals/ConfirmModal"));

  const loc = useLocation();
  const data = JSON.parse(loc.state);
  let dynamicName = localStorage.getItem("templatesOptionsName");
  const fileRef = React.createRef();
  const thumbnailFormData = new FormData();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(false);

  // state for showing upload modal and dialog
  const [showLetterHeadUpload, setShowLetterHeadUpload] = useState(false);
  const [showAssetDetailsModal, setShowAssetDetailsModal] = useState(false);
  const [showConfirmForThumbnail, setShowConfirmForThumbnail] = useState(false);
  const [shareAllClicked, setShareAllClicked] = useState(false);
  const [shareClicked, setShareClicked] = useState(false);
  const [permissionAllClicked, setPermissionAllClicked] = useState(false);
  const [permissionClicked, setPermissionClicked] = useState(false);
  const [showThumbnailDelete, setShowThumbnailDelete] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  // const [showSetAsThumbnailText, setShowSetAsThumbnailText] = useState(false);

  // state for userRole 
  const [userRole, setUserRole] = useState("");

  // state for templateDetails Data
  const [assetData, setAssetData] = useState([]);
  const [selectedSize, setSelectedSize] = useState({ sizeDimensions: "" });
  const [viewImgID, setViewImgID] = useState("");
  const [viewImgSrc, setViewImgSrc] = useState("");
  const [viewImgGroup, setViewImgGroup] = useState("");
  const [assetType, setAssetType] = useState("");
  const [fileType, setFileType] = useState("");
  const [desc, setDesc] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [viewImgIDs, setViewImgIDs] = useState("");


  let sizeData = [];
  let adminAssetsData = [];
  let userAssetsData = [];
  adminAssetsData = useSelector((state) => state.userReducer.letterheads);
  userAssetsData = useSelector((state) => state.userReducer.AssetsDataByUserId);

  useEffect(() => {
    if (!data) {
      navigate("/");
    }
    if (localStorage.getItem("roleId") === "1") {
      setUserRole("1");
    } else if (localStorage.getItem("roleId") === "2") {
      setUserRole("2");
    } else {
      setUserRole("3");
    }

    if (localStorage.getItem("roleId") === "3") {
      const obj = {
        id: localStorage.getItem("userId"),
        assetType: dynamicName,
      };
      dispatch(getAssetsByMemberId(obj, successHandlerForGetRequest));
    } else {
      const obj = {
        name: localStorage.getItem("orgName"),
        assetType: dynamicName,
      };
      dispatch(getLetterHeads(obj, successHandlerForGetRequest));
    }
  }, [adminAssetsData.length, userAssetsData.length]);

  console.log(viewImgSrc);
  useEffect(() => {
    return () => {
      localStorage.removeItem("letterHeadGroupName");
    };
  }, []);

  /**
   * This method will call after successful fetch request
   * @param {*} resp
   */
  const successHandlerForGetRequest = (resp) => {
    const filteredData = resp?.filter((item) => item.groupName === localStorage.getItem("letterHeadGroupName"));
    setAssetData(filteredData);
    filteredData?.forEach((element, index) => {
      if (index === 0) {
        setViewImgGroup(element.groupName);
        setAssetType(element.assetType);
        setDesc(element.comments);
        setViewImgID(element.id);
        setFileType(element.fileType);
        setFileExtension(element.fileExtension);
        setSelectedSize({ ...selectedSize, sizeDimensions: element.id });

        if (element.thumbnailPath) {
          setViewImgSrc(element.thumbnailPath);
          if (document.getElementById("deleteThumbnailIcon")) {
            document.getElementById("deleteThumbnailIcon").removeAttribute("class");
          }
        } else {
          setViewImgSrc(element.path);
          if (document.getElementById("deleteThumbnailIcon")) {
            document.getElementById("deleteThumbnailIcon").setAttribute("class", "d-none");
          }
        }

        // if (
        //   element.fileExtension == ".jpg" ||
        //   element.fileExtension == ".jpeg" ||
        //   element.fileExtension == ".png" ||
        //   element.fileExtension == ".jfif"
        // ) {
        //   document.getElementById("thumbnailAndDelete").setAttribute("class", "d-none");
        //   setViewImgSrc(element.path);
        //   setShowSetAsThumbnailText(true);
        // } else {
        //   document.getElementById("thumbnailAndDelete").removeAttribute("class");
        //   if (element.thumbnailPath) {
        //     setViewImgSrc(element.thumbnailPath);
        //     setShowSetAsThumbnailText(true);
        //     if (document.getElementById("deleteThumbnailIcon")) {
        //       document.getElementById("deleteThumbnailIcon").removeAttribute("class");
        //     }
        //   } else {
        //     setShowSetAsThumbnailText(false);
        //     setViewImgSrc(element.path);
        //     if (document.getElementById("deleteThumbnailIcon")) {
        //       document.getElementById("deleteThumbnailIcon").setAttribute("class", "d-none");
        //     }
        //   }
        // }
        if (element.externalUse) {
          setShowPermissions(true);
        } else {
          setShowPermissions(false);
        }
      }
    });
  };

  /* Preparing the option in select field  */
  assetData?.forEach((element, index) => {
    // if (element.comments.length > 35) {
    let pushELe = "";
    if (element.fileType.length > 0) {
      pushELe = element.fileType;
    }
    if (element.fileSize !== "-" && element.fileSize !== "") {
      if (element.fileSize.length > 10) {
        pushELe = pushELe + " : " + element.fileSize.substring(0, 10) + "..";
      } else {
        pushELe = pushELe + " : " + element.fileSize;
      }
    }
    if (element.dpi !== "-" && element.dpi !== "") {
      if (element.dpi.length > 10) {
        pushELe = pushELe + " : " + element.dpi.substring(0, 10) + "..";
      } else {
        pushELe = pushELe + " : " + element.dpi;
      }
    }
    if (
      element.fileExtension.replace(".", "").toUpperCase() !== "-" &&
      element.fileExtension.replace(".", "").toUpperCase() !== ""
    ) {
      pushELe = pushELe + " : " + element.fileExtension.replace(".", "").toUpperCase();
    }
    if (element.comments !== "-" && element.comments !== "") {
      if (element.comments.length > 10) {
        pushELe = pushELe + " : " + element.comments.substring(0, 10) + "..";
      } else {
        pushELe = pushELe + " : " + element.comments;
      }
    }

    sizeData.push({
      label: pushELe,
      value: element.id,
    });
    // } else {
    //   let pushELe = "";
    //   if (element.fileType.length > 0) {
    //     pushELe = element.fileType;
    //   }
    //   if (element.fileSize != "-" && element.fileSize != "") {
    //     pushELe = pushELe + " : " + element.fileSize;
    //   }
    //   if (element.dpi != "-" && element.dpi != "") {
    //     pushELe = pushELe + " : " + element.dpi;
    //   }
    //   if (
    //     element.fileExtension.replace(".", "").toUpperCase() != "-" &&
    //     element.fileExtension.replace(".", "").toUpperCase() != ""
    //   ) {
    //     pushELe = pushELe + " : " + element.fileExtension.replace(".", "").toUpperCase();
    //   }
    //   if (element.comments != "-" && element.comments != "") {
    //     pushELe = pushELe + " : " + element.comments.substring(0, 35) + "...";
    //   }
    //   sizeData.push({
    //     label: pushELe,
    //     value: element.id,
    //   });
    // }
  });

  /**
   * When user change the Dimension
   * @param {*} e
   */
  const dimensionChange = (e) => {
    const selectedLogo = assetData.filter((item) => item.id === e.target.value);
    setViewImgGroup(selectedLogo[0].groupName);
    setAssetType(selectedLogo[0].assetType);
    setDesc(selectedLogo[0].comments);
    setViewImgID(selectedLogo[0].id);
    setFileType(selectedLogo[0].fileType);
    setFileExtension(selectedLogo[0].fileExtension);
    setSelectedSize({ ...selectedSize, sizeDimensions: e.target.value });

    if (selectedLogo[0].thumbnailPath) {
      setViewImgSrc(selectedLogo[0].thumbnailPath);
      if (document.getElementById("deleteThumbnailIcon")) {
        document.getElementById("deleteThumbnailIcon").removeAttribute("class");
      }
    } else {
      setViewImgSrc(selectedLogo[0].path);
      if (document.getElementById("deleteThumbnailIcon")) {
        document.getElementById("deleteThumbnailIcon").setAttribute("class", "d-none");
      }
    }

    // if (
    //   selectedLogo[0].fileExtension == ".jpg" ||
    //   selectedLogo[0].fileExtension == ".jpeg" ||
    //   selectedLogo[0].fileExtension == ".png" ||
    //   selectedLogo[0].fileExtension == ".jfif"
    // ) {
    //   document.getElementById("thumbnailAndDelete").setAttribute("class", "d-none");
    //   setShowSetAsThumbnailText(true);
    //   setViewImgSrc(selectedLogo[0].path);
    // } else {
    //   document.getElementById("thumbnailAndDelete").removeAttribute("class");
    //   if (selectedLogo[0].thumbnailPath) {
    //     setViewImgSrc(selectedLogo[0].thumbnailPath);
    //     setShowSetAsThumbnailText(true);
    //     if (document.getElementById("deleteThumbnailIcon")) {
    //       document.getElementById("deleteThumbnailIcon").removeAttribute("class");
    //     }
    //   } else {
    //     setViewImgSrc(selectedLogo[0].path);
    //     setShowSetAsThumbnailText(false);
    //     if (document.getElementById("deleteThumbnailIcon")) {
    //       document.getElementById("deleteThumbnailIcon").setAttribute("class", "d-none");
    //     }
    //   }
    // }

    if (selectedLogo[0].externalUse) {
      setShowPermissions(true);
    } else {
      setShowPermissions(false);
    }
  };

  /**
   * To download the Images
   */
  const downloadFile = () => {
    setDisplayLoader(true);
    const obj = {
      name: localStorage.getItem("orgName"),
      groupName: viewImgGroup,
      assetType: assetType,
      fileType: fileType,
      fileExtension: fileExtension,
      assetId: viewImgID,
    };

    const path = `${api}/assets/download/${obj.name}/${obj.groupName}/${obj.assetType}/null/${obj.fileType}/${obj.fileExtension}/${obj.assetId}`;

    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", "image.png"); //or any other extension
    document.body.appendChild(link);
    link.click();
    setDisplayLoader(false);
  };

  /**
   * To download  All the Images
   */
  const downloadAllAssets = () => {
    setDisplayLoader(true);
    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: assetType,
      groupName: viewImgGroup,
    };

    const path = `${api}/assets/downloadPackage/${obj.name}/${obj.assetType}/${obj.groupName}`;

    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", "image.png"); //or any other extension
    document.body.appendChild(link);
    link.click();
    setDisplayLoader(false);
  };

  /**
   * This method will call when user clicked on Permissions button of template
   * @param {*} event
   */
  const onPermissionClick = (event) => {
    event.preventDefault();
    setPermissionClicked(true);
    setPermissionAllClicked(false);
    setDisplayLoader(true);
    setTimeout(() => {
      dispatch(showModal("permission"));
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method will call when user clicked on PermissionAll of templates
   * @param {*} event
   */
  const onPermissionAllClick = (event) => {
    event.preventDefault();
    setPermissionAllClicked(true);
    setPermissionClicked(false);
    let ids = "";
    assetData.forEach((element, index) => {
      ids = ids + element.id + ",";
    });
    setViewImgIDs(ids.substring(0, ids.length - 1));
    setDisplayLoader(true);
    setTimeout(() => {
      dispatch(showModal("permission"));
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method will call when user clicked on share of template 
   * @param {*} event
   */
  const onShareClick = (event) => {
    event.preventDefault();
    setShareClicked(true);
    setShareAllClicked(false);
    setDisplayLoader(true);
    setTimeout(() => {
      dispatch(showModal("share"));
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method will call when user clicked on shareAll of assets 
   * @param {*} event
   */
  const onShareAllClick = (event) => {
    event.preventDefault();
    setShareAllClicked(true);
    setShareClicked(false);
    let ids = "";
    assetData.forEach((element, index) => {
      ids = ids + element.id + ",";
    });
    setViewImgIDs(ids.substring(0, ids.length - 1));
    setDisplayLoader(true);
    setTimeout(() => {
      dispatch(showModal("share"));
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method will call when user clicked on delete button to Delete the Template
   */
  const onDeleteClickHandler = () => {
    setShowDelete(true);
  };

  /**
   * Close Confirm Modal If user Clicked "No"
   */
  const closeDelete = () => {
    setShowDelete(false);
  };

  /**
   * Delete Asset If user Clicked "Yes"
   */
  const deleteAssetId = () => {
    setShowDelete(false);
    setDisplayLoader(true);
    dispatch(deleteAsset(viewImgID, successHandler, errorHandler));
  };

  /**
   * This method is used for display Upload Modal
   */
  const displayUpload = () => {
    if (assetType === dynamicName) {
      setShowLetterHeadUpload(true);
    }
  };

  /**
   * API Success Handler for deleting the template
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
      toast.success("Asset has been deleted successfully");
      if (assetType === dynamicName) {
        navigate("/templates/template");
      }
    }, 500);
  };

  /**
   * This method will call if any error occurs during execution 
   */
  const errorHandler = () => {
    if (assetType === dynamicName) {
      navigate("/templates/template");
    }
    setDisplayLoader(false);
  };

  /*
   * This method is used for showing confirm modal when user clicked on set as thumbnail text
   */
  const onClickSetAsThumbnailHandler = () => {
    setShowConfirmForThumbnail(true);
  };

  /*
  Close Confirm Modal If user Clicked "No"
  */
  const closeThumbnailConfirmModal = () => {
    setShowConfirmForThumbnail(false);
  };

  /*
   * This method is used for set as thumbnail If user clicked "Yes"
   */
  const confirmSetGroupThumbnailHandler = () => {
    const obj = {
      assetId: viewImgID,
      assetType: dynamicName,
      groupName: viewImgGroup,
      name: localStorage.getItem("orgName"),
    };
    setDisplayLoader(true);
    dispatch(SetAsGroupThumbnail(obj, successHandlerForSetThumbnail, errorHandlerForSetThumbnail));
  };

  /**
   * Set Group Thumbnail API Success Handler
   */
  const successHandlerForSetThumbnail = () => {
    setDisplayLoader(false);
    setShowConfirmForThumbnail(false);
  };

  /**
   * Set Group Thumbnail API Error Handler
   */
  const errorHandlerForSetThumbnail = () => {
    setDisplayLoader(false);
  };

  /**
   * To confirm delete Thumbnail
   */
  const onClickDeleteThumbnailHandler = () => {
    setShowThumbnailDelete(true);
  };

  /**
   * Close Confirm Modal If user Clicked "No"
   */
  const closeDeleteThumbnail = () => {
    setShowThumbnailDelete(false);
  };

  /**
   * Delete Thumbnail If user Clicked "Yes"
   */
  const deleteThumbnail = () => {
    setShowThumbnailDelete(false);
    setDisplayLoader(true);
    dispatch(DeleteThumbnail(viewImgID, successHandlerForDeleteThumbnail, errorHandlerForGet));
  };

  /**
   * This method is fired after successful thumbnail Delete
   */
  const successHandlerForDeleteThumbnail = () => {
    setDisplayLoader(false);
    // setShowSetAsThumbnailText(false);
    if (document.getElementById("deleteThumbnailIcon")) {
      document.getElementById("deleteThumbnailIcon").setAttribute("class", "d-none");
    }
    const selectedLogo = assetData.filter((item) => item.id === viewImgID);
    setViewImgSrc(selectedLogo[0].path);
    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: dynamicName,
    };
    dispatch(getLetterHeads(obj));
  };

  /**
   * This method is fired when user click on the "+" Icon
   */
  const onClickUploadThumbnailForAsset = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  /**
   * This method is used to upload the thumbnail images
   */
  const handleThumbnailChange = () => {
    let fileName = fileRef.current.files[0].name;
    let fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

    // validating the uploaded Thumbnail with specified extensions
    if (fileExtension === "png" || fileExtension === "jpeg" || fileExtension === "jpg") {
    } else {
      fileRef.current.value = "";
      return <div className="warningMsg">{toast.warning("Please Add valid Thumbnail")}</div>;
    }

    let thumbnailList = [];
    thumbnailList.push({
      fileType: fileType,
      id: viewImgID,
    });

    thumbnailFormData.append("name", localStorage.getItem("orgName"));
    thumbnailFormData.append("groupName", viewImgGroup);
    thumbnailFormData.append("assetType", dynamicName);
    thumbnailFormData.append("assetList", JSON.stringify(thumbnailList));
    thumbnailFormData.append("imagesList", fileRef.current.files[0]);

    setDisplayLoader(true);
    dispatch(AddingThumbnails(thumbnailFormData, successHandlerForUploadThumbnail, errorHandlerForUploadThumbnail));
  };

  /**
   * This method will call after sucessful Thumbnail upload 
   */
  const successHandlerForUploadThumbnail = () => {
    setDisplayLoader(false);
    // setShowSetAsThumbnailText(true);
    if (document.getElementById("deleteThumbnailIcon")) {
      document.getElementById("deleteThumbnailIcon").removeAttribute("class");
    }
    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: dynamicName,
    };
    dispatch(getLetterHeads(obj, successHandlerForGet, errorHandlerForGet));
  };

  /**
   * This method will call after if any error occurs during Thumbnail upload
   */
  const errorHandlerForUploadThumbnail = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will called after sucessful get request
   * @param {*} resp
   */
  const successHandlerForGet = (resp) => {
    let filteredData = [];
    if (resp) {
      for (let index = 0; index < resp.length; index++) {
        if (viewImgID === resp[index].id) {
          setViewImgSrc(resp[index].thumbnailPath);
        }
      }
      filteredData = resp.filter((item) => item.groupName === viewImgGroup);
      setAssetData(filteredData);
    }
  };

  /**
   * This Method will call if any error occurs during execution
   */
  const errorHandlerForGet = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will used for to display the editAsset details modal when user clicked on settings icon
   */
  const dispayEditAssetDetailsModal = () => {
    setShowAssetDetailsModal(true);
  };

  return (
    <>
      <div className="hide logoDetail mt-3">
        <div className="row mb-2">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12">
            <h6 className="titLe py-2 m-0 my-auto">
              Templates <span> - {assetType}</span>
            </h6>
          </div>
        </div>

        <div className="row pt-3">
          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="linear mb-2">
              <div id="thumbnailAndDelete">
                {userRole === "1" || userRole === "2" ? (
                  <div className="d-flex justify-content-between p-3">
                    <Tooltip title="Upload Thumbnail">
                      <div onClick={onClickUploadThumbnailForAsset}>
                        <img style={{ cursor: "pointer" }} className="img-fluid" src={uploadThumbnailImg} alt="" />
                        <label className="m-0 p-0 my-auto uploadThumbnailview ps-1" style={{ cursor: "pointer" }}>
                          Add Thumbnail
                        </label>
                        <input
                          id="inputThumbnail"
                          type="file"
                          accept="image/png,image/jpeg,image/jpg"
                          ref={fileRef}
                          onChange={handleThumbnailChange}
                          className="d-none"
                          style={{ width: "50%" }}
                        />
                      </div>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <img
                        id="deleteThumbnailIcon"
                        className="d-none"
                        style={{ cursor: "pointer" }}
                        src={deleteImg}
                        alt=""
                        onClick={onClickDeleteThumbnailHandler}
                      />
                    </Tooltip>
                  </div>
                ) : (
                  ""
                )}
              </div>

              <div className="logoThumbnail ImgDetails d-flex">
                {viewImgSrc.includes("pdf") ? (
                  <div className="m-auto">
                    <img className="img-fluid ImgDetails pdfDefaultView p-1" src={pdf} alt="" />
                  </div>
                ) : viewImgSrc.includes("doc") ||
                  viewImgSrc.includes("docx") ||
                  viewImgSrc.includes("dot") ||
                  viewImgSrc.includes("dotx") ? (
                  <div className="m-auto">
                    <img className="img-fluid ImgDetails pdfDefaultView p-1" src={word} alt="" />
                  </div>
                ) : viewImgSrc.includes("xls") ? (
                  <div className="m-auto">
                    <img className="img-fluid ImgDetails pdfDefaultView p-1" src={excel} alt="" />
                  </div>
                ) : viewImgSrc.includes("pptx") || viewImgSrc.includes("ppt") || viewImgSrc.includes("potx") ? (
                  <div className="m-auto">
                    <img className="img-fluid ImgDetails pdfDefaultView p-1" src={ppt} alt="" />
                  </div>
                ) : (
                  <div className="m-auto">
                    <img className="img-fluid ImgDetails p-1" src={viewImgSrc} alt="" />
                  </div>
                )}
              </div>
              {userRole === "1" || userRole === "2" ? (
                // showSetAsThumbnailText ? (
                <div
                  className="text-center py-3 fw-bold"
                  style={{ cursor: "pointer", color: "#8fbcd9", fontSize: "12px", textDecoration: "underline" }}
                  onClick={onClickSetAsThumbnailHandler}
                >
                  Set as Group Thumbnail
                </div>
                // ) : (
                //   <div></div>
                // )
              ) : (
                <div></div>
              )}
              <div className="footerGrid">
                <p className="text-start text-break px-2 m-0 py-3">{desc}</p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-6 col-sm-6 col-12">
            <div className="LogoDescription p-3">
              <div className="logoImgDesc pb-2 row">
                <div className="col-12 d-flex justify-content-between">
                  <div>
                    <p className="subListTitle text-break packageTitle m-0"> {viewImgGroup}</p>
                  </div>
                  {userRole === "1" || userRole === "2" ? (
                    <div className="d-flex px-1 buttonList">
                      <Tooltip title="Edit Asset Details">
                        <div className="buttonGap me-0 my-auto" onClick={dispayEditAssetDetailsModal}>
                          <IconButton
                            className="iconsStyles borderBtn"
                            img={settings}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="EDIT"
                          />
                        </div>
                      </Tooltip>
                      <Tooltip title="Add">
                        <div className="buttonGap me-0 my-auto" onClick={() => displayUpload()}>
                          <IconButton
                            className="iconsStyles borderBtn border-end-0"
                            img={upload}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="ADD"
                          />
                        </div>
                      </Tooltip>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="py-3">
                <label className="sideListTitle">Select below for multiple options to download or share:</label>
                <Form.Select
                  className="memberInput"
                  name="selectedGroups"
                  value={selectedSize.sizeDimensions}
                  onChange={dimensionChange}
                >
                  <option value={0}>Select Group</option>
                  {sizeData.map((item, index) => {
                    return (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    );
                  })}
                </Form.Select>
                {!showPermissions && (
                  <p className="mt-1 mb-0" style={{ color: "red", fontSize: "12px", fontFamily: "roboto-bold" }}>
                    Not approved for external use!
                  </p>
                )}
              </div>

              {userRole === "1" || userRole === "2" ? (
                <div className="buttonList row m-0">
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "14.28%" }}>
                    {/* Download All Button */}
                    <Tooltip title="Download All">
                      <div className="buttonGap me-0 my-auto" onClick={downloadAllAssets}>
                        <IconButton
                          className="iconsStyles"
                          img={downloadAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="DOWNLOAD ALL"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  {/* Download Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "14.28%" }}>
                    <Tooltip title="Download">
                      <div className="buttonGap me-0 my-auto" onClick={downloadFile}>
                        <IconButton
                          className="iconsStyles"
                          img={downloadImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="DOWNLOAD"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  {/* Permissions All Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "14.28%" }}>
                    <Tooltip title="Permission All">
                      <div className="buttonGap me-0 my-auto" onClick={onPermissionAllClick}>
                        <IconButton
                          className="iconsStyles"
                          img={permissionAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="Permission All"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  {/* Permissions  Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "14.28%" }}>
                    <Tooltip title="Permission">
                      <div className="buttonGap me-0 my-auto" onClick={onPermissionClick}>
                        <IconButton
                          className="iconsStyles"
                          img={permission}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="Permission"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  {/* Share All Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "14.28%" }}>
                    {showPermissions ? (
                      <Tooltip title="Share All">
                        <div className="buttonGap me-0" onClick={onShareAllClick}>
                          <IconButton
                            className="iconsStyles"
                            img={shareAllImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE ALL"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Share All">
                        <div className="buttonGap me-0">
                          <IconButton
                            className="iconsStyles disable"
                            img={shareAllImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE ALL"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {/* Share Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "14.28%" }}>
                    {showPermissions ? (
                      <Tooltip title="Share">
                        <div className="buttonGap me-0" onClick={onShareClick}>
                          <IconButton
                            className="iconsStyles"
                            img={shareImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Share">
                        <div className="buttonGap me-0">
                          <IconButton
                            className="iconsStyles disable"
                            img={shareImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {/* Delete Button */}

                  <div className="my-auto d-flex justify-content-center" style={{ width: "14.28%" }}>
                    <Tooltip title="Delete">
                      <div className="buttonGap me-0" onClick={onDeleteClickHandler}>
                        <IconButton
                          className="iconsStyles"
                          img={deleteImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="SHARE"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              ) : (
                <div className="buttonList row m-0">
                  {/* Download All Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "25%" }}>
                    <Tooltip title="Download All">
                      <div className="buttonGap me-0 my-auto" onClick={downloadAllAssets}>
                        <IconButton
                          className="iconsStyles"
                          img={downloadAll}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="DOWNLOAD ALL"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  {/* Download Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "25%" }}>
                    <Tooltip title="Download">
                      <div className="buttonGap me-0 my-auto" onClick={downloadFile}>
                        <IconButton
                          className="iconsStyles"
                          img={downloadImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="DOWNLOAD"
                        />
                      </div>
                    </Tooltip>
                  </div>

                  {/* Share All Button */}
                  <div className="borderBtn d-flex justify-content-center" style={{ width: "25%" }}>
                    {showPermissions ? (
                      <Tooltip title="Share All">
                        <div className="buttonGap me-0" onClick={onShareAllClick}>
                          <IconButton
                            className="iconsStyles"
                            img={shareAllImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE ALL"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Share All">
                        <div className="buttonGap me-0">
                          <IconButton
                            className="iconsStyles disable"
                            img={shareAllImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE ALL"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>

                  {/* Share Button */}
                  <div className="my-auto d-flex justify-content-center" style={{ width: "25%" }}>
                    {showPermissions ? (
                      <Tooltip title="Share">
                        <div className="buttonGap me-0" onClick={onShareClick}>
                          <IconButton
                            className="iconsStyles"
                            img={shareImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE"
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Share">
                        <div className="buttonGap me-0">
                          <IconButton
                            className="iconsStyles disable"
                            img={shareImg}
                            imageClass="imageHeight"
                            buttonClass="download"
                            label="SHARE"
                          />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>{displayLoader ? <Loader /> : <div></div>}</div>
      </div>

      {showAssetDetailsModal ? (
        <EditAssetDetailsModal
          showAssetDetailsModal={showAssetDetailsModal}
          setShowAssetDetailsModal={setShowAssetDetailsModal}
        />
      ) : (
        ""
      )}

      <Suspense fallback={<Loader />}>
        {
          showLetterHeadUpload &&
          <LetterHeadUploadModal
            showLetterHeadUpload={showLetterHeadUpload}
            setShowLetterHeadUpload={setShowLetterHeadUpload}
            groupName={viewImgGroup}
          />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          shareAllClicked &&
          <ShareModal asset="null" assetId={viewImgIDs} groupName={viewImgGroup} assetType={dynamicName} />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          shareClicked &&
          <ShareModal
            asset={viewImgSrc.includes("pdf") ? pdf
              : (viewImgSrc.includes("doc") || viewImgSrc.includes("dotx") || viewImgSrc.includes("docx")) ? word
                : (viewImgSrc.includes("pptx") || viewImgSrc.includes("ppt") || viewImgSrc.includes("potx")) ? ppt
                  : (viewImgSrc.includes("xls") || viewImgSrc.includes("xlsx")) ? excel
                    : viewImgSrc}
            assetId={viewImgID}
            groupName={viewImgGroup}
            assetType={dynamicName}
          />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          permissionAllClicked &&
          <Permissions asset="null" assetId={viewImgIDs} groupName={viewImgGroup} assetType={dynamicName} />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          permissionClicked &&
          <Permissions
            asset={viewImgSrc.includes("pdf") ? pdf
              : (viewImgSrc.includes("doc") || viewImgSrc.includes("dotx") || viewImgSrc.includes("docx")) ? word
                : (viewImgSrc.includes("pptx") || viewImgSrc.includes("ppt") || viewImgSrc.includes("potx"))   ? ppt
                  : (viewImgSrc.includes("xls") || viewImgSrc.includes("xlsx")) ? excel
                    : viewImgSrc}
            assetId={viewImgID}
            groupName={viewImgGroup}
            assetType={dynamicName}
          />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          showDelete &&
          <ConfirmModal
            open={showDelete}
            onConfirm={deleteAssetId}
            onClose={closeDelete}
            titleText={"Are you sure you want to delete Asset?"}
          />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          showConfirmForThumbnail &&
          <ConfirmModal
            open={showConfirmForThumbnail}
            onConfirm={confirmSetGroupThumbnailHandler}
            onClose={closeThumbnailConfirmModal}
            titleText={"Are you sure you want to set as Group Thumbnail ?"}
          />
        }
      </Suspense>

      <Suspense fallback={<Loader />}>
        {
          showThumbnailDelete &&
          <ConfirmModal
            open={showThumbnailDelete}
            onClose={closeDeleteThumbnail}
            onConfirm={deleteThumbnail}
            titleText={"Are you sure want to Delete Thumbnail ?"}
          />
        }
      </Suspense>
    </>
  );
}

export default TemplateDetails;
