import MaterialTable, { MTableAction } from "@material-table/core";
import Switch from "@material-ui/core/Switch";
import { ChevronLeft, ChevronRight, FirstPage, LastPage } from "@material-ui/icons";
import Check from "@material-ui/icons/Check";
import Clear from "@material-ui/icons/Clear";
import React, { forwardRef, Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import assistsIcon from "../../../images/assistsicon.svg";
import deleteImg from "../../../images/delete.svg";
import { deleteTemplateAsset, getTemplatesList, updateTemplateAssets } from "../../../redux/Actions/action";
import { useCallbackPrompt } from "../../Hooks/useCallbackPrompt";
import { Loader } from "../../Shared/Loader";
import "./Templates.css";

/* Icons used in the material table */
const tableIcons = {
  Add: () => (
    <div className="addButtonField">
      <span className="plusField"> + </span>
      <span className="addText"> ADD TEMPLATE TYPE </span>
    </div>
  ),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: () => (
    <div>
      <img alt="" src={deleteImg} />
    </div>
  ),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
};

toast.configure({
  autoClose: 2000,
});

/**
 * Templates Functional Component
 * @return {*}
 */
const Templates = () => {
  const DialogBox = React.lazy(() => import("../../Hooks/DialogBox.component"));

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const addActionRef = React.useRef();

  // templates state
  const [templates, setTemplates] = useState([]);

  // pagination state
  const [paging, setPaging] = useState(true);

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(false);
  
  // state for showing dialog
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  
  let templateAssets = [];
  templateAssets = useSelector((state) => state.templateReducer.getTemplates);

  /* Preparing an array of existing templateAsset names */
  let uniqueArray = [];
  for (let index = 0; index < templateAssets.length; index++) {
    const element = templateAssets[index];
    uniqueArray.push((element?.name).toLowerCase());
  }

  // Switch props
  const TemplatesLabel = { inputProps: { "aria-label": "Switch demo" } };

  useEffect(() => {
    localStorage.setItem("switchUser", "false");
    localStorage.removeItem("orgType");
    localStorage.removeItem("brandAssetsOptionsName");
    localStorage.removeItem("templatesOptionsName");
    localStorage.removeItem("schoolMenuIndex");
    sessionStorage.removeItem("primaryValue");
    sessionStorage.removeItem("secondaryValue");
    sessionStorage.removeItem("gradientValue");
    localStorage.setItem("orgName", "Super Admin");
    
    if (localStorage.getItem("roleId") === "1") {
      dispatch(getTemplatesList(successHandler, errorHandler));
      if (templateAssets) {
        setTemplates(templateAssets);
        const val = { value: templateAssets.length > 10 ? true : false };
        setPaging(val.value);
      } else {
        setTemplates([]);
        setPaging(false);
      }
    } else {
      navigate("/");
      localStorage.setItem("schoolMenuIndex", 0);
    }
  }, [templateAssets.length]);

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call after successful save
   */
  const saveSuccessHandler = () => {
    setTimeout(() => {
      dispatch(getTemplatesList(successHandler, errorHandler));
    }, 500);
  };

  /**
   * This method will call if any error occurs  during execution
   */
  const saveErrorHandler = () => {
    setTimeout(() => {
      dispatch(getTemplatesList(successHandler, errorHandler));
    }, 500);
  };

  /**
   * On Eduction Only Menu Check Box Change Event
   * @param {*} event
   */
  const onEducationChange = (event) => {
    setShowDialog(true);
    let filteredItems = [];
    filteredItems = templates.map((item) => {
      if (item.name !== event.target.name) {
        return item;
      } else {
        return {
          id: item?.id,
          name: event.target.name,
          eduMenu: event.target.checked ? 1 : 0,
          orgMenu: item?.orgMenu ? item?.orgMenu : 0,
        };
      }
    });

    setTemplates(filteredItems);
  };

  /**
   * On Organization Menu Check Box change event
   * @param {*} event
   */
  const onOrganizationChange = (event) => {
    setShowDialog(true);
    let filteredItems = [];
    filteredItems = templates.map((item) => {
      if (item.name !== event.target.name) {
        return item;
      } else {
        return {
          id: item?.id,
          name: event.target.name,
          eduMenu: item?.eduMenu ? item?.eduMenu : 0,
          orgMenu: event.target.checked ? 1 : 0,
        };
      }
    });

    setTemplates(filteredItems);
  };

  /**
   * This method is used for Save Templates
   */
  const saveItems = () => {
    setShowDialog(false);
    setDisplayLoader(true);
    dispatch(updateTemplateAssets(templates, saveSuccessHandler, saveErrorHandler));
  };

  return (
    <div className="templatesTable mt-3">
       <Suspense fallback={<Loader />}>
        {showPrompt &&
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        }
      </Suspense>

      <div className="row mb-2">
        <div className="col-lg-9 d-flex">
          <h6 className="titLe py-2 m-0 my-auto">
            Menu Settings <span>- Templates</span>
          </h6>
        </div>

        <div className="col-lg-3 d-flex justify-content-end">
          <button className="applySettingsBtn" onClick={saveItems}>
            APPLY SETTINGS
          </button>
        </div>
      </div>

      <div>
        <MaterialTable
          title=""
          icons={tableIcons}
          columns={[
            {
              title: "Template Type",
              field: "name",
              cellStyle: {
                textTransform: "capitalize",
              },
              render: (customers) => (
                <div className="d-flex">
                  <div className="me-2">
                    <img className="img-fluid memBrIcon" alt="" src={assistsIcon} />
                  </div>
                  <div className="my-auto">{customers.name}</div>
                </div>
              ),
            },
            {
              title: "Education",
              field: "educationOnlyItemMenu",
              editable: "never",
              headerStyle: { textAlign: "center" },
              render: ({ name }) => (
                <div className="text-center">
                  <Switch
                    {...TemplatesLabel}
                    name={name}
                    checked={
                      templates.filter((item) => {
                        if (item.name === name) {
                          return item.eduMenu === 1 ? true : false;
                        }
                      }).length === 1
                        ? true
                        : false
                    }
                    onChange={onEducationChange}
                  />
                </div>
              ),
            },
            {
              title: "Corporate",
              field: "organizationMenu",
              editable: "never",
              headerStyle: { textAlign: "center" },
              render: ({ name }) => (
                <div className="text-center">
                  <Switch
                    {...TemplatesLabel}
                    name={name}
                    checked={
                      templates.filter((item) => {
                        if (item.name === name) {
                          return item.orgMenu === 1 ? true : false;
                        }
                      }).length === 1
                        ? true
                        : false
                    }
                    onChange={onOrganizationChange}
                  />
                </div>
              ),
            },
          ]}
          cellEditable={{
            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
              return new Promise((resolve, reject) => {
                let filteredItems = [];
                filteredItems = templates.map((item) => {
                  if (item.name === oldValue) {
                    if (newValue.trim().length === 0) {
                      return item;
                    } else if (uniqueArray.includes(newValue.trim().toLowerCase())) {
                      <div className="warningMsg">{toast.warning("Asset type already exists")}</div>;
                      return item;
                    } else {
                      setShowDialog(true);
                      return {
                        id: item?.id,
                        name: newValue,
                        eduMenu: item?.eduMenu,
                        orgMenu: item?.orgMenu,
                      };
                    }
                  } else {
                    return item;
                  }
                });
                setTemplates(filteredItems);
                setTimeout(resolve, 1000);
              });
            },
          }}
          options={{
            search: false,
            sorting: false,
            emptyRowsWhenPaging: false,
            paging: paging,
            pageSize: 10,
            draggable: false,
            actionsColumnIndex: -1,
            headerStyle: {
              color: "#15355C ",
              fontSize: "15px",
              fontWeight: "bold",
            },
            rowStyle: (data, index) => ({
              backgroundColor: index % 2 === 0 ? "#F2F4F6" : "white",
              color: "darkblue",
            }),
            cellStyle: {
              padding: "0px",
              paddingLeft: "15px",
            },
          }}
          components={{
            Container: (props) => (
              <div>
                <div {...props} />
              </div>
            ),
            Action: (props) => {
              if (typeof props.action === typeof Function || props.action.tooltip !== "Add") {
                return <MTableAction {...props} />;
              } else {
                return <div ref={addActionRef} onClick={props.action.onClick} />;
              }
            },
          }}
          data={templates}
          actions={[]}
          editable={{
            onRowAdd: (newData) =>
              new Promise((resolve, reject) => {
                if (newData.name.trim().length > 0) {
                  if (uniqueArray.includes(newData.name.trim().toLowerCase())) {
                    reject();
                    <div className="warningMsg">{toast.warning("Asset type already exists")}</div>;
                  } else {
                    setShowDialog(true);
                    setTemplates([...templates, { name: newData.name, eduMenu: 0, orgMenu: 0 }]);
                    resolve();
                  }
                } else {
                  setShowDialog(true);
                  <div className="warningmsg">{toast.warning("Please Provide Valid Education Menu Name")}</div>;
                  resolve();
                }
              }),
            onRowDelete: (oldData) =>
              new Promise((resolve, reject) => {
                dispatch(deleteTemplateAsset(oldData.id, saveSuccessHandler, errorHandler));
                resolve();
              }),
          }}
        />
      </div>
      <div className="ps-1 py-3">
        <button className="inviteBtn" onClick={() => addActionRef.current.click()}>
          ADD TEMPLATE TYPE
        </button>
      </div>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
};

export default Templates;
