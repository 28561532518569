import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import upload from "../../images/fnt_upload.svg";
import google from "../../images/google.svg";
import leading from "../../images/leading.svg";
import minTrack from "../../images/mintrack.svg";
import Settings from "../../images/settings.svg";
import add from "../../images/add.svg";
import { deleteFonts, getGoogleFonts, saveFonts } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import { useCallbackPrompt } from "../Hooks/useCallbackPrompt";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./brandAssets.css";

toast.configure({
  autoClose: 2000,
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "unset" : null,
    color: state.isSelected ? "#000" : null,
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: state.isFocused && "1px solid hsl(0, 0%, 80%)",
    "&:hover": {
      border: "1px solid hsl(0, 0%, 80%)",
    },
    minHeight: "33px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px 8px",
  }),
  placeholder: (provided) => ({
    ...provided,
    marginTop: "3px",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: "3px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

/**
 * Functional component for fonts
 * @return {*} 
 */
function Fonts() {
  const FontsConfigModal = React.lazy(() => import("../Shared/Modals/FontsConfigModal"));
  const FontsUploadModal = React.lazy(() => import("../Shared/Modals/FontsUploadModal"));
  const DialogBox = React.lazy(() => import("../Hooks/DialogBox.component.tsx"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state for showing config modal and dialog
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);
  const [showFontsConfig, setShowFontsConfig] = useState(false);
  const [showFonts, setShowFonts] = useState(false);

  // state for orgName and userRole
  const [orgName, setOrgName] = useState("");
  const [userRole, setUserRole] = useState("");

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  // state for fontSet
  const [fontsSet, setFontsSet] = useState(sessionStorage.getItem("fontsSet") || 0);

  // state for fontName of four sets
  const [fontName0, setFontName0] = useState("");
  const [fontName1, setFontName1] = useState("");
  const [fontName2, setFontName2] = useState("");
  const [fontName3, setFontName3] = useState("");

  // state for fontFiles of four sets
  const [fontFiles0, setFontFiles0] = useState([]);
  const [fontFiles1, setFontFiles1] = useState([]);
  const [fontFiles2, setFontFiles2] = useState([]);
  const [fontFiles3, setFontFiles3] = useState([]);

  // state for customFontFiles of four sets
  const [customFontFiles0, setCustomFontFiles0] = useState([]);
  const [customFontFiles1, setCustomFontFiles1] = useState([]);
  const [customFontFiles2, setCustomFontFiles2] = useState([]);
  const [customFontFiles3, setCustomFontFiles3] = useState([]);

  // state to show fontNameChildDropdown of four sets
  const [fontNameChildDropdown0, setFontNameChildDropdown0] = useState(false);
  const [fontNameChildDropdown1, setFontNameChildDropdown1] = useState(false);
  const [fontNameChildDropdown2, setFontNameChildDropdown2] = useState(false);
  const [fontNameChildDropdown3, setFontNameChildDropdown3] = useState(false);

  // state for description count of four sets
  const [count0, setCount0] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  // state for regularFontSize of four sets
  const [regularFontSize0, setRegularFontSize0] = useState(32);
  const [regularFontSize1, setRegularFontSize1] = useState(32);
  const [regularFontSize2, setRegularFontSize2] = useState(32);
  const [regularFontSize3, setRegularFontSize3] = useState(32);

  // state for five static dropdown
  const [selectedZeroOption, setSelectedZeroOption] = useState(null);
  const [selectedOneOption, setSelectedOneOption] = useState(null);
  const [selectedTwoOption, setSelectedTwoOption] = useState(null);
  const [selectedThreeOption, setSelectedThreeOption] = useState(null);
  const [selectedFourOption, setSelectedFourOption] = useState(null);

  // state for fontSets of Google Change resetPlaceholder
  const [resetPlaceHolder0, setResetPlaceHolder0] = useState(null);
  const [resetPlaceHolder1, setResetPlaceHolder1] = useState(null);
  const [resetPlaceHolder2, setResetPlaceHolder2] = useState(null);
  const [resetPlaceHolder3, setResetPlaceHolder3] = useState(null);

  // state for fontSet1 resetPlaceholder
  const [resetPlaceHolderDropdownTitle0, setResetPlaceHolderDropdownTitle0] = useState(null);
  const [resetPlaceHolderDropdown00, setResetPlaceHolderDropdown00] = useState(null);
  const [resetPlaceHolderDropdown01, setResetPlaceHolderDropdown01] = useState(null);
  const [resetPlaceHolderDropdown02, setResetPlaceHolderDropdown02] = useState(null);
  const [resetPlaceHolderDropdown03, setResetPlaceHolderDropdown03] = useState(null);
  const [resetPlaceHolderDropdown04, setResetPlaceHolderDropdown04] = useState(null);
  const [resetPlaceHolderDropdown05, setResetPlaceHolderDropdown05] = useState(null);
  const [resetPlaceHolderDropdown06, setResetPlaceHolderDropdown06] = useState(null);
  const [resetPlaceHolderDropdown07, setResetPlaceHolderDropdown07] = useState(null);

  // state for  fontSet2 resetPlaceholder
  const [resetPlaceHolderDropdownTitle1, setResetPlaceHolderDropdownTitle1] = useState(null);
  const [resetPlaceHolderDropdown10, setResetPlaceHolderDropdown10] = useState(null);
  const [resetPlaceHolderDropdown11, setResetPlaceHolderDropdown11] = useState(null);
  const [resetPlaceHolderDropdown12, setResetPlaceHolderDropdown12] = useState(null);
  const [resetPlaceHolderDropdown13, setResetPlaceHolderDropdown13] = useState(null);
  const [resetPlaceHolderDropdown14, setResetPlaceHolderDropdown14] = useState(null);
  const [resetPlaceHolderDropdown15, setResetPlaceHolderDropdown15] = useState(null);
  const [resetPlaceHolderDropdown16, setResetPlaceHolderDropdown16] = useState(null);
  const [resetPlaceHolderDropdown17, setResetPlaceHolderDropdown17] = useState(null);

  // state for  fontSet3 resetPlaceholder
  const [resetPlaceHolderDropdownTitle2, setResetPlaceHolderDropdownTitle2] = useState(null);
  const [resetPlaceHolderDropdown20, setResetPlaceHolderDropdown20] = useState(null);
  const [resetPlaceHolderDropdown21, setResetPlaceHolderDropdown21] = useState(null);
  const [resetPlaceHolderDropdown22, setResetPlaceHolderDropdown22] = useState(null);
  const [resetPlaceHolderDropdown23, setResetPlaceHolderDropdown23] = useState(null);
  const [resetPlaceHolderDropdown24, setResetPlaceHolderDropdown24] = useState(null);
  const [resetPlaceHolderDropdown25, setResetPlaceHolderDropdown25] = useState(null);
  const [resetPlaceHolderDropdown26, setResetPlaceHolderDropdown26] = useState(null);
  const [resetPlaceHolderDropdown27, setResetPlaceHolderDropdown27] = useState(null);

  // state for  fontSet4 resetPlaceholder
  const [resetPlaceHolderDropdownTitle3, setResetPlaceHolderDropdownTitle3] = useState(null);
  const [resetPlaceHolderDropdown30, setResetPlaceHolderDropdown30] = useState(null);
  const [resetPlaceHolderDropdown31, setResetPlaceHolderDropdown31] = useState(null);
  const [resetPlaceHolderDropdown32, setResetPlaceHolderDropdown32] = useState(null);
  const [resetPlaceHolderDropdown33, setResetPlaceHolderDropdown33] = useState(null);
  const [resetPlaceHolderDropdown34, setResetPlaceHolderDropdown34] = useState(null);
  const [resetPlaceHolderDropdown35, setResetPlaceHolderDropdown35] = useState(null);
  const [resetPlaceHolderDropdown36, setResetPlaceHolderDropdown36] = useState(null);
  const [resetPlaceHolderDropdown37, setResetPlaceHolderDropdown37] = useState(null);

  // Get Data from the Google WEB Fonts API
  const googleFontsData = useSelector((state) => state.userReducer.GroupFonts);

  // Used to reset the defined dropdown options data
  const [googleDropdownChanged, setGoogleDropdownChanged] = useState(0);

  // Based on the Google WEB Fonts API response Prepare the Dropdown
  const googleFontsOptionsZeroData = [];
  const googleFontsOptionsOneData = [];
  const googleFontsOptionsTwoData = [];
  const googleFontsOptionsThreeData = [];

  for (let i = 0; i < googleFontsData.length; i++) {
    if (
      googleFontsData[i].family != fontName1 &&
      googleFontsData[i].family != fontName2 &&
      googleFontsData[i].family != fontName3
    ) {
      googleFontsOptionsZeroData.push({
        value: i,
        label: googleFontsData[i].family,
        files: googleFontsData[i].files,
      });
    }
  }

  for (let i = 0; i < googleFontsData.length; i++) {
    if (
      googleFontsData[i].family != fontName0 &&
      googleFontsData[i].family != fontName2 &&
      googleFontsData[i].family != fontName3
    ) {
      googleFontsOptionsOneData.push({
        value: i,
        label: googleFontsData[i].family,
        files: googleFontsData[i].files,
      });
    }
  }

  for (let i = 0; i < googleFontsData.length; i++) {
    if (
      googleFontsData[i].family != fontName0 &&
      googleFontsData[i].family != fontName1 &&
      googleFontsData[i].family != fontName3
    ) {
      googleFontsOptionsTwoData.push({
        value: i,
        label: googleFontsData[i].family,
        files: googleFontsData[i].files,
      });
    }
  }

  for (let i = 0; i < googleFontsData.length; i++) {
    if (
      googleFontsData[i].family != fontName0 &&
      googleFontsData[i].family != fontName1 &&
      googleFontsData[i].family != fontName2
    ) {
      googleFontsOptionsThreeData.push({
        value: i,
        label: googleFontsData[i].family,
        files: googleFontsData[i].files,
      });
    }
  }

  // Based on User selected Google WEB Font Prepare the Dropdown for selected font families
  let googleFontFamilyOptionsData0 = [];
  var arrObj0 = Object.entries(fontFiles0); // Convert Object to Array
  if (customFontFiles0.length == 0) {
    for (const element of arrObj0) {
      googleFontFamilyOptionsData0.push({
        id: element[1].split(":")[1],
        label: fontName0 + " - " + element[0],
        fName: "",
      });
    }
  } else {
    // For User Uploaded Fonts - set 0
    for (const element of customFontFiles0) {
      googleFontFamilyOptionsData0.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  }

  const googleFontFamilyOptionsData1 = [];
  var arrObj1 = Object.entries(fontFiles1); // Convert Object to Array
  if (customFontFiles1.length == 0) {
    for (const element of arrObj1) {
      googleFontFamilyOptionsData1.push({
        id: element[1].split(":")[1],
        label: fontName1 + " - " + element[0],
        fName: "",
      });
    }
  } else {
    // For User Uploaded Fonts - set 1
    for (const element of customFontFiles1) {
      googleFontFamilyOptionsData1.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  }

  const googleFontFamilyOptionsData2 = [];
  var arrObj2 = Object.entries(fontFiles2); // Convert Object to Array
  if (customFontFiles2.length == 0) {
    for (const element of arrObj2) {
      googleFontFamilyOptionsData2.push({
        id: element[1].split(":")[1],
        label: fontName2 + " - " + element[0],
        fName: "",
      });
    }
  } else {
    // For User Uploaded Fonts - set 2
    for (const element of customFontFiles2) {
      googleFontFamilyOptionsData2.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  }

  const googleFontFamilyOptionsData3 = [];
  var arrObj3 = Object.entries(fontFiles3); // Convert Object to Array
  if (customFontFiles3.length == 0) {
    for (const element of arrObj3) {
      googleFontFamilyOptionsData3.push({
        id: element[1].split(":")[1],
        label: fontName3 + " - " + element[0],
        fName: "",
      });
    }
  } else {
    // For User Uploaded Fonts - set 3
    for (const element of customFontFiles3) {
      googleFontFamilyOptionsData3.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  }

  const googleFontFamilyOptionsFinalData = [];
  var arrObj4 = [
    ...googleFontFamilyOptionsData0,
    ...googleFontFamilyOptionsData1,
    ...googleFontFamilyOptionsData2,
    ...googleFontFamilyOptionsData3,
  ];
  // Convert Object to Array
  for (const element of arrObj4) {
    googleFontFamilyOptionsFinalData.push({
      id: element.id,
      label: element.label,
      fName: element.fName,
    });
  }

  // Set Dynamic HTML Content to the array based used selected configuration
  const fontsConfigSetsHTMLDivs = [];

  // Set Dynamic HTML Content to the array based used selected configuration
  const fontFamilySelectionHTMLDivsZero = [];
  const fontFamilySelectionHTMLDivsOne = [];
  const fontFamilySelectionHTMLDivsTwo = [];
  const fontFamilySelectionHTMLDivsThree = [];

  let finalAllSelectedDropdown = [];
  // for set1 Child Dropdown selected options
  if (resetPlaceHolderDropdown00 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown00);
  }
  if (resetPlaceHolderDropdown01 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown01);
  }
  if (resetPlaceHolderDropdown02 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown02);
  }
  if (resetPlaceHolderDropdown03 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown03);
  }
  if (resetPlaceHolderDropdown04 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown04);
  }
  if (resetPlaceHolderDropdown05 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown05);
  }
  if (resetPlaceHolderDropdown06 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown06);
  }
  if (resetPlaceHolderDropdown07 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown07);
  }

  // for set2 Child Dropdown selected options
  if (resetPlaceHolderDropdown10 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown10);
  }
  if (resetPlaceHolderDropdown11 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown11);
  }
  if (resetPlaceHolderDropdown12 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown12);
  }
  if (resetPlaceHolderDropdown13 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown13);
  }
  if (resetPlaceHolderDropdown14 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown14);
  }
  if (resetPlaceHolderDropdown15 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown15);
  }
  if (resetPlaceHolderDropdown16 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown16);
  }
  if (resetPlaceHolderDropdown17 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown17);
  }

  // for set3 Child Dropdown selected options
  if (resetPlaceHolderDropdown20 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown20);
  }
  if (resetPlaceHolderDropdown21 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown21);
  }
  if (resetPlaceHolderDropdown22 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown22);
  }
  if (resetPlaceHolderDropdown23 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown23);
  }
  if (resetPlaceHolderDropdown24 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown24);
  }
  if (resetPlaceHolderDropdown25 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown25);
  }
  if (resetPlaceHolderDropdown26 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown26);
  }
  if (resetPlaceHolderDropdown27 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown27);
  }

  // for set4 Child Dropdown selected options
  if (resetPlaceHolderDropdown30 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown30);
  }
  if (resetPlaceHolderDropdown31 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown31);
  }
  if (resetPlaceHolderDropdown32 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown32);
  }
  if (resetPlaceHolderDropdown33 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown33);
  }
  if (resetPlaceHolderDropdown34 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown34);
  }
  if (resetPlaceHolderDropdown35 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown35);
  }
  if (resetPlaceHolderDropdown36 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown36);
  }
  if (resetPlaceHolderDropdown37 !== null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown37);
  }

  // convert to id,label format
  const arrayOfSelectedDropdown = [];
  for (const element of finalAllSelectedDropdown) {
    arrayOfSelectedDropdown.push({
      id: element.value,
      label: element.label,
      fName: element.fName,
    });
  }

  // concating google fonts regular and child dropdown
  let finalUniqueSelectedDropdown = [...arrayOfSelectedDropdown];

  // UniqueFinal Dropdown Of selected Fonts
  const finalAllSelectedUniqueDropdown = Array.from(new Set(finalUniqueSelectedDropdown.map(JSON.stringify))).map(
    JSON.parse
  );

  useEffect(() => {
    // Get the Pre defined Google Fonts
    dispatch(getGoogleFonts("AIzaSyB_9EPsTlSidU8qmhtNszALThM9VkEDbrw", successHandler, errorHandler));

    if (sessionStorage.getItem("fontsSet")) {
      setFontsSet(sessionStorage.getItem("fontsSet"));
      setOrgName(localStorage.getItem("orgName"));
      setUserRole(localStorage.getItem("roleId"));
    } else {
      navigate("/brandassets/fontsView");
    }

    return () => {
      sessionStorage.removeItem("fontsSet");
      setTimeout(() => {
        dispatch(deleteFonts(successHandler, errorHandler));
      }, 2000);
    };
  }, []);

  /**
   * Service Error Handler
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * Service Error Handler
   */
  const errorHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method is used to reset the Static DropdownData
   *
   */
  const resetStaticDropdownData = () => {
    setSelectedZeroOption(null);
    setSelectedOneOption(null);
    setSelectedTwoOption(null);
    setSelectedThreeOption(null);
    setSelectedFourOption(null);

    if (document.getElementById("title")) {
      document.getElementById("title").style.fontFamily = "unset";
    }

    if (document.getElementById("headTitle")) {
      document.getElementById("headTitle").style.fontFamily = "unset";
    }

    if (document.getElementById("subHeading1")) {
      document.getElementById("subHeading1").style.fontFamily = "unset";
    }

    if (document.getElementById("subHeading2")) {
      document.getElementById("subHeading2").style.fontFamily = "unset";
    }

    if (document.getElementById("caption")) {
      document.getElementById("caption").style.fontFamily = "unset";
    }
  };

  /**
   * This method is used to reset the Dynamic set1 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown0 = () => {
    setResetPlaceHolderDropdownTitle0(null);
    setResetPlaceHolderDropdown00(null);
    setResetPlaceHolderDropdown01(null);
    setResetPlaceHolderDropdown02(null);
    setResetPlaceHolderDropdown03(null);
    setResetPlaceHolderDropdown04(null);
    setResetPlaceHolderDropdown05(null);
    setResetPlaceHolderDropdown06(null);
    setResetPlaceHolderDropdown07(null);
  };

  /**
   * This method is used to reset the Dynamic set2 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown1 = () => {
    setResetPlaceHolderDropdownTitle1(null);
    setResetPlaceHolderDropdown10(null);
    setResetPlaceHolderDropdown11(null);
    setResetPlaceHolderDropdown12(null);
    setResetPlaceHolderDropdown13(null);
    setResetPlaceHolderDropdown14(null);
    setResetPlaceHolderDropdown15(null);
    setResetPlaceHolderDropdown16(null);
    setResetPlaceHolderDropdown17(null);
  };

  /**
   * This method is used to reset the Dynamic set3 Child DropDown PlaceHolder
   *
   */
  const resetDynamicChildDropdown2 = () => {
    setResetPlaceHolderDropdownTitle2(null);
    setResetPlaceHolderDropdown20(null);
    setResetPlaceHolderDropdown21(null);
    setResetPlaceHolderDropdown22(null);
    setResetPlaceHolderDropdown23(null);
    setResetPlaceHolderDropdown24(null);
    setResetPlaceHolderDropdown25(null);
    setResetPlaceHolderDropdown26(null);
    setResetPlaceHolderDropdown27(null);
  };

  /**
   * This method is used to reset the Dynamic set4 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown3 = () => {
    setResetPlaceHolderDropdownTitle3(null);
    setResetPlaceHolderDropdown30(null);
    setResetPlaceHolderDropdown31(null);
    setResetPlaceHolderDropdown32(null);
    setResetPlaceHolderDropdown33(null);
    setResetPlaceHolderDropdown34(null);
    setResetPlaceHolderDropdown35(null);
    setResetPlaceHolderDropdown36(null);
    setResetPlaceHolderDropdown37(null);
  };

  /**
   * Used in Google Font Change
   * @param {*} e
   * @param {*} index
   */
  const onFontChange = (e, index) => {
    setShowDialog(true);
    resetStaticDropdownData();
    var totalFontObj = Object.entries(e.files); // Convert Object to Array
    let fonts = [];
    for (const element of totalFontObj) {
      fonts.push({
        id: element[1],
        label: fontName3 + " - " + element[0],
      });
    }
    for (let i = 1; i < fonts.length + 1; i++) {
      if (document.getElementById(`fName${index}${i}`)) {
        document.getElementById(`fName${index}${i}`).style.fontFamily = "unset";
      }
    }

    setGoogleDropdownChanged(1);
    setDisplayLoader(true);
    let filteredUrl = "";
    let filterArray = [];

    if (document.getElementById("fGDropdown" + index)) {
      var doc = document.getElementById("fGDropdown" + index);
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
      document.getElementById("fGDropdown" + index).style.borderRadius = "4px";
    }

    if (document.getElementById("fDesc" + index)) {
      document.getElementById("fDesc" + index).value = "";
    }

    if (index == 0) {
      setResetPlaceHolder0({
        label: e.label,
        value: e.files,
      });
      setFontName0(e.label);
      setFontFiles0(e.files);
      setCustomFontFiles0([]);
      setFontNameChildDropdown0(true);
      setCount0(0);
      resetDynamicChildDropdown0();
      setResetPlaceHolderDropdownTitle0({
        label: e.label + " - " + "regular",
        value: e.files,
      });
    } else if (index == 1) {
      setResetPlaceHolder1({
        label: e.label,
        value: e.files,
      });
      setFontName1(e.label);
      setFontFiles1(e.files);
      setCustomFontFiles1([]);
      setFontNameChildDropdown1(true);
      setCount1(0);
      resetDynamicChildDropdown1();
      setResetPlaceHolderDropdownTitle1({
        label: e.label + " - " + "regular",
        value: e.files,
      });
    } else if (index == 2) {
      setResetPlaceHolder2({
        label: e.label,
        value: e.files,
      });
      setFontName2(e.label);
      setFontFiles2(e.files);
      setCustomFontFiles2([]);
      setFontNameChildDropdown2(true);
      setCount2(0);
      resetDynamicChildDropdown2();
      setResetPlaceHolderDropdownTitle2({
        label: e.label + " - " + "regular",
        value: e.files,
      });
    } else if (index == 3) {
      setResetPlaceHolder3({
        label: e.label,
        value: e.files,
      });
      setFontName3(e.label);
      setFontFiles3(e.files);
      setCustomFontFiles3([]);
      setFontNameChildDropdown3(true);
      setCount3(0);
      resetDynamicChildDropdown3();
      setResetPlaceHolderDropdownTitle3({
        label: e.label + " - " + "regular",
        value: e.files,
      });
    }

    var arrayData = Object.entries(e.files);
    for (const element of arrayData) {
      filterArray.push({
        id: element[1].split(":")[1],
        label: element[0],
      });
    }
    filteredUrl = filterArray.filter((x) => x.label == "regular");

    var fontsUrl = filteredUrl[0].id;
    var upFontFace = new FontFace(`upFont${index}${0}`, `url(${fontsUrl})`);
    document.fonts.add(upFontFace);
    upFontFace.load();

    setTimeout(() => {
      if (document.getElementById("fName" + index + 0)) {
        document.getElementById("fName" + index + 0).textContent = e.label;
        document.getElementById("fName" + index + 0).style.fontFamily = `upFont${index}${0}`;
      }
      if (document.getElementById("fUrl" + index + 0)) {
        document.getElementById("fUrl" + index + 0).textContent = filteredUrl[0].id;
      }
      setDisplayLoader(false);
    }, 1000);
  };

  /**
   * Used in Google Font Family Weights Change
   * @param {*} event
   * @param {*} index
   * @param {*} selectedControlId
   */
  const onFontFamilyChange = (event, index, selectedControlId) => {
    // on change to set the font family in the hidden field
    document.getElementById("ffamily" + selectedControlId + index).textContent = event.fName;
    if (selectedControlId == 0) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle0({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let changeTitle = event.label;
        if (resetPlaceHolderDropdownTitle0 != null) {
          if (changeTitle != resetPlaceHolderDropdownTitle0.label) {
            resetStaticDropdownData();
          }
        }
      }
      if (index == 1) {
        setResetPlaceHolderDropdown00({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change00 = event.label;
        if (resetPlaceHolderDropdown00 != null) {
          if (change00 != resetPlaceHolderDropdown00.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 2) {
        setResetPlaceHolderDropdown01({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change01 = event.label;
        if (resetPlaceHolderDropdown01 != null) {
          if (change01 != resetPlaceHolderDropdown01.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 3) {
        setResetPlaceHolderDropdown02({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change02 = event.label;
        if (resetPlaceHolderDropdown02 != null) {
          if (change02 != resetPlaceHolderDropdown02.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 4) {
        setResetPlaceHolderDropdown03({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change03 = event.label;
        if (resetPlaceHolderDropdown03 != null) {
          if (change03 != resetPlaceHolderDropdown03.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 5) {
        setResetPlaceHolderDropdown04({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change04 = event.label;
        if (resetPlaceHolderDropdown04 != null) {
          if (change04 != resetPlaceHolderDropdown04.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 6) {
        setResetPlaceHolderDropdown05({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change05 = event.label;
        if (resetPlaceHolderDropdown05 != null) {
          if (change05 != resetPlaceHolderDropdown05.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 7) {
        setResetPlaceHolderDropdown06({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change06 = event.label;
        if (resetPlaceHolderDropdown06 != null) {
          if (change06 != resetPlaceHolderDropdown06.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 8) {
        setResetPlaceHolderDropdown07({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change07 = event.label;
        if (resetPlaceHolderDropdown07 != null) {
          if (change07 != resetPlaceHolderDropdown07.label) {
            resetStaticDropdownData();
          }
        }
      }
    } else if (selectedControlId == 1) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle1({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let changeTitle2 = event.label;
        if (resetPlaceHolderDropdownTitle1 != null) {
          if (changeTitle2 != resetPlaceHolderDropdownTitle1.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 1) {
        setResetPlaceHolderDropdown10({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change10 = event.label;
        if (resetPlaceHolderDropdown10 != null) {
          if (change10 != resetPlaceHolderDropdown10.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 2) {
        setResetPlaceHolderDropdown11({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change11 = event.label;
        if (resetPlaceHolderDropdown11 != null) {
          if (change11 != resetPlaceHolderDropdown11.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 3) {
        setResetPlaceHolderDropdown12({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change12 = event.label;
        if (resetPlaceHolderDropdown12 != null) {
          if (change12 != resetPlaceHolderDropdown12.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 4) {
        setResetPlaceHolderDropdown13({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change13 = event.label;
        if (resetPlaceHolderDropdown13 != null) {
          if (change13 != resetPlaceHolderDropdown13.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 5) {
        setResetPlaceHolderDropdown14({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change14 = event.label;
        if (resetPlaceHolderDropdown14 != null) {
          if (change14 != resetPlaceHolderDropdown14.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 6) {
        setResetPlaceHolderDropdown15({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change15 = event.label;
        if (resetPlaceHolderDropdown15 != null) {
          if (change15 != resetPlaceHolderDropdown15.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 7) {
        setResetPlaceHolderDropdown16({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change16 = event.label;
        if (resetPlaceHolderDropdown16 != null) {
          if (change16 != resetPlaceHolderDropdown16.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 8) {
        setResetPlaceHolderDropdown17({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change17 = event.label;
        if (resetPlaceHolderDropdown17 != null) {
          if (change17 != resetPlaceHolderDropdown17.label) {
            resetStaticDropdownData();
          }
        }
      }
    } else if (selectedControlId == 2) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle2({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 1) {
        setResetPlaceHolderDropdown20({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change20 = event.label;
        if (resetPlaceHolderDropdown20 != null) {
          if (change20 != resetPlaceHolderDropdown20.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 2) {
        setResetPlaceHolderDropdown21({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change21 = event.label;
        if (resetPlaceHolderDropdown21 != null) {
          if (change21 != resetPlaceHolderDropdown21.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 3) {
        setResetPlaceHolderDropdown22({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change22 = event.label;
        if (resetPlaceHolderDropdown22 != null) {
          if (change22 != resetPlaceHolderDropdown22.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 4) {
        setResetPlaceHolderDropdown23({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change23 = event.label;
        if (resetPlaceHolderDropdown23 != null) {
          if (change23 != resetPlaceHolderDropdown23.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 5) {
        setResetPlaceHolderDropdown24({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change24 = event.label;
        if (resetPlaceHolderDropdown24 != null) {
          if (change24 != resetPlaceHolderDropdown24.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 6) {
        setResetPlaceHolderDropdown25({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change25 = event.label;
        if (resetPlaceHolderDropdown25 != null) {
          if (change25 != resetPlaceHolderDropdown25.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 7) {
        setResetPlaceHolderDropdown26({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change26 = event.label;
        if (resetPlaceHolderDropdown26 != null) {
          if (change26 != resetPlaceHolderDropdown26.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 8) {
        setResetPlaceHolderDropdown27({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change27 = event.label;
        if (resetPlaceHolderDropdown27 != null) {
          if (change27 != resetPlaceHolderDropdown27.label) {
            resetStaticDropdownData();
          }
        }
      }
    } else if (selectedControlId == 3) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle3({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 1) {
        setResetPlaceHolderDropdown30({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change30 = event.label;
        if (resetPlaceHolderDropdown30 != null) {
          if (change30 != resetPlaceHolderDropdown30.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 2) {
        setResetPlaceHolderDropdown31({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change31 = event.label;
        if (resetPlaceHolderDropdown31 != null) {
          if (change31 != resetPlaceHolderDropdown31.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 3) {
        setResetPlaceHolderDropdown32({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change32 = event.label;
        if (resetPlaceHolderDropdown32 != null) {
          if (change32 != resetPlaceHolderDropdown32.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 4) {
        setResetPlaceHolderDropdown33({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change33 = event.label;
        if (resetPlaceHolderDropdown33 != null) {
          if (change33 != resetPlaceHolderDropdown33.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 5) {
        setResetPlaceHolderDropdown34({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change34 = event.label;
        if (resetPlaceHolderDropdown34 != null) {
          if (change34 != resetPlaceHolderDropdown34.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 6) {
        setResetPlaceHolderDropdown35({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change35 = event.label;
        if (resetPlaceHolderDropdown35 != null) {
          if (change35 != resetPlaceHolderDropdown35.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 7) {
        setResetPlaceHolderDropdown36({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change36 = event.label;
        if (resetPlaceHolderDropdown36 != null) {
          if (change36 != resetPlaceHolderDropdown36.label) {
            resetStaticDropdownData();
          }
        }
      } else if (index == 8) {
        setResetPlaceHolderDropdown37({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
        let change37 = event.label;
        if (resetPlaceHolderDropdown37 != null) {
          if (change37 != resetPlaceHolderDropdown37.label) {
            resetStaticDropdownData();
          }
        }
      }
    }

    var fontsUrl = event.id;
    var upFontFace = new FontFace(`upFont${selectedControlId}${index}`, `url(${fontsUrl})`);
    document.fonts.add(upFontFace);
    upFontFace.load();

    if (index > 0) {
      if (document.getElementById("fName" + selectedControlId + index)) {
        document.getElementById("fName" + selectedControlId + index).textContent = event.label;
        document.getElementById(
          "fName" + selectedControlId + index
        ).style.fontFamily = `upFont${selectedControlId}${index}`;

        var doc = document.getElementById("fDropdown" + selectedControlId + index);
        doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
      }
    }

    if (document.getElementById("fUrl" + selectedControlId + index)) {
      document.getElementById("fUrl" + selectedControlId + index).textContent = event.id;
    }
  };

  /**
   * Used in Font Family Title Change
   * @param {*} event
   */
  const onFontFamilyTitleChange = (event) => {
    setSelectedZeroOption({
      label: event.label,
      value: event.id,
      fName: event.fName,
    });

    if (document.getElementById("title")) {
      if (document.getElementById("cUrl0")) {
        document.getElementById("cUrl0").textContent = event.id;
      }
      if (document.getElementById("cFname0")) {
        document.getElementById("cFname0").textContent = event.fName;
      }

      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontTitle`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("title").style.fontFamily = `upFontTitle`;
      var doc = document.getElementById("definedDropdown0");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family HeadLine Change
   * @param {*} event
   */
  const onFontFamilyHeadLineChange = (event) => {
    setSelectedOneOption({
      label: event.label,
      value: event.id,
      fName: event.fName,
    });
    if (document.getElementById("headTitle")) {
      if (document.getElementById("cUrl1")) {
        document.getElementById("cUrl1").textContent = event.id;
      }
      if (document.getElementById("cFname1")) {
        document.getElementById("cFname1").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontHeadTitle`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("headTitle").style.fontFamily = `upFontHeadTitle`;
      var doc = document.getElementById("definedDropdown1");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family Sub Heading1 Change
   * @param {*} event
   */
  const onFontFamilySubHeadingChangeH3 = (event) => {
    setSelectedTwoOption({
      label: event.label,
      value: event.id,
      fName: event.fName,
    });
    if (document.getElementById("subHeading1")) {
      if (document.getElementById("cUrl2")) {
        document.getElementById("cUrl2").textContent = event.id;
      }
      if (document.getElementById("cFname2")) {
        document.getElementById("cFname2").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontSubHeading1`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("subHeading1").style.fontFamily = `upFontSubHeading1`;
      var doc = document.getElementById("definedDropdown2");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family Sub Heading2 Change
   * @param {*} event
   */
  const onFontFamilySubHeadingChangeH4 = (event) => {
    setSelectedThreeOption({
      label: event.label,
      value: event.id,
      fName: event.fName,
    });
    if (document.getElementById("subHeading2")) {
      if (document.getElementById("cUrl3")) {
        document.getElementById("cUrl3").textContent = event.id;
      }
      if (document.getElementById("cFname3")) {
        document.getElementById("cFname3").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontSubHeading2`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("subHeading2").style.fontFamily = `upFontSubHeading2`;
      var doc = document.getElementById("definedDropdown3");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family Caption Change
   * @param {*} event
   */
  const onFontFamilyCaptionChange = (event) => {
    setSelectedFourOption({
      label: event.label,
      value: event.id,
      fName: event.fName,
    });
    if (document.getElementById("caption")) {
      if (document.getElementById("cUrl4")) {
        document.getElementById("cUrl4").textContent = event.id;
      }
      if (document.getElementById("cFname4")) {
        document.getElementById("cFname4").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`caption`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("caption").style.fontFamily = `caption`;
      var doc = document.getElementById("definedDropdown4");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Update Fonts Configuration Settings
   */
  const updateFontsConfig = () => {
    setShowFontsConfig(true);
  };

  /**
   * Update Fonts Sets by selection the font files
   */
  const showFontsModal = (event) => {
    event.preventDefault();
    sessionStorage.setItem("fontsSetId", event.target.id.split("-")[1]);
    setShowFonts(true);
  };

  /**
   * Used to update the font size for the regular font family
   * @param {*} e
   * @param {*} index
   * @param {*} selectedControlId
   */
  const onBlurFontSizeForRegular = (e, index, selectedControlId) => {
    if (e.target.value.trim() != "") {
      if (e.target.value < 8) {
        document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #ff8181";
        return <div className="warningMsg">{toast.warning("Font Size must be greater than 8pt")}</div>;
      } else {
        document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #e5e4e4";
        document.getElementById("fName" + selectedControlId + index).style.fontSize = e.target.value + "pt";
      }
    } else {
      document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #e5e4e4";
      document.getElementById("fSize" + selectedControlId + index).value = "";
      document.getElementById("fSize" + selectedControlId + index).setAttribute("placeholder", "32");
    }
  };

  /**
   * Used to update the font family for the Description font family
   * @param {*} index
   */
  const onBlurFontFamilyForDescription = (index) => {
    if (document.getElementById("fDesc" + index)) {
      if (document.getElementById("fDesc" + index).value.trim().length >= 50) {
        document.getElementById("fDesc" + index).style.border = "1px solid #e5e4e4";
        document.getElementById("errDesc" + index).textContent = "";
      } else if (document.getElementById("fDesc" + index).value.trim().length == 0) {
        document.getElementById("fDesc" + index).value = "";
      } else {
        document.getElementById("fDesc" + index).style.border = "1px solid #ff8181";
        document.getElementById("errDesc" + index).textContent =
          "Description Length must be between 50 to 500 characters";
        document.getElementById("errDesc" + index).style.color = "#ff8181";
        document.getElementById("errDesc" + index).style.fontSize = "11px";
      }
    }
  };

  /**
   * Used to update the font family for the Description font family
   * @param {*} e
   * @param {*} index
   */
  const onChangeFontFamilyForDescription = (e, index) => {
    if (index == 0) {
      setCount0(e.target.value.length);
    } else if (index == 1) {
      setCount1(e.target.value.length);
    } else if (index == 2) {
      setCount2(e.target.value.length);
    } else if (index == 3) {
      setCount3(e.target.value.length);
    }
    if (document.getElementById("fDesc" + index)) {
      if (document.getElementById("fDesc" + index).value.trim().length >= 50) {
        document.getElementById("fDesc" + index).style.border = "1px solid #e5e4e4";
        document.getElementById("errDesc" + index).textContent = "";
      } else {
        document.getElementById("fDesc" + index).style.border = "1px solid #ff8181";
        document.getElementById("errDesc" + index).textContent =
          "Description Length must be between 50 to 500 characters";
        document.getElementById("errDesc" + index).style.color = "#ff8181";
        document.getElementById("errDesc" + index).style.fontSize = "11px";
      }
    }
  };

  /**
   * Used to update the font size for the other than regular font family
   * @param {*} e
   * @param {*} length
   * @param {*} selectedControlId
   * @return {*} 
   */
  const onBlurFontSizeForOtherThanRegular = (e, selectedControlId, indexValue) => {
    if (e.target.value.trim() != "") {
      if (e.target.value < 8) {
        document.getElementById("fSize" + selectedControlId + indexValue).style.border = "1px solid #ff8181";
        return <div className="warningMsg">{toast.warning("Font Size must be greater than 8pt")}</div>;
      } else {
        document.getElementById("fSize" + selectedControlId + indexValue).style.border = "1px solid #e5e4e4";
        document.getElementById("fName" + selectedControlId + indexValue).style.fontSize = e.target.value + "pt";
      }
    } else {
      document.getElementById("fSize" + selectedControlId + indexValue).style.border = "1px solid #e5e4e4";
      document.getElementById("fSize" + selectedControlId + indexValue).value = "";
      document.getElementById("fSize" + selectedControlId + indexValue).setAttribute("placeholder", "26");
    }
  };

  /**
   * Used to update the Font Size for the pre defined tags as Title, Heading, Sub Heading and Captions
   * @param {*} e
   * @param {*} id
   * @param {*} index
   * @return {*} 
   */
  const onBlurFontSize = (e, id, index) => {
    if (e.target.value != "") {
      if (e.target.value < 8) {
        document.getElementById(e.target.id).style.border = "1px solid #ff8181";
        return <div className="warningMsg">{toast.warning("Font Size must be greater than 8pt")}</div>;
      } else if (e.target.value >= 8) {
        document.getElementById(e.target.id).style.border = "1px solid #cccccc";
        if (document.getElementById(id)) document.getElementById(id).style.fontSize = e.target.value + "pt";
        if (document.getElementById("lead" + index))
          document.getElementById("lead" + index).value = Number(e.target.value) + 1;
      }
    }
  };

  /**
   * Used to update the Line Height for the pre defined tags as Title, Heading, Sub Heading and Captions
   * @param {*} e
   * @param {*} id
   * @param {*} index
   * @return {*} 
   */
  const onBlurLeading = (e, id, index) => {
    if (document.getElementById("definedSize" + index)) {
      if (document.getElementById("definedSize" + index).value) {
        let fontSize = document.getElementById("definedSize" + index).value;
        if (e.target.value != "") {
          if (Number(fontSize) >= Number(e.target.value)) {
            document.getElementById(e.target.id).style.border = "1px solid #ff8181";
            return <div className="warningMsg">{toast.warning("Leading Value Must be greater than Size Value")}</div>;
          } else {
            document.getElementById(id).style.lineHeight = e.target.value + "pt";
            document.getElementById(e.target.id).style.border = "1px solid #cccccc";
            if (document.getElementById("definedSize" + index).value > 8) {
              document.getElementById("definedSize" + index).style.border = "1px solid #cccccc";
            }
            document.getElementById(id).style.fontSize = document.getElementById("definedSize" + index).value + "pt";
          }
        }
      } else {
        return <div className="warningMsg">{toast.warning("Font Size can not be empty")}</div>;
      }
    }
  };

  /**
   * Used to update the Letter Spacing for the pre defined tags as Title, Heading, Sub Heading and Captions
   * @param {*} e
   * @param {*} id
   */
  const onBlurMinTrack = (e, id) => {
    if (document.getElementById(id)) {
      if (document.getElementById(e.target.id).value != "") {
        if (document.getElementById(e.target.id).value >= 0) {
          document.getElementById(id).style.letterSpacing = e.target.value + "pt";
          document.getElementById(e.target.id).style.border = "1px solid #cccccc";
        } else {
          document.getElementById(id).style.letterSpacing = "0px";
          document.getElementById(e.target.id).style.border = "1px solid #ff8181";
        }
      } else {
        document.getElementById(id).style.letterSpacing = "0px";
      }
    }
  };

  /**
   * Used to validate the input fields
   * @return {*}
   */
  const validateControls = () => {
    // Loop based on Fonts Configuration Count
    for (let configs = 0; configs < fontsSet; configs++) {
      // After that Loop based on inner Font Weights Count
      var weightsLength = 0;
      if (configs == 0) {
        weightsLength = googleFontFamilyOptionsData0.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData0.length;
        }
      } else if (configs == 1) {
        weightsLength = googleFontFamilyOptionsData1.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData1.length;
        }
      } else if (configs == 2) {
        weightsLength = googleFontFamilyOptionsData2.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData2.length;
        }
      } else if (configs == 3) {
        weightsLength = googleFontFamilyOptionsData3.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData3.length;
        }
      }

      // These are the above dynamic fonts
      for (let weights = 0; weights < weightsLength; weights++) {
        if (document.getElementById("fDesc" + configs).value.length == 0) {
          document.getElementById("fDesc" + configs).style.border = "1px solid #ff8181";
        }
      }

      for (let index = 0; index < fontsSet; index++) {
        if (!document.getElementById("cUrl" + index).textContent.includes("grafixark-assets")) {
          if (document.getElementById("fName0" + index)) {
            if (document.getElementById("fName0" + index).textContent.length == 0) {
              // var doc = document.getElementById("fGDropdown" + index);
              // doc.getElementsByTagName("div")[0].style.borderColor = "#ff8181";
              // document.getElementById("fGDropdown" + index).style.borderRadius =
              //   "4px";
            }
          }
        }
      }

      // These are the below static controls
      for (let index = 0; index < 5; index++) {
        if (document.getElementById("definedDropdown" + index)) {
          if (document.getElementById("definedDropdown" + index).textContent == "Please Select Font") {
            var doc = document.getElementById("definedDropdown" + index);
            doc.getElementsByTagName("div")[0].style.borderColor = "#ff8181";
            document.getElementById("definedDropdown" + index).style.borderRadius = "4px";
          }
        }

        if (document.getElementById("definedSize" + index).value) {
          if (
            Number(document.getElementById("definedSize" + index).value) >=
            Number(document.getElementById("lead" + index).value)
          ) {
            document.getElementById("lead" + index).style.border = "1px solid #ff8181";
          } else {
            document.getElementById("lead" + index).style.border = "1px solid #e5e4e4";
          }
        } else {
          if (document.getElementById("lead" + index).value) {
            document.getElementById("definedSize" + index).style.border = "1px solid #ff8181";
          }
        }
      }
    }
  };

  /**
   * Used to validate the input fields
   * @return {*}
   */
  const validate = () => {
    // Loop based on Fonts Configuration Count
    for (let configs = 0; configs < fontsSet; configs++) {
      // After that Loop based on inner Font Weights Count
      var weightsLength = 0;
      if (configs == 0) {
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData0.length;
        }
      } else if (configs == 1) {
        weightsLength = googleFontFamilyOptionsData1.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData1.length;
        }
      } else if (configs == 2) {
        weightsLength = googleFontFamilyOptionsData2.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData2.length;
        }
      } else if (configs == 3) {
        weightsLength = googleFontFamilyOptionsData3.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData3.length;
        }
      }

      // These are the above dynamic fonts
      for (let weights = 0; weights < weightsLength; weights++) {
        if (document.getElementById("fDesc" + configs).value.length < 50) {
          return "true";
        }
      }

      for (let weights = 0; weights < weightsLength; weights++) {
        if (document.getElementById("fSize" + configs + weights)) {
          if (document.getElementById("fSize" + configs + weights).value != "") {
            if (document.getElementById("fSize" + configs + weights).value < 8) {
              return "true";
            }
          }
        }
      }

      // These are the below static controls
      for (let index = 0; index < 5; index++) {
        if (document.getElementById("definedDropdown" + index)) {
          if (document.getElementById("definedDropdown" + index).textContent == "Please Select Font") {
            return "true";
          }
        }

        if (document.getElementById("definedSize" + index).value) {
          if (
            Number(document.getElementById("definedSize" + index).value) >=
            Number(document.getElementById("lead" + index).value)
          ) {
            return "true";
          }
        } else {
          if (document.getElementById("lead" + index).value) {
            return "true";
          }
        }

        if (document.getElementById("definedSize" + index).value) {
          if (Number(document.getElementById("definedSize" + index).value) < 8) {
            return "true";
          }
        }
      }
    }
  };

  /**
   * Used to add the fonts
   */
  const addFonts = () => {
    // After that Loop based on inner Font Weights Count
    for (let configs = 0; configs < fontsSet; configs++) {
      if (configs == 0) {
        if (googleFontFamilyOptionsData0.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set one")}</div>;
        }
      } else if (configs == 1) {
        if (googleFontFamilyOptionsData1.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set two")}</div>;
        }
      } else if (configs == 2) {
        if (googleFontFamilyOptionsData2.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set three")}</div>;
        }
      } else if (configs == 3) {
        if (googleFontFamilyOptionsData3.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set four")}</div>;
        }
      }
    }

    validateControls();

    if (validate() == "true") {
      return <div className="warningMsg">{toast.warning("Please complete the required fields")}</div>;
    } else {
      setShowDialog(false);
      let finalObj = {
        fonts: [],
      };
      let dynamicObj;
      let definedObj;

      // Loop based on Fonts Configuration Count
      for (let configs = 0; configs < fontsSet; configs++) {
        // After that Loop based on inner Font Weights Count
        var weightsLength = 0;
        if (configs == 0) {
          weightsLength = googleFontFamilyOptionsData0.length;
          if (weightsLength > 8) {
            weightsLength = 8;
          } else {
            weightsLength = googleFontFamilyOptionsData0.length;
          }
        } else if (configs == 1) {
          weightsLength = googleFontFamilyOptionsData1.length;
          if (weightsLength > 8) {
            weightsLength = 8;
          } else {
            weightsLength = googleFontFamilyOptionsData1.length;
          }
        } else if (configs == 2) {
          weightsLength = googleFontFamilyOptionsData2.length;
          if (weightsLength > 8) {
            weightsLength = 8;
          } else {
            weightsLength = googleFontFamilyOptionsData2.length;
          }
        } else if (configs == 3) {
          weightsLength = googleFontFamilyOptionsData3.length;
          if (weightsLength > 8) {
            weightsLength = 8;
          } else {
            weightsLength = googleFontFamilyOptionsData3.length;
          }
        }

        // These are the above dynamic fonts
        for (let weights = 0; weights < weightsLength + 1; weights++) {
          if (document.getElementById("fDropdown" + configs + weights).textContent != "Please Select Font") {
            if (
              (configs == 0 && weights == 0) ||
              (configs == 1 && weights == 0) ||
              (configs == 2 && weights == 0) ||
              (configs == 3 && weights == 0)
            ) {
              dynamicObj = {
                orgName: orgName,
                fontName: document.getElementById("fDropdown" + configs + weights).textContent,
                fontFamily: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? document.getElementById("ffamily" + configs + weights).textContent
                  : document.getElementById("fDropdown" + configs + weights).textContent.split(" - ")[0],
                fontSize: document.getElementById("fSize" + configs + weights).value
                  ? document.getElementById("fSize" + configs + weights).value
                  : 32,
                fontURL: document.getElementById("fUrl" + configs + weights).textContent,
                description: document.getElementById("fDesc" + configs).value,
                leadValue: "",
                minTrack: "",
                type: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? 1
                  : 0, // 0 for Google Fonts and 1 for Customized Fonts
                fontSet: configs,
              };
              finalObj.fonts.push(dynamicObj);
            } else {
              dynamicObj = {
                orgName: orgName,
                fontName: document.getElementById("fName" + configs + weights).textContent,
                fontFamily: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? document.getElementById("ffamily" + configs + weights).textContent
                  : document.getElementById("fDropdown" + configs + weights).textContent.split(" - ")[0],
                fontSize: document.getElementById("fSize" + configs + weights).value
                  ? document.getElementById("fSize" + configs + weights).value
                  : 26,
                fontURL: document.getElementById("fUrl" + configs + weights).textContent,
                description: document.getElementById("fDesc" + configs).value,
                leadValue: "",
                minTrack: "",
                type: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? 1
                  : 0, // 0 for Google Fonts and 1 for Customized Fonts
                fontSet: configs,
              };
              finalObj.fonts.push(dynamicObj);
            }
          }
        }
      }

      // These are the below static controls
      for (let index = 0; index < 5; index++) {
        if (index == 0) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 55,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 56,
            minTrack: document.getElementById("track" + index).value
              ? document.getElementById("track" + index).value
              : 0,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 1) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 26,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 27,
            minTrack: document.getElementById("track" + index).value
              ? document.getElementById("track" + index).value
              : 0,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 2) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 16,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 17,
            minTrack: document.getElementById("track" + index).value
              ? document.getElementById("track" + index).value
              : 0,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 3) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 10,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 11,
            minTrack: document.getElementById("track" + index).value
              ? document.getElementById("track" + index).value
              : 0,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 4) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 9,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 10,
            minTrack: document.getElementById("track" + index).value
              ? document.getElementById("track" + index).value
              : 0,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        }
      }

      setDisplayLoader(true);
      dispatch(saveFonts(JSON.stringify(finalObj), fontsAddedSuccessHandler, errorHandler));
    }
  };

  /**
   * Service Suucessful Handler
   */
  const fontsAddedSuccessHandler = () => {
    <div className="success">{toast.success("Fonts have been saved successfully..")}</div>;
    setTimeout(() => {
      setDisplayLoader(false);
      navigate("/brandassets/fontsView");
    }, 1000);
  };

  for (let index = 0; index < fontsSet; index++) {
    fontsConfigSetsHTMLDivs.push(
      <div>
        <div className="p-3 py-1 fontConfiguration">
          <div className="row my-3">
            <div className="col-lg-6 my-auto">
              <h6 className="m-0 uploadSet">For Configuration Add Set Of Fonts</h6>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-end">
              <div className="my-auto">
                <img className="px-2 img-fluid" src={google} />
              </div>

              {index == 0 ? (
                <Select
                  value={resetPlaceHolder0}
                  className="me-2 w-50"
                  id={"fGDropdown" + index}
                  placeholder="Please Select Google Font"
                  options={googleFontsOptionsZeroData}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : index == 1 ? (
                <Select
                  className="me-2 w-50"
                  id={"fGDropdown" + index}
                  value={resetPlaceHolder1}
                  placeholder="Please Select Google Font"
                  options={googleFontsOptionsOneData}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : index == 2 ? (
                <Select
                  className="me-2 w-50"
                  id={"fGDropdown" + index}
                  value={resetPlaceHolder2}
                  placeholder="Please Select Google Font"
                  options={googleFontsOptionsTwoData}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : index == 3 ? (
                <Select
                  className="me-2 w-50"
                  id={"fGDropdown" + index}
                  value={resetPlaceHolder3}
                  placeholder="Please Select Google Font"
                  options={googleFontsOptionsThreeData}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : (
                ""
              )}

              {index == 0 ? (
                <div>
                  <Tooltip title="Add Font Set">
                    <button id="fontSet-0" className="applySettingsBtn d-flex" onClick={showFontsModal}>
                      <div id="fontSet-0" className="ps-1 my-auto text-center">
                        <img id="fontSet-0" className="img-fluid" src={upload} />
                        <label id="fontSet-0" className="my-auto px-2 pt-1 uploadSetFont">
                          ADD FONT SET
                        </label>
                      </div>
                    </button>
                  </Tooltip>
                </div>
              ) : index == 1 ? (
                <div>
                  <Tooltip title="Add Font Set">
                    <button id="fontSet-1" className="applySettingsBtn d-flex" onClick={showFontsModal}>
                      <div id="fontSet-1" className="ps-1 my-auto text-center">
                        <img id="fontSet-1" className="img-fluid" src={upload} />
                        <label id="fontSet-1" className="my-auto px-2 pt-1 uploadSetFont">
                          ADD FONT SET
                        </label>
                      </div>
                    </button>
                  </Tooltip>
                </div>
              ) : index == 2 ? (
                <div>
                  <Tooltip title="Add Font Set">
                    <button id="fontSet-2" className="applySettingsBtn d-flex" onClick={showFontsModal}>
                      <div id="fontSet-2" className="ps-1 my-auto text-center">
                        <img id="fontSet-2" className="img-fluid" src={upload} />
                        <label id="fontSet-2" className="my-auto px-2 pt-1 uploadSetFont">
                          ADD FONT SET
                        </label>
                      </div>
                    </button>
                  </Tooltip>
                </div>
              ) : index == 3 ? (
                <div>
                  <Tooltip title="Add Font Set">
                    <button id="fontSet-3" className="applySettingsBtn d-flex" onClick={showFontsModal}>
                      <div id="fontSet-3" className="ps-1 my-auto text-center">
                        <img id="fontSet-3" className="img-fluid" src={upload} />
                        <label id="fontSet-3" className="my-auto px-2 pt-1 uploadSetFont">
                          ADD FONT SET
                        </label>
                      </div>
                    </button>
                  </Tooltip>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {index == 0 ? (
          <div>
            {fontFamilySelectionHTMLDivsZero}
            {fontFamilySelectionHTMLDivsZero ? <hr className="my-3" /> : ""}
          </div>
        ) : index == 1 ? (
          <div>
            {fontFamilySelectionHTMLDivsOne}
            {fontFamilySelectionHTMLDivsOne ? <hr className="my-3" /> : ""}
          </div>
        ) : index == 2 ? (
          <div>
            {fontFamilySelectionHTMLDivsTwo}
            {fontFamilySelectionHTMLDivsTwo ? <hr className="my-3" /> : ""}
          </div>
        ) : index == 3 ? (
          <div>
            {fontFamilySelectionHTMLDivsThree}
            {fontFamilySelectionHTMLDivsThree ? <hr className="my-3" /> : ""}
          </div>
        ) : (
          "sample"
        )}
      </div>
    );
  }

  if (fontNameChildDropdown0) {
    var len1 = googleFontFamilyOptionsData0.length;
    if (len1 > 8) {
      len1 = 8;
    } else {
      len1 = googleFontFamilyOptionsData0.length;
    }

    fontFamilySelectionHTMLDivsZero.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName00"} accept={".ttf"}>
              {fontName0}
            </h6>
            <span id={"fUrl00"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily00"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown00"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle0}
                options={googleFontFamilyOptionsData0}
                onChange={(event) => onFontFamilyChange(event, 0, 0)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize00"}
                name="Size"
                className="memberInput m-0 form-control"
                placeholder="32"
                value={regularFontSize0}
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize0((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 0)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsZero.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc0"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 0)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(0)}
            />
            <span id="errDesc0"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count0}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len1 + 1; index++) {
      // values for set1 Options
      let valueSet1Options = "";
      if (index == 1) {
        valueSet1Options = resetPlaceHolderDropdown00;
      } else if (index == 2) {
        valueSet1Options = resetPlaceHolderDropdown01;
      } else if (index == 3) {
        valueSet1Options = resetPlaceHolderDropdown02;
      } else if (index == 4) {
        valueSet1Options = resetPlaceHolderDropdown03;
      } else if (index == 5) {
        valueSet1Options = resetPlaceHolderDropdown04;
      } else if (index == 6) {
        valueSet1Options = resetPlaceHolderDropdown05;
      } else if (index == 7) {
        valueSet1Options = resetPlaceHolderDropdown06;
      } else if (index == 8) {
        valueSet1Options = resetPlaceHolderDropdown07;
      }

      fontFamilySelectionHTMLDivsZero.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6
                className="text-wrap text-break m-0"
                id={"fName0" + index}
                accept={".ttf"}
                style={{ fontSize: "26pt" }}
              >
                {fontName0}
              </h6>
              <span id={"fUrl0" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily0" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown0" + index}
                  value={valueSet1Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData0}
                  onChange={(event) => onFontFamilyChange(event, index, 0)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize0" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 0, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  if (fontNameChildDropdown1) {
    var len2 = googleFontFamilyOptionsData1.length;
    if (len2 > 8) {
      len2 = 8;
    } else {
      len2 = googleFontFamilyOptionsData1.length;
    }

    fontFamilySelectionHTMLDivsOne.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName10"} accept={".ttf"}>
              {fontName1}
            </h6>
            <span id={"fUrl10"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily10"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown10"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle1}
                options={googleFontFamilyOptionsData1}
                onChange={(event) => onFontFamilyChange(event, 0, 1)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize10"}
                name="Size"
                className="memberInput m-0 form-control"
                placeholder="32"
                value={regularFontSize1}
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize1((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 1)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsOne.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc1"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 1)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(1)}
            />
            <span id="errDesc1"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count1}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len2 + 1; index++) {
      // values for set2 Options
      let valueSet2Options = "";
      if (index == 1) {
        valueSet2Options = resetPlaceHolderDropdown10;
      } else if (index == 2) {
        valueSet2Options = resetPlaceHolderDropdown11;
      } else if (index == 3) {
        valueSet2Options = resetPlaceHolderDropdown12;
      } else if (index == 4) {
        valueSet2Options = resetPlaceHolderDropdown13;
      } else if (index == 5) {
        valueSet2Options = resetPlaceHolderDropdown14;
      } else if (index == 6) {
        valueSet2Options = resetPlaceHolderDropdown15;
      } else if (index == 7) {
        valueSet2Options = resetPlaceHolderDropdown16;
      } else if (index == 8) {
        valueSet2Options = resetPlaceHolderDropdown17;
      }

      fontFamilySelectionHTMLDivsOne.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6
                className="text-wrap text-break m-0"
                id={"fName1" + index}
                accept={".ttf"}
                style={{ fontSize: "26pt" }}
              >
                {fontName1}
              </h6>
              <span id={"fUrl1" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily1" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown1" + index}
                  value={valueSet2Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData1}
                  onChange={(event) => onFontFamilyChange(event, index, 1)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize1" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 1, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  if (fontNameChildDropdown2) {
    var len3 = googleFontFamilyOptionsData2.length;
    if (len3 > 8) {
      len3 = 8;
    } else {
      len3 = googleFontFamilyOptionsData2.length;
    }

    fontFamilySelectionHTMLDivsTwo.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName20"} accept={".ttf"}>
              {fontName2}
            </h6>
            <span id={"fUrl20"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily20"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown20"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle2}
                options={googleFontFamilyOptionsData2}
                onChange={(event) => onFontFamilyChange(event, 0, 2)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize20"}
                name="Size"
                className="memberInput m-0 form-control"
                placeholder="32"
                value={regularFontSize2}
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize2((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 2)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsTwo.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc2"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 2)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(2)}
            />
            <span id="errDesc2"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count2}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len3 + 1; index++) {
      // values for set3 options
      let valueSet3Options = "";
      if (index == 1) {
        valueSet3Options = resetPlaceHolderDropdown20;
      } else if (index == 2) {
        valueSet3Options = resetPlaceHolderDropdown21;
      } else if (index == 3) {
        valueSet3Options = resetPlaceHolderDropdown22;
      } else if (index == 4) {
        valueSet3Options = resetPlaceHolderDropdown23;
      } else if (index == 5) {
        valueSet3Options = resetPlaceHolderDropdown24;
      } else if (index == 6) {
        valueSet3Options = resetPlaceHolderDropdown25;
      } else if (index == 7) {
        valueSet3Options = resetPlaceHolderDropdown26;
      } else if (index == 8) {
        valueSet3Options = resetPlaceHolderDropdown27;
      }

      fontFamilySelectionHTMLDivsTwo.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6
                className="text-wrap text-break m-0"
                id={"fName2" + index}
                accept={".ttf"}
                style={{ fontSize: "26pt" }}
              >
                {fontName2}
              </h6>
              <span id={"fUrl2" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily2" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown2" + index}
                  value={valueSet3Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData2}
                  onChange={(event) => onFontFamilyChange(event, index, 2)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize2" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 2, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  if (fontNameChildDropdown3) {
    var len4 = googleFontFamilyOptionsData3.length;
    if (len4 > 8) {
      len4 = 8;
    } else {
      len4 = googleFontFamilyOptionsData3.length;
    }

    fontFamilySelectionHTMLDivsThree.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName30"} accept={".ttf"}>
              {fontName3}
            </h6>
            <span id={"fUrl30"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily30"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown30"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle3}
                options={googleFontFamilyOptionsData3}
                onChange={(event) => onFontFamilyChange(event, 0, 3)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize30"}
                name="Size"
                className="memberInput m-0 form-control"
                placeholder="32"
                value={regularFontSize3}
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize3((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 3)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsThree.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc3"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 3)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(3)}
            />
            <span id="errDesc3"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count3}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len4 + 1; index++) {
      // values for set4 options
      let valueSet4Options = "";
      if (index == 1) {
        valueSet4Options = resetPlaceHolderDropdown30;
      } else if (index == 2) {
        valueSet4Options = resetPlaceHolderDropdown31;
      } else if (index == 3) {
        valueSet4Options = resetPlaceHolderDropdown32;
      } else if (index == 4) {
        valueSet4Options = resetPlaceHolderDropdown33;
      } else if (index == 5) {
        valueSet4Options = resetPlaceHolderDropdown34;
      } else if (index == 6) {
        valueSet4Options = resetPlaceHolderDropdown35;
      } else if (index == 7) {
        valueSet4Options = resetPlaceHolderDropdown36;
      } else if (index == 8) {
        valueSet4Options = resetPlaceHolderDropdown37;
      }

      fontFamilySelectionHTMLDivsThree.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6
                className="text-wrap text-break m-0"
                id={"fName3" + index}
                accept={".ttf"}
                style={{ fontSize: "26pt" }}
              >
                {fontName3}
              </h6>
              <span id={"fUrl3" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily3" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown3" + index}
                  value={valueSet4Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData3}
                  onChange={(event) => onFontFamilyChange(event, index, 3)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize3" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 3, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  /**
   * Used after uploading Font
   * @param {*} upresult
   */
  const onFontUpload = (upresult) => {
    setShowDialog(true);
    resetStaticDropdownData();
    let index = sessionStorage.getItem("fontsSetId");
    for (let i = 0; i < upresult.length + 1; i++) {
      if (document.getElementById(`fName${index}${i}`)) {
        document.getElementById(`fName${index}${i}`).style.fontFamily = "unset";
      }
    }
    if (index == 0) {
      setResetPlaceHolder0(null);
      resetDynamicChildDropdown0();
      setFontName0(upresult[0]?.fontFamily);
      setCustomFontFiles0(upresult);
      setFontNameChildDropdown0(true);
      setResetPlaceHolderDropdownTitle0({
        label: upresult[0]?.fontName,
        value: upresult[0]?.fontURL,
      });
      if (document.getElementById("fDesc" + index)) {
        document.getElementById("fDesc" + index).value = "";
      }
      setCount0(0);
      document.getElementById("fUrl00").textContent = upresult[0].fontURL;
      document.getElementById("ffamily00").textContent = upresult[0].fontFamily;

      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily0" + Number(i + 1)).textContent = upresult[i]?.fontFamily;
      }
    } else if (index == 1) {
      if (document.getElementById("fDesc" + index)) {
        document.getElementById("fDesc" + index).value = "";
      }
      setCount1(0);
      setResetPlaceHolder1(null);
      resetDynamicChildDropdown1();
      setFontName1(upresult[0].fontFamily);
      setCustomFontFiles1(upresult);
      setFontNameChildDropdown1(true);
      setResetPlaceHolderDropdownTitle1({
        label: upresult[0]?.fontName,
        value: upresult[0]?.fontURL,
      });
      document.getElementById("fUrl10").textContent = upresult[0].fontURL;
      document.getElementById("ffamily10").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily1" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    } else if (index == 2) {
      if (document.getElementById("fDesc" + index)) {
        document.getElementById("fDesc" + index).value = "";
      }
      setCount2(0);
      setResetPlaceHolder2(null);
      resetDynamicChildDropdown2();
      setFontName2(upresult[0].fontFamily);
      setCustomFontFiles2(upresult);
      setFontNameChildDropdown2(true);
      setResetPlaceHolderDropdownTitle2({
        label: upresult[0]?.fontName,
        value: upresult[0]?.fontURL,
      });
      document.getElementById("fUrl20").textContent = upresult[0].fontURL;
      document.getElementById("ffamily20").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily2" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    } else if (index == 3) {
      if (document.getElementById("fDesc" + index)) {
        document.getElementById("fDesc" + index).value = "";
      }
      setCount3(0);
      setResetPlaceHolder3(null);
      resetDynamicChildDropdown3();
      setFontName3(upresult[0].fontFamily);
      setCustomFontFiles3(upresult);
      setFontNameChildDropdown3(true);
      setResetPlaceHolderDropdownTitle3({
        label: upresult[0]?.fontName,
        value: upresult[0]?.fontURL,
      });
      document.getElementById("fUrl30").textContent = upresult[0].fontURL;
      document.getElementById("ffamily30").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily3" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    }

    var fontsUrl = upresult[0].fontURL;
    var upFontFace = new FontFace(`upFont${index}`, `url(${fontsUrl})`);
    document.fonts.add(upFontFace);
    upFontFace.load();

    setTimeout(() => {
      if (document.getElementById("fName" + index + 0)) {
        document.getElementById("fName" + index + 0).textContent = upresult[0]?.fontFamily;
        document.getElementById("fName" + index + 0).style.fontFamily = `upFont${index}`;
      }
      if (document.getElementById("fUrl" + index + 0)) {
        document.getElementById("fUrl" + index + 0).textContent = fontsUrl;
      }
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * Call Back Method From child to Parent
   * @param {*} childdata
   */
  const childToParent = (childdata) => {
    onFontUpload(childdata);
  };

  return (
    <div className="mt-3 pb-4 userFonts">
      <Suspense fallback={<Loader />}>
        {showPrompt &&
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        }
      </Suspense>
      <div className="row mb-2">
        <div className="col-lg-9 d-flex">
          <h6 className="titLe py-2 m-0 my-auto">
            Brand Assets <span> - Fonts</span>
          </h6>
        </div>

        <div className="col-lg-3 d-flex justify-content-end my-auto">
          <div>
            {userRole == 1 || userRole == 2 ? (
              <div className="d-flex px-1 buttonList">
                <Tooltip title="Save and Preview">
                  <div className="buttonGap me-0" onClick={addFonts}>
                    <IconButton
                      className="iconsStyles borderBtn"
                      img={add}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>

                <Tooltip title="Fonts Configuration">
                  <div className="buttonGap me-0" onClick={updateFontsConfig}>
                    <IconButton
                      className="iconsStyles"
                      img={Settings}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <hr className="mb-2" />

      <div className="row userColors pb-2">
        <EditText assetType="Fonts" />
      </div>

      {fontsSet == null ? (
        <div className="row">
          <div className="col-lg-12">
            <div className="row text-center pt-5 mt-5">
              <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                No fonts are available
              </h1>
              <p
                style={{
                  color: "#15355c",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Please configure fonts by pressing above settings icon.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          {/* <hr className="mb-3" /> */}
          {fontsConfigSetsHTMLDivs}
          <div className="row pb-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  <h1 className="my-auto h1Title text-wrap text-break" id="title" accept={".ttf"}>
                    TITLE
                  </h1>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H1
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl0" hidden={true}></label>
                      <label id="cFname0" hidden={true}></label>
                      <Select
                        id="definedDropdown0"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilyTitleChange}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        value={selectedZeroOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        className="memberInput m-0"
                        id="definedSize0"
                        placeholder="55"
                        type="text"
                        name="Size"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "title", 0)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          className="memberInput m-0"
                          id="lead0"
                          placeholder="56"
                          type="text"
                          name="Size"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "title", 0)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track0"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "title")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  <h2 className="my-auto h2HeadTitle text-wrap text-break" id="headTitle" accept={".ttf"}>
                    HEADLINE
                  </h2>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H2
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl1" hidden={true}></label>
                      <label id="cFname1" hidden={true}></label>
                      <Select
                        id="definedDropdown1"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilyHeadLineChange}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        value={selectedOneOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize1"
                        className="memberInput m-0"
                        placeholder="26"
                        type="text"
                        name="Size"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "headTitle", 1)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead1"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="27"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "headTitle", 1)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track1"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "headTitle")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  <h3 className="my-auto h3SubHeading1 text-wrap text-break" id="subHeading1" accept={".ttf"}>
                    Sub Heading
                  </h3>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H3
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl2" hidden={true}></label>
                      <label id="cFname2" hidden={true}></label>
                      <Select
                        id="definedDropdown2"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilySubHeadingChangeH3}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        value={selectedTwoOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize2"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="16"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "subHeading1", 2)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead2"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="17"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "subHeading1", 2)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track2"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "subHeading1")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3  my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="my-auto h3SubHeading2 text-wrap text-break" id="subHeading2" accept={".ttf"}>
                    Body
                  </h4>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H4
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl3" hidden={true}></label>
                      <label id="cFname3" hidden={true}></label>
                      <Select
                        id="definedDropdown3"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilySubHeadingChangeH4}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        value={selectedThreeOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize3"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="10"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "subHeading2", 3)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead3"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="11"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "subHeading2", 3)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track3"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "subHeading2")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  <h5 className="my-auto h5Caption text-wrap text-break" id="caption" accept={".ttf"}>
                    Captions
                  </h5>
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H5
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl4" hidden={true}></label>
                      <label id="cFname4" hidden={true}></label>
                      <Select
                        id="definedDropdown4"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilyCaptionChange}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        menuPlacement="top"
                        value={selectedFourOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize4"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="9"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "caption", 4)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead4"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="10"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "caption", 4)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track4"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "caption")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={<Loader />}>
        {showFonts &&
          <FontsUploadModal childToParent={childToParent} showFontsUpload={showFonts} setShowFontsUpload={setShowFonts} />
        }
        {showFontsConfig &&
          <FontsConfigModal showConfigUpload={showFontsConfig} setShowConfigUpload={setShowFontsConfig} />
        }
      </Suspense>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default Fonts;
