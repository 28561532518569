import { Fragment } from "react";

function InputTextField({
  type = "",
  value = "",
  onChange,
  onBlur,
  name,
  className = "",
  error,
  placeholder = "",
  disabled = "",
  onKeyUp,
  onKeyPress,
  onKeyDown,
  minLength,
  maxLength,
  onFocus,
  id,
  style,
}) {
  return (
    <Fragment>
      <input
        type={type}
        className={className}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeholder}
        onKeyUp={onKeyUp}
        autoComplete="off"
        disabled={disabled}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        minLength={minLength}
        maxLength={maxLength}
        id={id}
        onFocus={onFocus}
        style={style}
      />
      {error == "" ? undefined : (
        <p style={{ maxWidth: "380px !important" }} className="errorMsg">
          {error}
        </p>
      )}
    </Fragment>
  );
}

export default InputTextField;
