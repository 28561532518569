import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ViewSDKClient from "../../src/ViewSDKClient.js";
import IconButton from "../Components/IconButton";
import update from "../images/upload.svg";
import {
  AddingNewPDF,
  getAssetsByMemberId, getStyleGuide
} from "../redux/Actions/action";
import EditText from "./ContentEditable/EditText.js";
import { Loader } from "./Shared/Loader.js";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for StyleGuide
 * @return {*} 
 */
const Styleguide = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const inputRef = React.createRef();
  const formData = new FormData();
  const [userRole, setUserRole] = useState("");
  const [displayLoader, setDisplayLoader] = useState(false);
  const switchUser = localStorage.getItem("switchUser");
  const roleId = localStorage.getItem("roleId");

  let adminAssetsData = [];
  let userAssetsData = [];
  let styleGuideUrl = "";

  adminAssetsData = useSelector((state) => state.userReducer.StyleGuide);
  userAssetsData = useSelector((state) => state.userReducer.AssetsDataByUserId);

  if (adminAssetsData?.length > 0) {
    styleGuideUrl = adminAssetsData[0]?.path;
  } else if (userAssetsData?.length > 0) {
    styleGuideUrl = userAssetsData[0]?.path;
  }

  /**
   * This method is used to load PDF 
   */
  const loadPDF = () => {
    const viewSDKClient = new ViewSDKClient();
    viewSDKClient.ready().then(() => {
      viewSDKClient.previewFile(
        "pdf-div",
        {
          embedMode: "SIZED_CONTAINER",
        },
        styleGuideUrl
      );
    });
  };

  useEffect(() => {
    if (roleId === "1" && switchUser === "false") {
      navigate("/");
    }
    setUserRole(localStorage.getItem("roleId"));
    if (localStorage.getItem("roleId") === "3") {
      const obj = {
        id: localStorage.getItem("userId"),
        assetType: "Style Guide",
        orgName: localStorage.getItem("orgName"),
      };
      dispatch(getAssetsByMemberId(obj, successHandler, errorHandler));
    } else {
      const obj = {
        name: localStorage.getItem("orgName"),
        assetType: "Style Guide",
      };
      dispatch(getStyleGuide(obj, successHandler, errorHandler));
    }

    setDisplayLoader(false);
  }, []);

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call after successful style guide fetching 
   * @return {*} 
   */
  const successHandlerForGetStyleGuide = () => {
    setDisplayLoader(false);
    return <div>{toast.success("Style Guide Updated Successfully")}</div>;
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call after successful style guide submition 
   */
  const uploadSuccessHandler = () => {
    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: "Style Guide",
    };
    dispatch(getStyleGuide(obj, successHandlerForGetStyleGuide, errorHandler));
  };

  /* To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /**
   * This method will call when user click on the update button
   * @param {*} evt
   */
  const inputFieldClick = (evt) => {
    evt.preventDefault();
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  /**
   * Method to be called when user select the file add
   * @param {*} event
   */
  const handleLogoChange = (event) => {
    event.preventDefault();
    formData.append("name", localStorage.getItem("orgName"));
    formData.append("assetType", "Style Guide");
    for (let index = 0; index < event.target.files.length; index++) {
      formData.append("pdf", event.target.files[index]);
    }
    setDisplayLoader(true);
    dispatch(AddingNewPDF(formData, uploadSuccessHandler, errorHandler));
  };

  return (
    <div className="mt-3">
      <div className="styleGuide mb-2">
        <div className="row mb-2">
          <div className="col-lg-11 col-md-11 col-sm-11 col-11 my-auto">
            <h6 className="titLe py-2 m-0 my-auto">Style Guide</h6>
          </div>
          <div className="col-lg-1 col-md-1 col-sm-1 col-12 my-auto d-flex justify-content-end">
            {userRole === "1" || userRole === "2" ? (
              <div className="d-flex buttonList">
                <Tooltip title="Add">
                  <div className="buttonGap m-0" onClick={inputFieldClick}>
                    <IconButton
                      className="iconsStyles borderBtn border-end-0"
                      img={update}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                    />
                  </div>
                </Tooltip>
              </div>
            ) : (
              ""
            )}
            <input
              accept="application/pdf"
              type="file"
              style={{ visibility: "hidden", display: "none" }}
              ref={inputRef}
              onChange={handleLogoChange}
            />
          </div>
        </div>

        <hr className="m-1 mb-2"></hr>

        <div className="row userColors">
          <EditText assetType="Style Guide" />
        </div>
      </div>
      {styleGuideUrl !== "" ? (
        <div id="pdf-div" className="full-window-div border border-gray-100" onDocumentLoad={loadPDF()}></div>
      ) : (userRole === "1" || userRole === "2") ? (
        <div className="text-center pt-5 mt-5">
          <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
            No Style guide documents available
          </h1>
          <p style={{ color: "#15355c", fontWeight: "bold", fontSize: "12px" }}>Please add style guide document</p>
        </div>
      ) : (
        <div className="text-center pt-5 mt-5">
          <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
            No style guide documents available
          </h1>
        </div>
      )}
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
};

export default Styleguide;
