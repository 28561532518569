import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setPassword } from "../redux/Actions/action";
import { appRoutes } from "../util/constants";
import "./Header.css";
import InputTextField from "./Shared/InputTextField";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for password creation
 * @return {*} 
 */
const Password = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /**
   * Function for URL params
   * @return {*} 
   */
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const query = useQuery();
  const [passwordError, setPasswordErr] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });

  /**
   *This method is used to validate the input fields
   * @param {*} e
   */
  const handleValidation = (e) => {
    const passwordInputValue = e?.target?.value;
    const passwordInputFieldName = e?.target?.name;

    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Please provide valid password";
      } else if (!minLengthPassword) {
        errMsg =
          "Password must be minimum 8 characters include one uppercase, lowercase, number and special character: @$! % * ? &";
      } else if (uppercasePassword < 1) {
        errMsg = "Must contain one uppercase letter";
      } else if (lowercasePassword < 1) {
        errMsg = "Must contain one lowercase letter";
      } else if (digitsPassword < 1) {
        errMsg = "Must contain one number";
      } else if (specialCharPassword < 1) {
        errMsg = "Must contain one special character: @$! % * ? &";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
      setConfirmPasswordError("");
    }

    // for confirm password
    if (
      passwordInputFieldName === "confirmPassword" ||
      (passwordInputFieldName === "password" && passwordInput.confirmPassword.length > 0)
    ) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else if (passwordInput.password.length > 0 && passwordInput.confirmPassword == "") {
        setConfirmPasswordError("");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  /**
   * This method is used for password submit
   * @param {*} e
   */
  const onResetPasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput.password == "" && passwordInput.confirmPassword == "") {
      setPasswordErr("Please provide valid password");
      setConfirmPasswordError("Please provide valid confirm password");
    } else if (passwordInput.password.length != "" && passwordInput.confirmPassword == "") {
      setConfirmPasswordError("Please provide valid confirm password");
    } else if (passwordInput.password != passwordInput.confirmPassword) {
      setConfirmPasswordError("Confirm password is not matched");
    } else if (passwordError.length > 0 || confirmPasswordError.length > 0) {
    } else {
      dispatch(setPassword({ password: passwordInput.password, email: query.get("Email") }, onPasswordSuccess));
    }
  };

  /**
   * This method will call after successful password creation
   * @return {*} 
   */
  const onPasswordSuccess = () => {
    navigate(appRoutes.homepage);
    return <div className="success">{toast.success("password created successfully")}</div>;
  };

  /**
   * This method will call when user change the input fields
   * @param {*} e
   */
  const onResetPasswordChange = (e) => {
    let { name, value } = e.target;
    setPasswordInput({ ...passwordInput, [name]: value.trim() });
  };

  return (
    <div style={{ marginTop: "-3px", minHeight: "calc(100vh - 163px)" }} className="passWordReset">
      <form
        className="py-5 passWordReset"
        onSubmit={onResetPasswordSubmit}
        // style={{ background: "lightgray" }}
      >
        <div className="container py-5">
          <div className="row">
            <div className="mx-auto" style={{ width: "35%" }}>
              <div className="col-12">
                <p class="text-white">
                  Hi <span className="textBoldname">{query.get("firstName")}</span> ! <br /> Welcome to Grafix Ark,
                  create a password to get started
                </p>

                <label className="loginLabels resetLabels mb-2">
                  User Name : <strong> {query.get("Email")}</strong>
                </label>
              </div>
              <div className="col-12">
                <label className="loginLabels resetLabels">
                  Enter Password<span className="required-Felid"> *</span>
                </label>
                <InputTextField
                  type="password"
                  className="loginInput w-100 mb-3"
                  name="password"
                  value={passwordInput.password}
                  onChange={onResetPasswordChange}
                  onBlur={handleValidation}
                  onKeyUp={handleValidation}
                />
                <p className="errorMsg-Pwd setErrorPwd">{passwordError}</p>
              </div>
              <div className="col-12 pt-3">
                <label className="loginLabels resetLabels">
                  Re-enter Password
                  <span className="required-Felid"> *</span>
                </label>
                <InputTextField
                  type="password"
                  className="loginInput w-100 mb-3"
                  name="confirmPassword"
                  value={passwordInput.confirmPassword}
                  onChange={onResetPasswordChange}
                  onKeyUp={handleValidation}
                />
                <p className="errorMsg-Pwd setErrorPwd">{confirmPasswordError}</p>
              </div>
            </div>
          </div>
          <div className="col-12 py-4 text-center">
            <button className="model btnModel reSet">Create Password</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Password;
