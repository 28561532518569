import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import upload from "../../images/fnt_upload.svg";
import google from "../../images/google.svg";
import leading from "../../images/leading.svg";
import minTrack from "../../images/mintrack.svg";
import { deleteFonts, editFonts, getFonts, getGoogleFonts } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import { useCallbackPrompt } from "../Hooks/useCallbackPrompt";
import { Loader } from "../Shared/Loader";
import "./brandAssets.css";

toast.configure({
  autoClose: 2000,
});

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? "unset" : null,
    color: state.isSelected ? "#000" : null,
    cursor: "pointer",
  }),
  control: (provided, state) => ({
    ...provided,
    boxShadow: "none",
    border: state.isFocused && "1px solid hsl(0, 0%, 80%)",
    "&:hover": {
      border: "1px solid hsl(0, 0%, 80%)",
    },
    minHeight: "33px",
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "4px 8px",
  }),
  placeholder: (provided) => ({
    ...provided,
    marginTop: "3px",
  }),
  singleValue: (provided) => ({
    ...provided,
    marginTop: "3px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};

/**
 * Functional component for FontsEdit
 * @return {*} 
 */
function FontsEdit() {
  const FontsUploadModal = React.lazy(() => import("../Shared/Modals/FontsUploadModal"));
  const DialogBox = React.lazy(() => import("../Hooks/DialogBox.component.tsx"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // state for showing config modal and dialog
  const [showFonts, setShowFonts] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  // state for fontSet
  const [fontsSet, setFontsSet] = useState(sessionStorage.getItem("fontsSet") || 0);

  // state for orgName and userRole
  const [orgName, setOrgName] = useState("");

  // state for fontName of four sets
  const [fontName0, setFontName0] = useState("");
  const [fontName1, setFontName1] = useState("");
  const [fontName2, setFontName2] = useState("");
  const [fontName3, setFontName3] = useState("");

  // state for fontFiles of four sets
  const [fontFiles0, setFontFiles0] = useState([]);
  const [fontFiles1, setFontFiles1] = useState([]);
  const [fontFiles2, setFontFiles2] = useState([]);
  const [fontFiles3, setFontFiles3] = useState([]);

  // state for customFontFiles of four sets
  const [customFontFiles0, setCustomFontFiles0] = useState([]);
  const [customFontFiles1, setCustomFontFiles1] = useState([]);
  const [customFontFiles2, setCustomFontFiles2] = useState([]);
  const [customFontFiles3, setCustomFontFiles3] = useState([]);

  // state to show fontNameChildDropdown of four sets
  const [fontNameChildDropdown0, setFontNameChildDropdown0] = useState(false);
  const [fontNameChildDropdown1, setFontNameChildDropdown1] = useState(false);
  const [fontNameChildDropdown2, setFontNameChildDropdown2] = useState(false);
  const [fontNameChildDropdown3, setFontNameChildDropdown3] = useState(false);

  // state for description count of four sets
  const [count0, setCount0] = useState(0);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);

  // state for  fontSets of Google Change resetPlaceholder
  const [resetPlaceHolder0, setResetPlaceHolder0] = useState(null);
  const [resetPlaceHolder1, setResetPlaceHolder1] = useState(null);
  const [resetPlaceHolder2, setResetPlaceHolder2] = useState(null);
  const [resetPlaceHolder3, setResetPlaceHolder3] = useState(null);

  // Used to reset the defined dropdown options data
  const [googleDropdownChanged, setGoogleDropdownChanged] = useState(0);
  const [uploadDropdownChanged, setUploadDropdownChanged] = useState(0);
  const [childDropdownChanged, setChildDropdownChanged] = useState(0);

  // state for regularFontSize of four sets
  const [regularFontSize0, setRegularFontSize0] = useState("");
  const [regularFontSize1, setRegularFontSize1] = useState("");
  const [regularFontSize2, setRegularFontSize2] = useState("");
  const [regularFontSize3, setRegularFontSize3] = useState("");

  // state for UploadFontSet Clicked or not
  const [uploadClicked0, setUploadClicked0] = useState(0);
  const [uploadClicked1, setUploadClicked1] = useState(0);
  const [uploadClicked2, setUploadClicked2] = useState(0);
  const [uploadClicked3, setUploadClicked3] = useState(0);

  // state for fontSet1 resetPlaceholder
  const [resetPlaceHolderDropdownTitle0, setResetPlaceHolderDropdownTitle0] = useState(null);
  const [resetPlaceHolderDropdown00, setResetPlaceHolderDropdown00] = useState(null);
  const [resetPlaceHolderDropdown01, setResetPlaceHolderDropdown01] = useState(null);
  const [resetPlaceHolderDropdown02, setResetPlaceHolderDropdown02] = useState(null);
  const [resetPlaceHolderDropdown03, setResetPlaceHolderDropdown03] = useState(null);
  const [resetPlaceHolderDropdown04, setResetPlaceHolderDropdown04] = useState(null);
  const [resetPlaceHolderDropdown05, setResetPlaceHolderDropdown05] = useState(null);
  const [resetPlaceHolderDropdown06, setResetPlaceHolderDropdown06] = useState(null);
  const [resetPlaceHolderDropdown07, setResetPlaceHolderDropdown07] = useState(null);

  // state for  fontSet2 resetPlaceholder
  const [resetPlaceHolderDropdownTitle1, setResetPlaceHolderDropdownTitle1] = useState(null);
  const [resetPlaceHolderDropdown10, setResetPlaceHolderDropdown10] = useState(null);
  const [resetPlaceHolderDropdown11, setResetPlaceHolderDropdown11] = useState(null);
  const [resetPlaceHolderDropdown12, setResetPlaceHolderDropdown12] = useState(null);
  const [resetPlaceHolderDropdown13, setResetPlaceHolderDropdown13] = useState(null);
  const [resetPlaceHolderDropdown14, setResetPlaceHolderDropdown14] = useState(null);
  const [resetPlaceHolderDropdown15, setResetPlaceHolderDropdown15] = useState(null);
  const [resetPlaceHolderDropdown16, setResetPlaceHolderDropdown16] = useState(null);
  const [resetPlaceHolderDropdown17, setResetPlaceHolderDropdown17] = useState(null);

  // state for  fontSet3 resetPlaceholder
  const [resetPlaceHolderDropdownTitle2, setResetPlaceHolderDropdownTitle2] = useState(null);
  const [resetPlaceHolderDropdown20, setResetPlaceHolderDropdown20] = useState(null);
  const [resetPlaceHolderDropdown21, setResetPlaceHolderDropdown21] = useState(null);
  const [resetPlaceHolderDropdown22, setResetPlaceHolderDropdown22] = useState(null);
  const [resetPlaceHolderDropdown23, setResetPlaceHolderDropdown23] = useState(null);
  const [resetPlaceHolderDropdown24, setResetPlaceHolderDropdown24] = useState(null);
  const [resetPlaceHolderDropdown25, setResetPlaceHolderDropdown25] = useState(null);
  const [resetPlaceHolderDropdown26, setResetPlaceHolderDropdown26] = useState(null);
  const [resetPlaceHolderDropdown27, setResetPlaceHolderDropdown27] = useState(null);

  // state for  fontSet4 resetPlaceholder
  const [resetPlaceHolderDropdownTitle3, setResetPlaceHolderDropdownTitle3] = useState(null);
  const [resetPlaceHolderDropdown30, setResetPlaceHolderDropdown30] = useState(null);
  const [resetPlaceHolderDropdown31, setResetPlaceHolderDropdown31] = useState(null);
  const [resetPlaceHolderDropdown32, setResetPlaceHolderDropdown32] = useState(null);
  const [resetPlaceHolderDropdown33, setResetPlaceHolderDropdown33] = useState(null);
  const [resetPlaceHolderDropdown34, setResetPlaceHolderDropdown34] = useState(null);
  const [resetPlaceHolderDropdown35, setResetPlaceHolderDropdown35] = useState(null);
  const [resetPlaceHolderDropdown36, setResetPlaceHolderDropdown36] = useState(null);
  const [resetPlaceHolderDropdown37, setResetPlaceHolderDropdown37] = useState(null);

  // Based on User selected Google WEB Font Prepare the Dropdown for selected font families
  let adminFontsData = [];
  let fontsSetZeroData = [];
  let fontsSetOneData = [];
  let fontsSetTwoData = [];
  let fontsSetThreeData = [];
  let fontsStaticSet = [];
  let uniqueResult = [];

  const map = new Map();
  adminFontsData = useSelector((state) => state.userReducer.FontsData);

  if (adminFontsData?.length > 0) {
    for (const item of adminFontsData) {
      if (!map.has(item.fontSet)) {
        map.set(item.fontSet, true);
        uniqueResult.push(item);
      }
    }
    fontsSetZeroData = adminFontsData.filter((x) => x.fontSet == 0);
    fontsSetOneData = adminFontsData.filter((x) => x.fontSet == 1);
    fontsSetTwoData = adminFontsData.filter((x) => x.fontSet == 2);
    fontsSetThreeData = adminFontsData.filter((x) => x.fontSet == 3);
    fontsStaticSet = adminFontsData.filter((x) => x.fontSet == null);
  }

  // defaultValues for static DropDowns
  let defaultValues0 = {
    id: fontsStaticSet[0]?.fontURL,
    label: fontsStaticSet[0]?.fontName,
    fName: fontsStaticSet[0]?.fontFamily,
  };
  let defaultValues1 = {
    id: fontsStaticSet[1]?.fontURL,
    label: fontsStaticSet[1]?.fontName,
    fName: fontsStaticSet[1]?.fontFamily,
  };
  let defaultValues2 = {
    id: fontsStaticSet[2]?.fontURL,
    label: fontsStaticSet[2]?.fontName,
    fName: fontsStaticSet[2]?.fontFamily,
  };
  let defaultValues3 = {
    id: fontsStaticSet[3]?.fontURL,
    label: fontsStaticSet[3]?.fontName,
    fName: fontsStaticSet[3]?.fontFamily,
  };
  let defaultValues4 = {
    id: fontsStaticSet[4]?.fontURL,
    label: fontsStaticSet[4]?.fontName,
    fName: fontsStaticSet[4]?.fontFamily,
  };

  // state for five static dropdown
  const [selectedZeroOption, setSelectedZeroOption] = useState(defaultValues0);
  const [selectedOneOption, setSelectedOneOption] = useState(defaultValues1);
  const [selectedTwoOption, setSelectedTwoOption] = useState(defaultValues2);
  const [selectedThreeOption, setSelectedThreeOption] = useState(defaultValues3);
  const [selectedFourOption, setSelectedFourOption] = useState(defaultValues4);

  useEffect(() => {
    // Get the Pre defined Google Fonts
    dispatch(getGoogleFonts("AIzaSyB_9EPsTlSidU8qmhtNszALThM9VkEDbrw", successHandler, errorHandler));

    // Get the Saved Fonts Data
    dispatch(getFonts(localStorage.getItem("orgName"), successHandler, errorHandler));

    if (uniqueResult.length > 0) {
      setFontsSet(uniqueResult.length - 1);
    } else {
      navigate("/brandassets/fontsView");
    }
    setOrgName(localStorage.getItem("orgName"));

    if (document.getElementById("fDesc0")) {
      document.getElementById("fDesc0").value = fontsSetZeroData[0]?.description;
      setCount0(fontsSetZeroData[0]?.description.length);
    }
    if (document.getElementById("fUrl00")) {
      document.getElementById("fUrl00").textContent = fontsSetZeroData[0].fontURL;
    }

    for (let index = 0; index < fontsSetZeroData.length; index++) {
      if (index == 0) {
        setResetPlaceHolderDropdownTitle0({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 1) {
        setResetPlaceHolderDropdown00({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 2) {
        setResetPlaceHolderDropdown01({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 3) {
        setResetPlaceHolderDropdown02({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 4) {
        setResetPlaceHolderDropdown03({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 5) {
        setResetPlaceHolderDropdown04({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 6) {
        setResetPlaceHolderDropdown05({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 7) {
        setResetPlaceHolderDropdown06({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }
      if (index == 8) {
        setResetPlaceHolderDropdown07({
          id: fontsSetZeroData[index]?.fontURL,
          label: fontsSetZeroData[index]?.fontName,
          fName: fontsSetZeroData[index]?.fontFamily,
        });
      }

      if (document.getElementById("fSize0" + index)) {
        document.getElementById("fSize0" + index).value = fontsSetZeroData[index]?.fontSize;
      }
      if (document.getElementById("fUrl0" + index)) {
        document.getElementById("fUrl0" + index).textContent = fontsSetZeroData[index].fontURL;
      }
      if (document.getElementById("ffamily0" + index)) {
        document.getElementById("ffamily0" + index).textContent = fontsSetZeroData[index].fontFamily;
      }
    }

    if (document.getElementById("fDesc1")) {
      document.getElementById("fDesc1").value = fontsSetOneData[0]?.description;
      setCount1(fontsSetOneData[0]?.description.length);
    }
    if (document.getElementById("fUrl10")) {
      document.getElementById("fUrl10").textContent = fontsSetOneData[0].fontURL;
    }

    for (let index = 0; index < fontsSetOneData.length; index++) {
      if (index == 0) {
        setResetPlaceHolderDropdownTitle1({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 1) {
        setResetPlaceHolderDropdown10({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 2) {
        setResetPlaceHolderDropdown11({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 3) {
        setResetPlaceHolderDropdown12({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 4) {
        setResetPlaceHolderDropdown13({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 5) {
        setResetPlaceHolderDropdown14({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 6) {
        setResetPlaceHolderDropdown15({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 7) {
        setResetPlaceHolderDropdown16({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (index == 8) {
        setResetPlaceHolderDropdown17({
          id: fontsSetOneData[index]?.fontURL,
          label: fontsSetOneData[index]?.fontName,
          fName: fontsSetOneData[index]?.fontFamily,
        });
      }
      if (document.getElementById("fSize1" + index)) {
        document.getElementById("fSize1" + index).value = fontsSetOneData[index]?.fontSize;
      }
      if (document.getElementById("fUrl1" + index)) {
        document.getElementById("fUrl1" + index).textContent = fontsSetOneData[index].fontURL;
      }

      if (document.getElementById("ffamily1" + index)) {
        document.getElementById("ffamily1" + index).textContent = fontsSetOneData[index].fontFamily;
      }
    }

    if (document.getElementById("fDesc2")) {
      document.getElementById("fDesc2").value = fontsSetTwoData[0]?.description;
      setCount2(fontsSetTwoData[0]?.description.length);
    }
    if (document.getElementById("fUrl20")) {
      document.getElementById("fUrl20").textContent = fontsSetTwoData[0].fontURL;
    }

    for (let index = 0; index < fontsSetTwoData.length; index++) {
      if (index == 0) {
        setResetPlaceHolderDropdownTitle2({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 1) {
        setResetPlaceHolderDropdown20({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 2) {
        setResetPlaceHolderDropdown21({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 3) {
        setResetPlaceHolderDropdown22({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 4) {
        setResetPlaceHolderDropdown23({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 5) {
        setResetPlaceHolderDropdown24({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 6) {
        setResetPlaceHolderDropdown25({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 7) {
        setResetPlaceHolderDropdown26({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (index == 8) {
        setResetPlaceHolderDropdown27({
          id: fontsSetTwoData[index]?.fontURL,
          label: fontsSetTwoData[index]?.fontName,
          fName: fontsSetTwoData[index]?.fontFamily,
        });
      }
      if (document.getElementById("fSize2" + index)) {
        document.getElementById("fSize2" + index).value = fontsSetTwoData[index]?.fontSize;
      }
      if (document.getElementById("fUrl2" + index)) {
        document.getElementById("fUrl2" + index).textContent = fontsSetTwoData[index].fontURL;
      }

      if (document.getElementById("ffamily2" + index)) {
        document.getElementById("ffamily2" + index).textContent = fontsSetTwoData[index].fontFamily;
      }
    }

    if (document.getElementById("fDesc3")) {
      document.getElementById("fDesc3").value = fontsSetThreeData[0]?.description;
      setCount3(fontsSetThreeData[0]?.description.length);
    }
    if (document.getElementById("fUrl30")) {
      document.getElementById("fUrl30").textContent = fontsSetThreeData[0].fontURL;
    }

    for (let index = 0; index < fontsSetThreeData.length; index++) {
      if (index == 0) {
        setResetPlaceHolderDropdownTitle3({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 1) {
        setResetPlaceHolderDropdown30({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 2) {
        setResetPlaceHolderDropdown31({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 3) {
        setResetPlaceHolderDropdown32({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 4) {
        setResetPlaceHolderDropdown33({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 5) {
        setResetPlaceHolderDropdown34({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 6) {
        setResetPlaceHolderDropdown35({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 7) {
        setResetPlaceHolderDropdown36({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (index == 8) {
        setResetPlaceHolderDropdown37({
          id: fontsSetThreeData[index]?.fontURL,
          label: fontsSetThreeData[index]?.fontName,
          fName: fontsSetThreeData[index]?.fontFamily,
        });
      }
      if (document.getElementById("fSize3" + index)) {
        document.getElementById("fSize3" + index).value = fontsSetThreeData[index]?.fontSize;
      }
      if (document.getElementById("fUrl3" + index)) {
        document.getElementById("fUrl3" + index).textContent = fontsSetThreeData[index].fontURL;
      }

      if (document.getElementById("ffamily3" + index)) {
        document.getElementById("ffamily3" + index).textContent = fontsSetThreeData[index].fontFamily;
      }
    }

    // Set the values for the Static HTML Controls
    if (document.getElementById("definedSize0")) {
      document.getElementById("definedSize0").value = fontsStaticSet[0]?.fontSize;
    }
    if (document.getElementById("definedSize1")) {
      document.getElementById("definedSize1").value = fontsStaticSet[1]?.fontSize;
    }
    if (document.getElementById("definedSize2")) {
      document.getElementById("definedSize2").value = fontsStaticSet[2]?.fontSize;
    }
    if (document.getElementById("definedSize3")) {
      document.getElementById("definedSize3").value = fontsStaticSet[3]?.fontSize;
    }
    if (document.getElementById("definedSize4")) {
      document.getElementById("definedSize4").value = fontsStaticSet[4]?.fontSize;
    }

    if (document.getElementById("lead0")) {
      document.getElementById("lead0").value = fontsStaticSet[0]?.leadValue;
    }
    if (document.getElementById("lead1")) {
      document.getElementById("lead1").value = fontsStaticSet[1]?.leadValue;
    }
    if (document.getElementById("lead2")) {
      document.getElementById("lead2").value = fontsStaticSet[2]?.leadValue;
    }
    if (document.getElementById("lead3")) {
      document.getElementById("lead3").value = fontsStaticSet[3]?.leadValue;
    }
    if (document.getElementById("lead4")) {
      document.getElementById("lead4").value = fontsStaticSet[4]?.leadValue;
    }

    if (document.getElementById("track0")) {
      document.getElementById("track0").value = fontsStaticSet[0]?.minTrack;
    }
    if (document.getElementById("track1")) {
      document.getElementById("track1").value = fontsStaticSet[1]?.minTrack;
    }
    if (document.getElementById("track2")) {
      document.getElementById("track2").value = fontsStaticSet[2]?.minTrack;
    }
    if (document.getElementById("track3")) {
      document.getElementById("track3").value = fontsStaticSet[3]?.minTrack;
    }
    if (document.getElementById("track4")) {
      document.getElementById("track4").value = fontsStaticSet[4]?.minTrack;
    }

    // Set the URLS for the  hidden fields
    if (document.getElementById("cUrl0")) {
      document.getElementById("cUrl0").textContent = fontsStaticSet[0]?.fontURL;
    }
    if (document.getElementById("cUrl1")) {
      document.getElementById("cUrl1").textContent = fontsStaticSet[1]?.fontURL;
    }
    if (document.getElementById("cUrl2")) {
      document.getElementById("cUrl2").textContent = fontsStaticSet[2]?.fontURL;
    }
    if (document.getElementById("cUrl3")) {
      document.getElementById("cUrl3").textContent = fontsStaticSet[3]?.fontURL;
    }
    if (document.getElementById("cUrl4")) {
      document.getElementById("cUrl4").textContent = fontsStaticSet[4]?.fontURL;
    }

    // Set the FontNames for the  hidden fields
    if (document.getElementById("cFname0")) {
      document.getElementById("cFname0").textContent = fontsStaticSet[0]?.fontFamily;
    }
    if (document.getElementById("cFname1")) {
      document.getElementById("cFname1").textContent = fontsStaticSet[1]?.fontFamily;
    }
    if (document.getElementById("cFname2")) {
      document.getElementById("cFname2").textContent = fontsStaticSet[2]?.fontFamily;
    }
    if (document.getElementById("cFname3")) {
      document.getElementById("cFname3").textContent = fontsStaticSet[3]?.fontFamily;
    }
    if (document.getElementById("cFname4")) {
      document.getElementById("cFname4").textContent = fontsStaticSet[4]?.fontFamily;
    }

    return () => {
      sessionStorage.removeItem("fontsSet");
      setTimeout(() => {
        dispatch(deleteFonts(successHandler, errorHandler));
      }, 2000);
    };
  }, []);

  //  default values of google dropdown
  let definedValues0 = [];
  let definedValues1 = [];
  let definedValues2 = [];
  let definedValues3 = [];

  // changed values of google dropdown
  let changedValues0 = [];
  let changedValues1 = [];
  let changedValues2 = [];
  let changedValues3 = [];

  // Get Data from the Google WEB Fonts API
  const googleFontsData = useSelector((state) => state.userReducer.GroupFonts);

  /**
   * This method is used to reset the Dynamic set1 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown0 = () => {
    setResetPlaceHolderDropdownTitle0(null);
    setResetPlaceHolderDropdown00(null);
    setResetPlaceHolderDropdown01(null);
    setResetPlaceHolderDropdown02(null);
    setResetPlaceHolderDropdown03(null);
    setResetPlaceHolderDropdown04(null);
    setResetPlaceHolderDropdown05(null);
    setResetPlaceHolderDropdown06(null);
    setResetPlaceHolderDropdown07(null);
  };

  /**
   * This method is used to reset the Dynamic set2 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown1 = () => {
    setResetPlaceHolderDropdownTitle1(null);
    setResetPlaceHolderDropdown10(null);
    setResetPlaceHolderDropdown11(null);
    setResetPlaceHolderDropdown12(null);
    setResetPlaceHolderDropdown13(null);
    setResetPlaceHolderDropdown14(null);
    setResetPlaceHolderDropdown15(null);
    setResetPlaceHolderDropdown16(null);
    setResetPlaceHolderDropdown17(null);
  };

  /**
   * This method is used to reset the Dynamic set3 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown2 = () => {
    setResetPlaceHolderDropdownTitle2(null);
    setResetPlaceHolderDropdown20(null);
    setResetPlaceHolderDropdown21(null);
    setResetPlaceHolderDropdown22(null);
    setResetPlaceHolderDropdown23(null);
    setResetPlaceHolderDropdown24(null);
    setResetPlaceHolderDropdown25(null);
    setResetPlaceHolderDropdown26(null);
    setResetPlaceHolderDropdown27(null);
  };

  /**
   * This method is used to reset the Dynamic set4 Child DropDown PlaceHolder
   */
  const resetDynamicChildDropdown3 = () => {
    setResetPlaceHolderDropdownTitle3(null);
    setResetPlaceHolderDropdown30(null);
    setResetPlaceHolderDropdown31(null);
    setResetPlaceHolderDropdown32(null);
    setResetPlaceHolderDropdown33(null);
    setResetPlaceHolderDropdown34(null);
    setResetPlaceHolderDropdown35(null);
    setResetPlaceHolderDropdown36(null);
    setResetPlaceHolderDropdown37(null);
  };

  /**
   * Used after uploading Font
   * @param {*} upresult
   */
  const onFontUpload = (upresult) => {
    setShowDialog(true);
    resetStaticData();
    setUploadDropdownChanged(1);
    let index = sessionStorage.getItem("fontsSetId");
    for (let i = 0; i < upresult.length + 1; i++) {
      if (document.getElementById(`fName${index}${i}`)) {
        document.getElementById(`fName${index}${i}`).style.fontFamily = "unset";
      }
    }
    if (document.getElementById("fDesc" + index)) {
      document.getElementById("fDesc" + index).value = "";
    }
    if (index == 0) {
      setCount0(0);
      setRegularFontSize0(32);
      fontsSetZeroData = [];
      setUploadClicked0(1);
      setResetPlaceHolder0(null);
      setFontName0(upresult[0]?.fontFamily);
      setCustomFontFiles0(upresult);
      setFontNameChildDropdown0(true);
      resetDynamicChildDropdown0();
      setResetPlaceHolderDropdownTitle0({
        id: upresult[0]?.fontURL,
        label: upresult[0]?.fontName,
        fName: upresult[0]?.fontFamily,
      });
      document.getElementById("fUrl00").textContent = upresult[0].fontURL;
      if (document.getElementById("setChanged00")) {
        document.getElementById("setChanged00").textContent = "changed";
      }
      document.getElementById("ffamily00").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily0" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    } else if (index == 1) {
      setCount1(0);
      setRegularFontSize1(32);
      fontsSetOneData = [];
      setUploadClicked1(1);
      setResetPlaceHolder1(null);
      setFontName1(upresult[0].fontFamily);
      setCustomFontFiles1(upresult);
      setFontNameChildDropdown1(true);
      resetDynamicChildDropdown1();
      setResetPlaceHolderDropdownTitle1({
        id: upresult[0]?.fontURL,
        label: upresult[0]?.fontName,
        fName: upresult[0]?.fontFamily,
      });
      document.getElementById("fUrl10").textContent = upresult[0].fontURL;
      if (document.getElementById("setChanged10")) {
        document.getElementById("setChanged10").textContent = "changed";
      }
      document.getElementById("ffamily10").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily1" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    } else if (index == 2) {
      setCount2(0);
      setRegularFontSize2(32);
      fontsSetTwoData = [];
      setUploadClicked2(1);
      setResetPlaceHolder2(null);
      setFontName2(upresult[0].fontFamily);
      setCustomFontFiles2(upresult);
      setFontNameChildDropdown2(true);
      resetDynamicChildDropdown2();
      setResetPlaceHolderDropdownTitle2({
        id: upresult[0]?.fontURL,
        label: upresult[0]?.fontName,
        fName: upresult[0]?.fontFamily,
      });
      document.getElementById("fUrl20").textContent = upresult[0].fontURL;
      if (document.getElementById("setChanged20")) {
        document.getElementById("setChanged20").textContent = "changed";
      }
      document.getElementById("ffamily20").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily2" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    } else if (index == 3) {
      setCount3(0);
      setRegularFontSize3(32);
      fontsSetThreeData = [];
      setUploadClicked3(1);
      setResetPlaceHolder3(null);
      setFontName3(upresult[0].fontFamily);
      setCustomFontFiles3(upresult);
      setFontNameChildDropdown3(true);
      resetDynamicChildDropdown3();
      setResetPlaceHolderDropdownTitle3({
        id: upresult[0]?.fontURL,
        label: upresult[0]?.fontName,
        fName: upresult[0]?.fontFamily,
      });
      document.getElementById("fUrl30").textContent = upresult[0].fontURL;
      if (document.getElementById("setChanged30")) {
        document.getElementById("setChanged30").textContent = "changed";
      }
      document.getElementById("ffamily30").textContent = upresult[0]?.fontFamily;
      for (var i = 0; i < upresult.length; i++) {
        document.getElementById("ffamily3" + Number(i + 1)).textContent = upresult[0]?.fontFamily;
      }
    }

    var fontsUrl = upresult[0].fontURL;
    var upFontFace = new FontFace(`upFont${index}`, `url(${fontsUrl})`);
    document.fonts.add(upFontFace);
    upFontFace.load();

    setTimeout(() => {
      if (document.getElementById("fName" + index + 0)) {
        document.getElementById("fName" + index + 0).textContent = upresult[0]?.fontFamily;
        document.getElementById("fName" + index + 0).style.fontFamily = `upFont${index}`;
      }
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * Call Back Method From child to Parent
   * @param {*} childdata
   */
  const childToParent = (childdata) => {
    onFontUpload(childdata);
  };

  // For set 0 preparing dropdown of child
  let googleFontFamilyOptionsData0 = [];
  var arrObj0 = Object.entries(fontFiles0); // Convert Object to Array
  // Default set configuration
  if (customFontFiles0.length == 0 && fontFiles0.length == 0) {
    for (const element of fontsSetZeroData) {
      googleFontFamilyOptionsData0.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else if (customFontFiles0.length > 0) {
    // For User Uploaded Fonts - set 0
    for (const element of customFontFiles0) {
      googleFontFamilyOptionsData0.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else {
    // For Google OnChanged Fonts - set 0
    for (const element of arrObj0) {
      googleFontFamilyOptionsData0.push({
        id: element[1].split(":")[1],
        label: fontName0 + " - " + element[0],
        fName: "",
      });
    }
  }

  // For set 1 preparing dropdown of child
  let googleFontFamilyOptionsData1 = [];
  var arrObj1 = Object.entries(fontFiles1); // Convert Object to Array
  // Default set configuration
  if (customFontFiles1.length == 0 && fontFiles1.length == 0) {
    for (const element of fontsSetOneData) {
      googleFontFamilyOptionsData1.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else if (customFontFiles1.length > 0) {
    // For User Uploaded Fonts - set 1
    for (const element of customFontFiles1) {
      googleFontFamilyOptionsData1.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else {
    // For Google OnChanged Fonts - set 1
    for (const element of arrObj1) {
      googleFontFamilyOptionsData1.push({
        id: element[1].split(":")[1],
        label: fontName1 + " - " + element[0],
        fName: "",
      });
    }
  }

  // For set 2 preparing dropdown of child
  let googleFontFamilyOptionsData2 = [];
  var arrObj2 = Object.entries(fontFiles2); // Convert Object to Array
  // Default set configuration
  if (customFontFiles2.length == 0 && fontFiles2.length == 0) {
    for (const element of fontsSetTwoData) {
      googleFontFamilyOptionsData2.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else if (customFontFiles2.length > 0) {
    // For User Uploaded Fonts - set 0
    for (const element of customFontFiles2) {
      googleFontFamilyOptionsData2.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else {
    // For Google OnChanged Fonts - set 2
    for (const element of arrObj2) {
      googleFontFamilyOptionsData2.push({
        id: element[1].split(":")[1],
        label: fontName2 + " - " + element[0],
        fName: "",
      });
    }
  }

  // For set 3 preparing dropdown of child
  let googleFontFamilyOptionsData3 = [];
  var arrObj3 = Object.entries(fontFiles3); // Convert Object to Array
  // Default Configuration
  if (customFontFiles3.length == 0 && fontFiles3.length == 0) {
    for (const element of fontsSetThreeData) {
      googleFontFamilyOptionsData3.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else if (customFontFiles3.length > 0) {
    // For User Uploaded Fonts - set 3
    for (const element of customFontFiles3) {
      googleFontFamilyOptionsData3.push({
        id: element.fontURL,
        label: element.fontName,
        fName: element.fontFamily,
      });
    }
  } else {
    // For Google OnChanged Fonts - set 3
    for (const element of arrObj3) {
      googleFontFamilyOptionsData3.push({
        id: element[1].split(":")[1],
        label: fontName3 + " - " + element[0],
        fName: "",
      });
    }
  }

  googleFontFamilyOptionsData0 = Array.from(new Set(googleFontFamilyOptionsData0.map(JSON.stringify))).map(JSON.parse);
  googleFontFamilyOptionsData1 = Array.from(new Set(googleFontFamilyOptionsData1.map(JSON.stringify))).map(JSON.parse);
  googleFontFamilyOptionsData2 = Array.from(new Set(googleFontFamilyOptionsData2.map(JSON.stringify))).map(JSON.parse);
  googleFontFamilyOptionsData3 = Array.from(new Set(googleFontFamilyOptionsData3.map(JSON.stringify))).map(JSON.parse);

  const googleFontFamilyOptionsFinalData = [];
  var arrObj4 = [
    ...googleFontFamilyOptionsData0,
    ...googleFontFamilyOptionsData1,
    ...googleFontFamilyOptionsData2,
    ...googleFontFamilyOptionsData3,
  ];
  // Convert Object to Array
  for (const element of arrObj4) {
    googleFontFamilyOptionsFinalData.push({
      id: element.id,
      label: element.label,
      fName: element.fName,
    });
  }

  // Based on the Google WEB Fonts API response Prepare the Dropdown
  const googleFontsOptionsZeroData = [];
  const googleFontsOptionsOneData = [];
  const googleFontsOptionsTwoData = [];
  const googleFontsOptionsThreeData = [];

  if (googleDropdownChanged == 0 || uploadDropdownChanged == 0) {
    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName1 &&
        googleFontsData[i].family != fontName2 &&
        googleFontsData[i].family != fontName3 &&
        googleFontsData[i].family != fontsSetOneData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetTwoData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetThreeData[0]?.fontFamily
      ) {
        googleFontsOptionsZeroData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }

    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName0 &&
        googleFontsData[i].family != fontName2 &&
        googleFontsData[i].family != fontName3 &&
        googleFontsData[i].family != fontsSetZeroData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetTwoData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetThreeData[0]?.fontFamily
      ) {
        googleFontsOptionsOneData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }

    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName0 &&
        googleFontsData[i].family != fontName1 &&
        googleFontsData[i].family != fontName3 &&
        googleFontsData[i].family != fontsSetZeroData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetOneData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetThreeData[0]?.fontFamily
      ) {
        googleFontsOptionsTwoData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }

    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName0 &&
        googleFontsData[i].family != fontName1 &&
        googleFontsData[i].family != fontName2 &&
        googleFontsData[i].family != fontsSetZeroData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetOneData[0]?.fontFamily &&
        googleFontsData[i].family != fontsSetTwoData[0]?.fontFamily
      ) {
        googleFontsOptionsThreeData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }
  } else if (googleDropdownChanged == 1 || uploadDropdownChanged == 1) {
    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName1 &&
        googleFontsData[i].family != fontName2 &&
        googleFontsData[i].family != fontName3
      ) {
        googleFontsOptionsZeroData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }

    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName0 &&
        googleFontsData[i].family != fontName2 &&
        googleFontsData[i].family != fontName3
      ) {
        googleFontsOptionsOneData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }

    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName0 &&
        googleFontsData[i].family != fontName1 &&
        googleFontsData[i].family != fontName3
      ) {
        googleFontsOptionsTwoData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }

    for (let i = 0; i < googleFontsData.length; i++) {
      if (
        googleFontsData[i].family != fontName0 &&
        googleFontsData[i].family != fontName1 &&
        googleFontsData[i].family != fontName2
      ) {
        googleFontsOptionsThreeData.push({
          value: i,
          label: googleFontsData[i].family,
          files: googleFontsData[i].files,
        });
      }
    }
  }

  // Set Dynamic HTML Content to the array based used selected configuration
  const fontsConfigSetsHTMLDivs = [];

  // Set Dynamic HTML Content to the array based used selected configuration
  const fontFamilySelectionHTMLDivsZero = [];
  const fontFamilySelectionHTMLDivsOne = [];
  const fontFamilySelectionHTMLDivsTwo = [];
  const fontFamilySelectionHTMLDivsThree = [];

  // preparing array for selected child dropdowns of 4 sets
  let finalAllSelectedDropdown = [];
  // for set1 Child Dropdown selected options
  if (resetPlaceHolderDropdown00 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown00);
  }
  if (resetPlaceHolderDropdown01 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown01);
  }
  if (resetPlaceHolderDropdown02 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown02);
  }
  if (resetPlaceHolderDropdown03 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown03);
  }
  if (resetPlaceHolderDropdown04 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown04);
  }
  if (resetPlaceHolderDropdown05 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown05);
  }
  if (resetPlaceHolderDropdown06 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown06);
  }
  if (resetPlaceHolderDropdown07 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown07);
  }

  // for set2 Child Dropdown selected options
  if (resetPlaceHolderDropdown10 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown10);
  }
  if (resetPlaceHolderDropdown11 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown11);
  }
  if (resetPlaceHolderDropdown12 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown12);
  }
  if (resetPlaceHolderDropdown13 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown13);
  }
  if (resetPlaceHolderDropdown14 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown14);
  }
  if (resetPlaceHolderDropdown15 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown15);
  }
  if (resetPlaceHolderDropdown16 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown16);
  }
  if (resetPlaceHolderDropdown17 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown17);
  }

  // for set3 Child Dropdown selected options
  if (resetPlaceHolderDropdown20 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown20);
  }
  if (resetPlaceHolderDropdown21 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown21);
  }
  if (resetPlaceHolderDropdown22 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown22);
  }
  if (resetPlaceHolderDropdown23 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown23);
  }
  if (resetPlaceHolderDropdown24 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown24);
  }
  if (resetPlaceHolderDropdown25 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown25);
  }
  if (resetPlaceHolderDropdown26 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown26);
  }
  if (resetPlaceHolderDropdown27 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown27);
  }

  // for set4 Child Dropdown selected options
  if (resetPlaceHolderDropdown30 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown30);
  }
  if (resetPlaceHolderDropdown31 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown31);
  }
  if (resetPlaceHolderDropdown32 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown32);
  }
  if (resetPlaceHolderDropdown33 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown33);
  }
  if (resetPlaceHolderDropdown34 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown34);
  }
  if (resetPlaceHolderDropdown35 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown35);
  }
  if (resetPlaceHolderDropdown36 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown36);
  }
  if (resetPlaceHolderDropdown37 != null) {
    finalAllSelectedDropdown.push(resetPlaceHolderDropdown37);
  }

  // convert to id,label format
  const arrayOfSelectedDropdown = [];
  for (const element of finalAllSelectedDropdown) {
    arrayOfSelectedDropdown.push({
      id: element.id,
      label: element.label,
      fName: element.fName,
    });
  }

  let set0SelectedArray = [];
  let set1SelectedArray = [];
  let set2SelectedArray = [];
  let set3SelectedArray = [];

  // for set0 selected weights
  if (resetPlaceHolderDropdownTitle0 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdownTitle0);
  }
  if (resetPlaceHolderDropdown00 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown00);
  }
  if (resetPlaceHolderDropdown01 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown01);
  }
  if (resetPlaceHolderDropdown02 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown02);
  }
  if (resetPlaceHolderDropdown03 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown03);
  }
  if (resetPlaceHolderDropdown04 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown04);
  }
  if (resetPlaceHolderDropdown05 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown05);
  }
  if (resetPlaceHolderDropdown06 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown06);
  }
  if (resetPlaceHolderDropdown07 != null) {
    set0SelectedArray.push(resetPlaceHolderDropdown07);
  }

  // for set1 selected weights
  if (resetPlaceHolderDropdownTitle1 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdownTitle1);
  }
  if (resetPlaceHolderDropdown10 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown10);
  }
  if (resetPlaceHolderDropdown11 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown11);
  }
  if (resetPlaceHolderDropdown12 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown12);
  }
  if (resetPlaceHolderDropdown13 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown13);
  }
  if (resetPlaceHolderDropdown14 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown14);
  }
  if (resetPlaceHolderDropdown15 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown15);
  }
  if (resetPlaceHolderDropdown16 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown16);
  }
  if (resetPlaceHolderDropdown17 != null) {
    set1SelectedArray.push(resetPlaceHolderDropdown17);
  }

  // for set2 selected weights
  if (resetPlaceHolderDropdownTitle2 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdownTitle2);
  }
  if (resetPlaceHolderDropdown20 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown20);
  }
  if (resetPlaceHolderDropdown21 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown21);
  }
  if (resetPlaceHolderDropdown22 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown22);
  }
  if (resetPlaceHolderDropdown23 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown23);
  }
  if (resetPlaceHolderDropdown24 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown24);
  }
  if (resetPlaceHolderDropdown25 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown25);
  }
  if (resetPlaceHolderDropdown26 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown26);
  }
  if (resetPlaceHolderDropdown27 != null) {
    set2SelectedArray.push(resetPlaceHolderDropdown27);
  }

  // for set3 selected weights
  if (resetPlaceHolderDropdownTitle3 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdownTitle3);
  }
  if (resetPlaceHolderDropdown30 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown30);
  }
  if (resetPlaceHolderDropdown31 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown31);
  }
  if (resetPlaceHolderDropdown32 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown32);
  }
  if (resetPlaceHolderDropdown33 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown33);
  }
  if (resetPlaceHolderDropdown34 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown34);
  }
  if (resetPlaceHolderDropdown35 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown35);
  }
  if (resetPlaceHolderDropdown36 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown36);
  }
  if (resetPlaceHolderDropdown37 != null) {
    set3SelectedArray.push(resetPlaceHolderDropdown37);
  }

  // concating google fonts regular and child dropdown
  let finalUniqueSelectedDropdown = [...arrayOfSelectedDropdown];

  // filtering the array without undefined
  const filterFinal = finalUniqueSelectedDropdown.filter((item) => item.id != undefined);

  // adding the selected Dropdown values when child Dropdown changed
  const letters = new Set();

  // Add Values to the Set of Set0
  if (resetPlaceHolderDropdown00 != null) {
    letters.add(resetPlaceHolderDropdown00);
  }
  if (resetPlaceHolderDropdown01 != null) {
    letters.add(resetPlaceHolderDropdown01);
  }
  if (resetPlaceHolderDropdown02 != null) {
    letters.add(resetPlaceHolderDropdown02);
  }
  if (resetPlaceHolderDropdown03 != null) {
    letters.add(resetPlaceHolderDropdown03);
  }
  if (resetPlaceHolderDropdown04 != null) {
    letters.add(resetPlaceHolderDropdown04);
  }
  if (resetPlaceHolderDropdown05 != null) {
    letters.add(resetPlaceHolderDropdown05);
  }
  if (resetPlaceHolderDropdown06 != null) {
    letters.add(resetPlaceHolderDropdown06);
  }
  if (resetPlaceHolderDropdown07 != null) {
    letters.add(resetPlaceHolderDropdown07);
  }

  // Add Values to the Set of Set1
  if (resetPlaceHolderDropdown10 != null) {
    letters.add(resetPlaceHolderDropdown10);
  }
  if (resetPlaceHolderDropdown11 != null) {
    letters.add(resetPlaceHolderDropdown11);
  }
  if (resetPlaceHolderDropdown12 != null) {
    letters.add(resetPlaceHolderDropdown12);
  }
  if (resetPlaceHolderDropdown13 != null) {
    letters.add(resetPlaceHolderDropdown13);
  }
  if (resetPlaceHolderDropdown14 != null) {
    letters.add(resetPlaceHolderDropdown14);
  }
  if (resetPlaceHolderDropdown15 != null) {
    letters.add(resetPlaceHolderDropdown15);
  }
  if (resetPlaceHolderDropdown16 != null) {
    letters.add(resetPlaceHolderDropdown16);
  }
  if (resetPlaceHolderDropdown17 != null) {
    letters.add(resetPlaceHolderDropdown17);
  }

  // Add Values to the Set of Set2
  if (resetPlaceHolderDropdown20 != null) {
    letters.add(resetPlaceHolderDropdown20);
  }
  if (resetPlaceHolderDropdown21 != null) {
    letters.add(resetPlaceHolderDropdown21);
  }
  if (resetPlaceHolderDropdown22 != null) {
    letters.add(resetPlaceHolderDropdown22);
  }
  if (resetPlaceHolderDropdown23 != null) {
    letters.add(resetPlaceHolderDropdown23);
  }
  if (resetPlaceHolderDropdown24 != null) {
    letters.add(resetPlaceHolderDropdown24);
  }
  if (resetPlaceHolderDropdown25 != null) {
    letters.add(resetPlaceHolderDropdown25);
  }
  if (resetPlaceHolderDropdown26 != null) {
    letters.add(resetPlaceHolderDropdown26);
  }
  if (resetPlaceHolderDropdown27 != null) {
    letters.add(resetPlaceHolderDropdown27);
  }

  // Add Values to the Set of Set3
  if (resetPlaceHolderDropdown30 != null) {
    letters.add(resetPlaceHolderDropdown30);
  }
  if (resetPlaceHolderDropdown31 != null) {
    letters.add(resetPlaceHolderDropdown31);
  }
  if (resetPlaceHolderDropdown32 != null) {
    letters.add(resetPlaceHolderDropdown32);
  }
  if (resetPlaceHolderDropdown33 != null) {
    letters.add(resetPlaceHolderDropdown33);
  }
  if (resetPlaceHolderDropdown34 != null) {
    letters.add(resetPlaceHolderDropdown34);
  }
  if (resetPlaceHolderDropdown35 != null) {
    letters.add(resetPlaceHolderDropdown35);
  }
  if (resetPlaceHolderDropdown36 != null) {
    letters.add(resetPlaceHolderDropdown36);
  }
  if (resetPlaceHolderDropdown37 != null) {
    letters.add(resetPlaceHolderDropdown37);
  }

  const letters1 = [...new Set(letters)];

  // filtering the selected array without undefined
  var filtered = letters1.filter((item) => item.id != undefined || item.label != undefined || item.fName != undefined);

  // UniqueFinal Dropdown Of selected Fonts
  let finalAllSelectedUniqueDropdown = [];

  if (childDropdownChanged == 0) {
    // default dropDown Options
    finalAllSelectedUniqueDropdown = Array.from(new Set(filterFinal.map(JSON.stringify))).map(JSON.parse);
  } else {
    // DropDown Options when ChildDropDown Changed
    let selectedArray = Array.from(new Set(filtered.map(JSON.stringify))).map(JSON.parse);
    // filtering the options without empty values
    finalAllSelectedUniqueDropdown = selectedArray.filter((item) => item != {});
  }

  /**
   * Service Error Handler
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * Service Error Handler
   */
  const errorHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method is used to reset the Static DropDown Options and fontFamily when any changes done
   */
  const resetStaticData = () => {
    setSelectedZeroOption(null);
    setSelectedOneOption(null);
    setSelectedTwoOption(null);
    setSelectedThreeOption(null);
    setSelectedFourOption(null);

    if (document.getElementById("title")) {
      document.getElementById("title").style.fontFamily = "unset";
    }

    if (document.getElementById("headTitle")) {
      document.getElementById("headTitle").style.fontFamily = "unset";
    }

    if (document.getElementById("subHeading1")) {
      document.getElementById("subHeading1").style.fontFamily = "unset";
    }

    if (document.getElementById("subHeading2")) {
      document.getElementById("subHeading2").style.fontFamily = "unset";
    }

    if (document.getElementById("caption")) {
      document.getElementById("caption").style.fontFamily = "unset";
    }
  };

  /**
   * Used in Google Font Change
   * @param {*} e
   * @param {*} index
   */
  const onFontChange = (e, index) => {
    setShowDialog(true);
    resetStaticData();

    var totalFontObj = Object.entries(e.files); // Convert Object to Array
    let fonts = [];
    for (const element of totalFontObj) {
      fonts.push({
        id: element[1],
        label: fontName3 + " - " + element[0],
      });
    }
    for (let i = 1; i < fonts.length + 1; i++) {
      if (document.getElementById(`fName${index}${i}`)) {
        document.getElementById(`fName${index}${i}`).style.fontFamily = "unset";
      }
    }
    setGoogleDropdownChanged(1);
    setDisplayLoader(true);

    let filteredUrl = "";
    let filterArray = [];
    if (document.getElementById("fGDropdown" + index)) {
      document.getElementById("fGDropdown" + index).style.border = "1px solid hsl(0, 0%, 80%)";
      document.getElementById("fGDropdown" + index).style.borderRadius = "4px";
    }

    if (document.getElementById("fDesc" + index)) {
      document.getElementById("fDesc" + index).value = "";
    }

    if (index == 0) {
      fontsSetZeroData = [];
      setCustomFontFiles0([]);
      setFontName0(e.label);
      setFontFiles0(e.files);
      setFontNameChildDropdown0(true);
      setCount0(0);
      setRegularFontSize0(32);
      setResetPlaceHolder0({
        id: e.files,
        label: e.label,
      });
      resetDynamicChildDropdown0();
      setResetPlaceHolderDropdownTitle0({
        id: e.files,
        label: e.label + " - " + "regular",
      });
    } else if (index == 1) {
      fontsSetOneData = [];
      setCustomFontFiles1([]);
      setFontName1(e.label);
      setFontFiles1(e.files);
      setFontNameChildDropdown1(true);
      setCount1(0);
      setRegularFontSize1(32);
      setResetPlaceHolder1({
        id: e.files,
        label: e.label,
      });
      resetDynamicChildDropdown1();
      setResetPlaceHolderDropdownTitle1({
        id: e.files,
        label: e.label + " - " + "regular",
      });
    } else if (index == 2) {
      fontsSetTwoData = [];
      setCustomFontFiles2([]);
      setFontName2(e.label);
      setFontFiles2(e.files);
      setFontNameChildDropdown2(true);
      setCount2(0);
      setRegularFontSize2(32);
      setResetPlaceHolder2({
        id: e.files,
        label: e.label,
      });
      resetDynamicChildDropdown2();
      setResetPlaceHolderDropdownTitle2({
        id: e.files,
        label: e.label + " - " + "regular",
      });
    } else if (index == 3) {
      fontsSetThreeData = [];
      setCustomFontFiles3([]);
      setFontName3(e.label);
      setFontFiles3(e.files);
      setFontNameChildDropdown3(true);
      setCount3(0);
      setRegularFontSize3(32);
      setResetPlaceHolder3({
        id: e.files,
        label: e.label,
      });
      resetDynamicChildDropdown3();
      setResetPlaceHolderDropdownTitle3({
        id: e.files,
        label: e.label + " - " + "regular",
      });
    }

    var arrayData = Object.entries(e.files);
    for (const element of arrayData) {
      filterArray.push({
        id: element[1].split(":")[1],
        label: element[0],
      });
    }
    filteredUrl = filterArray.filter((x) => x.label == "regular");

    var fontsUrl = filteredUrl[0].id;
    var upFontFace = new FontFace(`upFont${index}`, `url(${fontsUrl})`);
    document.fonts.add(upFontFace);
    upFontFace.load();

    setTimeout(() => {
      if (document.getElementById("fName" + index + 0)) {
        document.getElementById("fName" + index + 0).textContent = e.label;
        document.getElementById("fName" + index + 0).style.fontFamily = `upFont${index}`;
      }
      if (document.getElementById("fUrl" + index + 0)) {
        document.getElementById("fUrl" + index + 0).textContent = filteredUrl[0].id;
      }
      if (document.getElementById(`setChanged${index}0`)) {
        document.getElementById(`setChanged${index}0`).textContent = "changed";
      }
      setDisplayLoader(false);
    }, 1000);
  };

  /**
   * Used in Google Font Family Weights Change
   * @param {*} event
   * @param {*} index
   * @param {*} selectedControlId
   */
  const onFontFamilyChange = (event, index, selectedControlId) => {
    setShowDialog(true);
    document.getElementById("ffamily" + selectedControlId + index).textContent = event.fName;
    setChildDropdownChanged(1);
    resetStaticData();
    // resetting placeholder to selected option for set0
    if (selectedControlId == 0) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
        }
        setResetPlaceHolderDropdownTitle0({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 1) {
        setResetPlaceHolderDropdown00({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 2) {
        setResetPlaceHolderDropdown01({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 3) {
        setResetPlaceHolderDropdown02({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 4) {
        setResetPlaceHolderDropdown03({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 5) {
        setResetPlaceHolderDropdown04({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 6) {
        setResetPlaceHolderDropdown05({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 7) {
        setResetPlaceHolderDropdown06({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 8) {
        setResetPlaceHolderDropdown07({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      }
    }
    // resetting placeholder to selected option for set1
    else if (selectedControlId == 1) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle1({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 1) {
        setResetPlaceHolderDropdown10({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 2) {
        setResetPlaceHolderDropdown11({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 3) {
        setResetPlaceHolderDropdown12({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 4) {
        setResetPlaceHolderDropdown13({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 5) {
        setResetPlaceHolderDropdown14({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 6) {
        setResetPlaceHolderDropdown15({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 7) {
        setResetPlaceHolderDropdown16({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 8) {
        setResetPlaceHolderDropdown17({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      }
    }
    // resetting placeholder to selected option for set2
    else if (selectedControlId == 2) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle2({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 1) {
        setResetPlaceHolderDropdown20({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 2) {
        setResetPlaceHolderDropdown21({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 3) {
        setResetPlaceHolderDropdown22({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 4) {
        setResetPlaceHolderDropdown23({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 5) {
        setResetPlaceHolderDropdown24({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 6) {
        setResetPlaceHolderDropdown25({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 7) {
        setResetPlaceHolderDropdown26({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 8) {
        setResetPlaceHolderDropdown27({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      }
    }
    // resetting placeholder to selected option for set3
    else if (selectedControlId == 3) {
      if (index == 0) {
        if (document.getElementById("fName" + selectedControlId + index)) {
          if (event.fName == "") {
            document.getElementById("fName" + selectedControlId + index).textContent = event.label.split("-")[0];
          } else {
            document.getElementById("fName" + selectedControlId + index).textContent = event.fName;
          }
          document.getElementById(
            "fName" + selectedControlId + index
          ).style.fontFamily = `upFont${selectedControlId}${index}`;
        }
        setResetPlaceHolderDropdownTitle3({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 1) {
        setResetPlaceHolderDropdown30({
          label: event.label,
          value: event.id,
          fName: event.fName,
        });
      } else if (index == 2) {
        setResetPlaceHolderDropdown31({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 3) {
        setResetPlaceHolderDropdown32({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 4) {
        setResetPlaceHolderDropdown33({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 5) {
        setResetPlaceHolderDropdown34({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 6) {
        setResetPlaceHolderDropdown35({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 7) {
        setResetPlaceHolderDropdown36({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      } else if (index == 8) {
        setResetPlaceHolderDropdown37({
          id: event.id,
          label: event.label,
          fName: event.fName,
        });
      }
    }

    var fontsUrl = event.id;
    var upFontFace = new FontFace(`upFont${selectedControlId}${index}`, `url(${fontsUrl})`);
    document.fonts.add(upFontFace);
    upFontFace.load();

    if (index > 0) {
      if (document.getElementById("fName" + selectedControlId + index)) {
        document.getElementById("fName" + selectedControlId + index).textContent = event.label;
        document.getElementById(
          "fName" + selectedControlId + index
        ).style.fontFamily = `upFont${selectedControlId}${index}`;

        var doc = document.getElementById("fDropdown" + selectedControlId + index);
        doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
      }
    }

    if (document.getElementById("fUrl" + selectedControlId + index)) {
      document.getElementById("fUrl" + selectedControlId + index).textContent = event.id;
    }
  };
  /**
   * Used in Font Family Title Change
   * @param {*} event
   */
  const onFontFamilyTitleChange = (event) => {
    setShowDialog(true);
    setSelectedZeroOption({
      id: event.id,
      label: event.label,
      fName: event.fName,
    });

    if (document.getElementById("title")) {
      if (document.getElementById("cUrl0")) {
        document.getElementById("cUrl0").textContent = event.id;
      }
      if (document.getElementById("cFname0")) {
        document.getElementById("cFname0").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontTitle`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("title").style.fontFamily = `upFontTitle`;
      // document.getElementById("definedDropdown0").style.border =
      //   "0px solid #cccccc";
      var doc = document.getElementById("definedDropdown0");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family HeadLine Change
   * @param {*} event
   */
  const onFontFamilyHeadLineChange = (event) => {
    setShowDialog(true);
    setSelectedOneOption({
      id: event.id,
      label: event.label,
      fName: event.fName,
    });

    if (document.getElementById("headTitle")) {
      if (document.getElementById("cUrl1")) {
        document.getElementById("cUrl1").textContent = event.id;
      }
      if (document.getElementById("cFname1")) {
        document.getElementById("cFname1").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontHeadTitle`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("headTitle").style.fontFamily = `upFontHeadTitle`;
      // document.getElementById("definedDropdown1").style.border =
      //   "0px solid #cccccc";
      var doc = document.getElementById("definedDropdown1");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family Sub Heading1 Change
   * @param {*} event
   */
  const onFontFamilySubHeadingChangeH3 = (event) => {
    setShowDialog(true);
    setSelectedTwoOption({
      id: event.id,
      label: event.label,
      fName: event.fName,
    });
    if (document.getElementById("subHeading1")) {
      if (document.getElementById("cUrl2")) {
        document.getElementById("cUrl2").textContent = event.id;
      }
      if (document.getElementById("cFname2")) {
        document.getElementById("cFname2").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontSubHeading1`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("subHeading1").style.fontFamily = `upFontSubHeading1`;
      // document.getElementById("definedDropdown2").style.border =
      //   "0px solid #cccccc";
      var doc = document.getElementById("definedDropdown2");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family Sub Heading2 Change
   * @param {*} event
   */
  const onFontFamilySubHeadingChangeH4 = (event) => {
    setShowDialog(true);
    setSelectedThreeOption({
      id: event.id,
      label: event.label,
      fName: event.fName,
    });
    if (document.getElementById("subHeading2")) {
      if (document.getElementById("cUrl3")) {
        document.getElementById("cUrl3").textContent = event.id;
      }
      if (document.getElementById("cFname3")) {
        document.getElementById("cFname3").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`upFontSubHeading2`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("subHeading2").style.fontFamily = `upFontSubHeading2`;
      // document.getElementById("definedDropdown3").style.border =
      //   "0px solid #cccccc";
      var doc = document.getElementById("definedDropdown3");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Used in Font Family Caption Change
   * @param {*} event
   */
  const onFontFamilyCaptionChange = (event) => {
    setShowDialog(true);
    setSelectedFourOption({
      id: event.id,
      label: event.label,
      fName: event.fName,
    });
    if (document.getElementById("caption")) {
      if (document.getElementById("cUrl4")) {
        document.getElementById("cUrl4").textContent = event.id;
      }
      if (document.getElementById("cFname4")) {
        document.getElementById("cFname4").textContent = event.fName;
      }
      var fontsUrl = event.id;
      var upFontFace = new FontFace(`caption`, `url(${fontsUrl})`);
      document.fonts.add(upFontFace);
      upFontFace.load();
      document.getElementById("caption").style.fontFamily = `caption`;
      // document.getElementById("definedDropdown4").style.border =
      //   "0px solid #cccccc";
      var doc = document.getElementById("definedDropdown4");
      doc.getElementsByTagName("div")[0].style.border = "1px solid hsl(0, 0%, 80%)";
    }
  };

  /**
   * Update Fonts Sets by selection the font files
   * @param {*} event
   */
  const showFontsModal = (event) => {
    sessionStorage.setItem("fontsSetId", event.target.id.split("-")[1]);
    setShowFonts(true);
  };

  /**
   * Used to update the font size for the regular font family
   * @param {*} e
   * @param {*} index
   * @param {*} selectedControlId
   * @return {*} 
   */
  const onBlurFontSizeForRegular = (e, index, selectedControlId) => {
    if (e.target.value.trim() != "") {
      if (e.target.value < 8) {
        document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #ff8181";
        return <div className="warningMsg">{toast.warning("Font Size must be grater than 8pt")}</div>;
      } else {
        document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #e5e4e4";
        document.getElementById("fName" + selectedControlId + index).style.fontSize = e.target.value + "pt";
      }
    } else {
      document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #e5e4e4";
      document.getElementById("fSize" + selectedControlId + index).value = "";
      document.getElementById("fSize" + selectedControlId + index).setAttribute("placeholder", "32");
    }
  };

  /**
   * Used to update the font family for the Description font family
   * @param {*} e
   * @param {*} index
   */
  const onChangeFontFamilyForDescription = (e, index) => {
    setShowDialog(true);
    if (index == 0) {
      setCount0(e.target.value.length);
    } else if (index == 1) {
      setCount1(e.target.value.length);
    } else if (index == 2) {
      setCount2(e.target.value.length);
    } else if (index == 3) {
      setCount3(e.target.value.length);
    }
    if (document.getElementById("fDesc" + index)) {
      if (document.getElementById("fDesc" + index).value.trim().length >= 50) {
        document.getElementById("fDesc" + index).style.border = "1px solid #e5e4e4";
        document.getElementById("errDesc" + index).textContent = "";
      } else {
        document.getElementById("fDesc" + index).style.border = "1px solid #ff8181";
        document.getElementById("errDesc" + index).textContent =
          "Description Length must be between 50 to 500 characters";
        document.getElementById("errDesc" + index).style.color = "#ff8181";
        document.getElementById("errDesc" + index).style.fontSize = "11px";
      }
    }
  };

  /**
   * Used to update the font family for the Description font family
   * @param {*} index
   */
  const onBlurFontFamilyForDescription = (index) => {
    if (document.getElementById("fDesc" + index)) {
      if (document.getElementById("fDesc" + index).value.trim().length >= 50) {
        document.getElementById("fDesc" + index).style.border = "1px solid #e5e4e4";
        document.getElementById("errDesc" + index).textContent = "";
      } else if (document.getElementById("fDesc" + index).value.trim().length == 0) {
        document.getElementById("fDesc" + index).value = "";
      } else {
        document.getElementById("fDesc" + index).style.border = "1px solid #ff8181";
        document.getElementById("errDesc" + index).textContent =
          "Description Length must be between 50 to 500 characters";
        document.getElementById("errDesc" + index).style.color = "#ff8181";
        document.getElementById("errDesc" + index).style.fontSize = "11px";
      }
    }
  };

  /**
   * Used to update the font size for the other than regular font family
   * @param {*} e
   * @param {*} selectedControlId
   * @param {*} index
   * @return {*} 
   */
  const onBlurFontSizeForOtherThanRegular = (e, selectedControlId, index) => {
    if (e.target.value.trim() != "") {
      if (e.target.value < 8) {
        document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #ff8181";
        return <div className="warningMsg">{toast.warning("Font Size must be grater than 8pt")}</div>;
      } else {
        document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #e5e4e4";
        document.getElementById("fName" + selectedControlId + index).style.fontSize = e.target.value + "pt";
      }
    } else {
      document.getElementById("fSize" + selectedControlId + index).style.border = "1px solid #e5e4e4";
      document.getElementById("fSize" + selectedControlId + index).value = "";
      document.getElementById("fSize" + selectedControlId + index).setAttribute("placeholder", "26");
    }
  };

  /**
   * Used to update the Font Size for the pre defined tags as Title, Heading, Sub Heading and Captions
   * @param {*} e
   * @param {*} id
   * @param {*} index
   * @return {*} 
   */
  const onBlurFontSize = (e, id, index) => {
    if (e.target.value != "") {
      if (e.target.value < 8) {
        document.getElementById(e.target.id).style.border = "1px solid #ff8181";
        return <div className="warningMsg">{toast.warning("Font Size must be greater than 8pt")}</div>;
      } else if (e.target.value >= 8) {
        document.getElementById(e.target.id).style.border = "1px solid #cccccc";
        if (document.getElementById(id)) document.getElementById(id).style.fontSize = e.target.value + "pt";
        if (document.getElementById("lead" + index))
          document.getElementById("lead" + index).value = Number(e.target.value) + 1;
      }
    }
  };

  /**
   * Used to update the Line Height for the pre defined tags as Title, Heading, Sub Heading and Captions
   * @param {*} e
   * @param {*} id
   * @param {*} index
   * @return {*} 
   */
  const onBlurLeading = (e, id, index) => {
    if (document.getElementById("definedSize" + index)) {
      if (document.getElementById("definedSize" + index).value) {
        let fontSize = document.getElementById("definedSize" + index).value;
        if (e.target.value != "") {
          if (Number(fontSize) >= Number(e.target.value)) {
            document.getElementById(e.target.id).style.border = "1px solid #ff8181";
            return <div className="warningMsg">{toast.warning("Leading Value Must be greater than Size Value")}</div>;
          } else {
            document.getElementById(id).style.lineHeight = e.target.value + "pt";
            document.getElementById(e.target.id).style.border = "1px solid #cccccc";
            if (document.getElementById("definedSize" + index).value > 8) {
              document.getElementById("definedSize" + index).style.border = "1px solid #cccccc";
            }
            document.getElementById(id).style.fontSize = document.getElementById("definedSize" + index).value + "pt";
          }
        }
      } else {
        return <div className="warningMsg">{toast.warning("Font Size can not be empty")}</div>;
      }
    }
  };

  /**
   * Used to update the Letter Spacing for the pre defined tags as Title, Heading, Sub Heading and Captions
   * @param {*} e
   * @param {*} id
   */
  const onBlurMinTrack = (e, id) => {
    if (document.getElementById(id)) {
      if (document.getElementById(e.target.id).value != "") {
        if (document.getElementById(e.target.id).value >= 0) {
          document.getElementById(id).style.letterSpacing = e.target.value + "pt";
          document.getElementById(e.target.id).style.border = "1px solid #cccccc";
        } else {
          document.getElementById(id).style.letterSpacing = "0px";
          document.getElementById(e.target.id).style.border = "1px solid #ff8181";
        }
      } else {
        document.getElementById(id).style.letterSpacing = "0px";
      }
    }
  };

  /**
   * Used to validate the input fields
   * @return {*}
   */
  const validateControls = () => {
    // Loop based on Fonts Configuration Count
    for (let configs = 0; configs < fontsSet; configs++) {
      // After that Loop based on inner Font Weights Count
      var weightsLength = 0;
      if (configs == 0) {
        weightsLength = googleFontFamilyOptionsData0.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData0.length;
        }
      } else if (configs == 1) {
        weightsLength = googleFontFamilyOptionsData1.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData1.length;
        }
      } else if (configs == 2) {
        weightsLength = googleFontFamilyOptionsData2.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData2.length;
        }
      } else if (configs == 3) {
        weightsLength = googleFontFamilyOptionsData3.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData3.length;
        }
      }

      // These are the above dynamic fonts
      for (let weights = 0; weights < weightsLength; weights++) {
        if (document.getElementById("fDesc" + configs).value == "") {
          document.getElementById("fDesc" + configs).style.border = "1px solid #ff8181";
        }
      }

      // These are the below static controls
      for (let index = 0; index < 5; index++) {
        if (document.getElementById("definedDropdown" + index)) {
          if (document.getElementById("definedDropdown" + index).textContent == "Please Select Font") {
            var doc = document.getElementById("definedDropdown" + index);
            doc.getElementsByTagName("div")[0].style.borderColor = "#ff8181";
            document.getElementById("definedDropdown" + index).style.borderRadius = "4px";
          }
        }

        if (document.getElementById("definedSize" + index).value) {
          if (
            Number(document.getElementById("definedSize" + index).value) >=
            Number(document.getElementById("lead" + index).value)
          ) {
            document.getElementById("lead" + index).style.border = "1px solid #ff8181";
          } else {
            document.getElementById("lead" + index).style.border = "1px solid #e5e4e4";
          }
        } else {
          if (document.getElementById("lead" + index).value) {
            document.getElementById("definedSize" + index).style.border = "1px solid #ff8181";
          }
        }
      }
    }
  };

  /**
   * Used to validate the input fields
   * @return {*}
   */
  const validate = () => {
    // Loop based on Fonts Configuration Count
    for (let configs = 0; configs < fontsSet; configs++) {
      // After that Loop based on inner Font Weights Count
      var weightsLength = 0;
      if (configs == 0) {
        weightsLength = googleFontFamilyOptionsData0.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData0.length;
        }
      } else if (configs == 1) {
        weightsLength = googleFontFamilyOptionsData1.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData1.length;
        }
      } else if (configs == 2) {
        weightsLength = googleFontFamilyOptionsData2.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData2.length;
        }
      } else if (configs == 3) {
        weightsLength = googleFontFamilyOptionsData3.length;
        if (weightsLength > 8) {
          weightsLength = 8;
        } else {
          weightsLength = googleFontFamilyOptionsData3.length;
        }
      }

      // These are the above dynamic fonts
      for (let weights = 0; weights < weightsLength; weights++) {
        if (document.getElementById("fDesc" + configs).value.length < 50) {
          return "true";
        }
      }

      for (let weights = 0; weights < weightsLength; weights++) {
        if (document.getElementById("fSize" + configs + weights)) {
          if (document.getElementById("fSize" + configs + weights).value != "") {
            if (document.getElementById("fSize" + configs + weights).value < 8) {
              return "true";
            }
          }
        }
      }

      // These are the below static controls
      for (let index = 0; index < 5; index++) {
        if (document.getElementById("definedDropdown" + index)) {
          if (document.getElementById("definedDropdown" + index).textContent == "Please Select Font") {
            return "true";
          }
        }

        if (document.getElementById("definedSize" + index).value) {
          if (
            Number(document.getElementById("definedSize" + index).value) >=
            Number(document.getElementById("lead" + index).value)
          ) {
            return "true";
          }
        } else {
          if (document.getElementById("lead" + index).value) {
            return "true";
          }
        }

        if (document.getElementById("definedSize" + index).value) {
          if (Number(document.getElementById("definedSize" + index).value) < 8) {
            return "true";
          }
        }
      }
    }
  };

  /**
   * Used to add the fonts
   */
  const addFonts = () => {
    let changedAnyConfigs = false;
    let editedFontsSet = "";

    if (googleDropdownChanged != 0 || uploadDropdownChanged != 0) {
      changedAnyConfigs = true;
    }

    for (let configs = 0; configs < fontsSet; configs++) {
      // After that Loop based on inner Font Weights Count

      if (configs == 0) {
        if (document.getElementById("setChanged00")) {
          if (document.getElementById("setChanged00").textContent.length > 0) {
            editedFontsSet = "0";
          }
        }
        if (googleFontFamilyOptionsData0.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set one")}</div>;
        }
      } else if (configs == 1) {
        if (document.getElementById("setChanged10")) {
          if (document.getElementById("setChanged10").textContent.length > 0) {
            if (editedFontsSet.length > 0) {
              editedFontsSet = editedFontsSet + "," + "1";
            } else {
              editedFontsSet = "1";
            }
          }
        }
        if (googleFontFamilyOptionsData1.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set two")}</div>;
        }
      } else if (configs == 2) {
        if (document.getElementById("setChanged20")) {
          if (document.getElementById("setChanged20").textContent.length > 0) {
            if (editedFontsSet.length > 0) {
              editedFontsSet = editedFontsSet + "," + "2";
            } else {
              editedFontsSet = "2";
            }
          }
        }
        if (googleFontFamilyOptionsData2.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set three")}</div>;
        }
      } else if (configs == 3) {
        if (document.getElementById("setChanged30")) {
          if (document.getElementById("setChanged30").textContent.length > 0) {
            if (editedFontsSet.length > 0) {
              editedFontsSet = editedFontsSet + "," + "3";
            } else {
              editedFontsSet = "3";
            }
          }
        }
        if (googleFontFamilyOptionsData3.length == 0) {
          return <div className="warningMsg">{toast.warning("Please select or Add the Fonts for set four")}</div>;
        }
      }
    }

    validateControls();
    if (validate() == "true") {
      return <div className="warningMsg">{toast.warning("Please complete the required fields")}</div>;
    } else {
      setShowDialog(false);
      let finalObj = {
        fonts: [],
        flag: changedAnyConfigs,
        editedFontsSet: editedFontsSet,
      };
      let dynamicObj;
      let definedObj;

      // Loop based on Fonts Configuration Count
      for (let configs = 0; configs < fontsSet; configs++) {
        var weightsLength = 0; // After that Loop based on inner Font Weights get the Count

        if (configs == 0) {
          // For set Zero Configuration
          if (set0SelectedArray.length > 0) {
            // If user changes the Google font ten get the selected font weights count
            weightsLength = set0SelectedArray.length;
            if (weightsLength > 8) {
              weightsLength = 9;
            } else {
              weightsLength = weightsLength;
            }
          }
        } else if (configs == 1) {
          if (set1SelectedArray.length > 0) {
            // If user changes the Google font ten get the selected font weights count
            weightsLength = set1SelectedArray.length;
            if (weightsLength > 8) {
              weightsLength = 9;
            } else {
              weightsLength = weightsLength;
            }
          }
        } else if (configs == 2) {
          if (set2SelectedArray.length > 0) {
            // If user changes the Google font ten get the selected font weights count
            weightsLength = set2SelectedArray.length;
            if (weightsLength > 8) {
              weightsLength = 9;
            } else {
              weightsLength = weightsLength;
            }
          }
        } else if (configs == 3) {
          if (set3SelectedArray.length > 0) {
            // If user changes the Google font ten get the selected font weights count
            weightsLength = set3SelectedArray.length;
            if (weightsLength > 8) {
              weightsLength = 9;
            } else {
              weightsLength = weightsLength;
            }
          }
        }

        // These are the above dynamic fonts
        for (let weights = 0; weights < weightsLength; weights++) {
          if (document.getElementById("fDropdown" + configs + weights).textContent != "Please Select Font") {
            if (
              (configs == 0 && weights == 0) ||
              (configs == 1 && weights == 0) ||
              (configs == 2 && weights == 0) ||
              (configs == 3 && weights == 0)
            ) {
              dynamicObj = {
                orgName: orgName,
                fontName: document.getElementById("fDropdown" + configs + weights).textContent,
                fontFamily: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? document.getElementById("ffamily" + configs + weights).textContent
                  : document.getElementById("fDropdown" + configs + weights).textContent.split(" - ")[0],
                fontSize: document.getElementById("fSize" + configs + weights).value
                  ? document.getElementById("fSize" + configs + weights).value
                  : 32,
                fontURL: document.getElementById("fUrl" + configs + weights).textContent,
                description: document.getElementById("fDesc" + configs).value,
                leadValue: "",
                minTrack: "",
                type: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? 1
                  : 0,
                fontSet: configs,
              };
              finalObj.fonts.push(dynamicObj);
            } else {
              dynamicObj = {
                orgName: orgName,
                fontName: document.getElementById("fName" + configs + weights).textContent,
                fontFamily: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? document.getElementById("ffamily" + configs + weights).textContent
                  : document.getElementById("fDropdown" + configs + weights).textContent.split(" - ")[0],
                fontSize: document.getElementById("fSize" + configs + weights).value
                  ? document.getElementById("fSize" + configs + weights).value
                  : 26,
                fontURL: document.getElementById("fUrl" + configs + weights).textContent,
                description: document.getElementById("fDesc" + configs).value,
                leadValue: "",
                minTrack: "",
                type: document.getElementById("fUrl" + configs + weights).textContent.includes("grafixark-assets")
                  ? 1
                  : 0,
                fontSet: configs,
              };
              finalObj.fonts.push(dynamicObj);
            }
          }
        }
      }

      // These are the below static controls
      for (let index = 0; index < 5; index++) {
        if (index == 0) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 55,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 56,
            minTrack: document.getElementById("track" + index).value,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 1) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 26,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 27,
            minTrack: document.getElementById("track" + index).value,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 2) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 16,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 17,
            minTrack: document.getElementById("track" + index).value,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 3) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 10,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 11,
            minTrack: document.getElementById("track" + index).value,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        } else if (index == 4) {
          definedObj = {
            orgName: orgName,
            fontName: document.getElementById("definedDropdown" + index).textContent,
            fontFamily: document.getElementById("cFname" + index).textContent,
            fontSize: document.getElementById("definedSize" + index).value
              ? document.getElementById("definedSize" + index).value
              : 9,
            leadValue: document.getElementById("lead" + index).value
              ? document.getElementById("lead" + index).value
              : 10,
            minTrack: document.getElementById("track" + index).value,
            fontURL: document.getElementById("cUrl" + index).textContent,
            description: "",
            type: document.getElementById("cUrl" + index).textContent.includes("grafixark-assets") ? 1 : 0,
            fontSet: "",
          };
          finalObj.fonts.push(definedObj);
        }
      }
      setDisplayLoader(true);
      dispatch(editFonts(JSON.stringify(finalObj), fontsAddedSuccessHandler, errorHandler));
    }
  };

  /**
   * Service Succesful Handler
   */
  const fontsAddedSuccessHandler = () => {
    <div className="success">{toast.success("Fonts have been updated successfully..")}</div>;
    setTimeout(() => {
      setDisplayLoader(false);
      navigate("/brandassets/fontsView");
    }, 500);
  };

  /**
   * Set the Font Family to the Set Zero
   * @param {*} url
   * @param {*} index
   * @return {*}
   */
  const dynamicFontForSetZero = (url, index) => {
    var upFontFace = new FontFace(`upFontZero${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontZero${index}`;
  };

  /**
   * Set the Font Family to the Set One
   * @param {*} url
   * @param {*} index
   * @return {*}
   */
  const dynamicFontForSetOne = (url, index) => {
    var upFontFace = new FontFace(`upFontOne${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontOne${index}`;
  };

  /**
   * Set the Font Family to the Set Two
   * @param {*} url
   * @param {*} index
   * @return {*}
   */
  const dynamicFontForSetTwo = (url, index) => {
    var upFontFace = new FontFace(`upFontTwo${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontTwo${index}`;
  };

  /**
   * Set the Font Family to the Set Three
   * @param {*} url
   * @param {*} index
   * @return {*}
   */
  const dynamicFontForSetThree = (url, index) => {
    var upFontFace = new FontFace(`upFontThree${index}`, `url(${url})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontThree${index}`;
  };

  /**
   * Set the Font Family to the Static Set
   * @param {*} index
   * @return {*}
   */
  const dynamicFontForStaticFields = (index) => {
    var upFontFace = new FontFace(`upFontStatic${index}`, `url(${fontsStaticSet[index]?.fontURL})`);
    document.fonts.add(upFontFace);
    upFontFace.load();
    return `upFontStatic${index}`;
  };

  for (let index = 0; index < uniqueResult.length - 1; index++) {
    // for set0 resetting the placeholder and values
    if (uniqueResult[0]?.type == 1) {
      definedValues0 = [];
    } else {
      definedValues0 = [
        {
          label: uniqueResult[0]?.fontFamily,
          value: uniqueResult[0]?.fontURL,
        },
      ];
    }

    if (fontName0 != "" && fontFiles0.length != 0) {
      changedValues0 = [
        {
          label: fontName0,
          value: fontFiles0,
        },
      ];
    }
    if (changedValues0.length != 0) {
      if (changedValues0 != definedValues0) {
        definedValues0 = [];
      } else {
        definedValues0 = {
          label: uniqueResult[0]?.fontFamily,
          value: uniqueResult[0]?.fontURL,
        };
      }
    }
    if (uploadClicked0 == 1) {
      definedValues0 = [];
    }

    // for set1 resetting the placeholder and values
    if (uniqueResult[1]?.type == 1) {
      definedValues1 = [];
    } else {
      definedValues1 = [
        {
          label: uniqueResult[1]?.fontFamily,
          value: uniqueResult[1]?.fontURL,
        },
      ];
    }

    if (fontName1 != "" && fontFiles1.length != 0) {
      changedValues1 = [
        {
          label: fontName1,
          value: fontFiles1,
        },
      ];
    }
    if (changedValues1.length != 0) {
      if (changedValues1 != definedValues1) {
        definedValues1 = [];
      } else {
        definedValues1 = {
          label: uniqueResult[1]?.fontFamily,
          value: uniqueResult[1]?.fontURL,
        };
      }
    }
    if (uploadClicked1 == 1) {
      definedValues1 = [];
    }

    // for set2 resetting the placeholder and values
    if (uniqueResult[2]?.type == 1) {
      definedValues2 = [];
    } else {
      definedValues2 = [
        {
          label: uniqueResult[2]?.fontFamily,
          value: uniqueResult[2]?.fontURL,
        },
      ];
    }

    if (fontName2 != "" && fontFiles2.length != 0) {
      changedValues2 = [
        {
          label: fontName2,
          value: fontFiles2,
        },
      ];
    }
    if (changedValues2.length != 0) {
      if (changedValues2 != definedValues2) {
        definedValues2 = [];
      } else {
        definedValues2 = {
          label: uniqueResult[2]?.fontFamily,
          value: uniqueResult[2]?.fontURL,
        };
      }
    }
    if (uploadClicked2 == 1) {
      definedValues2 = [];
    }

    // for set3 resetting the placeholder and values
    if (uniqueResult[3]?.type == 1) {
      definedValues3 = [];
    } else {
      definedValues3 = [
        {
          label: uniqueResult[3]?.fontFamily,
          value: uniqueResult[3]?.fontURL,
        },
      ];
    }

    if (fontName3 != "" && fontFiles3.length != 0) {
      changedValues3 = [
        {
          label: fontName3,
          value: fontFiles3,
        },
      ];
    }
    if (changedValues3.length != 0) {
      if (changedValues3 != definedValues3) {
        definedValues3 = [];
      } else {
        definedValues3 = {
          label: uniqueResult[3]?.fontFamily,
          value: uniqueResult[3]?.fontURL,
        };
      }
    }
    if (uploadClicked3 == 1) {
      definedValues3 = [];
    }

    fontsConfigSetsHTMLDivs.push(
      <div>
        <div className="p-3 py-1 fontConfiguration">
          <div className="row my-3">
            <div className="col-lg-6 my-auto">
              <h6 className="m-0 uploadSet">For Configuration Add Set of fonts</h6>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 col-12 d-flex justify-content-end">
              <div className="my-auto">
                <img className="px-2 img-fluid" src={google} />
              </div>

              {index == 0 ? (
                <Select
                  className="me-2 w-50"
                  placeholder="Please Select Google Font"
                  value={definedValues0.length == 0 ? resetPlaceHolder0 : definedValues0}
                  options={googleFontsOptionsZeroData}
                  id={"fGDropdown" + index}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : index == 1 ? (
                <Select
                  className="me-2 w-50"
                  placeholder="Please Select Google Font"
                  value={definedValues1.length == 0 ? resetPlaceHolder1 : definedValues1}
                  options={googleFontsOptionsOneData}
                  id={"fGDropdown" + index}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : index == 2 ? (
                <Select
                  className="me-2 w-50"
                  placeholder="Please Select Google Font"
                  value={definedValues2.length == 0 ? resetPlaceHolder2 : definedValues2}
                  options={googleFontsOptionsTwoData}
                  id={"fGDropdown" + index}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : index == 3 ? (
                <Select
                  className="me-2 w-50"
                  placeholder="Please Select Google Font"
                  value={definedValues3.length == 0 ? resetPlaceHolder3 : definedValues3}
                  options={googleFontsOptionsThreeData}
                  id={"fGDropdown" + index}
                  onChange={(event) => onFontChange(event, index)}
                  styles={customStyles}
                />
              ) : (
                ""
              )}

              <Tooltip title="Add Font Set">
                <button id={`fontSet-${index}`} className="applySettingsBtn d-flex" onClick={showFontsModal}>
                  <div id={`fontSet-${index}`} className="ps-1 my-auto text-center">
                    <img id={`fontSet-${index}`} className="img-fluid" src={upload} />
                    <label id={`fontSet-${index}`} className="my-auto px-2 pt-1">
                      ADD FONT SET
                    </label>
                  </div>
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        {index == 0 ? (
          <div>
            {fontFamilySelectionHTMLDivsZero}
            {fontFamilySelectionHTMLDivsZero ? <hr className="my-3" /> : ""}
          </div>
        ) : index == 1 ? (
          <div>
            {fontFamilySelectionHTMLDivsOne}
            {fontFamilySelectionHTMLDivsOne ? <hr className="my-3" /> : ""}
          </div>
        ) : index == 2 ? (
          <div>
            {fontFamilySelectionHTMLDivsTwo}
            {fontFamilySelectionHTMLDivsTwo ? <hr className="my-3" /> : ""}
          </div>
        ) : index == 3 ? (
          <div>
            {fontFamilySelectionHTMLDivsThree}
            {fontFamilySelectionHTMLDivsThree ? <hr className="my-3" /> : ""}
          </div>
        ) : (
          "sample"
        )}
      </div>
    );
  }

  if (fontNameChildDropdown0) {
    var len1 = googleFontFamilyOptionsData0.length;
    if (len1 > 8) {
      len1 = 8;
    } else {
      len1 = googleFontFamilyOptionsData0.length;
    }

    fontFamilySelectionHTMLDivsZero.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName00"} accept={".ttf"}>
              {fontName0}
            </h6>
            <span id={"fUrl00"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily00"} hidden={true}></span>
            <span id={"setChanged00"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown00"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle0}
                options={googleFontFamilyOptionsData0}
                onChange={(event) => onFontFamilyChange(event, 0, 0)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize00"}
                value={regularFontSize0}
                name="Size"
                className="memberInput m-0 form-control"
                placeholder="32"
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize0((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 0)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsZero.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc0"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 0)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(0)}
            />
            <span id="errDesc0"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count0}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len1 + 1; index++) {
      // values for set1 Options
      let valueSet1Options = "";
      if (index == 1) {
        valueSet1Options = resetPlaceHolderDropdown00;
      } else if (index == 2) {
        valueSet1Options = resetPlaceHolderDropdown01;
      } else if (index == 3) {
        valueSet1Options = resetPlaceHolderDropdown02;
      } else if (index == 4) {
        valueSet1Options = resetPlaceHolderDropdown03;
      } else if (index == 5) {
        valueSet1Options = resetPlaceHolderDropdown04;
      } else if (index == 6) {
        valueSet1Options = resetPlaceHolderDropdown05;
      } else if (index == 7) {
        valueSet1Options = resetPlaceHolderDropdown06;
      } else if (index == 8) {
        valueSet1Options = resetPlaceHolderDropdown07;
      }

      fontFamilySelectionHTMLDivsZero.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6 className="text-wrap text-break m-0" id={"fName0" + index} accept={".ttf"}>
                {fontName0}
              </h6>
              <span id={"fUrl0" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily0" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown0" + index}
                  value={valueSet1Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData0}
                  onChange={(event) => onFontFamilyChange(event, index, 0)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize0" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 0, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    var savedLen = fontsSetZeroData.length;
    for (let index = 0; index < savedLen; index++) {
      fontFamilySelectionHTMLDivsZero.push(
        <div>
          <div className="p-3 py-1 fontConfiguration">
            <div className="row py-2 secondaryFonts">
              <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
                {index == 0 ? (
                  <h6
                    className="text-wrap text-break m-0"
                    id={"fName0" + index}
                    accept={".ttf"}
                    style={{
                      fontSize: `${fontsSetZeroData[index].fontSize}pt`,
                      fontFamily: dynamicFontForSetZero(fontsSetZeroData[index].fontURL, index),
                    }}
                  >
                    {fontsSetZeroData[index]?.fontName.split("-")[0]}
                  </h6>
                ) : (
                  <h6
                    className="text-wrap text-break m-0"
                    id={"fName0" + index}
                    accept={".ttf"}
                    style={{
                      fontSize: `${fontsSetZeroData[index].fontSize}pt`,
                      fontFamily: dynamicFontForSetZero(fontsSetZeroData[index].fontURL, index),
                    }}
                  >
                    {fontsSetZeroData[index]?.fontName}
                  </h6>
                )}

                <span id={"fUrl0" + index} accept={".ttf"} hidden={true}></span>
                <span id={"ffamily0" + index} hidden={true}></span>
                {/* <span id={"setChanged0" + index} hidden={true}></span> */}
              </div>

              <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
                {index == 0 ? (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown0" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData0}
                      onChange={(event) => onFontFamilyChange(event, index, 0)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetZeroData[index].fontName,
                        value: fontsSetZeroData[index].fontURL,
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown0" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData0}
                      onChange={(event) => onFontFamilyChange(event, index, 0)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetZeroData[index].fontName,
                        value: fontsSetZeroData[index].fontURL,
                      }}
                    />
                  </div>
                )}

                {index == 0 ? (
                  <div>
                    <input
                      id={"fSize0" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="32"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForRegular(event, 0, 0)}
                    />
                  </div>
                ) : (
                  <div>
                    <input
                      id={"fSize0" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="26"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 0, index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {index == 0 ? (
            <div className="p-3 fontConfiguration">
              <div className="row primaryFonts">
                <div style={{ minHeight: "105px" }}>
                  <textarea
                    id={"fDesc0"}
                    name="description"
                    className="h-0 p-2"
                    minLength="50"
                    maxLength="500"
                    onChange={(e) => onChangeFontFamilyForDescription(e, 0)}
                    rows={4}
                    style={{
                      paddingTop: 0,
                      width: "100%",
                      border: "1px solid rgb(229, 228, 228)",
                      borderRadius: "5px",
                      color: "#a8aaaf",
                    }}
                    placeholder="Enter a description"
                    onBlur={() => onBlurFontFamilyForDescription(0)}
                  />
                  <span id="errDesc0"></span>
                  {<p className="d-flex justify-content-end p-0 m-0">{count0}/500</p>}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }

  if (fontNameChildDropdown1) {
    var len2 = googleFontFamilyOptionsData1.length;
    if (len2 > 8) {
      len2 = 8;
    } else {
      len2 = googleFontFamilyOptionsData1.length;
    }

    fontFamilySelectionHTMLDivsOne.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName10"} accept={".ttf"}>
              {fontName1}
            </h6>
            <span id={"fUrl10"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily10"} hidden={true}></span>
            <span id={"setChanged10"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown10"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle1}
                options={googleFontFamilyOptionsData1}
                onChange={(event) => onFontFamilyChange(event, 0, 1)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize10"}
                value={regularFontSize1}
                name="Size"
                className="memberInput m-0 form-control"
                placeholder="32"
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize1((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 1)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsOne.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc1"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 1)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(1)}
            />
            <span id="errDesc1"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count1}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len2 + 1; index++) {
      // values for set2 Options
      let valueSet2Options = "";
      if (index == 1) {
        valueSet2Options = resetPlaceHolderDropdown10;
      } else if (index == 2) {
        valueSet2Options = resetPlaceHolderDropdown11;
      } else if (index == 3) {
        valueSet2Options = resetPlaceHolderDropdown12;
      } else if (index == 4) {
        valueSet2Options = resetPlaceHolderDropdown13;
      } else if (index == 5) {
        valueSet2Options = resetPlaceHolderDropdown14;
      } else if (index == 6) {
        valueSet2Options = resetPlaceHolderDropdown15;
      } else if (index == 7) {
        valueSet2Options = resetPlaceHolderDropdown16;
      } else if (index == 8) {
        valueSet2Options = resetPlaceHolderDropdown17;
      }

      fontFamilySelectionHTMLDivsOne.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6 className="text-wrap text-break m-0" id={"fName1" + index} accept={".ttf"}>
                {fontName1}
              </h6>
              <span id={"fUrl1" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily1" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown1" + index}
                  value={valueSet2Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData1}
                  onChange={(event) => onFontFamilyChange(event, index, 1)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize1" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 1, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    var savedLen1 = fontsSetOneData.length;
    for (let index = 0; index < savedLen1; index++) {
      fontFamilySelectionHTMLDivsOne.push(
        <div>
          <div className="p-3 py-1 fontConfiguration">
            <div className="row py-2 secondaryFonts">
              <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
                <h6
                  className="text-wrap text-break m-0"
                  id={"fName1" + index}
                  accept={".ttf"}
                  style={{
                    fontSize: `${fontsSetOneData[index].fontSize}pt`,
                    fontFamily: dynamicFontForSetOne(fontsSetOneData[index].fontURL, index),
                  }}
                >
                  {fontsSetOneData[index].fontName}
                </h6>
                <span id={"fUrl1" + index} accept={".ttf"} hidden={true}></span>
                <span id={"ffamily1" + index} hidden={true}></span>
                {/* <span id={"setChanged1" + index} hidden={true}></span> */}
              </div>

              <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
                {index == 0 ? (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown1" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData1}
                      onChange={(event) => onFontFamilyChange(event, index, 1)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetOneData[index].fontName,
                        value: fontsSetOneData[index].fontURL,
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown1" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData1}
                      onChange={(event) => onFontFamilyChange(event, index, 1)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetOneData[index].fontName,
                        value: fontsSetOneData[index].fontURL,
                      }}
                    />
                  </div>
                )}

                {index == 0 ? (
                  <div>
                    <input
                      id={"fSize1" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="32"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForRegular(event, 0, 1)}
                    />
                  </div>
                ) : (
                  <div>
                    <input
                      id={"fSize1" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="26"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 1, index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {index == 0 ? (
            <div className="p-3 fontConfiguration">
              <div className="row primaryFonts">
                <div style={{ minHeight: "105px" }}>
                  <textarea
                    id={"fDesc1"}
                    name="description"
                    className="h-0 p-2"
                    minLength="50"
                    maxLength="500"
                    onChange={(e) => onChangeFontFamilyForDescription(e, 1)}
                    rows={4}
                    style={{
                      paddingTop: 0,
                      width: "100%",
                      border: "1px solid rgb(229, 228, 228)",
                      borderRadius: "5px",
                      color: "#a8aaaf",
                    }}
                    placeholder="Enter a description"
                    onBlur={() => onBlurFontFamilyForDescription(1)}
                  />
                  <span id="errDesc1"></span>
                  {<p className="d-flex justify-content-end p-0 m-0">{count1}/500</p>}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }

  if (fontNameChildDropdown2) {
    var len3 = googleFontFamilyOptionsData2.length;
    if (len3 > 8) {
      len3 = 8;
    } else {
      len3 = googleFontFamilyOptionsData2.length;
    }

    fontFamilySelectionHTMLDivsTwo.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName20"} accept={".ttf"}>
              {fontName2}
            </h6>
            <span id={"fUrl20"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily20"} hidden={true}></span>
            <span id={"setChanged20"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown20"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle2}
                options={googleFontFamilyOptionsData2}
                onChange={(event) => onFontFamilyChange(event, 0, 2)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize20"}
                name="Size"
                value={regularFontSize2}
                className="memberInput m-0 form-control"
                placeholder="32"
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize2((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 2)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsTwo.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc2"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 2)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(2)}
            />
            <span id="errDesc2"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count2}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len3 + 1; index++) {
      // values for set3 options
      let valueSet3Options = "";
      if (index == 1) {
        valueSet3Options = resetPlaceHolderDropdown20;
      } else if (index == 2) {
        valueSet3Options = resetPlaceHolderDropdown21;
      } else if (index == 3) {
        valueSet3Options = resetPlaceHolderDropdown22;
      } else if (index == 4) {
        valueSet3Options = resetPlaceHolderDropdown23;
      } else if (index == 5) {
        valueSet3Options = resetPlaceHolderDropdown24;
      } else if (index == 6) {
        valueSet3Options = resetPlaceHolderDropdown25;
      } else if (index == 7) {
        valueSet3Options = resetPlaceHolderDropdown26;
      } else if (index == 8) {
        valueSet3Options = resetPlaceHolderDropdown27;
      }

      fontFamilySelectionHTMLDivsTwo.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6 className="text-wrap text-break m-0" id={"fName2" + index} accept={".ttf"}>
                {fontName2}
              </h6>
              <span id={"fUrl2" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily2" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown2" + index}
                  value={valueSet3Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData2}
                  onChange={(event) => onFontFamilyChange(event, index, 2)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize2" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 2, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    var savedLen2 = fontsSetTwoData.length;
    for (let index = 0; index < savedLen2; index++) {
      fontFamilySelectionHTMLDivsTwo.push(
        <div>
          <div className="p-3 py-1 fontConfiguration">
            <div className="row py-2 secondaryFonts">
              <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
                <h6
                  className="text-wrap text-break m-0"
                  id={"fName2" + index}
                  accept={".ttf"}
                  style={{
                    fontSize: `${fontsSetTwoData[index].fontSize}pt`,
                    fontFamily: dynamicFontForSetTwo(fontsSetTwoData[index].fontURL, index),
                  }}
                >
                  {fontsSetTwoData[index].fontName}
                </h6>
                <span id={"fUrl2" + index} accept={".ttf"} hidden={true}></span>
                <span id={"ffamily2" + index} hidden={true}></span>
                {/* <span id={"setChanged2" + index} hidden={true}></span> */}
              </div>

              <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
                {index == 0 ? (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown2" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData2}
                      onChange={(event) => onFontFamilyChange(event, index, 2)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetTwoData[index].fontName,
                        value: fontsSetTwoData[index].fontURL,
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown2" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData2}
                      onChange={(event) => onFontFamilyChange(event, index, 2)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetTwoData[index].fontName,
                        value: fontsSetTwoData[index].fontURL,
                      }}
                    />
                  </div>
                )}

                {index == 0 ? (
                  <div>
                    <input
                      id={"fSize2" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="32"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForRegular(event, 0, 2)}
                    />
                  </div>
                ) : (
                  <div>
                    <input
                      id={"fSize2" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="26"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 2, index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {index == 0 ? (
            <div className="p-3 fontConfiguration">
              <div className="row primaryFonts">
                <div style={{ minHeight: "105px" }}>
                  <textarea
                    id={"fDesc2"}
                    name="description"
                    className="h-0 p-2"
                    minLength="50"
                    maxLength="500"
                    onChange={(e) => onChangeFontFamilyForDescription(e, 2)}
                    rows={4}
                    style={{
                      paddingTop: 0,
                      width: "100%",
                      border: "1px solid rgb(229, 228, 228)",
                      borderRadius: "5px",
                      color: "#a8aaaf",
                    }}
                    placeholder="Enter a description"
                    onBlur={() => onBlurFontFamilyForDescription(2)}
                  />
                  <span id="errDesc2"></span>
                  {<p className="d-flex justify-content-end p-0 m-0">{count2}/500</p>}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }

  if (fontNameChildDropdown3) {
    var len4 = googleFontFamilyOptionsData3.length;
    if (len4 > 8) {
      len4 = 8;
    } else {
      len4 = googleFontFamilyOptionsData3.length;
    }

    fontFamilySelectionHTMLDivsThree.push(
      <div className="p-3 py-1 fontConfiguration">
        <div className="row pt-3 primaryFonts">
          <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
            <h6 className="text-wrap text-break m-0" id={"fName30"} accept={".ttf"}>
              {fontName3}
            </h6>
            <span id={"fUrl30"} accept={".ttf"} hidden={true}></span>
            <span id={"ffamily30"} hidden={true}></span>
            <span id={"setChanged30"} hidden={true}></span>
          </div>

          <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
            <div className="w-75 me-2">
              <label>Font</label>
              <Select
                id={"fDropdown30"}
                placeholder="Please Select Font"
                value={resetPlaceHolderDropdownTitle3}
                options={googleFontFamilyOptionsData3}
                onChange={(event) => onFontFamilyChange(event, 0, 3)}
                styles={customStyles}
              />
            </div>

            <div>
              <label className="memberLabel my-auto form-label">Size</label>
              <input
                id={"fSize30"}
                name="Size"
                value={regularFontSize3}
                className="memberInput m-0 form-control"
                placeholder="32"
                autoComplete="off"
                minLength="2"
                maxLength="2"
                onChange={(e) => setRegularFontSize3((e.target.value = e.target.value.replace(/[^0-9]/g, "")))}
                onBlur={(event) => onBlurFontSizeForRegular(event, 0, 3)}
                type="text"
                style={{ width: "60px" }}
              />
            </div>
          </div>
        </div>
      </div>
    );

    fontFamilySelectionHTMLDivsThree.push(
      <div className="p-3 fontConfiguration">
        <div className="row primaryFonts">
          <div style={{ minHeight: "105px" }}>
            <textarea
              id={"fDesc3"}
              name="description"
              className="h-0 p-2"
              minLength="50"
              maxLength="500"
              onChange={(e) => onChangeFontFamilyForDescription(e, 3)}
              rows={4}
              style={{
                paddingTop: 0,
                width: "100%",
                border: "1px solid rgb(229, 228, 228)",
                borderRadius: "5px",
                color: "#a8aaaf",
              }}
              placeholder="Enter a description"
              onBlur={() => onBlurFontFamilyForDescription(3)}
            />
            <span id="errDesc3"></span>
            {<p className="d-flex justify-content-end p-0 m-0">{count3}/500</p>}
          </div>
        </div>
      </div>
    );

    for (let index = 1; index < len4 + 1; index++) {
      // values for set4 options
      let valueSet4Options = "";
      if (index == 1) {
        valueSet4Options = resetPlaceHolderDropdown30;
      } else if (index == 2) {
        valueSet4Options = resetPlaceHolderDropdown31;
      } else if (index == 3) {
        valueSet4Options = resetPlaceHolderDropdown32;
      } else if (index == 4) {
        valueSet4Options = resetPlaceHolderDropdown33;
      } else if (index == 5) {
        valueSet4Options = resetPlaceHolderDropdown34;
      } else if (index == 6) {
        valueSet4Options = resetPlaceHolderDropdown35;
      } else if (index == 7) {
        valueSet4Options = resetPlaceHolderDropdown36;
      } else if (index == 8) {
        valueSet4Options = resetPlaceHolderDropdown37;
      }

      fontFamilySelectionHTMLDivsThree.push(
        <div className="p-3 py-1 fontConfiguration">
          <div className="row py-2 secondaryFonts">
            <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
              <h6 className="text-wrap text-break m-0" id={"fName3" + index} accept={".ttf"}>
                {fontName3}
              </h6>
              <span id={"fUrl3" + index} accept={".ttf"} hidden={true}></span>
              <span id={"ffamily3" + index} hidden={true}></span>
            </div>

            <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
              <div className="w-75 me-2">
                <Select
                  id={"fDropdown3" + index}
                  value={valueSet4Options}
                  className="w-100"
                  placeholder="Please Select Font"
                  options={googleFontFamilyOptionsData3}
                  onChange={(event) => onFontFamilyChange(event, index, 3)}
                  styles={customStyles}
                />
              </div>

              <div>
                <input
                  id={"fSize3" + index}
                  name="Size"
                  className="memberInput m-0 form-control"
                  placeholder="26"
                  autoComplete="off"
                  minLength="2"
                  maxLength="2"
                  type="text"
                  style={{ width: "60px" }}
                  onChange={(e) => (e.target.value = e.target.value.replace(/[^0-9]/g, ""))}
                  onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 3, index)}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }
  } else {
    var savedLen3 = fontsSetThreeData.length;
    for (let index = 0; index < savedLen3; index++) {
      fontFamilySelectionHTMLDivsThree.push(
        <div>
          <div className="p-3 py-1 fontConfiguration">
            <div className="row py-2 secondaryFonts">
              <div className="col-md-6 col-lg-6 col-sm-6 my-auto">
                <h6
                  className="text-wrap text-break m-0"
                  id={"fName3" + index}
                  accept={".ttf"}
                  style={{
                    fontSize: `${fontsSetThreeData[index].fontSize}pt`,
                    fontFamily: dynamicFontForSetThree(fontsSetThreeData[index].fontURL, index),
                  }}
                >
                  {fontsSetThreeData[index].fontName}
                </h6>
                <span id={"fUrl3" + index} accept={".ttf"} hidden={true}></span>
                <span id={"ffamily3" + index} hidden={true}></span>
                {/* <span id={"setChanged3" + index} hidden={true}></span> */}
              </div>

              <div className="col-md-6 col-lg-6 col-sm-6 d-flex justify-content-end my-auto">
                {index == 0 ? (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown3" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData3}
                      onChange={(event) => onFontFamilyChange(event, index, 3)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetThreeData[index].fontName,
                        value: fontsSetThreeData[index].fontURL,
                      }}
                    />
                  </div>
                ) : (
                  <div className="w-75 me-2">
                    <Select
                      id={"fDropdown3" + index}
                      className="w-100"
                      placeholder="Please Select Font"
                      options={googleFontFamilyOptionsData3}
                      onChange={(event) => onFontFamilyChange(event, index, 3)}
                      styles={customStyles}
                      defaultValue={{
                        label: fontsSetThreeData[index].fontName,
                        value: fontsSetThreeData[index].fontURL,
                      }}
                    />
                  </div>
                )}

                {index == 0 ? (
                  <div>
                    <input
                      id={"fSize3" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="32"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForRegular(event, 0, 3)}
                    />
                  </div>
                ) : (
                  <div>
                    <input
                      id={"fSize3" + index}
                      name="Size"
                      className="memberInput m-0 form-control"
                      placeholder="26"
                      autoComplete="off"
                      minLength="2"
                      maxLength="2"
                      type="text"
                      style={{ width: "60px" }}
                      onChange={(e) => ((e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true))}
                      onBlur={(event) => onBlurFontSizeForOtherThanRegular(event, 3, index)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {index == 0 ? (
            <div className="p-3 fontConfiguration">
              <div className="row primaryFonts">
                <div style={{ minHeight: "105px" }}>
                  <textarea
                    id={"fDesc3"}
                    name="description"
                    className="h-0 p-2"
                    minLength="50"
                    maxLength="500"
                    onChange={(e) => onChangeFontFamilyForDescription(e, 3)}
                    rows={4}
                    style={{
                      paddingTop: 0,
                      width: "100%",
                      border: "1px solid rgb(229, 228, 228)",
                      borderRadius: "5px",
                      color: "#a8aaaf",
                    }}
                    placeholder="Enter a description"
                    onBlur={() => onBlurFontFamilyForDescription(3)}
                  />
                  <span id="errDesc3"></span>
                  {<p className="d-flex justify-content-end p-0 m-0">{count3}/500</p>}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      );
    }
  }

  return (
    <div className="mt-3 pb-4 userFonts">
      <Suspense fallback={<Loader />}>
        {showPrompt &&
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        }
      </Suspense>
      <div className="row mb-2">
        <div className="col-lg-9 d-flex">
          <h6 className="titLe py-2 m-0 my-auto">
            Brand Assets <span> - Fonts</span>
          </h6>
        </div>
        <div className="col-lg-3 d-flex justify-content-end my-auto">
          <div
            className="titLe text-decoration-underline"
            style={{ color: "#00B8F1", cursor: "pointer" }}
            onClick={addFonts}
          >
            <span> Update and Preview</span>
          </div>
        </div>
      </div>

      <hr className="mb-2" />

      <div
        className="row userColors mt-3"
        style={{
          background: "#fff",
          position: "relative",
        }}
      >
        <EditText assetType="Fonts" />
      </div>

      {fontsSet == null ? (
        <div className="row">
          <div className="col-lg-12">
            <div className="row text-center pt-5 mt-5">
              <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                No fonts are available
              </h1>
              <p
                style={{
                  color: "#15355c",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Please configure fonts by pressing above settings icon.
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <hr className="mb-3" />
          {fontsConfigSetsHTMLDivs}
          <div className="row pb-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  {googleDropdownChanged == 1 || uploadDropdownChanged == 1 ? (
                    <h1
                      className="my-auto h1Title text-wrap text-break pe-2"
                      id="title"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[0]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[0]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[0]?.minTrack}pt`,
                      }}
                    >
                      TITLE
                    </h1>
                  ) : (
                    <h1
                      className="my-auto h1Title text-wrap text-break pe-2"
                      id="title"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[0]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[0]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[0]?.minTrack}pt`,
                        fontFamily: dynamicFontForStaticFields(0),
                      }}
                    >
                      TITLE
                    </h1>
                  )}
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H1
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font </label>
                      <label id="cUrl0" hidden={true}></label>
                      <label id="cFname0" hidden={true}></label>
                      <Select
                        id="definedDropdown0"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilyTitleChange}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        value={selectedZeroOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        className="memberInput m-0"
                        id="definedSize0"
                        type="text"
                        name="Size"
                        placeholder="55"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "title", 0)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          className="memberInput m-0"
                          id="lead0"
                          type="text"
                          name="Size"
                          placeholder="56"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "title", 0)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track0"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "title")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  {googleDropdownChanged == 1 || uploadDropdownChanged == 1 ? (
                    <h2
                      className="my-auto h2HeadTitle text-wrap text-break pe-2"
                      id="headTitle"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[1]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[1]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[1]?.minTrack}pt`,
                      }}
                    >
                      HEADLINE
                    </h2>
                  ) : (
                    <h2
                      className="my-auto h2HeadTitle text-wrap text-break pe-2"
                      id="headTitle"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[1]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[1]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[1]?.minTrack}pt`,
                        fontFamily: dynamicFontForStaticFields(1),
                      }}
                    >
                      HEADLINE
                    </h2>
                  )}
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H2
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl1" hidden={true}></label>
                      <label id="cFname1" hidden={true}></label>
                      <Select
                        id="definedDropdown1"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilyHeadLineChange}
                        styles={customStyles}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        value={selectedOneOption}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize1"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="26"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "headTitle", 1)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead1"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="27"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "headTitle", 1)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2 pe-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track1"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "headTitle")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  {googleDropdownChanged == 1 || uploadDropdownChanged == 1 ? (
                    <h3
                      className="my-auto h3SubHeading1 text-wrap text-break pe-2"
                      id="subHeading1"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[2]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[2]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[2]?.minTrack}pt`,
                      }}
                    >
                      Sub Heading
                    </h3>
                  ) : (
                    <h3
                      className="my-auto h3SubHeading1 text-wrap text-break pe-2"
                      id="subHeading1"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[2]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[2]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[2]?.minTrack}pt`,
                        fontFamily: dynamicFontForStaticFields(2),
                      }}
                    >
                      Sub Heading
                    </h3>
                  )}
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H3
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl2" hidden={true}></label>
                      <label id="cFname2" hidden={true}></label>
                      <Select
                        id="definedDropdown2"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilySubHeadingChangeH3}
                        styles={customStyles}
                        value={selectedTwoOption}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize2"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="16"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "subHeading1", 2)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead2"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="17"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "subHeading1", 2)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track2"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "subHeading1")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3  my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  {googleDropdownChanged == 1 || uploadDropdownChanged == 1 ? (
                    <h4
                      className="my-auto h3SubHeading2 text-wrap text-break pe-2"
                      id="subHeading2"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[3]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[3]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[3]?.minTrack}pt`,
                      }}
                    >
                      Body
                    </h4>
                  ) : (
                    <h4
                      className="my-auto h3SubHeading2 text-wrap text-break pe-2"
                      id="subHeading2"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[3]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[3]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[3]?.minTrack}pt`,
                        fontFamily: dynamicFontForStaticFields(3),
                      }}
                    >
                      Body
                    </h4>
                  )}
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H4
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl3" hidden={true}></label>
                      <label id="cFname3" hidden={true}></label>

                      <Select
                        id="definedDropdown3"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilySubHeadingChangeH4}
                        styles={customStyles}
                        value={selectedThreeOption}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize3"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="10"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "subHeading2", 3)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead3"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="11"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "subHeading2", 3)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track3"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "subHeading2")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row py-3" style={{ borderBottom: "1px solid rgb(229, 228, 228)" }}>
            <div className="col-lg-3 my-auto">
              <div className="d-flex justify-content-between">
                <div>
                  {googleDropdownChanged == 1 || uploadDropdownChanged == 1 ? (
                    <h5
                      className="my-auto h5Caption text-wrap text-break pe-2"
                      id="caption"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[4]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[4]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[4]?.minTrack}pt`,
                      }}
                    >
                      Captions
                    </h5>
                  ) : (
                    <h5
                      className="my-auto h5Caption text-wrap text-break pe-2"
                      id="caption"
                      accept={".ttf"}
                      style={{
                        fontSize: `${fontsStaticSet[4]?.fontSize}pt`,
                        lineHeight: `${fontsStaticSet[4]?.leadValue}pt`,
                        letterSpacing: `${fontsStaticSet[4]?.minTrack}pt`,
                        fontFamily: dynamicFontForStaticFields(4),
                      }}
                    >
                      Captions
                    </h5>
                  )}
                </div>
                <div className="my-auto">
                  <span
                    style={{
                      padding: "10px",
                      background: "#15355c",
                      color: "#fff",
                      fontFamily: "roboto-bold",
                      fontSize: "16px",
                    }}
                  >
                    H5
                  </span>
                </div>
              </div>
            </div>

            <div className="col-lg-9 my-auto">
              <div className="row justify-content-end">
                <div className="col-lg-6">
                  <div className="row justify-content-end">
                    <div className="col-lg-8">
                      <label>Font</label>
                      <label id="cUrl4" hidden={true}></label>
                      <label id="cFname4" hidden={true}></label>

                      <Select
                        id="definedDropdown4"
                        className="me-2 w-100"
                        placeholder="Please Select Font"
                        options={finalAllSelectedUniqueDropdown}
                        onChange={onFontFamilyCaptionChange}
                        styles={customStyles}
                        value={selectedFourOption}
                        minMenuHeight={"30px"}
                        maxMenuHeight={"160px"}
                        menuPlacement="top"
                      />
                    </div>

                    <div className="col-lg-3">
                      <Form.Label className="memberLabel my-auto">Size</Form.Label>
                      <Form.Control
                        id="definedSize4"
                        className="memberInput m-0"
                        type="text"
                        name="Size"
                        placeholder="9"
                        autoComplete="off"
                        minLength="1"
                        maxLength="2"
                        onChange={(e) => (
                          (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                        )}
                        onBlur={(event) => onBlurFontSize(event, "caption", 4)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4">
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Leading</label>
                      <div className="d-flex">
                        <img className="pe-2" src={leading} />
                        <Form.Control
                          id="lead4"
                          className="memberInput m-0"
                          type="text"
                          name="Size"
                          placeholder="10"
                          autoComplete="off"
                          minLength="1"
                          maxLength="2"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurLeading(event, "caption", 4)}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6">
                      <label className="memberLabel my-auto form-label ps-4 ms-2">Min Track</label>
                      <div className="d-flex">
                        <img className="pe-2" src={minTrack} />
                        <Form.Control
                          id="track4"
                          className="memberInput m-0"
                          type="text"
                          name="minTrack"
                          placeholder="0"
                          autoComplete="off"
                          minLength="1"
                          maxLength="1"
                          onChange={(e) => (
                            (e.target.value = e.target.value.replace(/[^0-9]/g, "")), setShowDialog(true)
                          )}
                          onBlur={(event) => onBlurMinTrack(event, "caption")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={<Loader />}>
        {showFonts &&
          <FontsUploadModal childToParent={childToParent} showFontsUpload={showFonts} setShowFontsUpload={setShowFonts} />
        }
      </Suspense>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default FontsEdit;
