import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import excel from "../../images/excel.svg";
import pdf from "../../images/pdf.svg";
import ppt from "../../images/ppt.svg";
import upload from "../../images/upload.svg";
import word from "../../images/word.svg";
import { getAssetsByMemberId, getLetterHeads } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./LetterHead.css";

/**
 *Functional component for Templates
 * @return {*} 
 */
function LetterHeads() {
  const LetterHeadUploadModal = React.lazy(() => import("../Shared/Modals/LetterHeadUploadModal"));
  let dynamicName = localStorage.getItem("templatesOptionsName");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [letterHeadUpload, setShowLetterHeadUpload] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [displayLoader, setDisplayLoader] = useState(true);

  let adminAssetsData = [];
  let userAssetsData = [];

  adminAssetsData = useSelector((state) => state.userReducer.letterheads);
  userAssetsData = useSelector((state) => state.userReducer.AssetsDataByUserId);

  const uniqueResultOfAdminAssets = [];
  const uniqueResultOfUserAssets = [];

  const map = new Map();

  let assetTypeName = adminAssetsData[0]?.assetType;
  if (assetTypeName === dynamicName) {
    for (const item of adminAssetsData) {
      if (!map.has(item.groupName)) {
        map.set(item.groupName, true);
        uniqueResultOfAdminAssets.push(item);
      }
    }
  }

  let userAssetTypeName = userAssetsData[0]?.assetType;
  if (userAssetTypeName === dynamicName) {
    for (const item of userAssetsData) {
      if (!map.has(item.groupName)) {
        map.set(item.groupName, true);
        uniqueResultOfUserAssets.push(item);
      }
    }
  }

  useEffect(() => {
    if (!dynamicName) {
      navigate("/");
    }
    setUserRole(localStorage.getItem("roleId"));
    if (localStorage.getItem("roleId") === "3") {
      const obj = {
        id: localStorage.getItem("userId"),
        assetType: dynamicName,
      };
      dispatch(getAssetsByMemberId(obj, successHandler, errorHandler));
    } else {
      const obj = {
        name: localStorage.getItem("orgName"),
        assetType: dynamicName,
      };
      dispatch(getLetterHeads(obj, successHandler, errorHandler));
    }
  }, [dynamicName]);

  /**
  * This method will call after Successful function execution
  */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
    adminAssetsData = [];
    userAssetsData = [];
  };

  /**
   * This method will call when user click on the individual template
   * @param {*} groupName
   */
  const templateClick = (groupName) => {
    localStorage.setItem("letterHeadGroupName", groupName);
    let selectedLetterHead = [];
    if (localStorage.getItem("roleId") !== "3") {
      selectedLetterHead = adminAssetsData.filter((item) => item.groupName === groupName);
    } else {
      selectedLetterHead = userAssetsData.filter((item) => item.groupName === groupName);
    }
    navigate("/templates/templatedetails", {
      state: JSON.stringify(selectedLetterHead),
    });
  };

  return (
    <div className="hide mt-3 userLetterHead">
      <div className="row mb-2">
        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
          <h6 className="titLe py-2 m-0 my-auto">
            Templates <span> - {dynamicName}</span>
          </h6>
        </div>
        <div className="col-lg-6 col-md-6 col-sm-6 col-6 my-auto">
          <div className="d-flex justify-content-end">
            <div className="d-flex buttonList">
              <div>
                {(userRole === "1" || userRole === "2") ? (
                  <Tooltip title="Add">
                    <div className="buttonGap me-0">
                      <IconButton
                        className="iconsStyles borderBtn border-end-0"
                        img={upload}
                        imageClass="imageHeight"
                        buttonClass="download"
                        label="UPDATE"
                        onClick={(e) => setShowLetterHeadUpload(true)}
                      />
                    </div>
                  </Tooltip>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr className="m-1 mb-2"></hr>

      <div className="row userColors">
        <EditText assetType={dynamicName} />

        <Suspense fallback={<Loader />}>
          {
            letterHeadUpload &&
            <LetterHeadUploadModal
              showLetterHeadUpload={letterHeadUpload}
              setShowLetterHeadUpload={setShowLetterHeadUpload}
            />
          }
        </Suspense>

      </div>

      <div className="row">
        <div className="my-3">
          {uniqueResultOfAdminAssets.length > 0 && userRole !== "3" ? (
            <div className="row mb-2" style={{ alignItems: "center" }}>
              {uniqueResultOfAdminAssets.map((item) => {
                return (
                  <div className="col-lg-3 col-md-3 col-sm-3 col-12 mb-3">
                    <div className="linear p-3" onClick={() => templateClick(item.groupName)}>
                      {item.groupThumbnail ?
                        (
                          item.thumbnailPath ?
                            (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={item.thumbnailPath} alt="" />
                                </div>
                              </div>
                            ) : (
                              (
                                item.path?.includes("pdf") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={pdf} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("doc") || item.path?.includes("docx") || item.path?.includes("dot") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={word} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("xls") || item.path?.includes("xlsx") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={excel} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("ppt") || item.path?.includes("pptx") || item.path?.includes("potx") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={ppt} alt="" />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={item.path} alt="" />
                                    </div>
                                  </div>
                                )
                              )
                            )
                        ) :
                        (
                          item.path?.includes("pdf") ? (
                            <div className="logoThumbnail d-flex">
                              <div className="m-auto">
                                <img className="p-1 img-fluid templateMouseOver" src={pdf} alt="" />
                              </div>
                            </div>
                          ) : item.path?.includes("doc") || item.path?.includes("docx") || item.path?.includes("dot") ? (
                            <div className="logoThumbnail d-flex">
                              <div className="m-auto">
                                <img className="p-1 img-fluid templateMouseOver" src={word} alt="" />
                              </div>
                            </div>
                          ) : item.path?.includes("xls") || item.path?.includes("xlsx") ? (
                            <div className="logoThumbnail d-flex">
                              <div className="m-auto">
                                <img className="p-1 img-fluid templateMouseOver" src={excel} alt="" />
                              </div>
                            </div>
                          ) : item.path?.includes("ppt") || item.path?.includes("pptx") ? (
                            <div className="logoThumbnail d-flex">
                              <div className="m-auto">
                                <img className="p-1 img-fluid templateMouseOver" src={ppt} alt="" />
                              </div>
                            </div>
                          ) : (
                            <div className="logoThumbnail d-flex">
                              <div className="m-auto">
                                <img className="p-1 img-fluid templateMouseOver" src={item.path} alt="" />
                              </div>
                            </div>
                          )
                        )
                      }
                    </div>
                    <p className="mb-1 py-1 text-center letterHeadListName">
                      {/* <span>Group Name: </span> */}
                      {item.groupName}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : uniqueResultOfUserAssets.length > 0 && userRole === "3" ? (
            <div className="row mb-2" style={{ alignItems: "center" }}>
              {uniqueResultOfUserAssets.map((item) => {
                return (
                  <div className="col-lg-4 col-md-4 col-sm-12 col-12 mb-3">
                    <div className="linear p-2" onClick={() => templateClick(item.groupName)}>
                      <div className="logoThumbnail d-flex">
                        {item.groupThumbnail ?
                          (item.thumbnailPath ?
                            (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={item.thumbnailPath} alt="" />
                                </div>
                              </div>
                            ) :
                            (
                              (
                                item.path?.includes("pdf") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={pdf} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("doc") || item.path?.includes("docx") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={word} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("xls") || item.path?.includes("xlsx") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={excel} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("ppt") || item.path?.includes("pptx") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={ppt} alt="" />
                                    </div>
                                  </div>
                                ) : item.path?.includes("dot") || item.path?.includes("dotx") ? (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={word} alt="" />
                                    </div>
                                  </div>
                                ) : (
                                  <div className="logoThumbnail d-flex">
                                    <div className="m-auto">
                                      <img className="p-1 img-fluid templateMouseOver" src={item.path} alt="" />
                                    </div>
                                  </div>
                                ))
                            )
                          ) :
                          (
                            item.path?.includes("pdf") ? (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={pdf} alt="" />
                                </div>
                              </div>
                            ) : item.path?.includes("doc") || item.path?.includes("docx") ? (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={word} alt="" />
                                </div>
                              </div>
                            ) : item.path?.includes("xls") || item.path?.includes("xlsx") ? (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={excel} alt="" />
                                </div>
                              </div>
                            ) : item.path?.includes("ppt") || item.path?.includes("pptx") ? (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={ppt} alt="" />
                                </div>
                              </div>
                            ) : item.path?.includes("dot") || item.path?.includes("dotx") ? (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={word} alt="" />
                                </div>
                              </div>
                            ) : (
                              <div className="logoThumbnail d-flex">
                                <div className="m-auto">
                                  <img className="p-1 img-fluid templateMouseOver" src={item.path} alt="" />
                                </div>
                              </div>
                            ))}
                      </div>
                    </div>
                    <p className="mb-1 py-1 text-center letterHeadListName">
                      {item.groupName}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (userRole === "1" || userRole === "2") ? (
            <div className="row mt-5 text-center pt-5 mt-5">
              <div>
                <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                  No {dynamicName} available
                </h1>
                <p
                  style={{
                    color: "#15355c",
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                >
                  Please add your {dynamicName}
                </p>
              </div>
            </div>
          ) : (
            <div className="row mt-5 text-center pt-5 mt-5">
              <div>
                <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                  No {dynamicName} available
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default LetterHeads;
