import "./Suggestion.css";

const SuggestionsListComponent = ({ filteredSuggestions, activeSuggestionIndex, onClick }) => {
  return filteredSuggestions.length ? (
    <ul className="suggestions">
      {filteredSuggestions.map((suggestion, index) => {
        let className;

        if (index === activeSuggestionIndex) {
          className = "suggestion-active";
        }
        return (
          <li className={className} key={suggestion} onClick={onClick}>
            {suggestion}
          </li>
        );
      })}
    </ul>
  ) : (
    <div className="no-suggestions d-none">
      <em>No suggestions, you're on your own!</em>
    </div>
  );
};
export default SuggestionsListComponent;
