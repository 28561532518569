import { Navigate, Outlet } from 'react-router-dom';

const AdminRoutes = () => {
    let auth = localStorage.getItem("accessToken");
    let roleId = localStorage.getItem("roleId");
    return (
        auth && (roleId === "1" || roleId === "2") ? <Outlet /> : <Navigate to="/" />
    )
};

export default AdminRoutes;