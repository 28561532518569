import "./Loader.css";

export const Loader = () => {
  return (
    <div>
      <div className="loading-overlay"></div>
      <div className="spin"></div>
    </div>
  );
};
