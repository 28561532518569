import MaterialTable from "@material-table/core";
import { Check, Clear, DeleteOutline, Edit, Search } from "@material-ui/icons";
import { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import customerIcon from "../images/customericon.svg";
import { getOrgGroups } from "../redux/Actions/action";
import "./Groups.css";
import { Loader } from "./Shared/Loader";

/* Icons used in the material table */
const tableIcons = {
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

/**
 * Functional component for groups
 * @return {*} 
 */
function Groups() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [paging, setPaging] = useState(false);
  const [displayLoader, setDisplayLoader] = useState(true);
  const orgGroupsData = useSelector((state) => state.userReducer.orgGroups);
  const switchUser = localStorage.getItem("switchUser");
  const roleId = localStorage.getItem("roleId");

  useEffect(() => {
    if (roleId === "1" && switchUser === "false") {
      navigate("/");
    }
    setTimeout(() => {
      dispatch(getOrgGroups(successHandler, errorHandler));
    }, 500);
    if (orgGroupsData) {
      const val = { value: orgGroupsData.length > 10 ? true : false };
      setPaging(val.value);
    } else {
      setPaging(false);
    }
  }, [orgGroupsData.length]);

  useEffect(() => { }, [orgGroupsData]);

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * This method will call when user click on the create group
   */
  const createGroupHandler = () => {
    navigate("/groups/creategroup");
  };

  /**
   * Navigate to Edit page
   */
  const gotoEditPage = (orgGroupsData) => {
    navigate("/groups/editgroup", { state: JSON.stringify(orgGroupsData) });
  };

  return (
    <div className="groups mt-3">
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12">
          <h6 className="titLe py-2 m-0 my-auto">
            Admin <span> - Groups</span>
          </h6>
        </div>
      </div>

      <div className="row groupViewTable">
        <h6 className="m-0 pb-0 MemberSubTitle"> Search Groups </h6>
        <div className="col-lg-10">
          <MaterialTable
            title=""
            icons={tableIcons}
            columns={[
              {
                title: "Name",
                field: "groupName",
                cellStyle: {
                  textTransform: "capitalize",
                },
                render: (orgGroupsData) => (
                  <div className="d-flex">
                    <div className="me-2">
                      <img className="img-fluid memBrIcon" src={customerIcon} />
                    </div>
                    <div className="my-auto">{orgGroupsData.groupName}</div>
                  </div>
                ),
              },
              {
                title: "Members",
                field: "membersCount",
                cellStyle: {
                  textTransform: "capitalize",
                },
                render: (orgGroupsData) => <div className="my-auto">{orgGroupsData.membersCount}</div>,
              },
              {
                title: "Actions",
                field: "organizationMenu",
                headerStyle: { textAlign: "center" },
                render: (orgGroupsData) => (
                  <div className="text-center">
                    <Edit
                      style={{
                        width: "18px",
                        height: "18px",
                        cursor: "pointer",
                      }}
                      onClick={() => gotoEditPage(orgGroupsData)}
                    />
                  </div>
                ),
              },
            ]}
            options={{
              search: true,
              sorting: false,
              emptyRowsWhenPaging: false,
              draggable: false,
              paging: paging,
              pageSize: 10,
              headerStyle: {
                color: "#15355C ",
                fontSize: "15px",
                fontWeight: "bold",
              },
              rowStyle: (data, index) => ({
                backgroundColor: index % 2 == 0 ? "#F2F4F6" : "white",
                color: "darkblue",
              }),
              cellStyle: {
                padding: "0px",
                paddingLeft: "15px",
              },
            }}
            components={{
              Container: (props) => (
                <div>
                  <div {...props} />
                </div>
              ),
            }}
            data={orgGroupsData}
          />
        </div>

        <div className="col-lg-2">
          <button className="btnAddMember w-100" onClick={createGroupHandler}>
            CREATE GROUP
          </button>
          <br />
        </div>
      </div>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default Groups;
