import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import html2PDF from "jspdf-html2canvas";
import Editor from "nib-core";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import simpleColorConverter from "simple-color-converter";
import downloadImg from "../../images/downloadlist.svg";
import IconBlue from "../../images/ico_copy_blue.svg";
import CopyWhite from "../../images/ico_copy_grey.svg";
import logo from "../../images/logoheader.svg";
import { getColoursPreviewData, getDescription } from "../../redux/Actions/action";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./../ContentEditable/editorStyles.css";
import "./ColorsView.css";

toast.configure({
  autoClose: 2000,
});

/**
 * Functional component for colors share
 * @return {*} 
 */
function ColorsShare() {
  const dispatch = useDispatch();

  /**
   * function for get URL params
   * @return {*} 
   */
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const orgName = query.get("org") || "";

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  // state for userRole
  const [userRole, setUserRole] = useState("");

  // Primary Conversions
  let primaryColourNames = [];
  let primaryCmykCodes = [];
  let primaryRgbCodes = [];
  let primaryPantoneCodes = [];
  
  // Secondary Conversions
  let secondaryColourNames = [];
  let secondaryCmykCodes = [];
  let secondaryRgbCodes = [];
  let secondaryPantoneCodes = [];
  
  sessionStorage.removeItem("primaryValue");
  sessionStorage.removeItem("secondaryValue");
  sessionStorage.removeItem("gradientValue");
  
  let descriptionText = [];
  let innerHtmlContent = "";
  const coloursConfigurations = useSelector((state) => state.userReducer.ColoursData);
  descriptionText = useSelector((state) => state.userReducer.Description);

  useEffect(() => {
    setUserRole(localStorage.getItem("roleId"));
    dispatch(getColoursPreviewData(query.get("org"), successHandler, errorHandler));
  }, []);

  useEffect(() => {
    dispatch(getDescription(query.get("org"), "Colours", successHandler, errorHandler));
    innerHtmlContent = document.querySelector(".ProseMirror")?.innerHTML;

    if (innerHtmlContent) {
      document.getElementById("editContentDiv").setAttribute("class", "d-none");
      document.getElementById("contentDiv").innerHTML = innerHtmlContent;
    }
  }, [descriptionText?.description?.length]);

  /**
   * This method will call after successful execution
   */
  const successHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   *This method will call if any error occur during execution
   */
  const errorHandler = () => {
    setTimeout(() => {
      setDisplayLoader(false);
    }, 500);
  };

  /**
   * This method will call when user clicked on download button
   */
  const onClickDownloadPdf = () => {
    let pages;
    if (coloursConfigurations.pcCount > 3) {
      if (coloursConfigurations.scCount == 0 && coloursConfigurations.gcCount == 0) {
        pages = document.getElementsByClassName("pdfPageOnlyprwith4");
      } else {
        pages = document.getElementsByClassName("pdfPage");
      }
    } else {
      if (coloursConfigurations.pcCount <= 3) {
        if (coloursConfigurations.scCount == 0 && coloursConfigurations.gcCount == 0) {
          pages = document.getElementsByClassName("pdfPageOnlypr");
        } else {
          pages = document.getElementsByClassName("spdfPage");
        }
      }
    }

    html2PDF(pages, {
      jsPDF: {
        format: "a4",
        scale: 0.1,
      },
      imageType: "image/jpeg",
      output: orgName + ".pdf",
      imageQuality: 1,
      margin: {
        top: 10,
        right: 10,
        bottom: 0,
        left: 10,
      },
    });
  };

  /**
   * This method is used to copy the Primary Colour Code
   * @param {*} index
   */
  function copyToClipboardPrimaryColor(index) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(coloursConfigurations?.primaryColors["p" + [index + 1]]).select();
    document.execCommand("copy");
    $temp.remove();
    toast.success("Copied to clipboard");
  }

  /**
   * This method is used to copy the Secondary Colour Code
   * @param {*} index
   */
  function copyToClipboardSecondaryColor(index) {
    var $temp = $("<input>");
    $("body").append($temp);
    $temp.val(coloursConfigurations?.secondaryColors["s" + [index + 1]]).select();
    document.execCommand("copy");
    $temp.remove();
    toast.success("Copied to clipboard");
  }

  const primaryValuesHTMLDivs = [];
  const secondaryValuesHTMLDivs = [];
  const gradientValuesHTMLDivs = [];

  for (let index = 0; index < coloursConfigurations?.primaryColors?.primaryColorNames?.split(",").length; index++) {
    primaryColourNames.push(coloursConfigurations?.primaryColors?.primaryColorNames?.split(",")[index]);
    primaryPantoneCodes.push(coloursConfigurations?.primaryColors?.primaryPantoneNames.split(",")[index]);
    primaryCmykCodes.push(coloursConfigurations?.primaryColors?.primaryCMYKValues?.split(",")[index]);
  }

  for (let index = 0; index < coloursConfigurations?.secondaryColors?.secondaryColorNames?.split(",").length; index++) {
    secondaryColourNames.push(coloursConfigurations?.secondaryColors?.secondaryColorNames?.split(",")[index]);
    secondaryPantoneCodes.push(coloursConfigurations?.secondaryColors?.secondaryPantoneNames.split(",")[index]);
    secondaryCmykCodes.push(coloursConfigurations?.secondaryColors?.secondaryCMYKValues?.split(",")[index]);
  }

  for (let index = 0; index < coloursConfigurations?.pcCount; index++) {
    var primaryHexCode = coloursConfigurations?.primaryColors["p" + [index + 1]];
    primaryHexCode = primaryHexCode.replace("#", "");
    var primaryRGBColour;

    if (primaryHexCode.length == 3) {
      primaryRGBColour = new simpleColorConverter({
        hex3: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(primaryRGBColour.color);
    }
    if (primaryHexCode.length == 4) {
      primaryRGBColour = new simpleColorConverter({
        hex4: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(primaryRGBColour.color);
    }
    if (primaryHexCode.length == 6) {
      primaryRGBColour = new simpleColorConverter({
        hex6: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(primaryRGBColour.color);
    }
    if (primaryHexCode.length == 8) {
      primaryRGBColour = new simpleColorConverter({
        hex8: coloursConfigurations?.primaryColors["p" + [index + 1]],
        to: "rgb",
      });
      primaryRgbCodes.push(primaryRGBColour.color);
    }

    primaryValuesHTMLDivs.push(
      <div
        className={
          coloursConfigurations?.pcCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
            : coloursConfigurations?.pcCount == 2
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : coloursConfigurations?.pcCount == 3
            ? "col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3"
            : coloursConfigurations?.pcCount == 4
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : ""
        }
      >
        <div
          className="p-1"
          style={{
            background: coloursConfigurations?.primaryColors["p" + [index + 1]],
          }}
        ></div>
        <div className="border-bottom py-2 d-flex justify-content-between">
          <h6
            className="m-0"
            style={{
              color: coloursConfigurations?.primaryColors["p" + [index + 1]],
            }}
          >
            {primaryColourNames[index]}
          </h6>
        </div>

        <div className="py-1 pb-2 d-flex border-bottom">
          <div style={{ width: "37.5%" }} className="pe-3">
            <div className="d-flex pb-1">
              <label className="text-title m-0 pe-2">WEB / HEX</label>
              <Tooltip title="Copy">
                <img
                  id="imgCopy"
                  src={IconBlue}
                  style={{ cursor: "pointer" }}
                  onClick={() => copyToClipboardPrimaryColor(index)}
                />
              </Tooltip>
            </div>
            <p className="m-0 TextClr">{coloursConfigurations?.primaryColors["p" + [index + 1]]}</p>
          </div>

          <div className="d-flex">
            <div className="d-flex">
              <div className="me-1">
                <div className="text-title">RGB</div>
                <p className="m-0 TextClr">
                  R {primaryRgbCodes[index]?.r} G{primaryRgbCodes[index]?.g} B{primaryRgbCodes[index]?.b}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="py-1 pt-2 d-flex border-bottom">
          <div className="CmykView">
            <div className="d-flex">
              <div className="me-1">
                <div className="text-title">CMYK</div>
                <p className="m-0 TextClr">
                  {primaryCmykCodes[index]?.split("-")[0] == "null" &&
                  primaryCmykCodes[index]?.split("-")[1] == "null" &&
                  primaryCmykCodes[index]?.split("-")[2] == "null" &&
                  primaryCmykCodes[index]?.split("-")[3] == "null" ? (
                    "Not Provided"
                  ) : (
                    <p className="m-0">
                      C{primaryCmykCodes[index]?.split("-")[0].replace("null", 0)} M
                      {primaryCmykCodes[index]?.split("-")[1].replace("null", 0)} Y
                      {primaryCmykCodes[index]?.split("-")[2].replace("null", 0)} K
                      {primaryCmykCodes[index]?.split("-")[3].replace("null", 0)}
                    </p>
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="pe-3" style={{ width: "37.5%" }}>
            <div>
              <label className="text-title m-0 pb-1">PANTONE</label>
            </div>
            <p className="m-0 TextClr">{primaryPantoneCodes[index]}</p>
          </div>
          <div style={{ width: "25%" }}>
            <div>
              <label className="text-title m-0 pb-1">TINTS</label>
            </div>
            <p className="m-0 TextClr">100% - 10%</p>
          </div>
        </div>

        <div className="ColourSelection d-flex">
          <div
            className="ColourViewGridLeft w-75"
            style={{
              background: coloursConfigurations?.primaryColors["p" + [index + 1]],
            }}
          ></div>
          <div className="ColourViewGridRight w-25">
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.9",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.8",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.7",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.6",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.5",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.4",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.3",
              }}
            ></div>
            <div
              className="colorViewList"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.2",
              }}
            ></div>
            <div
              className="colorViewList border-bottom-0"
              style={{
                background: coloursConfigurations?.primaryColors["p" + [index + 1]],
                opacity: "0.1",
              }}
            ></div>
          </div>
        </div>
      </div>
    );
  }

  for (let index = 0; index < coloursConfigurations?.scCount; index++) {
    var secondaryHexCode = coloursConfigurations?.secondaryColors["s" + [index + 1]];
    secondaryHexCode = secondaryHexCode.replace("#", "");
    var secondaryRGBColour;

    if (secondaryHexCode.length == 3) {
      secondaryRGBColour = new simpleColorConverter({
        hex3: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(secondaryRGBColour.color);
    }
    if (secondaryHexCode.length == 4) {
      secondaryRGBColour = new simpleColorConverter({
        hex4: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(secondaryRGBColour.color);
    }
    if (secondaryHexCode.length == 6) {
      secondaryRGBColour = new simpleColorConverter({
        hex6: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(secondaryRGBColour.color);
    }
    if (secondaryHexCode.length == 8) {
      secondaryRGBColour = new simpleColorConverter({
        hex8: coloursConfigurations?.secondaryColors["s" + [index + 1]],
        to: "rgb",
      });
      secondaryRgbCodes.push(secondaryRGBColour.color);
    }
    secondaryValuesHTMLDivs.push(
      <div
        className={
          coloursConfigurations?.scCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3"
            : coloursConfigurations?.scCount == 2
            ? "col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mb-3"
            : coloursConfigurations?.scCount == 3
            ? "col-12 col-sm-4 col-md-4 col-lg-4 col-xl-4 mb-3"
            : coloursConfigurations?.scCount == 4
            ? "col-12 col-sm-3 col-md-3 col-lg-3 col-xl-3 mb-3"
            : ""
        }
      >
        <div className="border-bottom py-2 d-flex justify-content-between">
          <h6
            className="m-0"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1]],
            }}
          >
            {secondaryColourNames[index]}
          </h6>
        </div>

        <div
          className="SecDrColorsBgr ps-1 py-3"
          style={{
            background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
          }}
        >
          <div className="py-1 ps-2">
            <div className="SecondaryWebInput">
              <div className="d-flex pb-1">
                <label
                  className="m-0 text-title pe-2"
                  style={{
                    color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                  }}
                >
                  WEB / HEX
                </label>
                <Tooltip title="Copy">
                  <img
                    id="imgCopy"
                    src={CopyWhite}
                    style={{ cursor: "pointer" }}
                    onClick={() => copyToClipboardSecondaryColor(index)}
                  />
                </Tooltip>
              </div>
              <p
                className="m-0 TextClr"
                style={{
                  color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                }}
              >
                {coloursConfigurations?.secondaryColors["s" + [index + 1]]}
              </p>
            </div>
          </div>

          <div className="py-1 d-flex w-100 ps-2">
            <div>
              <div className="d-flex">
                <div className="me-1">
                  <div
                    className="text-title"
                    style={{
                      color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                    }}
                  >
                    RGB
                  </div>
                  <p
                    className="m-0 TextClr"
                    style={{
                      color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                    }}
                  >
                    R {secondaryRgbCodes[index]?.r} G{secondaryRgbCodes[index]?.g} B{secondaryRgbCodes[index]?.b}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="py-1 d-flex w-100 ps-2">
            <div className="w-50">
              <div className="d-flex">
                <div className="me-1">
                  <div
                    style={{
                      fontFamily: "roboto-bold",
                      color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                    }}
                  >
                    CMYK
                  </div>

                  <p
                    className="m-0 TextClr"
                    style={{
                      color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                    }}
                  >
                    {secondaryCmykCodes[index]?.split("-")[0] == "null" &&
                    secondaryCmykCodes[index]?.split("-")[1] == "null" &&
                    secondaryCmykCodes[index]?.split("-")[2] == "null" &&
                    secondaryCmykCodes[index]?.split("-")[3] == "null" ? (
                      "Not Provided"
                    ) : (
                      <p className="m-0">
                        C{secondaryCmykCodes[index]?.split("-")[0].replace("null", 0)} M
                        {secondaryCmykCodes[index]?.split("-")[1].replace("null", 0)} Y
                        {secondaryCmykCodes[index]?.split("-")[2].replace("null", 0)} K
                        {secondaryCmykCodes[index]?.split("-")[3].replace("null", 0)}
                      </p>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="py-1 ps-2">
            <div className="SecondaryWebInput">
              <div>
                <label
                  className="text-title m-0 pb-1"
                  style={{
                    color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                  }}
                >
                  PANTONE
                </label>
              </div>
              <p
                className="m-0 TextClr"
                style={{
                  color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
                }}
              >
                {secondaryPantoneCodes[index]}
              </p>
            </div>
          </div>
        </div>

        <div className="SecondaryColourViewGrid w-100">
          <div
            className="ps-2 SecondaryListGrid"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "1",
            }}
          >
            <label className="p-1 bd-highlight">100%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.9",
            }}
          >
            <label className="p-1 bd-highlight">90%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.8",
            }}
          >
            <label className="p-1 bd-highlight">80%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.7",
            }}
          >
            <label className="p-1 bd-highlight">70%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.6",
            }}
          >
            <label className="p-1 bd-highlight">60%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.5",
            }}
          >
            <label className="p-1 bd-highlight">50%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.4",
            }}
          >
            <label className="p-1 bd-highlight">40%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.3",
            }}
          >
            <label className="p-1 bd-highlight">30%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.2",
            }}
          >
            <label className="p-1 bd-highlight">20%</label>
          </div>
          <div
            className="ps-2 SecondaryListGrid1 border-bottom-0"
            style={{
              color: coloursConfigurations?.secondaryColors["s" + [index + 1] + "Color"],
              background: coloursConfigurations?.secondaryColors["s" + [index + 1]],
              opacity: "0.1",
            }}
          >
            <label className="p-1 bd-highlight">10%</label>
          </div>
        </div>
      </div>
    );
  }

  for (let index = 0; index < coloursConfigurations?.gcCount; index++) {
    gradientValuesHTMLDivs.push(
      <div
        className={
          coloursConfigurations?.gcCount == 1
            ? "col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"
            : coloursConfigurations?.gcCount == 2
            ? "col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6"
            : ""
        }
      >
        <div className="border-bottom py-2 pt-3 d-flex justify-content-between">
          <h6 className="m-0">Gradient {index + 1}</h6>
        </div>

        <div className="border-bottom py-2 d-flex justify-content-between DropDownSelect">
          <div className="w-50 me-4">
            <label className="pb-1 GradinTitleLabel">Colour 1</label>
            <p className="m-0 TextClr">{coloursConfigurations?.gradientColors["g" + [index + 1]].split(",")[0]}</p>
          </div>
          <div className="w-50">
            <label className="pb-1 GradinTitleLabel">Colour 2</label>
            <p className="m-0 TextClr">{coloursConfigurations?.gradientColors["g" + [index + 1]].split(",")[1]}</p>
          </div>
        </div>

        <div
          className="ColourSelection d-flex"
          style={{
            minHeight: "300px",
            backgroundImage:
              "linear-gradient(" + "45deg," + coloursConfigurations?.gradientColors["g" + [index + 1]] + ")",
          }}
        ></div>
      </div>
    );
  }

  return (
    <>
      <div className="container">
        <div className="mt-3 colorSection">
          <div className="row">
            <div className="col-lg-11 col-md-11 col-sm-11 col-12 my-auto">
              <h6 className="titLe py-2 m-0 my-auto border-bottom-0">
                Brand Assets <span> - Colours </span>
              </h6>
            </div>

            <div className="col-lg-1 col-md-1 col-sm-1 col-12">
              <div className="d-flex justify-content-end">
                <div>
                  <div className="d-flex px-1 buttonList">
                    <Tooltip title="Download" placement="top-start">
                      <div className="buttonGap me-0">
                        <IconButton
                          onClick={onClickDownloadPdf}
                          className="iconsStyles borderBtn border-end-0"
                          img={downloadImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="Download"
                        />
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr className="ColorsBorder" />

          <div
            className="row userColors"
            style={{
              background: "#fff",
              zIndex: "9999",
              position: "relative",
            }}
          >
            {/* DESCRIPTION READABLE - IF DESCRIPTION ALREADY EXISTS */}
            <div className="col-11 my-auto" id="contentDiv" style={{ minHeight: "40px" }} />

            {/* DESCRIPTION EDITABLE - IF DESCRIPTION NOT AVAILABLE/ EDIT THE DESCRIPTION */}
            <div
              className="col-12 my-auto"
              id="editContentDiv"
              style={{ pointerEvents: "none", cursor: "not-allowed" }}
            >
              {descriptionText?.description ? (
                <Editor
                  config={{
                    plugins: {
                      options: "inline link blockquote list",
                    },
                    toolbar: {
                      options: "top",
                      top: {
                        options: "inline link blockquote list",
                        inline: { options: "strong em underline subsup" },
                      },
                      inline: {
                        options: "inline link list",
                        inline: { options: "strong em underline" },
                      },
                    },
                  }}
                  defaultValue={JSON.parse(descriptionText.description)}
                />
              ) : (
                ""
              )}
            </div>
          </div>

          <div>
            {coloursConfigurations?.length == 0 ? (
              userRole === "1" || userRole === "2" ? (
                <div className="row text-center pt-5 mt-5">
                  <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                    No Colours are available
                  </h1>
                  <p
                    style={{
                      color: "#15355c",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                  >
                    Please configure colours by pressing above settings icon.
                  </p>
                </div>
              ) : (
                <div className="row text-center pt-5 mt-5">
                  <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                    No colours are available
                  </h1>
                </div>
              )
            ) : (
              <div style={{ marginTop: "-43px" }}>
                {/* if primary count greater than 3, secondary and gradient count is zero */}
                <div
                  className={
                    coloursConfigurations.pcCount == 4 &&
                    coloursConfigurations.scCount == 0 &&
                    coloursConfigurations.gcCount == 0
                      ? "pdfPageOnlyprwith4"
                      : "d-none"
                  }
                >
                  <div className="PriMartColors py-3 pt-1">
                    <div>
                      <div className="row displayItems">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <img src={logo} className="header-img" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 justify-content-end d-flex my-auto">
                          <span style={{ fontWeight: "bold" }}>{orgName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                      </div>
                    </div>
                    <hr className="ColorsBorder" />

                    <div className="row">{primaryValuesHTMLDivs}</div>
                  </div>
                </div>

                {/* if primary count greater than 3, secondary or gradient count not equal to zero */}
                <div
                  className={
                    coloursConfigurations.pcCount == 4 &&
                    (coloursConfigurations.scCount > 0 || coloursConfigurations.gcCount > 0)
                      ? "pdfPage"
                      : "d-none"
                  }
                >
                  <div className="PriMartColors py-3 pt-1">
                    <div>
                      <div className="row displayItems">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <img src={logo} className="header-img" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 justify-content-end d-flex my-auto">
                          <span style={{ fontWeight: "bold" }}>{orgName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                      </div>
                    </div>
                    <hr className="ColorsBorder" />

                    <div className="row">{primaryValuesHTMLDivs}</div>
                  </div>
                </div>

                {/* if primary count greater than 3, secondary or gradient count not equal to zero */}
                <div className={coloursConfigurations.pcCount == 4 ? "pdfPage" : "d-none"}>
                  {coloursConfigurations.scCount > 0 ? (
                    <div className="SecDrColors">
                      <h5 className="border-bottom text-uppercase pb-1">Secondary Colours</h5>
                      <div className="row">{secondaryValuesHTMLDivs}</div>
                    </div>
                  ) : (
                    ""
                  )}

                  {coloursConfigurations.gcCount > 0 ? (
                    <div className="GrDntColors py-3">
                      <h5 className="border-bottom mb-0 text-uppercase pb-1">Gradients</h5>
                      <div className="row">{gradientValuesHTMLDivs}</div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* if primary count less than 4, secondary and gradient count is zero */}
                <div
                  className={
                    coloursConfigurations.pcCount <= 3 &&
                    coloursConfigurations.scCount == 0 &&
                    coloursConfigurations.gcCount == 0
                      ? "pdfPageOnlypr"
                      : "d-none"
                  }
                >
                  <div className="PriMartColors py-3 pt-1">
                    <div>
                      <div className="row displayItems">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <img src={logo} className="header-img" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 justify-content-end d-flex my-auto">
                          <span style={{ fontWeight: "bold" }}>{orgName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                      </div>
                    </div>
                    <hr className="ColorsBorder" />

                    <div className="row">{primaryValuesHTMLDivs}</div>
                  </div>
                </div>

                {/* if primary count less than 4, secondary or gradient count not equal to zero */}
                <div
                  className={
                    coloursConfigurations.pcCount <= 3 && coloursConfigurations.scCount > 0 ? "spdfPage" : "d-none"
                  }
                >
                  <div className="PriMartColors py-3 pt-1">
                    <div>
                      <div className="row displayItems">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <img src={logo} className="header-img" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 justify-content-end d-flex my-auto">
                          <span style={{ fontWeight: "bold" }}>{orgName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                      </div>
                    </div>
                    <hr className="ColorsBorder" />

                    <div className="row">{primaryValuesHTMLDivs}</div>
                  </div>
                  <div className="SecDrColors">
                    <h5 className="border-bottom text-uppercase pb-1">Secondary Colours</h5>
                    <div className="row">{secondaryValuesHTMLDivs}</div>
                  </div>
                </div>

                {/* if primary count less than 4, secondary count not equal to 0 and gradient count is zero */}
                <div
                  className={
                    coloursConfigurations.pcCount <= 3 &&
                    coloursConfigurations.gcCount > 0 &&
                    coloursConfigurations.scCount == 0
                      ? "spdfPage"
                      : "d-none"
                  }
                >
                  <div className="PriMartColors py-3 pt-1">
                    <div>
                      <div className="row displayItems">
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                          <img src={logo} className="header-img" />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 col-6 justify-content-end d-flex my-auto">
                          <span style={{ fontWeight: "bold" }}>{orgName}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-6">
                        <h5 className="text-uppercase pb-1 m-0">Primary Colours</h5>
                      </div>
                    </div>
                    <hr className="ColorsBorder" />

                    <div className="row">{primaryValuesHTMLDivs}</div>
                  </div>
                  <div className="GrDntColors py-3">
                    <h5 className="border-bottom mb-0 text-uppercase pb-1">Gradients</h5>
                    <div className="row">{gradientValuesHTMLDivs}</div>
                  </div>
                </div>

                {/* if primary count less than 4, secondary count is zero or not equal to zero and gradient count  not equal to zero */}
                <div
                  className={
                    coloursConfigurations.pcCount <= 3 && coloursConfigurations.gcCount > 0 ? "spdfPage" : "d-none"
                  }
                >
                  <div className="GrDntColors py-3">
                    <h5 className="border-bottom mb-0 text-uppercase pb-1">Gradients</h5>
                    <div className="row">{gradientValuesHTMLDivs}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </>
  );
}

export default ColorsShare;
