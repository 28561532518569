import * as types from "../Actions/actionType";

const templateData = {
  getTemplates: [],
};

export const templateReducer = (template = templateData, action) => {
  switch (action.type) {
    case types.FETCH_TEMPLATES:
      return {
        ...template,
        getTemplates: action.payload,
      };
    default:
      return {
        ...template,
      };
  }
};
