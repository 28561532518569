import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import customer from "../images/customers.svg";
import customerHighlight from "../images/customers_hilights.svg";
import downArrow from "../images/down.svg";
import downBlue from "../images/downblue.svg";
import list from "../images/lists.svg";
import listHighlight from "../images/lists_hilights.svg";
import menu from "../images/menu.svg";
import menuHighlight from "../images/menu_hilights.svg";
import user from "../images/users.svg";
import userHighlight from "../images/users_hilights.svg";
import "./ExpandMenu.css";

/* assigning all options to an array */
const TREE_ITEMS = [
  {
    name: "Customers",
    link: "/admin/viewedit",
    icon1: customer,
    icon: customerHighlight,
    icon2: downArrow,
    icon3: downBlue,
    index: 0,
    options: [
      { name: "View/Edit", link: "/admin/viewedit" },
      { name: "Add", link: "/admin/adduser" },
    ],
  },
  {
    name: "Menus",
    link: "/admin/brandassets",
    icon1: menu,
    icon: menuHighlight,
    icon2: downArrow,
    icon3: downBlue,
    index: 1,
    options: [
      { name: "Style Guide", link: "#" },
      { name: "Brand Assets", link: "/admin/brandassets" },
      { name: "Templates", link: "/admin/templates" },
    ],
  },
  {
    name: "Lists",
    icon1: list,
    icon: listHighlight,
    index: 2,
    link: "/admin/lists",
    options: [],
  },
  {
    name: "Users",
    icon1: user,
    icon: userHighlight,
    link: "/admin/members",
    index: 3,
  },
];

/**
 * Functional component for AdminPortalmenu
 * @return {*} 
 */
const AdminExpandedmenu = () => {
  const [expand, setExpand] = useState(localStorage.getItem("menuIndex") || 0);

  useEffect(() => {
    if (document.getElementById("0s")) {
      document.getElementById("0s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("1s")) {
      document.getElementById("1s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("2s")) {
      document.getElementById("2s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("3s")) {
      document.getElementById("3s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById(localStorage.getItem("mainMenu"))) {
      document.getElementById(localStorage.getItem("mainMenu")).style.borderLeft = "5px solid #15355c";
    }
  }, []);

  /**
   * This method will call when user clicks on any menu item
   * @param {*} index
   */
  const onItemClick = (index) => {
    localStorage.setItem("menuIndex", index);
    setExpand(localStorage.getItem("menuIndex"));

    if (document.getElementById("0s")) {
      document.getElementById("0s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("1s")) {
      document.getElementById("1s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("2s")) {
      document.getElementById("2s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("3s")) {
      document.getElementById("3s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById(index + "s")) {
      document.getElementById(index + "s").style.borderLeft = "5px solid #15355c";
    }
    localStorage.setItem("mainMenu", index + "s");
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
      <div className="m-0 p-0" style={{ overflow: "hidden" }}>
        {TREE_ITEMS.map((items) => (
          <div key={items.name}>
            <NavLink
              title={items.name}
              id={items.index}
              style={{ cursor: "pointer" }}
              onClick={() => onItemClick(items.index)}
              to={items.link ?? "#"}
              className={`school-menu ${expand == items.index ? "school-menu-selected" : ""}`}
            >
              <div id={items.index + "s"} className="selectedList" style={{ height: "39px", padding: "5px" }}>
                {expand == items.index ? (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                ) : (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon1}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                )}
                <label
                  style={{
                    paddingRight: "0px",
                    float: "left",
                    marginLeft: "2px",
                    marginTop: "5px",
                  }}
                >
                  {items.name}
                </label>
                {expand == items.index ? (
                  <img
                    alt=""
                    src={items.icon2}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <img
                    alt=""
                    src={items.icon3}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                )}
                {expand == items.index && items.options?.length ? "" : ""}
              </div>
            </NavLink>

            {expand == items.index &&
              items.options?.map((optionsData, index) => (
                <div key={optionsData.name}>
                  <div>
                    {optionsData.link == "#" ? (
                      <NavLink to={optionsData.link} className="menu-items-styles" style={{ cursor: "not-allowed" }}>
                        {optionsData.name}
                        <br />
                      </NavLink>
                    ) : (
                      <NavLink
                        to={optionsData.link}
                        className={({ isActive }) =>
                          [isActive ? "menu-item-lbl" : "menu-items-styles"].filter(Boolean).join(" ")
                        }
                      >
                        {optionsData.name}
                        <br />
                      </NavLink>
                    )}
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminExpandedmenu;
