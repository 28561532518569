export const appRoutes = {
  // Common Pages
  rootRoute: "/*",
  pageUnderConstruction: "/pageUnderConstruction",
  underConstruction: "/underConstruction",
  termsAndConditions: "/termsAndConditions",
  homepage: "/homepage",
  resetpassword: "/reset?verifyId",
  resetPasswordId: "/reset",
  setPassword: "/setpassword",
  feedback: "/feedback",
  colourShare: "/colourPreview",
  dynamicLoginPage: "/grafix/:username",

  // Admin Pages
  viewEdit: "/admin/viewedit",
  addUser: "/admin/adduser",
  editUser: "/admin/editUser",
  adminStyleGuide: "admin/styleguide",
  adminBrandAssets: "/admin/brandassets",
  adminTemplates: "/admin/templates",
  lists: "/admin/lists",
  adminMembers: "/admin/members",

  // School Admin And School User Pages
  styleGuide: "/styleguide",
  brandAssetLogos: "/brandassets/logos",
  colours: "/brandassets/colours",
  coloursEdit: "/brandassets/coloursedit",
  coloursView: "/brandassets/coloursview",
  fonts: "/brandassets/fonts",
  fontsEdit: "/brandassets/fontsEdit",
  fontsView: "/brandassets/fontsView",
  logoDetails: "/brandassets/logodetails",
  brandAssetGeneric: "/brandassets/brandAsset",
  assetDetails: "/brandassets/assetDetails",
  templates: "/templates",
  letterHeadTemplate: "/templates/template",
  templateDetails: "/templates/templatedetails",
  orgMembers: "/orgmembers",
  orgMembersAdd: "/orgmembers/add",
  orgMembersEdit: "/orgmembers/edit",
  groups: "/groups",
  groupsCreateGroup: "/groups/creategroup",
  groupsEditGroup: "/groups/editgroup",
  aboutUs: "/aboutus",
};
