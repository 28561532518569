import Switch from "@material-ui/core/Switch";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import customerIcon from "../../../images/customerprofile.svg";
import btn_share from "../../../images/shareicon.svg";
import defaultLogo from "../../../images/uploadprofile.png";
import { createOrganization, inviteUser, isEmailExisted, isUserNameExisted } from "../../../redux/Actions/action";
import { appRoutes } from "../../../util/constants";
import { useCallbackPrompt } from "../../Hooks/useCallbackPrompt";
import InputTextField from "../../Shared/InputTextField";
import { Loader } from "../../Shared/Loader";
import "./viewEdit.css";

/**
 * Functional component is used to create a new customer
 * @return {*} 
 */
function Adduser() {
  const ImageUploadModal = React.lazy(() => import("../../Shared/Modals/ImageUploadModal/ImageUploadModal"));
  const ConfirmModal = React.lazy(() => import("../../Shared/Modals/ConfirmModal"));
  const DialogBox = React.lazy(() => import("../../Hooks/DialogBox.component.tsx"));

  const navigate = useNavigate("");
  const dispatch = useDispatch();


  const [organization, setOrganization] = useState({
    type: 0,
    Name: "",
    schoolContactNumber: "",
    firstName: "",
    lastName: "",
    email: "",
    file: "",
    logo: defaultLogo,
    status: 0,
    subscriptionType: 0,
    subscriptionDate: "",
    lastRenewalDate: "",
    nextRenewalDate: "",
    userPack: 1,
  });

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(false);

  // error state for mobile, firstName, lastName and originzation
  const [organizationError, setOrganizationError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [isOrganizationExist, setIsOrganizationExist] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isEmailExist, setIsEmailExist] = useState("");
  const [mobileError, setMobileError] = useState("");

  // state for showing upload modal and dialog
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showInvite, setShowInvite] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  // UserStatus state
  const [state, setState] = useState({ userStatus: true });

  useEffect(() => {
    localStorage.setItem("switchUser", "false");
    localStorage.removeItem("orgType");
    localStorage.removeItem("brandAssetsOptionsName");
    localStorage.removeItem("templatesOptionsName");
    localStorage.removeItem("schoolMenuIndex");
    sessionStorage.removeItem("primaryValue");
    sessionStorage.removeItem("secondaryValue");
    sessionStorage.removeItem("gradientValue");
    localStorage.setItem("orgName", "Super Admin");

    if (localStorage.getItem("roleId") !== "1") {
      navigate("/");
      localStorage.setItem("schoolMenuIndex", 0);
    }
  }, []);

  /**
   * To close the Confirmation pop up
   */
  const closeInvite = () => {
    setShowInvite(false);
  };

  /**
   * Method to be called when user change the type
   * @param {*} evt
   */
  const onRadioTypeChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({ ...prev, type: evt.target.value }));
  };

  /**
   * Method to be called when user enter the school name
   * @param {*} evt
   */
  const onNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      Name: evt.target.value,
    }));
    // validate(organization, "organizationName", evt.target.value);
  };

  /**
   * Used to check the Name existence
   * @param {*} evt
   */
  const onNameBlur = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setOrganization((prev) => ({
      ...prev,
      Name: evt.target.value,
    }));
    if (evt.target.value.length > 0) {
      dispatch(isUserNameExisted(evt.target.value, onNameResponse));
    }
    validate(organization, "organizationName", evt.target.value);
  };

  /**
   * Used to Set the Name existences error
   * @param {*} res
   */
  const onNameResponse = (res) => {
    if (res.status) {
      setIsOrganizationExist("");
    } else {
      setIsOrganizationExist(res.message);
    }
  };

  /**
   * Method to be called when user enters phone number
   * @param {*} evt
   */
  const onNumberChange = (evt) => {
    setShowDialog(true);
    evt.target.value = evt.target.value.replace(/[^0-9]/g, "");
    setOrganization((prev) => ({
      ...prev,
      schoolContactNumber: evt.target.value,
    }));
    validate(organization, "Mobile", evt.target.value);
  };

  /**
   * Method to called when user select the logo image
   * @param {*} evt
   */
  const handleLogoChange = (evt) => {
    let isImage = evt.target.files[0];
    if (isImage["type"] === "image/png" || isImage["type"] === "image/jpeg" || isImage["type"] === "image/jpg") {
    } else {
      evt.target.value = "";
      return <div className="warningMsg">{toast.warning("Please Add valid Logo")}</div>;
    }

    let file_size = evt.target.files[0].size;
    const file = Math.round(file_size / 1024);
    const reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        if (file > 2048) {
          setShowImageUpload(false);
          toast.warning("Please Add Logos with size less than 2MB");
        } else {
          setShowDialog(true);
          setOrganization((prev) => ({
            ...prev,
            logo: URL.createObjectURL(evt.target.files[0]),
            file: evt.target.files[0],
          }));
          setShowImageUpload(false);
        }
      });
    });
  };

  /**
   * Method to be called when user enter the first name
   * @param {*} evt
   */
  const onFirstNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  };

  /**
   * Method to be called when user enter the first name
   * @param {*} evt
   */
  const onBlurFirstNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    validate(organization, "FirstName", evt.target.value);
  };

  /**
   * Method to be called when user enter the Last name
   * @param {*} evt
   */
  const onLastNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  };

  /**
   * Method to be called when user enter the Last name
   * @param {*} evt
   */
  const onBlurLastNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    validate(organization, "LastName", evt.target.value);
  };

  /**
   * Method to be called when user enter the Email
   * @param {*} evt
   */
  const onEmailChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    validate(organization, "Email", evt.target.value);
  };

  /**
   * Used to check the Email existences
   * @param {*} evt
   */
  const onEmailBlur = (evt) => {
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    if (evt.target.value.length > 0) {
      dispatch(isEmailExisted(evt.target.value, onEmailResponse));
    }
    validate(organization, "Email", evt.target.value);
  };

  /**
   * Used to set the Email existences error
   * @param {*} res
   */
  const onEmailResponse = (res) => {
    if (res.status) {
      setIsEmailExist("");
    } else {
      setIsEmailExist("user email is already exist");
    }
  };

  /**
   * Used to user status changed
   * @param {*} event
   */
  const handleChange = (event) => {
    setShowDialog(true);
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  /**
   * Method to be called when user clicks on active and in active button
   * @param {*} val
   */
  // const changeActiveStatus = (val) => {
  //   setOrganization((prev) => ({ ...prev, status: val }));
  // };

  /**
   * Method to be called when user change the type
   * @param {*} evt
   */
  const onSubRadioChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      subscriptionType: evt.target.value,
    }));
  };

  /**
   * Method to be called when user change the Subscription Date
   * @param {*} evt
   */
  const onSubscriptionDateChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      subscriptionDate: evt.target.value,
    }));
  };

  /**
   * Method to be called when user change the Last Renewal Date
   * @param {*} evt
   */
  const onLastRenewalDateChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      lastRenewalDate: evt.target.value,
    }));
  };

  /**
   * Method to be called when user change the Next Renewal Date
   * @param {*} evt
   */
  const onNextRenewalDataChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      nextRenewalDate: evt.target.value,
    }));
  };

  /**
   * Method to be called when user change the Next Renewal Date
   * @param {*} evt
   */
  const onRadioUserPackChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      userPack: evt.target.value,
    }));
  };

  /**
   * Method to Validate All the Input fields
   * @param {*} value
   * @param {*} inputType
   * @param {*} inputValue
   * @return {*}
   */
  const validate = (value, inputType, inputValue) => {
    const regex = /^(?!\.)[a-zA-Z0-9-]+(?!.*?\.\.)[a-zA-Z0-9-_.]+(?!.*\.$)+@[a-zA-Z0-9-]+[a-z.0-9]+\.[a-z.]{2,4}$/;
    let error = {};
    const regex1 = /^[\d]*[a-z_ ][a-z \d_]*$/gi;
    console.log(typeof (value.email));
    if (inputType === "Form") {
      // School Name Validation
      if (value.Name.trim() === "") {
        setIsOrganizationExist("");
        setOrganizationError("Please provide name...");
      } else if (!regex1.test(value.Name.trim())) {
        setOrganizationError("Name should be alphanumeric");
      } else if (value.Name.trim().length < 4) {
        setOrganizationError("Name of Education/Corporate should be between 4 to 50 characters");
      } else {
        setOrganizationError("");
      }

      // Mobile Number Validation
      if (value.schoolContactNumber) {
        if (value.schoolContactNumber.trim() !== "") {
          if (value.schoolContactNumber.length < 5) {
            setMobileError("Please enter 5 to 15 digits Phone Number...");
          } else {
            setMobileError("");
          }
        } else {
          setMobileError("");
        }
      }

      // Email Validation
      if (value.email === "") {
        setIsEmailExist("");
        setEmailError("Please provide Valid Email...");
      } else {
        if (!regex.test(value.email)) {
          setIsEmailExist("");
          setEmailError("Please provide Valid Email format...");
        } else {
          setEmailError("");
        }
      }
    }

    // School name or organization validation
    if (inputType === "organizationName") {
      if (inputValue.trim() === "") {
        setIsOrganizationExist("");
        setOrganizationError("Please provide name...");
      } else if (!regex1.test(value.Name.trim())) {
        setOrganizationError("Name should be alphanumeric");
      } else if (inputValue.trim().length < 4) {
        setOrganizationError("Name of Education/Corporate should be between 4 to 50 characters");
      } else {
        setOrganizationError("");
      }
    }

    // First Name validations
    if (inputType === "FirstName") {
      if (inputValue.trim() !== "") {
        if (inputValue.trim().length < 2) {
          setFirstNameError("First Name should be between 2 to 16 characters");
        } else if (!regex1.test(value.firstName.trim())) {
          setFirstNameError("First Name should be alphanumeric");
        } else {
          setFirstNameError("");
        }
      } else {
        setFirstNameError("");
      }
    }

    // Last Name validations
    if (inputType === "LastName") {
      if (inputValue.trim() !== "") {
        if (inputValue.trim().length < 2) {
          setLastNameError("Last Name should be between 2 to 16 characters");
        } else if (!regex1.test(value.lastName.trim())) {
          setLastNameError("Last Name should be alphanumeric");
        } else {
          setLastNameError("");
        }
      } else {
        setLastNameError("");
      }
    }

    // Mobile Number validation when user enters the input data
    if (inputType === "Mobile") {
      if (inputValue.trim() !== "") {
        if (inputValue.length < 5) {
          setMobileError("Please enter 5 to 15 digits Phone Number...");
        } else {
          setMobileError("");
        }
      } else {
        setMobileError("");
      }
    }

    // Email validation when user enters the input data
    if (inputType === "Email") {
      if (inputValue === "") {
        setIsEmailExist("");
        setEmailError("Please provide Valid Email...");
      } else {
        if (!regex.test(inputValue)) {
          setEmailError("Please provide Valid Email format...");
        } else {
          setEmailError("");
        }
      }
    }

    return error;
  };

  /**
   * First Name and Last Name Validations
   * @param {*} event
   * @return {*}
   */
  const EkeyPress = (event) => {
    var regex = new RegExp("^[a-zA-Z@0-9_.]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  /**
   * Method to called when user clicks on invite button
   */
  const onInvite = () => {
    validate(organization, "Form", organization);
    if (
      organizationError.length > 0 ||
      isOrganizationExist.length > 0 ||
      mobileError.length > 0 ||
      // firstNameError.length > 0 ||
      // lastNameError.length > 0 ||
      emailError.length > 0 ||
      isEmailExist.length > 0
    ) {
      setShowInvite(false);
      return;
    } else if (organization.Name.trim() !== "" && organization.email !== "") {
      setShowDialog(false);
      setDisplayLoader(true);
      dispatch(inviteUser(organization, onInviteSuccessHandler, errorHandler));
    }

    setShowInvite(false);
  };

  /**
   * Method to be called when user click on save button
   * @param {*} e
   */
  const onSave = (e) => {
    e.preventDefault();
    validate(organization, "Form", organization);

    if (
      organizationError.length > 0 ||
      isOrganizationExist.length > 0 ||
      mobileError.length > 0 ||
      firstNameError.length > 0 ||
      lastNameError.length > 0 ||
      emailError.length > 0 ||
      isEmailExist.length > 0
    ) {
      return;
    } else if (organization.Name !== "" && organization.email !== "") {
      setShowDialog(false);
      setDisplayLoader(true);
      dispatch(createOrganization(organization, successHandler, errorHandler));
    }
  };

  const onInviteSuccessHandler = (res) => {
    setDisplayLoader(false);
    navigate("/admin/editUser", { state: JSON.stringify(res) });
  };

  const successHandler = () => {
    setDisplayLoader(false);
    navigate(appRoutes.viewEdit);
  };

  const errorHandler = () => {
    setDisplayLoader(false);
    setShowDialog(true);
  };

  const showInviteModal = () => {
    if (document.getElementById("email").value.length > 0) {
      setShowInvite(true);
    } else {
      validate(organization, "Form", organization);
    }
  };
  return (
    <div>
      <Suspense fallback={<Loader />}>
        {showPrompt &&
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        }
      </Suspense>
      <Suspense fallback={<Loader />}>
        {
          showImageUpload &&
          <ImageUploadModal
            showImageUpload={showImageUpload}
            setShowImageUpload={setShowImageUpload}
            handleLogoChange={handleLogoChange}
          />
        }
      </Suspense>
      <div id="remove-padding" className="container addUserList mt-3">
        <div className="row mb-2">
          <div className="col-lg-9 d-flex">
            <h6 className="titLe py-2 m-0 my-auto">
              Customers <span>- Add New</span>
            </h6>
          </div>

          <div className="col-lg-3 d-flex justify-content-end">
            <button className="saveBtn" onClick={onSave}>
              SAVE
            </button>
          </div>
        </div>

        <hr />

        <div className="row pt-3 py-2">
          <div className="col-lg-4">
            <label className="addUserLabel my-auto w-100 labelText">Customer Type</label>
            <div className="d-flex form-group align-items-center mt-2">
              <div className="form-group d-flex align-items-center mt-2">
                <label className="addUserLabel my-auto">Type</label>
                <label className="pe-2 radioLabel">
                  <input
                    type="radio"
                    name="type"
                    onChange={onRadioTypeChange}
                    className="radioButtons"
                    value={0}
                    checked={organization?.type == 0}
                  />
                  Education
                </label>
                <label className="pl-3 radioLabel">
                  <input
                    type="radio"
                    name="type"
                    onChange={onRadioTypeChange}
                    className="radioButtons"
                    value={1}
                    checked={organization?.type == 1}
                  />
                  Corporate
                </label>
              </div>
            </div>

            <div className="form-group align-items-center mt-2">
              <label className="addUserLabel my-auto labelText">Name of Education/Corporate</label>
              <InputTextField
                type="text"
                placeholder="Please enter name"
                className="textField w-100 m-0"
                name="name"
                value={organization.Name}
                onChange={onNameChange}
                onBlur={onNameBlur}
                autocomplete="off"
                minLength="4"
                maxLength="50"
              />

              <p className="text-danger lblErr" id="dr_none">
                {isOrganizationExist.length > 0 ? isOrganizationExist : organizationError}
              </p>
            </div>

            <div className="form-group align-items-center">
              <label className="addUserLabel my-auto labelText">Phone Number</label>
              <InputTextField
                type="text"
                placeholder="Please enter phone number"
                name="schoolContactNumber"
                className="textField w-100 m-0"
                value={organization.schoolContactNumber}
                onChange={onNumberChange}
                autocomplete="off"
                minLength="5"
                maxLength="15"
              />

              <p className="text-danger lblErr">{mobileError}</p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="align-items-center pb-2 text-center">
              <label className="labelText my-auto ">Submit Your Logo</label>

              <div className="logoContainer mt-2">
                <div className="px-5 py-2 uploadText" onClick={(e) => setShowImageUpload(true)}>
                  <div className="profileImage mx-auto d-flex align-items-center justify-content-center">
                    <img className="img-fluid" alt="" src={organization.logo} />
                  </div>
                  <div>
                    <p className="m-0 pt-1">Click here to Add your logo (max 2MB)</p>
                    <p className="AddEdit pt-1 m-0 text-decoration-underline">ADD/EDIT</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="row pb-2">
          <div className="py-3">
            <label className="addUserLabel my-auto headLabel pe-3">PRIMARY CONTACT</label>
            <br />
            <span className="primaryText">
              Primary contact will always have full administrator rights for the customer
            </span>
          </div>

          <div className="col-lg-4">
            <label className="addUserLabel my-auto labelText">First Name</label>
            <InputTextField
              type="text"
              placeholder="Please enter first name"
              className="textField w-100 m-0"
              name="firstName"
              value={organization.firstName}
              onChange={onFirstNameChange}
              onBlur={onBlurFirstNameChange}
              autocomplete="off"
              minLength="2"
              maxLength="16"
            />
            <p className="text-danger lblErr">{firstNameError}</p>
          </div>
          <div className="col-lg-4">
            <label className="addUserLabel my-auto labelText">Last Name</label>
            <InputTextField
              type="text"
              placeholder="Please enter last name"
              className="textField w-100 m-0"
              name="lastName"
              value={organization.lastName}
              onChange={onLastNameChange}
              onBlur={onBlurLastNameChange}
              autocomplete="off"
              minLength="2"
              maxLength="16"
            />
            <p className="text-danger lblErr">{lastNameError}</p>
          </div>

          <div className="col-lg-8 EmailInput">
            <label className="addUserLabel my-auto labelText">Email</label>

            <div className="input-group inviteLeft">
              <InputTextField
                id="email"
                type="email"
                name="email"
                onChange={onEmailChange}
                value={organization.email}
                onBlur={onEmailBlur}
                onKeyPress={EkeyPress}
                className="form-control m-0 textField"
                placeholder="name@domainname.com"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              {emailError?.trim().length > 0 ||
                organizationError.length > 0 ||
                isOrganizationExist.length > 0 ||
                isEmailExist?.trim().length > 0 ? (
                <span className="input-group-text textField InviteField inviteInput" id="basic-addon2">
                  <img alt="" src={btn_share} />
                  <label>INVITE</label>
                </span>
              ) : (
                <span
                  className="input-group-text textField InviteField inviteInput"
                  id="basic-addon2"
                  onClick={showInviteModal}
                >
                  <img alt="" src={btn_share} />
                  <label>INVITE</label>
                </span>
              )}
            </div>

            <p className="text-danger lblErr">{isEmailExist.length > 0 ? isEmailExist : emailError}</p>
          </div>
        </div>

        <hr />

        <div className="row py-3">
          <label className="addUserLabel my-auto headLabel py-2">SUBSCRIPTION DETAILS</label>

          <div className="col-lg-4 swiTch d-flex">
            <label className="addUserLabel my-auto labelText">Status</label>
            <Switch
              checked={state.userStatus}
              onChange={handleChange}
              color="primary"
              name="userStatus"
              disabled
              style={{ cursor: "not-allowed !important" }}
              inputProps={{ "aria-label": "primary checkbox" }}
              className="AddStats"
            />
            <label className="addUserLabel my-auto text-secondary">Active</label>
          </div>
          <div className="col-lg-8">
            <div className="form-group d-flex align-items-center mt-2 py-1">
              <label className="addUserLabel my-auto pe-2 labelText">Type</label>
              <label className="pe-4 radioLabel labelText">
                <input
                  type="radio"
                  name="subscriptionType"
                  value={0}
                  className="radioButtons"
                  defaultChecked
                  onChange={onSubRadioChange}
                />
                Annual
              </label>
              <label className="radioLabel labelText">
                <input
                  type="radio"
                  name="subscriptionType"
                  value={1}
                  className="radioButtons"
                  onChange={onSubRadioChange}
                />
                Monthly
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <label className="addUserLabel my-auto subScript pe-3 labelText">Subscription Date</label>
            <InputTextField
              type="date"
              className="textField w-100 m-0"
              onChange={onSubscriptionDateChange}
              value={organization.subscriptionDate}
            />
          </div>
          <div className="col-lg-4">
            <label className="addUserLabel my-auto subScript labelText">Last Renewal Date</label>
            <InputTextField
              type="date"
              value={organization.lastRenewalDate}
              onChange={onLastRenewalDateChange}
              className="textField w-100 m-0"
            />
          </div>
          <div className="col-lg-4">
            <label className="addUserLabel my-auto subScript labelText">Next Renewal Date</label>
            <InputTextField
              type="date"
              className="textField w-100 m-0"
              value={organization.nextRenewalDate}
              onChange={onNextRenewalDataChange}
            />
          </div>
        </div>

        <div className="row py-3 pb-3">
          <div className="col-lg-8">
            <label className="addUserLabel my-auto pb-2 labelText">User Pack</label>
            <div className="row form-group">
              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      value={1}
                      onChange={onRadioUserPackChange}
                      defaultChecked
                    />
                  </div>
                  Upto 10
                </label>
              </div>

              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      value={2}
                      onChange={onRadioUserPackChange}
                    />
                  </div>
                  Upto 50
                </label>
              </div>

              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      value={3}
                      onChange={onRadioUserPackChange}
                    />
                  </div>
                  Upto 100
                </label>
              </div>

              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      value={4}
                      onChange={onRadioUserPackChange}
                    />
                  </div>
                  Unlimited
                </label>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group align-items-center">
              <label className="addUserLabel my-auto pb-2 labelText pe-2">Current Users</label>
              <div className="d-flex justify-content-start">
                <label className="radioLabel curntUser currentUsersLabel currentUser pe-2">
                  <img alt="" src={customerIcon} className="pe-4" />
                  <span>0 &nbsp;</span> out of
                  <span>
                    &nbsp;
                    {organization.userPack == 1
                      ? "10"
                      : organization.userPack == 2
                        ? "50"
                        : organization.userPack == 3
                          ? "100"
                          : "∞"}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Suspense fallback={<Loader />}>
        {showInvite &&
          <ConfirmModal
            open={showInvite}
            onClose={closeInvite}
            onConfirm={onInvite}
            titleText={
              "You are about to invite the Primary Contact to have access to their site. This will give full access to the clients site. Are you sure you want to send this invitation?"
            }
          />
        }
      </Suspense>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default Adduser;
