import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import admin from "../images/admin.svg";
import brandAssetsIcon from "../images/brandassets.svg";
import brandAssetHighlight from "../images/brandassetsb.svg";
import adminIcon from "../images/btn_admsetting.svg";
import portfolio from "../images/btn_templtes.svg";
import downArrow from "../images/down.svg";
import downBlue from "../images/downblue.svg";
import template from "../images/portfolio.svg";
import styleGuideIcon from "../images/styleguide.svg";
import styleGuideHighlight from "../images/styleguides.svg";
import { getBrandAssetList, getTemplatesList } from "../redux/Actions/action";
import "./ExpandMenu.css";
import { Loader } from "./Shared/Loader";

/**
 * Functional component for user portal menu
 * @return {*} 
 */
const Expandedmenu = () => {
  const dispatch = useDispatch();

  const [expand, setExpand] = useState(localStorage.getItem("schoolMenuIndex") || 0);

  const [brandMenuItems, setBrandMenuItems] = useState([]);
  const [templatesMenuItems, setTemplatesMenuItems] = useState([]);
  const [displayLoader, setDisplayLoader] = useState(true);

  let eduBrandAssetsItems = [];
  let orgBrandAssetsItems = [];
  let eduTemplates = [];
  let orgTemplates = [];
  let brandAssets = [];
  let templateAssets = [];

  brandAssets = useSelector((state) => state.assetReducer.getBrandAssets);
  templateAssets = useSelector((state) => state.templateReducer.getTemplates);

  useEffect(() => {
    dispatch(getBrandAssetList(successHandler, errorHandler));
    setBrandMenuItems(brandAssets);
    dispatch(getTemplatesList(successHandler, errorHandler));
    setTemplatesMenuItems(templateAssets);
    if (document.getElementById("0s")) {
      document.getElementById("0s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("1s")) {
      document.getElementById("1s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("2s")) {
      document.getElementById("2s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("3s")) {
      document.getElementById("3s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById(localStorage.getItem("mainMenu"))) {
      document.getElementById(localStorage.getItem("mainMenu")).style.borderLeft = "5px solid #15355c";
    }
  }, [brandAssets.length, templateAssets.length]);

  /**
   * This method will call when user clicks on the menu item
   * @param {*} index
   */
  const onItemClick = (index) => {
    localStorage.setItem("schoolMenuIndex", index);
    setExpand(localStorage.getItem("schoolMenuIndex"));
    if (document.getElementById("0s")) {
      document.getElementById("0s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("1s")) {
      document.getElementById("1s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("2s")) {
      document.getElementById("2s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById("3s")) {
      document.getElementById("3s").style.borderLeft = "5px solid #f7fafd";
    }
    if (document.getElementById(index + "s")) {
      document.getElementById(index + "s").style.borderLeft = "5px solid #15355c";
    }
    localStorage.setItem("mainMenu", index + "s");
  };

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
  };

  if (brandMenuItems) {
    brandMenuItems.map((item) => {
      if (item.eduMenu === 1) {
        if (item.name.trim() === "Colours") {
          eduBrandAssetsItems.push({
            name: "Colours",
            link: "/brandassets/coloursview",
          });
        } else if (item.name.trim() === "Fonts") {
          eduBrandAssetsItems.push({
            name: "Fonts",
            link: "/brandassets/fontsView",
          });
        } else {
          eduBrandAssetsItems.push({
            name: item.name,
            link: "/brandassets/brandAsset",
          });
        }
      }

      if (item.orgMenu === 1) {
        if (item.name.trim() === "Colours") {
          orgBrandAssetsItems.push({
            name: "Colours",
            link: "/brandassets/coloursview",
          });
        } else if (item.name.trim() === "Fonts") {
          orgBrandAssetsItems.push({
            name: "Fonts",
            link: "/brandassets/fontsView",
          });
        } else {
          orgBrandAssetsItems.push({
            name: item.name,
            link: "/brandassets/brandAsset",
          });
        }
      }
    });
  }

  if (templatesMenuItems) {
    templatesMenuItems.map((item) => {
      if (item.eduMenu == 1) {
        eduTemplates.push({
          name: item.name,
          link: "/templates/template",
        });
      }

      if (item.orgMenu == 1) {
        orgTemplates.push({
          name: item.name,
          link: "/templates/template",
        });
      }
    });
  }

  const TREE_ITEMS = [
    {
      name: "Style Guide",
      icon: styleGuideIcon,
      icon1: styleGuideHighlight,
      link: "/styleguide",
      index: 0,
    },
  ];

  const BRANDASSETS_TREE_ITEMS = [
    {
      name: "Brand Assets",
      icon1: brandAssetsIcon,
      icon: brandAssetHighlight,
      icon2: downArrow,
      icon3: downBlue,
      index: 1,
      options: localStorage.getItem("orgType") == "School" ? eduBrandAssetsItems : orgBrandAssetsItems,
    },
  ];

  const TEMPLATES_TREE_ITEMS = [
    {
      name: "Templates",
      icon: template,
      icon1: portfolio,
      icon2: downArrow,
      icon3: downBlue,
      index: 2,
      options: localStorage.getItem("orgType") == "School" ? eduTemplates : orgTemplates,
    },
  ];

  const BOTTOM_TREE_ITEMS = [
    {
      name: "Admin Settings",
      link: "/orgmembers",
      icon: admin,
      icon1: adminIcon,
      icon2: downArrow,
      icon3: downBlue,
      index: 3,
      options: [
        { name: "Members", link: "/orgmembers" },
        { name: "Groups", link: "/groups" },
      ],
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
    >
      {displayLoader ? <Loader /> : <div></div>}
      <div className="m-0">
        {TREE_ITEMS.map((items) => (
          <div key={items.name}>
            <NavLink
              id={items.index}
              style={{ cursor: "pointer" }}
              onClick={() => onItemClick(items.index)}
              to={items.link ?? "#"}
              className={`school-menu ${expand == items.index ? "school-menu-selected" : ""}`}
            >
              <div id={items.index + "s"} className="selectedList" style={{ height: "39px", padding: "5px" }}>
                {expand == items.index ? (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                ) : (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon1}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                )}

                <label
                  title={items.name}
                  style={{
                    paddingRight: "0px",
                    float: "left",
                    marginLeft: "2px",
                    marginTop: "5px",
                  }}
                >
                  {items.name}
                </label>
                {expand == items.index ? (
                  <img
                    alt=""
                    src={items.icon2}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <img
                    alt=""
                    src={items.icon3}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                )}
                {expand == items.index && items.options ? "" : ""}
              </div>
            </NavLink>
            {expand == items.index &&
              items.options?.map((optionsData, index) => (
                <div key={optionsData.name}>
                  <div>
                    <NavLink
                      id={optionsData.index}
                      style={{ cursor: "pointer" }}
                      to={optionsData.link ?? "#"}
                      onClick={() => localStorage.setItem("optionsName", optionsData.name)}
                      className={({ isActive }) =>
                        [
                          isActive && optionsData.name == localStorage.getItem("optionsName")
                            ? "menu-item-lbl"
                            : "menu-items-styles",
                        ]
                          .filter(Boolean)
                          .join(" ")
                      }
                    >
                      {optionsData.name}
                      <br />
                    </NavLink>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="m-0">
        {BRANDASSETS_TREE_ITEMS.map((items) => (
          <div key={items.name}>
            <NavLink
              id={items.index}
              style={{ cursor: "pointer" }}
              onClick={() => onItemClick(items.index)}
              to={items.link ?? "#"}
              className={`school-menu ${expand == items.index ? "school-menu-selected" : ""}`}
            >
              <div id={items.index + "s"} className="selectedList" style={{ height: "39px", padding: "5px" }}>
                {expand == items.index ? (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                ) : (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon1}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                )}

                <label
                  title={items.name}
                  style={{
                    paddingRight: "0px",
                    float: "left",
                    marginLeft: "2px",
                    marginTop: "5px",
                  }}
                >
                  {items.name}
                </label>
                {expand == items.index ? (
                  <img
                    alt=""
                    src={items.icon2}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <img
                    alt=""
                    src={items.icon3}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                )}
                {expand == items.index && items.options ? "" : ""}
              </div>
            </NavLink>
            {expand == items.index &&
              items.options?.map((optionsData, index) => (
                <div key={optionsData.name}>
                  <div>
                    <NavLink
                      id={optionsData.index}
                      style={{ cursor: "pointer" }}
                      to={optionsData.link ?? "#"}
                      onClick={() => localStorage.setItem("brandAssetsOptionsName", optionsData.name)}
                      className={({ isActive }) =>
                        [
                          isActive && optionsData.name == localStorage.getItem("brandAssetsOptionsName")
                            ? "menu-item-lbl"
                            : "menu-items-styles",
                        ]
                          .filter(Boolean)
                          .join(" ")
                      }
                    >
                      {optionsData.name}
                      <br />
                    </NavLink>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="m-0">
        {TEMPLATES_TREE_ITEMS.map((items) => (
          <div key={items.name}>
            <NavLink
              id={items.index}
              style={{ cursor: "pointer" }}
              onClick={() => onItemClick(items.index)}
              to={items.link ?? "#"}
              className={`school-menu ${expand == items.index ? "school-menu-selected" : ""}`}
            >
              <div id={items.index + "s"} className="selectedList" style={{ height: "39px", padding: "5px" }}>
                {expand == items.index ? (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                ) : (
                  <img
                    alt=""
                    style={{
                      width: "24px",
                      height: "24px",
                      padding: "1px 1px",
                      float: "left",
                      marginTop: "2px",
                    }}
                    src={items.icon1}
                    className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                  />
                )}

                <label
                  title={items.name}
                  style={{
                    paddingRight: "0px",
                    float: "left",
                    marginLeft: "2px",
                    marginTop: "5px",
                  }}
                >
                  {items.name}
                </label>
                {expand == items.index ? (
                  <img
                    alt=""
                    src={items.icon2}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                ) : (
                  <img
                    alt=""
                    src={items.icon3}
                    style={{
                      width: "14px",
                      height: "auto",
                      float: "right",
                      marginTop: "10px",
                    }}
                  />
                )}
                {expand == items.index && items.options ? "" : ""}
              </div>
            </NavLink>
            {expand == items.index &&
              items.options?.map((optionsData, index) => (
                <div key={optionsData.name}>
                  <div>
                    <NavLink
                      id={optionsData.index}
                      style={{ cursor: "pointer" }}
                      to={optionsData.link ?? "#"}
                      onClick={() => localStorage.setItem("templatesOptionsName", optionsData.name)}
                      className={({ isActive }) =>
                        [
                          isActive && optionsData.name == localStorage.getItem("templatesOptionsName")
                            ? "menu-item-lbl"
                            : "menu-items-styles",
                        ]
                          .filter(Boolean)
                          .join(" ")
                      }
                    >
                      {optionsData.name}
                      <br />
                    </NavLink>
                  </div>
                </div>
              ))}
          </div>
        ))}
      </div>

      <div className="m-0">
        {localStorage.getItem("roleId") == 1 || localStorage.getItem("roleId") == 2 ? (
          <>
            {BOTTOM_TREE_ITEMS.map((items) => (
              <div key={items.name}>
                <div
                  id={items.index + "s"}
                  className="selectedList"
                  onClick={() => onItemClick(items.index)}
                  style={{ height: "39px", padding: "5px" }}
                >
                  <NavLink
                    id={items.index}
                    style={{ cursor: "pointer" }}
                    to={items.link ?? "#"}
                    className={`school-menu ${expand == items.index ? "school-menu-selected" : ""}`}
                  >
                    {expand == items.index ? (
                      <img
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: "1px 1px",
                          float: "left",
                          marginTop: "2px",
                        }}
                        src={items.icon}
                        className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                      />
                    ) : (
                      <img
                        alt=""
                        style={{
                          width: "24px",
                          height: "24px",
                          padding: "1px 1px",
                          float: "left",
                          marginTop: "2px",
                        }}
                        src={items.icon1}
                        className={items.name == "Brand Assets" || items.name == "Templates" ? "" : ""}
                      />
                    )}

                    <label
                      style={{
                        paddingRight: "0px",
                        float: "left",
                        marginLeft: "2px",
                        marginTop: "5px",
                      }}
                    >
                      {items.name}
                    </label>
                    {expand == items.index ? (
                      <img
                        alt=""
                        src={items.icon2}
                        style={{
                          width: "14px",
                          height: "auto",
                          float: "right",
                          marginTop: "10px",
                        }}
                      />
                    ) : (
                      <img
                        alt=""
                        src={items.icon3}
                        style={{
                          width: "14px",
                          height: "auto",
                          float: "right",
                          marginTop: "10px",
                        }}
                      />
                    )}
                    {expand == items.index && items.options ? "" : ""}
                  </NavLink>
                </div>
                {expand == items.index &&
                  items.options?.map((optionsData, index) => (
                    <div key={optionsData.name}>
                      <div id={optionsData.index} style={{ height: "30px", padding: "5px" }}>
                        <NavLink
                          to={optionsData.link ?? "#"}
                          onClick={() => localStorage.setItem("optionsName", optionsData.name)}
                          className={({ isActive }) =>
                            [isActive ? "menu-item-lbl" : "menu-items-styles"].filter(Boolean).join(" ")
                          }
                        >
                          {optionsData.name}
                          <br />
                        </NavLink>
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Expandedmenu;
