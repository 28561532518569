import Switch from "@material-ui/core/Switch";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import customerIcon from "../../../images/customerprofile.svg";
import btn_share from "../../../images/shareicon.svg";
import defaultLogo from "../../../images/uploadprofile.png";
import { editInviteUser, isEmailExisted, updateOrganization } from "../../../redux/Actions/action";
import { useCallbackPrompt } from "../../Hooks/useCallbackPrompt.ts";
import InputTextField from "../../Shared/InputTextField";
import { Loader } from "../../Shared/Loader";
import ConfirmModal from "../../Shared/Modals/ConfirmModal";
import "./viewEdit.css";

/**
 * Functional component is used for edit the details of existing customer
 * @return {*} 
 */
function EditUser() {
  const ImageUploadModal = React.lazy(() => import("../../Shared/Modals/ImageUploadModal/ImageUploadModal"));
  const DialogBox = React.lazy(() => import("../../Hooks/DialogBox.component.tsx"));

  const navigate = useNavigate("");
  const dispatch = useDispatch();
  const loc = useLocation();
  const data = JSON.parse(loc.state);

  if (data) {
    if (data?.type === "school" || data?.type === "School") {
      data.type = 0;
    } else {
      data.type = 1;
    }
  }

  const [organization, setOrganization] = useState({
    id: data?.id,
    type: data?.type,
    Name: data?.name,
    schoolContactNumber: data?.schoolContactNumber,
    logo: data != null ? data.logo : defaultLogo,
    file: "",
    firstName: data?.firstName,
    lastName: data?.lastName,
    email: data?.email,
    subScriptionStatus:
      data?.status === "active" ? 1 : data?.status === "inactive" ? 2 : data?.status === "disabled" ? 0 : 0,
    subscriptionType: data?.subscriptionDetails.subscriptionType,
    subscriptionDate: data?.subscriptionDetails.subscription_date,
    lastRenewalDate: data?.subscriptionDetails.last_renewal_date,
    nextRenewalDate: data?.subscriptionDetails.next_renewal_date,
    userPack: data?.subscriptionDetails.user_pick,
    isVerified: data?.isVerfied,
    subscriptionId: data?.subscriptionDetails.id,
    invited: data?.invited,
    userscount: data?.usersCount,
  });

  // error state for mobile, firstName, lastName and originzation
  const [organizationError, setOrganizationError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [isEmailExist, setIsEmailExist] = useState("");
  const [emailError, setEmailError] = useState("");

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(false);

  // state for showing upload modal and dialog
  const [showImageUpload, setShowImageUpload] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState("");
  const [showInvite, setShowInvite] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  // state for navigation propmt
  const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(showDialog);

  // subscription status state
  const [subscriptionStatus, setSubscriptionStatus] = useState(organization.subScriptionStatus);

  useEffect(() => {
    if (localStorage.getItem("roleId") !== "1" || !data) {
      navigate("/");
      localStorage.setItem("schoolMenuIndex", 0);
    }
  });

  /**
   *  Method to be called when user click on the Cancel button in Invite Modal
   */
  const closeInvite = () => {
    setShowInvite(false);
  };

  /**
   * Method to be called when user change the type
   * @param {*} evt
   */
  const onRadioTypeChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({ ...prev, type: Number(evt.target.value) }));
  };

  /**
   * Method to be called when user enters phone number
   * @param {*} evt
   */
  const onNumberChange = (evt) => {
    setShowDialog(true);
    evt.target.value = evt.target.value.replace(/[^0-9]/g, "");
    setOrganization((prev) => ({
      ...prev,
      schoolContactNumber: evt.target.value,
    }));
    validate(organization, "Mobile", evt.target.value);
  };

  /**
   * Method to called when user select the logo image
   * @param {*} evt
   * @return {*} 
   */
  const handleLogoChange = (evt) => {
    let isImage = evt.target.files[0];
    if (isImage["type"] === "image/png" || isImage["type"] === "image/jpeg" || isImage["type"] === "image/jpg") {
    } else {
      evt.target.value = "";
      return <div className="warningMsg">{toast.warning("Please Add valid Logo")}</div>;
    }

    let file_size = evt.target.files[0].size;
    const file = Math.round(file_size / 1024);
    const reader = new FileReader();
    reader.readAsDataURL(evt.target.files[0]);
    reader.addEventListener("load", (event) => {
      const _loadedImageUrl = event.target.result;
      const image = document.createElement("img");
      image.src = _loadedImageUrl;
      image.addEventListener("load", () => {
        if (file > 2048) {
          setShowImageUpload(false);
          toast.warning("Please Add Logos with size less than 2MB");
        } else {
          setShowDialog(true);
          setOrganization((prev) => ({
            ...prev,
            logo: URL.createObjectURL(evt.target.files[0]),
            file: evt.target.files[0],
          }));
          setShowImageUpload(false);
        }
      });
    });
  };

  /**
   * Method to be called when user enter the first name
   * @param {*} evt
   */
  const onFirstNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  };

  /**
   * Method to be called when user enter the first name
   * @param {*} evt
   */
  const onBlurFirstNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    validate(organization, "FirstName", evt.target.value);
  };

  /**
   * Method to be called when user enter the Last name
   * @param {*} evt
   */
  const onLastNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
  };

  /**
   * Method to be called when user enter the Last name
   * @param {*} evt
   */
  const onBlurLastNameChange = (evt) => {
    evt.target.value = evt.target.value.replace(/[^a-zA-Z0-9 ]/g, "");
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    validate(organization, "LastName", evt.target.value);
  };

  /**
   * Method to be called when user change the type
   * @param {*} evt
   */
  const onSubRadioChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      subscriptionType: Number(evt),
    }));
  };

  /**
   * Method to be called when user change the Subscription Date
   * @param {*} evt
   */
  const onSubscriptionDateChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      subscriptionDate: evt.target.value,
    }));
  };

  /**
   * Method to be called when user change the Last Renewal Date
   * @param {*} evt
   */
  const onLastRenewalDateChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      lastRenewalDate: evt.target.value,
    }));
  };

  /**
   * Method to be called when user change the Next Renewal Date
   * @param {*} evt
   */
  const onNextRenewalDataChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      nextRenewalDate: evt.target.value,
    }));
  };

  /**
   * Method to be called when user clicks on active and in active button
   * @param {*} event
   */
  const handleChangeSubscriptionStatus = (event) => {
    setShowDialog(true);
    setSubscriptionStatus(event.target.checked);
    if (event.target.checked === true) {
      organization.subScriptionStatus = 1;
      setShowConfirmModal("Subscription-Active");
    } else {
      organization.subScriptionStatus = 2;
      setShowConfirmModal("Subscription-InActive");
    }
    setShowInvite(true);
  };

  /**
   * Method to be called when user clicks on Cancel button in subscription modal
   */
  const subscriptionStatusDenied = () => {
    setShowDialog(true);
    if (organization.subScriptionStatus === 1) {
      organization.subScriptionStatus = 2;
    } else if (organization.subScriptionStatus === 2) {
      organization.subScriptionStatus = 1;
    }
    setSubscriptionStatus(!subscriptionStatus);
    setShowInvite(false);
  };

  /**
   * Method to be called when user change the Next Renewal Date
   * @param {*} evt
   * @param {*} index
   */
  const onRadioUserPackChange = (evt, index) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      userPack: Number(index),
    }));
  };

  /**
   * Method to Validate All the Input fields
   * @param {*} value
   * @param {*} inputType
   * @param {*} inputValue
   * @return {*} 
   */
  const validate = (value, inputType, inputValue) => {
    const regex = /^(?!\.)[a-zA-Z0-9-]+(?!.*?\.\.)[a-zA-Z0-9-_.]+(?!.*\.$)+@[a-zA-Z0-9-]+[a-z.0-9]+\.[a-z.]{2,4}$/;
    let error = {};
    const regex1 = /^[\d]*[a-z_ ][a-z \d_]*$/gi;

    if (inputType === "Form") {
      if (value.Name === "") {
        setOrganizationError("Please provide Name...");
      } else if (!regex1.test(value.Name.trim())) {
        setOrganizationError("Name should be alphanumeric");
      } else if (value.Name.trim().length < 4) {
        setOrganizationError("Name of Education/Corporate should be between 4 to 10 characters");
      } else {
        setOrganizationError("");
      }

      // Mobile Number Validation
      if (value.schoolContactNumber) {
        if (value.schoolContactNumber.trim() !== "") {
          if (value.schoolContactNumber.length < 5) {
            setMobileError("Please enter 5 to 15 digits Phone Number...");
          } else {
            setMobileError("");
          }
        } else {
          setMobileError("");
        }
      }
    }

    // First Name validations
    if (inputType === "FirstName") {
      if (inputValue.trim() !== "") {
        if (!regex1.test(value.firstName.trim())) {
          setFirstNameError("First Name should be alphanumeric");
        } else if (inputValue.trim().length < 2) {
          setFirstNameError("First Name should be between 2 to 16 characters");
        } else {
          setFirstNameError("");
        }
      } else {
        setFirstNameError("");
      }
    }

    // Last Name validations
    if (inputType === "LastName") {
      if (inputValue.trim() !== "") {
        if (!regex1.test(value.lastName.trim())) {
          setLastNameError("Last Name should be alphanumeric");
        } else if (inputValue.length < 2) {
          setLastNameError("Last Name should be between 2 to 16 characters");
        } else {
          setLastNameError("");
        }
      } else {
        setLastNameError("");
      }
    }

    // Mobile Number validation when user enters the input data
    if (inputType === "Mobile") {
      if (inputValue.trim() !== "") {
        if (inputValue.length < 5) {
          setMobileError("Please enter 5 to 15 digits Phone Number...");
        } else {
          setMobileError("");
        }
      } else {
        setMobileError("");
      }
    }

    // Email validation when user enters the input data
    if (inputType === "Email") {
      if (inputValue === "") {
        setIsEmailExist("");
        setEmailError("Please provide Valid Email...");
      } else {
        if (!regex.test(inputValue)) {
          setEmailError("Please provide Valid Email format...");
        } else {
          setEmailError("");
        }
      }
    }

    return error;
  };

  /**
   * Method to called when user clicks on invite button
   */
  const onInvite = () => {
    validate(organization, "Form");
    if (organization.Name !== "" && organization.email !== "") {
      const obj = {
        id: organization?.id,
        type: organization?.type,
        Name: organization?.Name,
        schoolContactNumber: organization?.schoolContactNumber,
        logo: organization?.logo,
        file: organization?.file,
        firstName: organization?.firstName,
        lastName: organization?.lastName,
        email: organization?.email,
        active: organization?.subScriptionStatus,
        subscriptionType: organization?.subscriptionType,
        subscriptionDate: organization?.subscriptionDate,
        lastRenewalDate: organization?.lastRenewalDate,
        nextRenewalDate: organization?.nextRenewalDate,
        userPack: organization?.userPack,
        isVerified: organization?.isVerified,
        subscriptionId: organization?.subscriptionId,
        invited: organization?.invited,
        userscount: organization?.userscount,
      };
      if (obj.type === 0) {
        obj.type = "School";
      } else {
        obj.type = "Corporate";
      }
      setShowDialog(false);
      setDisplayLoader(true);
      dispatch(editInviteUser(obj, onUserCreated, errorHandler));
    }
    setShowInvite(false);
  };

  /**
   * After User updated we navigate the user to users list
   */
  const onUserCreated = () => {
    setDisplayLoader(false);
  };

  /**
   * Method to be called after successful user creation
   */
  const onUserUpdated = () => {
    setDisplayLoader(false);
    navigate("/admin/viewedit");
  };

  /**
   * Method to be called if any error occurs
   */
  const errorHandler = () => {
    setDisplayLoader(false);
    setShowDialog(true);
  };

  /**
   * Method to be called for update the edit user form
   * @param {*} e
   * @return {*} 
   */
  const onUpdate = (e) => {
    e.preventDefault();
    validate(organization, "Form");

    if (
      emailError.length > 0 ||
      isEmailExist.length > 0 ||
      mobileError.length > 0 ||
      firstNameError.length > 0 ||
      lastNameError.length > 0
    ) {
      return;
    } else if (organization.Name !== "" && organization.email !== "") {
      const obj = {
        id: organization?.id,
        type: organization?.type,
        Name: organization?.Name,
        schoolContactNumber: organization?.schoolContactNumber,
        logo: organization?.logo,
        file: organization?.file,
        firstName: organization?.firstName,
        lastName: organization?.lastName,
        email: organization?.email,
        active: organization?.subScriptionStatus,
        subscriptionType: organization?.subscriptionType,
        subscriptionDate: organization?.subscriptionDate,
        lastRenewalDate: organization?.lastRenewalDate,
        nextRenewalDate: organization?.nextRenewalDate,
        userPack: organization?.userPack,
        isVerified: organization?.isVerified,
        subscriptionId: organization?.subscriptionId,
        invited: organization?.invited,
        userscount: organization?.userscount,
      };
      if (obj.type === 0) {
        obj.type = "School";
      } else {
        obj.type = "Corporate";
      }
      setShowDialog(false);
      setDisplayLoader(true);
      dispatch(updateOrganization(obj, onUserUpdated, errorHandler));
    }
  };

  /**
   * Used to set the Email existences error
   */
  const onEmailResponse = (res) => {
    if (res.status) {
      setIsEmailExist("");
    } else {
      setIsEmailExist("user email is already exist");
    }
  };

  /**
   * First Name and Last Name Validations
   * @param {*} event
   * @return {*}
   */
  const EkeyPress = (event) => {
    var regex = new RegExp("^[a-zA-Z@0-9.]+$");
    var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  /**
   * Used to check the Email existences
   * @param {*} evt
   */
  const onEmailBlur = (evt) => {
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    if (evt.target.value.length > 0) {
      if (data.email !== evt.target.value) dispatch(isEmailExisted(evt.target.value, onEmailResponse));
    }
    validate(organization, "Email", evt.target.value);
  };

  // const label = { inputProps: { "aria-label": "Switch demo" } };

  /**
   * Method to called on every change in Email input field
   * @param {*} evt
   */
  const onEmailChange = (evt) => {
    setShowDialog(true);
    setOrganization((prev) => ({
      ...prev,
      [evt.target.name]: evt.target.value,
    }));
    validate(organization, "Email", evt.target.value);
  };

  return (
    <div>
      <Suspense fallback={<Loader />}>
        {showPrompt &&
          <DialogBox
            showDialog={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
          />
        }
      </Suspense>
      <Suspense fallback={<Loader />}>
        {
          showImageUpload &&
          <ImageUploadModal
            showImageUpload={showImageUpload}
            setShowImageUpload={setShowImageUpload}
            handleLogoChange={handleLogoChange}
          />
        }
      </Suspense>

      <div id="remove-padding" className="container addUserList mt-3">
        <div className="row mb-2">
          <div className="col-lg-9 d-flex">
            <h6 className="titLe py-2 m-0 my-auto">
              Customers <span>- Edit</span>
            </h6>
          </div>

          <div className="col-lg-3 d-flex justify-content-end">
            <button className="saveBtn" onClick={onUpdate}>
              UPDATE
            </button>
          </div>
        </div>

        <hr />

        <div className="row pt-3 py-2">
          <div className="col-lg-4">
            <label className="addUserLabel my-auto w-100 labelText">Customer Type</label>
            <div className="d-flex form-group align-items-center mt-2">
              <div className="col-lg-6">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      name="type"
                      onChange={onRadioTypeChange}
                      checked={organization?.type === 0}
                      className="radioButtons"
                      value={0}
                    />
                  </div>
                  Education
                </label>
              </div>
              <div className="col-lg-6">
                <label className="d-flex ps-3 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      name="type"
                      onChange={onRadioTypeChange}
                      checked={organization?.type === 1}
                      className="radioButtons"
                      value={1}
                    />
                  </div>
                  Corporate
                </label>
              </div>
            </div>

            <div className="form-group align-items-center my-2">
              <label className="addUserLabel my-auto labelText">Name of Education/Corporate</label>
              <InputTextField
                type="text"
                placeholder="Please enter name"
                className="textField w-100 m-0"
                name="name"
                value={organization.Name}
                autocomplete="off"
                minLength="4"
                maxLength="30"
              />

              <p className="errorMsg lblErr" id="dr_none">
                {organizationError}
              </p>
            </div>

            <div className="form-group align-items-center">
              <label className="addUserLabel my-auto labelText">Phone Number</label>
              <InputTextField
                type="text"
                placeholder="Please enter phone number"
                name="schoolContactNumber"
                className="textField w-100 m-0"
                value={organization.schoolContactNumber}
                onChange={onNumberChange}
                autocomplete="off"
                minLength="5"
                maxLength="15"
              />

              <p className="text-danger lblErr">{mobileError}</p>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="align-items-center pb-2 text-center">
              <label className="labelText my-auto ">Submit Your Logo</label>

              <div className="logoContainer mt-2">
                <div className="px-5 py-2 logoBck">
                  <div className="profileImage mx-auto d-flex align-items-center justify-content-center">
                    <img className="img-fluid" alt="" src={organization.logo} />
                  </div>
                  <div onClick={(e) => setShowImageUpload(true)}>
                    {organization.logo !== defaultLogo ? (
                      ""
                    ) : (
                      <p className="m-0 pt-1">Click here to Add your logo (max 2MB)</p>
                    )}
                    <p className="AddEdit text-decoration-underline pt-1 m-0">ADD/EDIT</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="row pb-2">
          <div className="py-3">
            <label className="addUserLabel my-auto headLabel pe-3">PRIMARY CONTACT</label>
            <br />
            <span className="primaryText">
              Primary Contact will always have full administrator rights for the customer
            </span>
          </div>

          <div className="col-lg-4">
            <label className="addUserLabel my-auto labelText">First Name</label>
            <InputTextField
              type="text"
              placeholder="Please enter first name"
              className="textField w-100 m-0"
              name="firstName"
              value={organization.firstName}
              onChange={onFirstNameChange}
              onBlur={onBlurFirstNameChange}
              autocomplete="off"
              minLength="2"
              maxLength="16"
            />
            <p className="text-danger lblErr">{firstNameError}</p>
          </div>
          <div className="col-lg-4">
            <label className="addUserLabel my-auto labelText">Last Name</label>
            <InputTextField
              type="text"
              placeholder="Please enter Last name"
              className="textField w-100 m-0"
              name="lastName"
              value={organization.lastName}
              onChange={onLastNameChange}
              onBlur={onBlurLastNameChange}
              autocomplete="off"
              minLength="2"
              maxLength="16"
            />
            <p className="text-danger lblErr">{lastNameError}</p>
          </div>

          <div className="col-lg-8 EmailInput">
            <label className="addUserLabel my-auto labelText">Email</label>

            {organization.invited == 0 ||
              (organization.invited == null && (
                <div>
                  <div className="input-group m-0 inviteLeft">
                    {data?.status === "inactive" || data?.status === "disabled" ? (
                      <InputTextField
                        type="email"
                        name="email"
                        onChange={onEmailChange}
                        value={organization.email}
                        onBlur={onEmailBlur}
                        onKeyPress={EkeyPress}
                        className="form-control m-0 textField"
                        placeholder="name@domainname.com"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                    ) : (
                      <InputTextField
                        type="email"
                        name="email"
                        value={organization.email}
                        className="form-control m-0 textField"
                        placeholder="name@domainname.com"
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                      />
                    )}
                    {emailError?.trim().length > 0 || isEmailExist?.trim().length > 0 ? (
                      <span className="input-group-text textField InviteField inviteInput" id="basic-addon2">
                        <img alt="" src={btn_share} />
                        <label>INVITE</label>
                      </span>
                    ) : (
                      <span
                        className="input-group-text textField InviteField inviteInput"
                        id="basic-addon2"
                        onClick={(e) => {
                          setShowInvite(true);
                          setShowConfirmModal("Invite");
                        }}
                      >
                        <img alt="" src={btn_share} />
                        <label>INVITE</label>
                      </span>
                    )}
                  </div>
                  <p className="text-danger lblErr m-0">{isEmailExist.length > 0 ? isEmailExist : emailError}</p>
                </div>
              ))}

            {organization.invited == 1 && (
              <div>
                <div className="input-group m-0 inviteLeft">
                  {data?.status === "inactive" || data?.status === "disabled" ? (
                    <InputTextField
                      type="email"
                      name="email"
                      onChange={onEmailChange}
                      value={organization.email}
                      onBlur={onEmailBlur}
                      onKeyPress={EkeyPress}
                      className="form-control m-0 textField"
                      placeholder="name@domainname.com"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  ) : (
                    <InputTextField
                      type="email"
                      name="email"
                      value={organization.email}
                      className="form-control m-0 textField"
                      placeholder="name@domainname.com"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                    />
                  )}

                  {emailError?.trim().length > 0 || isEmailExist?.trim().length > 0 ? (
                    <span className="input-group-text textField InviteField inviteInput" id="basic-addon2">
                      <img alt="" src={btn_share} />

                      <label>RE-INVITE</label>
                    </span>
                  ) : (
                    <span
                      className="input-group-text textField InviteField inviteInput"
                      id="basic-addon2"
                      onClick={(e) => {
                        setShowInvite(true);
                        setShowConfirmModal("RE-Invite");
                      }}
                    >
                      <img alt="" src={btn_share} />

                      <label>RE-INVITE</label>
                    </span>
                  )}
                </div>
                <p className="text-danger lblErr m-0">{isEmailExist.length > 0 ? isEmailExist : emailError}</p>
              </div>
            )}
          </div>
        </div>

        <hr style={{ marginTop: "5px" }} />

        <div className="row py-3">
          <label className="addUserLabel my-auto headLabel py-2">SUBSCRIPTION DETAILS</label>

          <div className="col-lg-4 swiTch d-flex">
            {organization.subScriptionStatus === 0 ? (
              <div>
                <label className="addUserLabel my-auto labelText">Status</label>
                <Switch
                  checked={subscriptionStatus}
                  color="primary"
                  name="subStatus"
                  inputProps={{ "aria-label": "primary checkbox" }}
                  disabled
                />
              </div>
            ) : (
              <div>
                <label className="addUserLabel my-auto labelText">Status</label>
                <Switch
                  checked={organization.subScriptionStatus === 1 ? true : false}
                  onChange={handleChangeSubscriptionStatus}
                  color="primary"
                  name="subStatus"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                {organization.subScriptionStatus === 1 ? (
                  <label className="addUserLabel my-auto text-success">Active</label>
                ) : (
                  <label className="addUserLabel my-auto text-danger">InActive</label>
                )}
              </div>
            )}
          </div>
          <div className="col-lg-8">
            <div className="form-group d-flex align-items-center mt-2 py-1">
              <label className="addUserLabel my-auto pe-2 labelText">Type</label>
              <label className="pe-4 radioLabel labelText">
                <input
                  type="radio"
                  name="subscriptionType"
                  className="radioButtons"
                  checked={organization?.subscriptionType === 0}
                  onChange={(e) => onSubRadioChange(0)}
                  value={0}
                />
                Annual
              </label>
              <label className="radioLabel labelText">
                <input
                  type="radio"
                  name="subscriptionType"
                  className="radioButtons"
                  checked={organization?.subscriptionType === 1}
                  onChange={(e) => onSubRadioChange(1)}
                  value={1}
                />
                Monthly
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4">
            <label className="addUserLabel my-auto subScript pe-3 labelText">Subscription Date</label>
            <InputTextField
              type="date"
              className="textField w-100 m-0"
              onChange={onSubscriptionDateChange}
              value={organization.subscriptionDate}
            />
          </div>
          <div className="col-lg-4">
            <label className="addUserLabel my-auto subScript labelText">Last Renewal Date</label>
            <InputTextField
              type="date"
              value={organization.lastRenewalDate}
              onChange={onLastRenewalDateChange}
              className="textField w-100 m-0"
            />
          </div>
          <div className="col-lg-4">
            <label className="addUserLabel my-auto subScript labelText">Next Renewal Date</label>
            <InputTextField
              type="date"
              className="textField w-100 m-0"
              value={organization.nextRenewalDate}
              onChange={onNextRenewalDataChange}
            />
          </div>
        </div>

        <div className="row py-2 pb-3">
          <div className="col-lg-8">
            <label className="addUserLabel my-auto pb-2 labelText">User Pack</label>
            <div className="row form-group">
              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      checked={organization?.userPack == 1}
                      value={1}
                      onChange={(event) => onRadioUserPackChange(event, 1)}
                    />
                  </div>
                  Upto 10
                </label>
              </div>

              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      checked={organization?.userPack == 2}
                      value={2}
                      onChange={(event) => onRadioUserPackChange(event, 2)}
                    />
                  </div>
                  Upto 50
                </label>
              </div>

              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      checked={organization?.userPack == 3}
                      value={3}
                      onChange={(event) => onRadioUserPackChange(event, 3)}
                    />
                  </div>
                  Upto 100
                </label>
              </div>

              <div className="col-lg-3">
                <label className="d-flex pe-2 labelText align-items-center">
                  <div className="radioSelection">
                    <input
                      type="radio"
                      className="radioButtons"
                      name="userPack"
                      checked={organization?.userPack == 4}
                      value={4}
                      onChange={(event) => onRadioUserPackChange(event, 4)}
                    />
                  </div>
                  Unlimited
                </label>
              </div>
            </div>
          </div>

          <div className="col-lg-4">
            <div className="form-group align-items-center">
              <label className="addUserLabel my-auto pb-2 labelText pe-2">Current Users</label>
              <div className="d-flex justify-content-start">
                <label className="radioLabel curntUser currentUsersLabel currentUser pe-2">
                  <img alt="" src={customerIcon} className="pe-2" />
                  <span>{organization?.userscount}&nbsp;</span> out of
                  <span>
                    &nbsp;
                    {organization.userPack == 1
                      ? "10"
                      : organization.userPack == 2
                        ? "50"
                        : organization.userPack == 3
                          ? "100"
                          : "∞"}
                  </span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <hr />

        <div className="col-12 pb-3"></div>
      </div>

      {showConfirmModal === "Invite" ? (
        <ConfirmModal
          open={showInvite}
          onClose={closeInvite}
          onConfirm={onInvite}
          titleText={
            "You are about to invite the Primary Contact to have access to their site. This will give full access to the clients site. Are you sure you want to send this invitation?"
          }
        />
      ) : showConfirmModal === "RE-Invite" ? (
        <ConfirmModal
          open={showInvite}
          onClose={closeInvite}
          onConfirm={onInvite}
          titleText={
            "You are about to re-invite the Primary Contact to have access to their site. This will give full access to the clients site. Are you sure you want to send this invitation?"
          }
        />
      ) : (
        ""
      )}

      {showConfirmModal === "Subscription-Active" ? (
        <ConfirmModal
          open={showInvite}
          onClose={subscriptionStatusDenied}
          onConfirm={closeInvite}
          titleText={
            "You are about to make this account active. This will allow access to all users of this account. Are you sure you want to proceed?"
          }
        />
      ) : showConfirmModal === "Subscription-InActive" ? (
        <ConfirmModal
          open={showInvite}
          onClose={subscriptionStatusDenied}
          onConfirm={closeInvite}
          titleText={
            "You are about to make this account inactive. This will stop any access to all users of this account. Are you sure you want to proceed?"
          }
        />
      ) : (
        ""
      )}

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
}

export default EditUser;
