import Tooltip from "@mui/material/Tooltip";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { envVariables } from "../../Environments/env.local";
import downloadImg from "../../images/DownloadAll.svg";
import eps from "../../images/eps.svg";
import upload from "../../images/upload.svg";
import { getAssetsByMemberId, getGroupLogos } from "../../redux/Actions/action";
import EditText from "../ContentEditable/EditText";
import IconButton from "../IconButton";
import { Loader } from "../Shared/Loader";
import "./brandAssets.css";

/* API */
const api = envVariables.ip;

/**
 * This is functional component used for all brandAssets except colours and fonts
 * @return {*} 
 */
const BrandAssetGeneric = () => {
  const BrandAssetGenericUploadModal = React.lazy(() => import("../Shared/Modals/BrandAssetGenericUploadModal"));

  let dynamicName = localStorage.getItem("brandAssetsOptionsName");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // state for showing upload modal
  const [showImageUpload, setShowImageUpload] = useState(false);

  // state for userRole
  const [userRole, setUserRole] = useState("");

  // Used to display the loader while we call the service at timeOut functions
  const [displayLoader, setDisplayLoader] = useState(true);

  let uniqueResult = [];
  let adminAssetsData = [];
  let userAssetsData = [];

  adminAssetsData = useSelector((state) => state.userReducer.GroupLogosData);
  userAssetsData = useSelector((state) => state.userReducer.AssetsDataByUserId);

  const map = new Map();
  if (userRole !== "3") {
    for (const item of adminAssetsData) {
      if (!map.has(item.groupName)) {
        map.set(item.groupName, true);
        uniqueResult.push(item);
      }
    }
  } else {
    for (const item of userAssetsData) {
      if (!map.has(item.groupName)) {
        map.set(item.groupName, true);
        uniqueResult.push(item);
      }
    }
  }

  useEffect(() => {
    if (!dynamicName || (dynamicName === "Fonts" || dynamicName === "Colours")) {
      navigate("/");
    }
    setUserRole(localStorage.getItem("roleId"));
    if (localStorage.getItem("roleId") === "3") {
      const obj = {
        id: localStorage.getItem("userId"),
        assetType: dynamicName,
      };
      dispatch(getAssetsByMemberId(obj, successHandler, errorHandler));
    } else {
      const obj = {
        name: localStorage.getItem("orgName"),
        assetType: dynamicName,
      };
      dispatch(getGroupLogos(obj, successHandler, errorHandler));
    }
  }, [dynamicName]);

  /**
   * Method will call after successful executed function
   */
  const successHandler = () => {
    setDisplayLoader(false);
  };

  /**
   * Method will call if any error occurs during execution
   */
  const errorHandler = () => {
    setDisplayLoader(false);
    adminAssetsData = [];
    userAssetsData = [];
  };

  /**
   * To download the Images
   */
  const downloadIcons = () => {
    setDisplayLoader(true);
    const obj = {
      name: localStorage.getItem("orgName"),
      assetType: "Icons",
    };
    const path = `${api}/assets/downloadPackage/${obj.name}/${obj.assetType}`;
    const link = document.createElement("a");
    link.href = path;
    link.setAttribute("download", "image.png");
    document.body.appendChild(link);
    link.click();
    setDisplayLoader(false);
  };

  /**
   * This method will call when user click on the individual logo
   * @param {*} groupName
   */
  const logoClick = (groupName) => {
    localStorage.setItem("genericGroupName", groupName);
    let selectedLogos = [];
    if (localStorage.getItem("roleId") !== "3") {
      selectedLogos = adminAssetsData.filter((item) => item.groupName === groupName);
    } else {
      selectedLogos = userAssetsData.filter((item) => item.groupName === groupName);
    }
    navigate("/brandassets/assetDetails", {
      state: JSON.stringify(selectedLogos),
    });
  };

  return (
    <div className="hide logos mt-3">
      <div className="userLogos">
        <div className="row mb-2">
          <div className="col-lg-6 col-md-6 col-sm-6 col-6">
            <h6 className="titLe py-2 m-0 my-auto">
              Brand Assets <span> - {dynamicName} </span>
            </h6>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6 col-6 my-auto d-flex justify-content-end">
            <div className="buttonList">
              {(userRole === "1" || userRole === "2") && dynamicName !== "Icons" ? (
                <Tooltip title="Add">
                  <div className="buttonGap borderBtn border-end-0 me-0">
                    <IconButton
                      className="iconsStyles borderBtn border-end-0"
                      img={upload}
                      imageClass="imageHeight"
                      buttonClass="download"
                      label="UPDATE"
                      onClick={(e) => setShowImageUpload(true)}
                    />
                  </div>
                </Tooltip>
              ) : dynamicName === "Icons" || dynamicName === "icons" ? (
                <div className="d-flex">
                  {userRole === "1" || userRole === "2" ? (
                    <Tooltip title="Add">
                      <div className="buttonGap borderBtn me-0">
                        <IconButton
                          className="iconsStyles"
                          img={upload}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="UPDATE"
                          onClick={(e) => setShowImageUpload(true)}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    ""
                  )}
                  {(adminAssetsData.length > 0 && userRole !== "3") ||
                    (userAssetsData.length > 0 && userRole === "3") ? (
                    <Tooltip title="Download All">
                      <div className="buttonGap borderBtn border-end-0" onClick={downloadIcons}>
                        <IconButton
                          className="iconsStyles borderBtn border-end-0"
                          img={downloadImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="DOWNLOAD ALL"
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Download All">
                      <div className="buttonGap borderBtn border-end-0">
                        <IconButton
                          className="iconsStyles borderBtn border-end-0 disable"
                          img={downloadImg}
                          imageClass="imageHeight"
                          buttonClass="download"
                          label="DOWNLOAD ALL"
                        />
                      </div>
                    </Tooltip>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        <hr className="m-1 mb-2"></hr>

        <div className="row mb-2 userColors">
          <EditText assetType={dynamicName} />
        </div>
        <Suspense fallback={<Loader />}>
          {
            showImageUpload &&
            <BrandAssetGenericUploadModal showImageUpload={showImageUpload} setShowImageUpload={setShowImageUpload} />
          }
        </Suspense>
      </div>

      <div className="container my-3 px-0">
        {uniqueResult.length > 0 && userRole !== "3" ? (
          <div className="row mb-2" style={{ alignItems: "center" }}>
            {uniqueResult.map((item, index) => {
              return (
                <div className="col-lg-2 col-md-2 col-sm-2 col-12 mb-1">
                  <div className="linear p-2" onClick={() => logoClick(item.groupName)}>
                    {item.groupThumbnail ? (
                      item.thumbnailPath ? (
                        <div className="logoThumbnail d-flex">
                          <div className="m-auto">
                            <img className="p-1 img-fluid" alt="" src={item.thumbnailPath} />
                          </div>
                        </div>
                      ) : (
                        <div className="logoThumbnail d-flex">
                          {(item.fileExtension === ".eps" || item.fileExtension === ".ps" || item.fileExtension === ".ai") ? (
                            <div className="m-auto">
                              <img className="p-1 img-fluid" alt="eps" src={eps} />
                            </div>
                          ) : (
                            <div className="m-auto">
                              <img className="p-1 img-fluid" alt="" src={item.path} />
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <div className="logoThumbnail d-flex">
                        {(item.fileExtension === ".eps" || item.fileExtension === ".ps" || item.fileExtension === ".ai") ? (
                          <div className="m-auto">
                            <img className="p-1 img-fluid" alt="eps" src={eps} />
                          </div>
                        ) : (
                          <div className="m-auto">
                            <img className="p-1 img-fluid" alt="" src={item.path} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <p className="m-0 px-2 py-1 text-center logoListName">{item.groupName}</p>
                </div>
              );
            })}
          </div>
        ) : uniqueResult.length > 0 && userRole === "3" ? (
          <div className="row mb-2" style={{ alignItems: "center" }}>
            {uniqueResult.map((item, index) => {
              return (
                <div className="col-lg-2 col-md-2 col-sm-2 col-12">
                  <div className="linear p-2" onClick={() => logoClick(item.groupName)}>
                    {item.groupThumbnail ? (
                      item.thumbnailPath ? (
                        <div className="logoThumbnail d-flex">
                          <div className="m-auto">
                            <img className="p-1 img-fluid" alt="" src={item.thumbnailPath} />
                          </div>
                        </div>
                      ) : (
                        <div className="logoThumbnail d-flex">
                          {(item.fileExtension === ".eps" || item.fileExtension === ".ps" || item.fileExtension === ".ai") ? (
                            <div className="m-auto">
                              <img className="p-1 img-fluid" alt="eps" src={eps} />
                            </div>
                          ) : (
                            <div className="m-auto">
                              <img className="p-1 img-fluid" alt="" src={item.path} />
                            </div>
                          )}
                        </div>
                      )
                    ) : (
                      <div className="logoThumbnail d-flex">
                        {(item.fileExtension === ".eps" || item.fileExtension === ".ps" || item.fileExtension === ".ai") ? (
                          <div className="m-auto">
                            <img className="p-1 img-fluid" alt="eps" src={eps} />
                          </div>
                        ) : (
                          <div className="m-auto">
                            <img className="p-1 img-fluid" alt="" src={item.path} />
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <p className="m-0 px-2 py-1 text-center logoListName">{item.groupName}</p>
                </div>
              );
            })}
          </div>
        ) : userRole === "1" || userRole === "2" ? (
          <div className="row text-center pt-5 mt-5">
            <div>
              <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                No {dynamicName} available
              </h1>
              <p
                style={{
                  color: "#15355c",
                  fontWeight: "bold",
                  fontSize: "12px",
                }}
              >
                Please add your {dynamicName}
              </p>
            </div>
          </div>
        ) : (
          <div className="row text-center pt-5 mt-5">
            <div>
              <h1 className="m-0" style={{ fontSize: "16px", opacity: "0.5" }}>
                No {dynamicName} available
              </h1>
            </div>
          </div>
        )}
      </div>

      <div>{displayLoader ? <Loader /> : <div></div>}</div>
    </div>
  );
};

export default BrandAssetGeneric;
