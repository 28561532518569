import { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import closeImage from "../images/close.png";
import { closeModal, organizationSignup } from "../redux/Actions/action";
import "./Header.css";
import InputTextField from "./Shared/InputTextField";

/* SIgnUpModalData */
const signupModelData = {
  name: "",
  title: "",
  emailId: "",
  schoolName: "",
  address1: "",
  address2: "",
};

/**
 * Functional component for signup modal
 * @return {*} 
 */
const SignupModal = () => {
  toast.configure({
    autoClose: 2000,
  });

  let dispatch = useDispatch();
  const modal = useSelector((state) => state.userReducer.modal);
  const [error, setError] = useState({});
  const [signupData, setSignupData] = useState(signupModelData);

  /**
   * This method will call when user change the input field values
   * @param {*} e
   */
  const signUpChange = (e) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(error).length > 0) {
      setError(validate(signupData));
    }
  }, [signupData]);

  /**
   * This method is used to save the signUp form
   * @param {*} e
   */
  const signUpSubmit = (e) => {
    e.preventDefault();
    setError(validate(signupData));
    if (
      signupData.name != "" &&
      signupData.title != "" &&
      signupData.emailId != "" &&
      signupData.schoolName != "" &&
      signupData.address1 != "" &&
      signupData.address2 != ""
    ) {
      dispatch(organizationSignup(signupData, closeSignupModal));
    }
  };

  /**
   * This method is used to validate the input fields
   * @param {*} values
   * @return {*} 
   */
  const validate = (values) => {
    const errorData = {};

    if (values.name == "") {
      errorData.name = "Please Enter Name";
    }

    if (values.title == "") {
      errorData.title = "Please Enter Title";
    }

    if (values.emailId == "") {
      errorData.emailId = "Please Enter Email";
    }

    if (values.schoolName == "") {
      errorData.schoolName = "Please Enter Name of School";
    }

    if (values.address1 == "") {
      errorData.address1 = "Please Enter Address";
    }

    return errorData;
  };

  /**
   * This method is used to close the modal when user click on the close image
   */
  const closeSignupModal = () => {
    setSignupData(signupModelData);
    setError({});
    dispatch(closeModal("signup"));
  };

  return (
    <div className="signupmodal  w-100">
      <Modal isOpen={modal.signup} id="model" contentLabel="Example Modal" ariaHideApp={false}>
        <div>
          <img src={closeImage} className="modal-close-icon" onClick={closeSignupModal} alt="" />
        </div>

        <div className="mb-2" style={{ marginLeft: "10px", color: "white" }}>
          <h5 className="m-0">Sign up to grafixark</h5>
          <p className="loginHeader m-0">Please signup for an account</p>
        </div>

        <form onSubmit={signUpSubmit}>
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div>
                  <label style={{ color: "white" }} className="labels">
                    Name<span className="required-Felid">*</span>
                  </label>

                  <InputTextField
                    type="text"
                    className="text-input w-100 mb-3"
                    error={error.name}
                    name="name"
                    onChange={(e) => signUpChange(e)}
                    value={signupData.name}
                    pattern="[a-zA-Z]*"
                  />
                </div>
                <div>
                  <label style={{ color: "white" }} className="labels">
                    Designation<span className="required-Felid">*</span>
                  </label>

                  <InputTextField
                    type="text"
                    className="text-input w-100 mb-3"
                    name="title"
                    onChange={(e) => signUpChange(e)}
                    error={error.title}
                    value={signupData.title}
                  />
                </div>
                <div>
                  <label style={{ color: "white" }} className="labels">
                    Primary Address<span className="required-Felid">*</span>
                  </label>

                  <InputTextField
                    type="text"
                    className="text-input w-100 mb-3"
                    name="address1"
                    onChange={(e) => signUpChange(e)}
                    value={signupData.address1}
                    error={error.address1}
                  />
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                <div>
                  <div>
                    <label style={{ color: "white" }} className="labels">
                      Name of the Institution / Corporate
                      <span className="required-Felid">*</span>
                    </label>

                    <InputTextField
                      type="text"
                      className="text-input w-100 mb-3"
                      name="schoolName"
                      onChange={(e) => signUpChange(e)}
                      error={error.schoolName}
                      value={signupData.schoolName}
                    />
                  </div>
                  <div>
                    <label style={{ color: "white" }} className="labels">
                      Email Address<span className="required-Felid">*</span>
                    </label>

                    <InputTextField
                      type="email"
                      className="text-input w-100 mb-3"
                      name="emailId"
                      onChange={(e) => signUpChange(e)}
                      error={error.emailId}
                      value={signupData.emailId}
                    />
                  </div>

                  <div>
                    <label style={{ color: "white" }} className="labels">
                      Secondary Address<span className="required-Felid"></span>
                    </label>

                    <InputTextField
                      type="text"
                      className="text-input  w-100 mb-3"
                      name="address2"
                      value={signupData.address2}
                      onChange={(e) => signUpChange(e)}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 col-12 d-flex justify-content-center">
                <button className="signup btn-auth signupModel mt-3">SIGN UP</button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default SignupModal;
