import { combineReducers } from "redux";
import { userReducer } from "../Reducers/userreducer";
import { assetReducer } from "./assetReducer";
import { templateReducer } from "./templateReducer";

export const rootReducer = combineReducers({
  userReducer,
  assetReducer,
  templateReducer,
});
