import construction from "../images/under-Construction.svg";

function pageUnderConstruction() {
  return (
    <div className="customer-info">
      <img src={construction} />
    </div>
  );
}

export default pageUnderConstruction;
